import API from 'api';

export const handleUpdateCampaign = async (
  formData,
  id,
  oldFormValues,
  openNotification,
  backToDashboard,
  userId
) => {
  const campaignData = {
    ...formData,
    userId,
    name: formData.name,
    type: formData.type,
    linkedInAccount: formData?.linkedInAccount,
    linkedInPassword: formData?.linkedInPassword,
    minimumInvites: formData.invites[0],
    maximumInvites: formData.invites[1],
    campaignLink: formData.campaignLink ?? null,
    parsedLeads: formData?.parsedLeads ?? [], // Assign value to empty array if no parsed leads found
    emailFollowUpsSubject: formData.emailFollowUpsSubject || null,
    emailAddress: formData.emailAddress || null,
    emailPassword: formData.emailPassword || null,
    /** On the database the implementation logic is:
     *  TRUE -> We use INCEDO scraper
     *  FALSE -> We use Client scraper
     *  By requirement this value is defaulted to FALSE where the label says "Use my account to..." therefore needs to be flipped.
     */
    useScraperAccounts: !formData.useScraperAccounts,
    //If email campaign type
    smtpHost:
      formData.smtpAddress && formData.smtpPort
        ? `${formData?.smtpAddress}:${formData?.smtpPort}`
        : null,
    imapHost:
      formData.imapAddress && formData.imapPort
        ? `${formData?.imapAddress}:${formData?.imapPort}`
        : null,
  };

  let updateMessages = false;
  if (
    JSON.stringify(formData.initialMessage) !==
      JSON.stringify(oldFormValues.initialMessage) ||
    JSON.stringify(formData.followUps) !==
      JSON.stringify(oldFormValues.followUps) ||
    JSON.stringify(formData.emailFollowups) !==
      JSON.stringify(oldFormValues.emailFollowups) ||
    JSON.stringify(formData.emailSignature) !==
      JSON.stringify(oldFormValues.emailSignature)
  )
    updateMessages = true;

  const updateLeads = campaignData.parsedLeads.length > 0 ? true : false;

  const postObj = {
    campaign: campaignData,
    updateMessages: updateMessages,
    updateLeads: updateLeads,
  };

  try {
    await API.updateCampaign(id, postObj);
  } catch (error) {
    openNotification('error', 'Campaign not edited', error.error);
    return;
  }
  openNotification(
    'success',
    `Campaign edited successfully`,
    `The campaign "${formData.name}" has been edited successfully`
  );
  backToDashboard();
};
