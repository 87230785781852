import routes from 'routes';

import { TargetMarkets } from './TargetMarkets';

export default {
  routeProps: {
    path: routes.targetMarkets,
    component: TargetMarkets,
  },
  name: 'target-markets',
  routeVisibility: 'private',
};
