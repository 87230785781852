import './LeadsTable.styles.css';

import { FileTextOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, message, Popover, Row, Table } from 'antd';
import API from 'api';
import { useDebounce } from 'hooks/useDebounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DownloadFromAPI } from '../../../../components/DownloadFromApi';
import {
  LinkedInLinksRender,
  NameRender,
  StatusRenderer,
} from '../components/Columns/Columns';
import { LinkedinLeadModal } from '../components/LeadModal/LinkedinLeadModal';

export function LinkedinLeadsTable() {
  const [leadModalVisible, setLeadModalVisible] = useState(false);
  const [currentLead, setCurrentLead] = useState(null);
  const [followUpsNo, setFollowUpsNo] = useState(-1);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            maxWidth: 150,
          },
        };
      },
      render: (name, leadData) =>
        NameRender({
          name,
          title: leadData.title,
          imageUrl: leadData.imageUrl,
          showLeadModal: () => {
            setCurrentLead(leadData);
            setLeadModalVisible(true);
          },
        }),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Company',
      dataIndex: 'companyName',
      key: 'companyName',
      responsive: ['md'],
      render: (companyName, { companyUrl }) => {
        if (!companyUrl?.startsWith('http')) return companyName;
        return (
          <a href={companyUrl} target="_blank" rel="noopener noreferrer">
            {companyName}
          </a>
        );
      },
      sorter: (a, b) => a.companyUrl.localeCompare(b.companyUrl),
    },
    {
      title: 'City',
      responsive: ['lg'],
      dataIndex: 'city',
      key: 'city',
      sorter: (a, b) => a.city.localeCompare(b.city),
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      responsive: ['md'],
      render: (country) => <Row justify="center">{country}</Row>,
      sorter: (a, b) => a.country.localeCompare(b.country),
    },
    {
      title: 'Status',
      key: 'status',
      render: (_inviteSentAt, leadData) =>
        StatusRenderer({
          leadData,
          replied: leadData.replied,
          onClick: () => {
            setCurrentLead(leadData);
            setLeadModalVisible(true);
          },
        }),
      filters: [
        {
          text: 'Scraped',
          value: 'Scraped',
        },
        {
          text: 'Invited',
          value: 'Invited',
        },
        {
          text: 'Accepted',
          value: 'Accepted',
        },
        {
          text: 'Replied',
          value: 'Replied',
        },
        {
          text: 'Withdrawn',
          value: 'Withdrawn',
        },
      ],
      filterMode: 'tree',
      filterSearch: true,
      // onFilter: (value, record) => { // This is in server side now }
    },
    {
      title: 'View on LinkedIn',
      render: (_, { linkedInProfileUrl, salesNavProfileUrl }) =>
        LinkedInLinksRender({ linkedInProfileUrl, salesNavProfileUrl }),
    },
  ];

  const { id: campaignId } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [total, setTotal] = useState(1);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
    filters: {
      status: null,
    },
    sorter: {
      field: null,
      order: null,
    },
  });

  const handleTableOnChange = useCallback(
    (pagination, filters, sorter, _extra) => {
      setTableParams(() => ({
        pagination: {
          pageSize: pagination.pageSize,
          current: pagination.current,
        },
        filters,
        sorter: { field: sorter.columnKey, order: sorter.order },
      }));
    },
    []
  );

  const [keyword, setKeyword] = useState('');
  const debouncedKeyword = useDebounce(keyword, 700);

  const fetchLeads = useCallback(async () => {
    setLoading(true);
    try {
      const {
        data: { data: leads, count },
      } = await API.getPaginatedCampaignLeads(
        campaignId,
        {
          page: tableParams.pagination.current,
          limit: tableParams.pagination.pageSize,
        },
        {
          filters: tableParams.filters,
          sorter: tableParams.sorter,
        },
        debouncedKeyword
      );

      setTotal(count);
      setData(leads);
    } catch (error) {
      console.error(error);
      message.error('Could not load the leads');
    } finally {
      setLoading(false);
    }
  }, [tableParams, debouncedKeyword, campaignId]);

  /*
    Fetch leads when the fetchLeads function changes. This is the case when the
    table params (pagination, filters, sorter) or the keyword changes.
  */
  useEffect(() => fetchLeads(), [fetchLeads]);

  const fetchMessages = useCallback(async () => {
    try {
      const { data: messages } = await API.getCampaignMessages(campaignId);
      setFollowUpsNo(messages.length - 1); // Subtr. initial contact msg
    } catch (error) {
      console.warn(error);
      message.warn('Could not get follow-ups length.');
    }
  }, [campaignId]);

  /*
    Fetch the campaign follow-ups length when the fetchMessages function
    changes. This is the case when the campaignId changes.
  */
  useEffect(() => fetchMessages(), [fetchMessages]);

  /* Update the current lead in the dataset when the its updated. */
  const handleOnLeadUpdate = useCallback(
    (updatedLead) => {
      const index = data.findIndex(
        (lead) => lead.leadId === updatedLead.leadId
      );

      if (index === -1) {
        message.error('Lead to update not found');
        return;
      }

      const updatedData = [...data];

      updatedData[index] = updatedLead;

      setData(updatedData);
      setCurrentLead(updatedLead);
    },
    [data]
  );

  const { downloadUrl, filename } = useMemo(() => {
    return {
      downloadUrl: new URL(
        `leads/export/csv/${campaignId}`,
        process.env.REACT_APP_BACKEND_API_URL
      ).href,
      filename: `campaign-${campaignId}-leads-${new Date()
        .toJSON()
        .slice(0, 10)}.csv`,
    };
  }, [campaignId]);

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        rowKey="leadId"
        title={() => (
          <Input
            prefix={<SearchOutlined className="mr-2" />}
            placeholder="Search for leads by name, title, company, city, country or a LinkedIn profile/sales navigator URL"
            allowClear
            onChange={(e) => setKeyword(e.target.value)}
          />
        )}
        summary={() => (
          <Table.Summary.Row>
            <Table.Summary.Cell className="bottom-border-none">
              <Popover
                content={<span>Download all of the leads as a CSV file</span>}
              >
                <DownloadFromAPI
                  url={downloadUrl}
                  filename={filename}
                  buttonProps={{
                    type: 'link',
                    className:
                      'font-semibold text-secondary hover:text-secondary-dark active:text-secondary-light',
                  }}
                >
                  <FileTextOutlined /> <span>Export as CSV</span>
                </DownloadFromAPI>
              </Popover>
            </Table.Summary.Cell>
          </Table.Summary.Row>
        )}
        onChange={handleTableOnChange}
        loading={loading}
        pagination={{
          position: ['topRight', 'bottomRight'],
          current: tableParams.pagination.current,
          pageSize: tableParams.pagination.pageSize,
          total,
          showSizeChanger: true,
          pageSizeOptions: [5, 10, 20, 50, 100, 500],
        }}
      />

      {currentLead && (
        <LinkedinLeadModal
          visible={leadModalVisible}
          hideModal={() => setLeadModalVisible(false)}
          onLeadUpdate={handleOnLeadUpdate}
          data={currentLead}
          followUpsNo={followUpsNo}
        />
      )}
    </>
  );
}
