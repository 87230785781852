import { Radio } from 'antd';
import PropTypes from 'prop-types';

import SubTitle from '../SubTitle';

export const RadioItem = ({
  campaignType,
  img,
  desc,
  title,
  disabled,
  selected,
}) => {
  return (
    <Radio
      value={campaignType}
      disabled={disabled}
      className={`w-full h-26 border-2 border-gray-200 border-solid type-radio ${
        selected
          ? `border-solid border-0 border-l-4 border-t-0 border-b-0 border-r-4 border-yellow-400 bg-yellow-50`
          : 'border-none bg-white'
      } rounded-l-md rounded-r-md`}
    >
      <SubTitle title={title} desc={desc} img={img} />
    </Radio>
  );
};

RadioItem.propTypes = {
  campaignType: PropTypes.string,
  img: PropTypes.any,
  desc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
};
