import { Card, DatePicker, Slider, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

const Filter = (props) => {
  //keep track of the picker state ( open or not )
  const [pickerIsOpen, setPickerisOpen] = useState(false);
  const {
    isOpen,
    onClickOutside,
    viewBy,
    setViewBy,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    viewByValues,
    setViewByValues,
  } = props;
  //click outside handler for the filter to close or open
  const ref = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      const { current: wrap } = ref;
      if (wrap && !wrap.contains(event.target) && isOpen && !pickerIsOpen) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside, isOpen, pickerIsOpen]);

  const { Title } = Typography;
  const { RangePicker } = DatePicker;

  const filtersStyles = {
    width: '85%',
    marginLeft: 'auto',
    marginRight: 'auto',
  };
  // this return the possible viewBy values for the slider
  // depending on the difference between the start & end dates
  const getPossibleSliderValues = (maxOption) => {
    switch (maxOption) {
      case 'days':
        return {
          0: 'Day',
        };
      case 'week':
        return {
          0: 'Day',
          100: 'Week',
        };
      case 'month':
        return {
          0: 'Day',
          50: 'Week',
          100: 'Month',
        };
      case 'quarter':
        return {
          0: 'Day',
          33: 'Week',
          66: 'Month',
          100: 'Quarter',
        };
      case 'year':
        return {
          0: 'Day',
          25: 'Week',
          50: 'Month',
          75: 'Quarter',
          100: 'Year',
        };
      default:
        break;
    }
  };
  useEffect(() => {
    if (startDate && endDate) {
      const days = dayjs.duration(endDate.diff(startDate)).asDays();
      const months = dayjs.duration(endDate.diff(startDate)).asMonths();
      if (months > 12) {
        setViewByValues(getPossibleSliderValues('year'));
        setViewBy(75);
      } else if (months > 8) {
        setViewByValues(getPossibleSliderValues('quarter'));
        setViewBy(66);
      } else if (months > 2) {
        setViewByValues(getPossibleSliderValues('month'));
        setViewBy(50);
      } else if (days > 14) {
        setViewByValues(getPossibleSliderValues('week'));
        setViewBy(100);
      } else {
        setViewByValues(getPossibleSliderValues('days'));
        setViewBy(0);
      }
    }
  }, [startDate, endDate, setViewBy, setViewByValues]);

  return (
    <div
      style={{
        position: 'absolute',
        top: 38,
        right: 0,
        display: isOpen ? 'inline' : 'none',
      }}
      ref={ref}
    >
      <Card title="FILTER" style={{ width: 300 }} size="small">
        <Space direction="vertical" size="middle">
          <div>
            <Title level={5} style={{ opacity: 0.6 }}>
              Period
            </Title>
            <RangePicker
              disabledDate={(d) => !d || d.isAfter(dayjs(new Date()))}
              allowClear={false}
              value={[startDate, endDate]}
              style={{ width: '92%', marginBottom: '10px', marginLeft: '10px' }}
              onChange={(value) => {
                setStartDate(value[0]);
                setEndDate(value[1]);
              }}
              onOpenChange={() => setPickerisOpen(!pickerIsOpen)}
            />
          </div>
          <div>
            <Title level={5} style={{ opacity: 0.6, marginBottom: '12px' }}>
              View by
            </Title>
            <Slider
              marks={viewByValues}
              step={null}
              defaultValue={viewBy}
              value={viewBy}
              onChange={(value) => setViewBy(value)}
              style={{ ...filtersStyles }}
              tooltip={{ open: false }}
            />
          </div>
        </Space>
      </Card>
    </div>
  );
};

Filter.propTypes = {
  isOpen: PropTypes.bool,
  onClickOutside: PropTypes.func,
  viewByValues: PropTypes.object,
  setViewByValues: PropTypes.func,
  viewBy: PropTypes.number,
  setViewBy: PropTypes.func,
  startDate: PropTypes.objectOf(dayjs),
  endDate: PropTypes.objectOf(dayjs),
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
};

export default Filter;
