import { Skeleton, Statistic } from 'antd';
import { Card } from 'components/Card';
import PropTypes from 'prop-types';

export default function StatisticItem({
  title,
  icon,
  value,
  percentage,
  color,
}) {
  return (
    <Card shadow shadowOnHover className="flex-1">
      <Skeleton loading={value === undefined} active paragraph={{ rows: 1 }}>
        <Statistic
          title={title}
          value={isNaN(percentage) ? 0 : Math.round(percentage)}
          valueStyle={{ color }}
          prefix={icon ?? icon}
          suffix={
            <>
              <span style={{ fontSize: '17px', fontWeight: 'bold' }}>%</span>{' '}
              <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                {value} leads
              </span>
            </>
          }
        />
      </Skeleton>
    </Card>
  );
}

StatisticItem.propTypes = {
  /** Title of the card item */
  title: PropTypes.string,
  /** The react node to use as icon */
  icon: PropTypes.node,
  /** Displayed value, either a number or an 'NA' string */
  value: PropTypes.any,
  /** Displayed percentage, either a number or an 'NA' string */
  percentage: PropTypes.any,
  /** The base color oft he card item */
  color: PropTypes.string,
};
