import { Form, message } from 'antd';
import API from 'api';
import FormLabel from 'modules/Campaign/components/Form/FormLabel';
import FormSection from 'modules/Campaign/components/Form/FormSection';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  areObjectsEqual,
  filterBlacklistedLeads,
  filterDuplicatesInArray,
} from 'services/campaignForm';
import {
  getTypeDescription,
  isCSV,
  isExistingCampaignType,
  isLinkedInCSV,
  isOutreachSalesNav,
} from 'services/campaignType';

import FollowUpReview from '../../../components/Form/FollowUpReview';
import TextReview from '../../../components/Form/TextReview';
import { BlacklistedLeadsAlert } from '../../EditEmailCampaign/components/BlacklistedLeadsAlert';
import { DuplicateLeadsAlert } from '../../EditEmailCampaign/components/DuplicateLeadsAlert';

//EDIT CAMPAIGN

export const LinkedinReview = ({ campaign }) => {
  const form = Form.useFormInstance();

  const [formValues, setFormValues] = useState(form.getFieldsValue(true));
  const [duplicates, setDuplicates] = useState([]);
  const [isCheckingDuplicates, setIsCheckingDuplicates] = useState(false);

  /** If an UNDO action is performed we reflect the changes in the form object */
  useEffect(() => {
    form.setFieldsValue(formValues);
    // eslint-disable-next-line
  }, [formValues]);

  useEffect(() => {
    /**
     * IF the campaign is CSV type and we have leads in the parsedLeads field of the form object...
     * We want to check if any of the leads already is present on the DB. If that's the case, we filter the duplicates and update the parsedLeads field.
     */
    if (isLinkedInCSV(formValues.type) && formValues.parsedLeads.length > 0) {
      const getAllLeadsByCampaignId = async () => {
        try {
          setIsCheckingDuplicates(true);
          const { data: leads } = await API.getAllLeadsByCampaignId(
            formValues.id
          );
          const newLeads = checkNewLeads(leads, 'linkedinProfileUrl');
          setIsCheckingDuplicates(false);
          form.setFieldValue('parsedLeads', newLeads);
        } catch (error) {
          console.log(error);
        }
      };
      getAllLeadsByCampaignId();
    }

    // eslint-disable-next-line
  }, []);

  const checkNewLeads = (leads, key) => {
    const result = filterDuplicatesInArray(leads, formValues, key);
    setDuplicates(result.duplicateLeads);
    return result.filteredLeads;
  };

  // Blacklist checking
  const [numOfBlacklisted, setNumOfBlacklisted] = useState(0);
  const [isCheckingBlacklisted, setIsCheckingBlacklisted] = useState(false);

  const checkBlacklistedLeads = async () => {
    try {
      setIsCheckingBlacklisted(true);
      const profileUrls = formValues.parsedLeads.map(
        (lead) => lead.linkedInProfileUrl
      );
      const numOfEntries = await filterBlacklistedLeads(
        profileUrls,
        'LEAD_LINKEDIN'
      );
      setIsCheckingBlacklisted(false);

      setNumOfBlacklisted(numOfEntries);
    } catch (error) {
      console.log(error);
      message.error('Error occured while checking for blacklists');
    }
  };

  useEffect(() => {
    checkBlacklistedLeads();

    // eslint-disable-next-line
  }, [formValues]);

  const linkedInFollowUps = formValues?.followUps;
  const hasLinkedInFollowUps =
    linkedInFollowUps && linkedInFollowUps.length > 0;
  const hasLinkedInFollowUpsChanged = !areObjectsEqual(
    linkedInFollowUps,
    campaign.followUps
  );

  const emailFollowUps = formValues?.emailFollowups;
  const hadEmailFollowUps = campaign?.emailFollowups?.length > 0;
  const hasEmailFollowUps = emailFollowUps && emailFollowUps.length > 0;
  const hasEmailFollowUpsChanged = !areObjectsEqual(
    emailFollowUps,
    campaign.emailFollowups
  );

  return (
    <div className="flex flex-col gap-2 py-4 fade-in">
      <FormLabel>
        Please review the informations then click submit to modify your
        campaign.
      </FormLabel>

      <FormSection title={'Campaign basic information'}>
        <TextReview
          title={'Campaign name'}
          value={formValues?.name}
          formValue={formValues?.name}
          campValue={campaign.name}
          fieldName={'name'}
          setFormValues={setFormValues}
        />

        <TextReview
          title={'Campaign type'}
          value={getTypeDescription(formValues?.type)}
        />

        {!isCSV(formValues?.type) && (
          <TextReview
            title={'LinkedIn URL'}
            value={
              <a
                href={formValues?.campaignLink}
                target="_blank"
                rel="noreferrer"
              >
                {formValues?.campaignLink}
              </a>
            }
            formValue={formValues?.campaignLink}
            campValue={campaign?.campaignLink}
            fieldName={'campaignLink'}
            setFormValues={setFormValues}
          />
        )}

        {isLinkedInCSV(formValues.type) && formValues.parsedLeads.length > 0 && (
          <TextReview
            title={'Added Leads'}
            value={
              <div className="flex items-center ">
                <div>{formValues.parsedLeads.length ?? 0}</div>
                <div className="flex flex-col justify-start items-start">
                  {(isCheckingDuplicates || isCheckingBlacklisted) && (
                    <span className="ml-4 text-secondary">Validating...</span>
                  )}
                  {duplicates.length > 0 && (
                    <DuplicateLeadsAlert
                      duplicates={duplicates}
                      objkey={'linkedInProfileUrl'}
                    />
                  )}
                  {!!numOfBlacklisted && (
                    <BlacklistedLeadsAlert number={numOfBlacklisted} />
                  )}
                </div>
              </div>
            }
          />
        )}
      </FormSection>

      <FormSection
        title={
          !isExistingCampaignType(formValues?.type)
            ? 'Connection Invites'
            : 'First Message Setup'
        }
      >
        <TextReview
          title={'LinkedIn account'}
          value={formValues?.linkedInAccount}
        />

        {isOutreachSalesNav(formValues?.type) && (
          <TextReview
            title={'Scraper Account'}
            value={
              formValues.useScraperAccounts ? 'User account' : 'Incedo account'
            }
          />
        )}

        <TextReview
          title={
            !isExistingCampaignType(formValues?.type)
              ? 'Daily invites range'
              : 'Daily messages range'
          }
          value={`${formValues?.invites[0]} to ${formValues?.invites[1]}`}
          formValue={JSON.stringify(formValues?.invites)}
          campValue={JSON.stringify(campaign.invites)}
          fieldName={'invites'}
          setFormValues={setFormValues}
        />

        {formValues?.initialMessage.femaleSalutation && (
          <TextReview
            title={`Women's salutation`}
            value={formValues?.initialMessage.femaleSalutation}
            formValue={formValues?.initialMessage.femaleSalutation}
            campValue={campaign.initialMessage?.femaleSalutation}
            fieldName={['initialMessage', 'femaleSalutation']}
            setFormValues={setFormValues}
          />
        )}

        {formValues?.initialMessage.maleSalutation && (
          <TextReview
            title={`Men's salutation`}
            value={formValues?.initialMessage.maleSalutation}
            formValue={formValues?.initialMessage.maleSalutation}
            campValue={campaign.initialMessage?.maleSalutation}
            fieldName={['initialMessage', 'maleSalutation']}
            setFormValues={setFormValues}
          />
        )}

        <TextReview
          title={'Message content'}
          value={formValues?.initialMessage.content}
          formValue={formValues?.initialMessage.content}
          campValue={campaign.initialMessage.content}
          fieldName={['initialMessage', 'content']}
          setFormValues={setFormValues}
        />
      </FormSection>

      {hasLinkedInFollowUps || hasLinkedInFollowUpsChanged ? (
        <FormSection
          title={`LinkedIn follow ups (${linkedInFollowUps?.length || 0})`}
        >
          {hasLinkedInFollowUps ? (
            <>
              {hasLinkedInFollowUpsChanged && (
                <TextReview
                  value={'Follow Ups have been changed'}
                  formValue={JSON.stringify(linkedInFollowUps)}
                  campValue={JSON.stringify(campaign.followUps)}
                  fieldName={'followUps'}
                  setFormValues={setFormValues}
                />
              )}
              {linkedInFollowUps.map((followUp, idx) => (
                <FollowUpReview key={idx} msg={followUp} idx={idx} />
              ))}
            </>
          ) : hasLinkedInFollowUpsChanged ? (
            <TextReview
              value={'Follow ups have been deleted'}
              formValue={JSON.stringify(linkedInFollowUps)}
              campValue={JSON.stringify(campaign.followUps)}
              fieldName={'followUps'}
              setFormValues={setFormValues}
            />
          ) : null}
        </FormSection>
      ) : null}

      {hasEmailFollowUps || hasEmailFollowUpsChanged ? (
        <FormSection
          title={`Email follow ups (${emailFollowUps?.length || 0})`}
        >
          {hasEmailFollowUps ? (
            <>
              {hasEmailFollowUpsChanged && (
                <TextReview
                  value={'Emails have been changed'}
                  formValue={JSON.stringify(formValues?.emailFollowups)}
                  campValue={JSON.stringify(campaign.emailFollowups)}
                  fieldName={'emailFollowups'}
                  setFormValues={setFormValues}
                />
              )}

              <TextReview
                title={'Email account'}
                value={formValues?.emailAddress}
              />

              <TextReview
                title={'Email subject'}
                value={formValues?.emailFollowUpsSubject}
                formValue={
                  hadEmailFollowUps && formValues?.emailFollowUpsSubject
                }
                campValue={hadEmailFollowUps && campaign.emailFollowUpsSubject}
                fieldName={'emailFollowUpsSubject'}
                setFormValues={setFormValues}
              />

              {formValues.emailFollowups.map((followUp, idx) => (
                <FollowUpReview key={idx} msg={followUp} idx={idx} />
              ))}
            </>
          ) : (
            hasEmailFollowUpsChanged && (
              <TextReview
                value={'Emails have been deleted'}
                formValue={JSON.stringify(formValues?.emailFollowups)}
                campValue={JSON.stringify(campaign.emailFollowups)}
                fieldName={'emailFollowups'}
                setFormValues={setFormValues}
              />
            )
          )}
        </FormSection>
      ) : null}
    </div>
  );
};

LinkedinReview.propTypes = {
  campaign: PropTypes.any.isRequired,
};
