import api from 'api';

import { isCSV, isEmailTargetMargetCampaignType } from './campaignType';

/** In order to populate the form properly once a campaign is selected to be edited, we need to parse the data (names of the fields) from the DB to the Form.
 * This function recreates the form instance already populated, feeding the "initialValues" of the Form with this returning obj
 */
export const parseCampaignIntoForm = (campaign) => {
  if (campaign === null) return null;

  let newObj = {};
  /* Basic Info */
  newObj['id'] = campaign.id;
  newObj['type'] = campaign.type;
  newObj['name'] = campaign.name;

  /* CSV campaigns have no link, therefore the check */
  newObj['campaignLink'] = Object.hasOwn(campaign, 'campaignLink')
    ? campaign.campaignLink
    : '';

  /* Only for CSV campaigns */
  newObj['parsedLeads'] = isCSV(campaign.type) ? [] : null;

  /* Invites is number[] in the form, therefore the change */
  newObj['invites'] = [campaign.minimumInvites, campaign.maximumInvites];

  newObj['numOfLeads'] = campaign.numOfLeads;

  /**
   * TWIMC: On the database the implementation logic is:
   * TRUE -> We use INCEDO scraper
   * FALSE -> We use Client scraper
   * By requirement this value is defaulted to FALSE where the label says "Use my account to..." therefore needs to be flipped.
   */
  newObj['useScraperAccounts'] = !campaign.useScraperAccounts;

  /* Email Template data (address && subject) */
  /* Email MUST exist in EmailType campaign, although can be null in LinkedInType */
  if (campaign?.email) {
    if (campaign.email.address) {
      newObj['emailAddress'] = campaign.email.address;
    }

    if (campaign.email.password) {
      newObj['emailPassword'] = campaign.email.password;
    }

    newObj['emailFollowUpsSubject'] = campaign.emailFollowUpsSubject
      ? campaign.emailFollowUpsSubject
      : '';

    //Smtp settings
    if (campaign.email.smtpHost) {
      const [address, port] = campaign.email.smtpHost.split(':');
      newObj['smtpAddress'] = address;
      newObj['smtpPort'] = port;
    }

    //Imap settings
    if (campaign.email.imapHost) {
      const [address, port] = campaign.email.imapHost.split(':');
      newObj['imapAddress'] = address;
      newObj['imapPort'] = port;
    }
  }

  /* Messages */
  const campaignMessages = campaign['messages'];
  const initialMessage = campaignMessages?.find((m) => m.order === 0);
  newObj['initialMessage'] = {
    femaleSalutation: initialMessage?.femaleSalutation,
    maleSalutation: initialMessage?.maleSalutation,
    content: initialMessage?.content,
  };

  const followUps = campaignMessages.filter(
    (m) => m.type === 'LINKEDIN' && m.order != 0
  );

  if (followUps.length) {
    newObj.followUps = followUps;
  }

  const emailFollowups = campaignMessages.filter(
    (m) => m.type === 'EMAIL' && m.order != 0
  );

  if (emailFollowups.length) {
    newObj.emailFollowups = emailFollowups;
  }

  const emailSignature = campaignMessages.find(
    (m) => m.type === 'EMAIL_SIGNATURE'
  );
  newObj['emailSignature'] = emailSignature?.content ?? '';

  const linkedInAccountObj = campaign.linkedInAccount
    ? campaign.linkedInAccount
    : null;
  if (linkedInAccountObj) newObj['linkedInAccount'] = linkedInAccountObj.email;

  if (isEmailTargetMargetCampaignType)
    newObj['targetMarketId'] = campaign.targetMarketId;

  return newObj;
};

const stringifiedFields = ['invites', 'followUps', 'emailFollowups'];

export const resetValue = (value, fieldName, setFormValues) => {
  /** equality of two Array containing same values return false because the reference is different.
   * Comparing the stringified version of the arrays solves the issue. Hence in order to reset the value the string needs to be reverted to array
   */
  !stringifiedFields.includes(fieldName)
    ? setFormValues((prevState) => ({ ...prevState, [fieldName]: value }))
    : setFormValues((prevState) => ({
        ...prevState,
        [fieldName]: typeof value === 'string' ? JSON.parse(value) : value,
      }));
};

/**
 * Some values are nested, therefore the use of this helper to reset the values on the click on UNDO
 */
export const resetNestedValue = (value, field, nestedField, setFormValues) => {
  setFormValues((prevState) => ({
    ...prevState,
    [field]: { ...prevState[field], [nestedField]: value },
  }));
};

/**
 * Filters an array and gets the duplicated entries by a specific key: value pair
 * @param {*} leads
 * @param {*} formValues Value of the parsedLead coming from the form (leads parsed from a file)
 * @param {*} key :string - Object key defining the key: value pair on which filter
 * @returns
 */
export const filterDuplicatesInArray = (leads, formValues, key) => {
  const oldList = leads.map((element) => element[key]);
  const duplicateLeads = [];
  const filteredLeads = formValues.parsedLeads.flatMap((lead) => {
    if (oldList.includes(lead[key])) {
      duplicateLeads.push(lead);
      return [];
    }
    return lead;
  });
  return { filteredLeads, duplicateLeads };
};

export const filterBlacklistedLeads = async (leads, type) => {
  try {
    const res = await api.getBlacklistedFromKeywords(leads, type);
    const { numOfEntries } = await res.data;

    return numOfEntries;
  } catch (error) {
    throw new Error('Blacklist entries check failed');
  }
};

export const areObjectsEqual = (object1, object2) => {
  return JSON.stringify(object1) === JSON.stringify(object2);
};

/**
 * Using the signature object creates a preview of it depending on the field typed
 * @returns the text that previews the signature
 */
export function renderSignaturePreview(s) {
  if (s === undefined) return '';
  let text = '';
  const nl = '\n';

  // New Line after Name and Surname if the line exists | If exists one line after as well
  const first = s.firstname ? s.firstname + ' ' + s.lastname : s.lastname;
  if (first) text += first + nl + nl;
  // Title and Company on same line with a new line if exists
  const second = s.title ? s.title + ' ' + s.company : s.company;
  if (second) text += second + nl;
  if (s.website) text += s.website + nl;
  if (s.number) text += s.number + nl;
  if (text) text += nl;
  if (s.extra1) text += s.extra1 + nl;
  if (s.extra2) text += s.extra2 + nl;
  return text;
}
