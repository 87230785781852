import {
  isExistingCampaignType,
  isOutreachCampaignType,
} from 'services/campaignType';

const bRadius = 5;

export const generateData = (records, firstObjKey, secondObjKey) =>
  records.map((item) =>
    item.connected === 0
      ? 0
      : Math.round((item[firstObjKey] / item[secondObjKey]) * 100)
  );

export function lineDataToDisplayOutreachType(formattedRecords, campaignType) {
  return (
    !isExistingCampaignType(campaignType) && [
      {
        label: 'Connected',
        data: generateData(formattedRecords, 'connected', 'requested'),
        borderColor: 'rgba(55, 83, 97, 1)',
        backgroundColor: 'rgba(55, 83, 97, .5)',
      },
      {
        label: 'Replied',
        data: generateData(formattedRecords, 'replied', 'connected'),
        borderColor: 'rgba(25, 118, 78, 1)',
        backgroundColor: 'rgba(25, 118, 78, 0.5)',
      },
      {
        label: 'Converted',
        data: generateData(formattedRecords, 'replied', 'requested'),
        borderColor: 'rgb(13, 148, 136)',
        backgroundColor: 'rgba(25, 118, 78, 1)',
      },
    ]
  );
}

export function columnDataToDisplayLinkedinType(
  formattedRecords,
  campaignType
) {
  return isOutreachCampaignType(campaignType)
    ? [
        {
          label: 'Requested',
          data: formattedRecords.map((item) => item.requested),
          backgroundColor: 'rgba(215, 166, 0, 1)',
          borderRadius: bRadius,
        },
        {
          label: 'Connected',
          data: formattedRecords.map((item) => item.connected),
          backgroundColor: 'rgba(55, 83, 97, 1)',
          borderRadius: bRadius,
        },
        {
          label: 'Replied',
          data: formattedRecords.map((item) => item.replied),
          backgroundColor: 'rgba(25, 118, 78, 1)',
          borderRadius: bRadius,
        },
      ]
    : [
        {
          label: 'Contacted',
          data: formattedRecords.map((item) => item.requested),
          backgroundColor: 'rgba(215, 166, 0, 1)',
          borderRadius: bRadius,
        },
        {
          label: 'Replied',
          data: formattedRecords.map((item) => item.replied),
          backgroundColor: 'rgba(25, 118, 78, 1)',
          borderRadius: bRadius,
        },
      ];
}

export function columnDataToDisplayEmailType(formattedRecords, _) {
  return [
    {
      label: 'Contacted',
      data: formattedRecords.map((item) => item.requested),
      backgroundColor: 'rgba(215, 166, 0, 1)',
      borderRadius: bRadius,
    },
    {
      label: 'Opened',
      data: formattedRecords.map((item) => item.connected),
      backgroundColor: 'rgba(55, 83, 97, 1)',
      borderRadius: bRadius,
    },
    {
      label: 'Replied',
      data: formattedRecords.map((item) => item.replied),
      backgroundColor: 'rgba(25, 118, 78, 1)',
      borderRadius: bRadius,
    },
  ];
}
