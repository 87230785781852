import { type } from '../services/campaignType';

export const filterStatus = [
  {
    text: 'Active',
    value: 'ACTIVE',
  },
  {
    text: 'Paused',
    value: 'STOPPED',
  },
];

export const filterCampaignType = [
  {
    text: 'Outreach Sales Navigator',
    value: type.OUT_SAL,
  },
  {
    text: 'Outreach CSV',
    value: type.OUT_CSV,
  },
  {
    text: 'Existing Sales Navigator',
    value: type.EX_SAL,
  },
  {
    text: 'Existing Linkedin',
    value: type.EX_LIN,
  },
  {
    text: 'Existing CSV',
    value: type.EX_CSV,
  },
];

export const HEADER_SEARCH_LABEL =
  'Search by Campaign name, Campaign Type, Company or User email';
