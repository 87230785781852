import { RollbackOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import classNames from 'classnames';
import { ACCENT_COLOR } from 'constant/styles';
import PropTypes from 'prop-types';
import { resetNestedValue, resetValue } from 'services/campaignForm';

/** Component to show the UNDO button on the campaign edit review steps */
const TextReview = ({
  title,
  value,
  formValue,
  campValue,
  fieldName,
  setFormValues,
  onRevert,
  className,
}) => {
  const changed = formValue !== campValue;

  return (
    <div className="flex flex-wrap my-3">
      {title && (
        <div className="w-1/3 min-w-[10rem] flex-shrink-0 text-left text-gray-500">
          {title}
        </div>
      )}
      <div className="flex-1 flex items-center">
        <div
          className={classNames(
            'min-w-[10rem] flex-1 break-word line-clamp-2',
            {
              'bg-yellow-100 px-2 py-1 rounded': changed,
              '-my-1 -mx-2': changed && title,
            },
            className
          )}
        >
          {value}
        </div>
        {formValue !== campValue && (
          <Tooltip title="Revert changes" className="text-xs sm:text-sm ml-2">
            <Button
              style={{ color: ACCENT_COLOR }}
              type="link"
              size="small"
              icon={<RollbackOutlined />}
              onClick={
                onRevert ||
                (() =>
                  Array.isArray(fieldName)
                    ? resetNestedValue(
                        campValue,
                        fieldName[0],
                        fieldName[1],
                        setFormValues
                      )
                    : resetValue(campValue, fieldName, setFormValues))
              }
            >
              Undo
            </Button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default TextReview;

TextReview.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  formValue: PropTypes.any,
  campValue: PropTypes.any,
  fieldName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  setFormValues: PropTypes.func,
  onRevert: PropTypes.func,
  className: PropTypes.string,
};
