import { Form, message } from 'antd';
import FollowUpReview from 'modules/Campaign/components/Form/FollowUpReview';
import FormLabel from 'modules/Campaign/components/Form/FormLabel';
import FormSection from 'modules/Campaign/components/Form/FormSection';
import { Text } from 'modules/Campaign/components/Form/Text';
import { BlacklistedLeadsAlert } from 'modules/Campaign/EditCampaign/EditEmailCampaign/components/BlacklistedLeadsAlert';
import { useEffect, useState } from 'react';
import { filterBlacklistedLeads } from 'services/campaignForm';
import {
  getTypeDescription,
  isCSV,
  isExistingCampaignType,
  isOutreachCampaignType,
} from 'services/campaignType';

export const LinkedinTypeReview = () => {
  const form = Form.useFormInstance();
  const formValues = form.getFieldsValue(true);
  // Blacklist Checking
  const [numOfBlacklisted, setNumOfBlacklisted] = useState(0);
  const [isCheckingBlacklisted, setIsCheckingBlacklisted] = useState(false);

  const checkBlacklistedLeads = async () => {
    try {
      setIsCheckingBlacklisted(true);
      const profileUrls = formValues.parsedLeads.map(
        (lead) => lead.linkedInProfileUrl
      );
      const numOfEntries = await filterBlacklistedLeads(
        profileUrls,
        'LEAD_LINKEDIN'
      );
      setIsCheckingBlacklisted(false);

      setNumOfBlacklisted(numOfEntries);
    } catch (error) {
      console.log(error);
      message.error('Error occured while checking for blacklists');
    }
  };

  useEffect(() => {
    checkBlacklistedLeads();

    // eslint-disable-next-line
  }, [formValues]);

  return (
    <div className="flex flex-col gap-2 pt-4 fade-in">
      <FormLabel>
        Please review the informations then click submit to create your
        campaign.
      </FormLabel>

      <FormSection title={'Campaign basic information'}>
        <Text title={'Campaign name'} value={formValues.name} />
        <Text
          title={'Campaign type'}
          value={getTypeDescription(formValues.type) || ''}
        />
        {isOutreachCampaignType(formValues.type) && (
          <Text
            title={'Scraper Account'}
            value={
              formValues.useScraperAccounts ? 'User account' : 'Incedo account'
            }
          />
        )}
        {isCSV(formValues.type) ? (
          <>
            <Text title={'CSV File'} value={formValues.file.file.name} />
            <Text
              title={'Leads to create'}
              value={
                <div className="flex items-center ">
                  {isCheckingBlacklisted && (
                    <span className="ml-4 text-secondary">Validating...</span>
                  )}
                  <div>{formValues.parsedLeads.length ?? 0}</div>
                  <div className="flex flex-col justify-start items-start">
                    {!!numOfBlacklisted && (
                      <BlacklistedLeadsAlert number={numOfBlacklisted} />
                    )}
                  </div>
                </div>
              }
            />
          </>
        ) : (
          <Text
            title={'LinkedIn URL'}
            value={
              <a
                href={formValues?.campaignLink}
                target="_blank"
                rel="noreferrer"
                className="overflow-hidden text-secondary hover:text-secondary-dark break-word line-clamp-2"
              >
                {formValues?.campaignLink}
              </a>
            }
          />
        )}
      </FormSection>

      <FormSection
        title={
          !isExistingCampaignType(formValues.type)
            ? 'Connection Invites'
            : 'First Message Setup'
        }
      >
        <Text title={'LinkedIn account'} value={formValues.linkedInAccount} />
        <Text
          title={
            !isExistingCampaignType(formValues.type)
              ? 'Daily invites range'
              : 'Daily messages range'
          }
          value={`${formValues?.invites[0]} to ${formValues?.invites[1]}`}
        />
        {formValues?.initialMessage.femaleSalutation && (
          <Text
            title={`Women's salutation`}
            value={formValues.initialMessage.femaleSalutation}
          />
        )}
        {formValues?.initialMessage.maleSalutation && (
          <Text
            title={`Men's salutation`}
            value={formValues.initialMessage.maleSalutation}
          />
        )}
        <Text
          title={'Message content'}
          value={formValues?.initialMessage.content}
        />
      </FormSection>

      {formValues.followUps && formValues.followUps.length > 0 && (
        <FormSection
          title={`LinkedIn follow ups (${formValues.followUps.length})`}
        >
          {formValues.followUps.map((followUp, idx) => (
            <FollowUpReview key={idx} msg={followUp} idx={idx} />
          ))}
        </FormSection>
      )}

      {formValues.emailFollowups && formValues.emailFollowups.length > 0 && (
        <FormSection
          title={`Email follow ups (${formValues.emailFollowups.length})`}
        >
          <Text title={'Email account'} value={formValues.emailAddress} />
          <Text
            title={'Email subject'}
            value={formValues.emailFollowUpsSubject}
          />
          {formValues.emailFollowups.map((followUp, idx) => (
            <FollowUpReview key={idx} msg={followUp} idx={idx} />
          ))}
        </FormSection>
      )}
    </div>
  );
};
