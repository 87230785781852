import { InfoCircleFilled } from '@ant-design/icons';
import classNames from 'classnames';
import { Card } from 'components/Card';
import { IconWithText } from 'components/IconWithText';
import { List } from 'components/List';
import PropTypes from 'prop-types';

export const InfoEmailSignature = ({ className }) => {
  return (
    <Card
      background="bg-gray-100"
      borderRadius="rounded-md"
      className={classNames('text-xs text-gray-500', className)}
    >
      <IconWithText
        icon={() => <InfoCircleFilled className="text-secondary mt-1" />}
        className="mb-2"
        alignment="items-start"
      >
        Please edit the signature associated to the email address used for this
        campaign. Please keep in mind that your signature:
      </IconWithText>
      <List
        items={[
          'should be short and concise should NOT contain logos',
          'should NOT contain logos, too many links and/or too much formatting (these features increase the risk of your email landing in spam folders)',
        ]}
      />
    </Card>
  );
};

InfoEmailSignature.propTypes = {
  className: PropTypes.string,
};
