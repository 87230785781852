import { Progress } from 'antd';
import { PropTypes } from 'prop-types';
import { calcPercent } from 'utils/helpers';

const ProgressStat = ({ divider, dividend, label = '' }) => {
  return (
    <div className="flex flex-col justify-around align-middle">
      <Progress
        strokeColor="#375361"
        type="dashboard"
        status="normal"
        percent={calcPercent(divider, dividend)}
        size="small"
        width={80}
      />
      <div className="text-xs text-gray-500 uppercase">{label}</div>
    </div>
  );
};

export default ProgressStat;

ProgressStat.propTypes = {
  divider: PropTypes.number.isRequired,
  dividend: PropTypes.number.isRequired,
  label: PropTypes.string,
};
