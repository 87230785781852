import PropTypes from 'prop-types';

const SubTitle = ({ img, title, desc }) => {
  return (
    <div
      className={
        'flex flex-row opacity-90 justify-between items-center align-middle hover:opacity-100 my-1'
      }
    >
      <div className="grid h-8 place-items-center">
        <img className="h-6 mx-2" src={img} alt="leadsLogo" />
      </div>

      <div className="flex flex-col p-2">
        <div className="text-base font-medium text-gray-600">{title}</div>
        <span className="text-gray-500 text-md">{desc}</span>
      </div>
    </div>
  );
};

export default SubTitle;

SubTitle.propTypes = {
  img: PropTypes.any,
  desc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
