import { Button, Form, Input, message, notification } from 'antd';
import { Layout } from 'components/Layout';
import { Steps } from 'components/Steps';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import routes from 'routes';
import {
  isEmailCampaignType,
  isEmailTargetMargetCampaignType,
} from 'services/campaignType';

import { useUser } from '../../../hooks/useUser';
import { CampaignTypePicker } from '../components/Form/CampaignTypePicker';
import { FormButtons } from '../components/Form/FormButtons';
import { FormCard } from '../components/Form/FormCard';
import { SubHeader } from '../components/SubHeader';
import { TargetMarkets } from '../TargetMarkets/TargetMarkets';
import { submitCampaignCreation } from './helpers';
import { BasicEmailInfo } from './Steps/EmailType/steps/BasicEmailInfo';
import { EmailCampaignSetup } from './Steps/EmailType/steps/EmailCampaignSetup';
import { EmailTypeReview } from './Steps/EmailType/steps/EmailTypeReview';
import { BasicLinkedinInfo } from './Steps/LinkedInType/BasicLinkedinInfo';
import { LinkedinCampaignSetup } from './Steps/LinkedInType/LinkedinCampaignSetup';
import { LinkedinTypeReview } from './Steps/LinkedInType/LinkedinTypeReview';
import { EmailFollowups } from './Steps/Shared/EmailFollowups';
import { LinkedInFollowups } from './Steps/Shared/LinkedInFollowups';

export function CreateCampaign() {
  const openNotification = (type, title, desc) => {
    notification[type]({
      message: title,
      description: desc,
    });
  };

  // Steps state & handlers
  const [current, setCurrent] = useState(0);

  const [campaignForm] = Form.useForm();
  const [isSetup, setIsSetup] = useState(false);

  const selectedType = Form.useWatch('type', campaignForm);

  /** @type import('../../../components/Steps/index').StepsProps['steps'] */
  const steps = useMemo(() => {
    if (isEmailCampaignType(selectedType)) {
      return [
        isEmailTargetMargetCampaignType(selectedType) && {
          title: 'Target Market',
          content: (
            <TargetMarkets
              setCurrent={setCurrent}
              setIsSetup={setIsSetup}
              isInForm
            />
          ),
        },
        { title: 'Account Information', content: <BasicEmailInfo /> },
        { title: 'Campaign setup', content: <EmailCampaignSetup /> },
        { title: 'Email Follow Ups', content: <EmailFollowups /> },
        { title: 'Review', content: <EmailTypeReview /> },
      ].filter(Boolean);
    }

    return [
      { title: 'Account Information', content: <BasicLinkedinInfo /> },
      { title: 'Campaign setup', content: <LinkedinCampaignSetup /> },
      { title: 'LinkedIn follow ups', content: <LinkedInFollowups /> },
      { title: 'Email follow ups', content: <EmailFollowups /> },
      { title: 'Review', content: <LinkedinTypeReview /> },
    ];
  }, [selectedType]);

  const navigate = useNavigate();
  const backToDashboard = () => {
    navigate(routes.dashboard);
  };

  const { user } = useUser();
  // Handle campaign creation submit
  const handleSubmit = async () => {
    if (!isSetup) {
      campaignForm.setFieldValue('type', selectedType);
      setIsSetup(true);
      return;
    }

    if (current < steps.length - 1) {
      setCurrent(current + 1);
      return;
    }

    const formData = campaignForm.getFieldsValue(true);
    submitCampaignCreation(
      formData,
      openNotification,
      backToDashboard,
      user?.id
    );
  };

  return (
    <Layout>
      <SubHeader title={'Create New Campaign'} />
      <Form
        name="newCampaign"
        layout="vertical"
        form={campaignForm}
        onFinish={handleSubmit}
        onFinishFailed={(formErrors) => {
          formErrors.errorFields.map((e) => message.error(e.errors));
        }}
        scrollToFirstError={{
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        }}
      >
        {/*
          This is needed because we conditionally show the CampaignTypePicker
          which includes the Form.Item with the name "type". If we don't include
          this hidden Form.Item the value will be removed from the form when the
          CampaignTypePicker is removed.
        */}
        <Form.Item hidden name="type">
          <Input hidden />
        </Form.Item>

        <FormCard>
          {!isSetup ? (
            <CampaignTypePicker />
          ) : (
            <>
              <Steps
                steps={steps}
                currentStep={current}
                className="mt-2 mb-4 sm:mt-4 sm:mb-6"
              />
              {steps[current].content}
            </>
          )}

          {!(
            isEmailTargetMargetCampaignType(selectedType) &&
            isSetup &&
            current === 0
          ) && (
            <FormButtons>
              {!isSetup ? (
                <Button onClick={backToDashboard}>Cancel</Button>
              ) : (
                <Button
                  onClick={() =>
                    current === 0 ? setIsSetup(false) : setCurrent(current - 1)
                  }
                >
                  Previous
                </Button>
              )}
              <Button type="primary" htmlType="submit" disabled={!selectedType}>
                {isSetup
                  ? current === steps.length - 1
                    ? 'Submit'
                    : 'Next'
                  : 'Start'}
              </Button>
            </FormButtons>
          )}
        </FormCard>
      </Form>
    </Layout>
  );
}
