import 'antd/dist/reset.css';
import './App.styles.css';

import { ConfigProvider } from 'antd';
import { Header } from 'components/Header/Header';
import { QueryNotifications } from 'components/QueryNotifications';
import { ACCENT_COLOR } from 'constant/styles';
import { UserProvider } from 'hooks/useUser';
import { HashRouter as Router } from 'react-router-dom';

import { AppRoutes } from './components/AppRoutes';
import { ForUsers } from './components/ForUsers';
import { GraphQLProvider } from './hooks/graphQl';

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: ACCENT_COLOR,
          borderRadius: 5,
        },
      }}
    >
      <UserProvider>
        <GraphQLProvider>
          <Router>
            <QueryNotifications />
            <ForUsers>
              <Header />
            </ForUsers>
            <div className="flex-1 bg-gray-100">
              <AppRoutes />
            </div>
          </Router>
        </GraphQLProvider>
      </UserProvider>
    </ConfigProvider>
  );
}

export default App;
