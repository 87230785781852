import { PlusOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import FormElement from 'modules/Campaign/components/Form/FormSection';
import { MessageFormItem } from 'modules/Campaign/components/Form/MessageFormItem';
import { useRef } from 'react';

//Create Campaign

export const LinkedInFollowups = () => {
  /** Used as a scroll target. */
  const bottomRef = useRef(null);

  const formListName = 'followUps';

  return (
    <FormElement
      title="LinkedIn Follow Ups"
      subTitle="Follow up messages on LinkedIn are sent after the lead accepts the
            connection request. You can send between one to six follow up
            messages and define the intervals between them. If the lead himself
            replies or you send a message manually, the system will stop sending
            messages to the lead."
      className="fade-in grid"
    >
      <Form.List name={formListName}>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <MessageFormItem
                key={key}
                listName={formListName}
                name={name}
                remove={remove}
                restField={restField}
              />
            ))}
            <Form.Item>
              {fields.length < 6 && (
                <Button
                  type="dashed"
                  className="w-full mt-6 text-primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    add({ interval: 1 });
                    // Scroll to bottom
                    setTimeout(() =>
                      bottomRef.current.scrollIntoView({
                        behavior: 'smooth',
                      })
                    );
                  }}
                >
                  Add follow up message
                </Button>
              )}
              <Form.ErrorList errors={errors} />
              <div ref={bottomRef} />
            </Form.Item>
          </>
        )}
      </Form.List>
    </FormElement>
  );
};
