import { RightOutlined } from '@ant-design/icons';
import { Collapse, theme } from 'antd';
import { HELP } from 'constant/emailConfigurationHelper';
const { Panel } = Collapse;

export default function EmailConfigurationHelper() {
  const { token } = theme.useToken();

  const panelStyle = {
    marginBottom: 12,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };

  return (
    <div className="px-2">
      <Collapse
        bordered={false}
        defaultActiveKey={['1']}
        expandIcon={({ isActive }) => (
          <RightOutlined rotate={isActive ? 90 : 0} />
        )}
        style={{
          background: token.colorBgContainer,
        }}
      >
        <Panel
          header={
            <div className="font-medium">
              Why are those parameters important?
            </div>
          }
          key="1"
          style={panelStyle}
        >
          <p>{HELP.FACTORS}</p>
        </Panel>
        <Panel header="What should you be checking?" key="2" style={panelStyle}>
          <p>
            Your email address should have the following requirements:
            <ul className="list-disc">
              <li>it needs to be a stand-alone</li>
              <li>
                email address and cannot be an alias of an existing email
                address
              </li>
              <li>
                it needs to be personal, e.g. it contains either the first name,
                the last name or both of the sender of the emails
              </li>
            </ul>
          </p>
        </Panel>
        <Panel header="This is panel header 3" key="3" style={panelStyle}>
          <p>text</p>
        </Panel>
        <Panel header="This is panel header 3" key="4" style={panelStyle}>
          <p>text</p>
        </Panel>
        <Panel header="This is panel header 3" key="5" style={panelStyle}>
          <p>text</p>
        </Panel>
        <Panel
          header="What is a CNAME and how to set it up "
          key="6"
          style={panelStyle}
        >
          <p>{HELP.CNAME}</p>
        </Panel>
        <Panel
          header="How to set up your SPF record "
          key="7"
          style={panelStyle}
        >
          <p>{HELP.SPF}</p>
        </Panel>
        <Panel
          header="What is DKIM and how to set it up "
          key="8"
          style={panelStyle}
        >
          <p>{HELP.DKIM}</p>
        </Panel>
      </Collapse>
    </div>
  );
}
