import { Button, ButtonProps, message } from 'antd';
import { useCallback, useState } from 'react';

import { axiosInstance } from '../../api/axiosMiddleware';

export interface DownloadFromAPIProps {
  url: string;
  filename: string;
  children: React.ReactNode;
  buttonProps?: ButtonProps;
}

export function DownloadFromAPI({
  url,
  filename,
  children,
  buttonProps = {
    type: 'link',
  },
}: DownloadFromAPIProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleOnClick = useCallback(async () => {
    if (!url || isLoading) return;

    setIsLoading(true);

    try {
      const response = await axiosInstance.get(url, {
        responseType: 'blob',
      });

      const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');

      link.href = objectUrl;
      link.download = filename;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (e) {
      message.error('Error downloading file');
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, url, filename]);

  return (
    <Button {...buttonProps} onClick={handleOnClick} loading={isLoading}>
      {children}
    </Button>
  );
}
