import { Form, message } from 'antd';
import api from 'api';
import API from 'api';
import { EmailSignaturePreview } from 'modules/Campaign/components/Form/EmailSignaturePreview';
import FollowUpReview from 'modules/Campaign/components/Form/FollowUpReview';
import FormLabel from 'modules/Campaign/components/Form/FormLabel';
import FormSection from 'modules/Campaign/components/Form/FormSection';
import TextReview from 'modules/Campaign/components/Form/TextReview';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import {
  areObjectsEqual,
  filterDuplicatesInArray,
} from 'services/campaignForm';
import {
  getTypeDescription,
  isEmailCSV,
  isEmailTargetMargetCampaignType,
} from 'services/campaignType';

import { BlacklistedLeadsAlert } from './BlacklistedLeadsAlert';
import { DuplicateLeadsAlert } from './DuplicateLeadsAlert';

//EDIT CAMPAIGN

export const Review = ({ campaign }) => {
  const form = Form.useFormInstance();
  const [formValues, setFormValues] = useState(form.getFieldsValue(true));
  const [duplicates, setDuplicates] = useState([]);
  const [isCheckingDuplicates, setIsCheckingDuplicates] = useState(false);

  /** If an UNDO action is performed we reflect the changes in the form object */
  useEffect(() => {
    form.setFieldsValue(formValues);
  }, [formValues, form]);

  const checkNewLeads = useCallback(
    (leads) => {
      const result = filterDuplicatesInArray(leads, formValues, 'email');
      setDuplicates(result.duplicateLeads);
      return result.filteredLeads;
    },
    [formValues]
  );

  useEffect(() => {
    /*
      IF the campaign is CSV type and we have leads in the parsedLeads field of the form object...
      We want to check if any of the leads already is present on the DB. If that's the case, we filter the duplicates and update the parsedLeads field.
     */
    if (isEmailCSV(formValues.type) && formValues?.parsedLeads?.length > 0) {
      const getAllLeadsByCampaignId = async () => {
        try {
          setIsCheckingDuplicates(true);
          const { data: leads } = await API.getAllLeadsByCampaignId(
            formValues.id
          );
          const newLeads = checkNewLeads(leads, 'email');
          setIsCheckingDuplicates(false);
          form.setFieldValue('parsedLeads', newLeads);
        } catch (error) {
          console.log(error);
        }
      };
      if (isEmailCSV(formValues.type)) {
        getAllLeadsByCampaignId();
      }
    }
  }, [
    checkNewLeads,
    form,
    formValues.id,
    formValues.type,
    formValues?.parsedLeads?.length,
  ]);

  const emailFollowUps = formValues?.emailFollowups;
  const hasEmailFollowUps = emailFollowUps && emailFollowUps.length > 0;
  const hasEmailFollowUpsChanged = !areObjectsEqual(
    emailFollowUps,
    campaign.emailFollowups
  );

  const smtpHost = `${formValues?.smtpAddress}:${formValues?.smtpPort}`;
  const imapHost = `${formValues?.imapAddress}:${formValues?.imapPort}`;

  // Blacklist checking
  const [numOfBlacklisted, setNumOfBlacklisted] = useState(0);
  const [isCheckingBlacklisted, setIsCheckingBlacklisted] = useState(false);

  const checkBlacklistedLeads = async () => {
    try {
      setIsCheckingBlacklisted(true);
      const emails = formValues.parsedLeads.map((lead) => lead.email);
      const { data: leadsRawBlacklist } = await api.getBlacklists('LEAD_EMAIL');
      const { data: domainsRawBlacklist } = await api.getBlacklists(
        'EMAIL_DOMAIN'
      );
      // build up an array of strings out of the blacklist keywords
      // instead of the full objects for faster/easier lookup
      const domainsBlacklist = domainsRawBlacklist.map((item) => item.keyword);
      const leadsBlacklist = leadsRawBlacklist.map((item) => item.keyword);

      let numOfBlacklistedLeads = 0;
      emails.map((email) => {
        // check if the lead's email is part of the leads's blacklist,
        // or the lead's email domain is part of the domain's blacklist
        if (
          leadsBlacklist.includes(email) ||
          domainsBlacklist.includes(email.split('@')[1])
        )
          numOfBlacklistedLeads++;
      });
      setIsCheckingBlacklisted(false);

      setNumOfBlacklisted(numOfBlacklistedLeads);
    } catch (error) {
      console.log(error);
      message.error('Error occured while checking for blacklists');
    }
  };

  useEffect(() => {
    if (isEmailCSV(formValues?.type)) {
      checkBlacklistedLeads();
    }

    // eslint-disable-next-line
  }, [formValues]);

  return (
    <div className="flex flex-col gap-2 py-4 fade-in">
      <FormLabel>
        Please review the informations then click submit to modify your
        campaign.
      </FormLabel>

      <FormSection title="Campaign basic information">
        <TextReview
          title={'Campaign name'}
          value={formValues?.name}
          formValue={formValues?.name}
          campValue={campaign.name}
          fieldName={'name'}
          setFormValues={setFormValues}
        />

        <TextReview
          title={'Campaign type'}
          value={getTypeDescription(formValues?.type)}
        />

        {isEmailTargetMargetCampaignType(formValues?.type) && (
          <TextReview
            title={'Target Market'}
            value={formValues?.targetMarketName || ''}
          />
        )}

        {isEmailCSV(formValues.type) && formValues.parsedLeads.length > 0 && (
          <TextReview
            title={'Added Leads'}
            value={
              <div className="flex items-center ">
                <div>{formValues.parsedLeads.length ?? 0}</div>
                <div className="flex flex-col justify-start items-start">
                  {(isCheckingDuplicates || isCheckingBlacklisted) && (
                    <span className="ml-4 text-secondary">Validating...</span>
                  )}
                  {duplicates.length > 0 && (
                    <DuplicateLeadsAlert
                      duplicates={duplicates}
                      objkey={'linkedInProfileUrl'}
                    />
                  )}
                  {!!numOfBlacklisted && (
                    <BlacklistedLeadsAlert number={numOfBlacklisted} />
                  )}
                </div>
              </div>
            }
          />
        )}
      </FormSection>

      <FormSection title="Email Setup">
        <TextReview
          title={'Email address'}
          value={formValues?.emailAddress}
          formValue={formValues?.emailAddress}
          campValue={campaign.emailAddress}
          fieldName={'emailAddress'}
          setFormValues={setFormValues}
        />

        <TextReview
          title={'Email subject'}
          value={formValues?.emailFollowUpsSubject}
          formValue={formValues?.emailFollowUpsSubject}
          campValue={campaign.emailFollowUpsSubject}
          fieldName={'emailFollowUpsSubject'}
          setFormValues={setFormValues}
        />

        {formValues.emailSignature !== undefined && (
          <TextReview
            title={'Email signature'}
            value={
              <EmailSignaturePreview signature={formValues.emailSignature} />
            }
            formValue={formValues?.emailSignature}
            campValue={campaign?.emailSignature}
            fieldName={'emailSignature'}
            setFormValues={setFormValues}
          />
        )}

        <TextReview
          title={'SMTP'}
          value={smtpHost}
          formValue={smtpHost}
          campValue={campaign.smtpAddress + ':' + campaign.smtpPort}
          onRevert={() => {
            setFormValues((values) => ({
              ...values,
              smtpAddress: campaign.smtpAddress,
              smtpPort: campaign.smtpPort,
            }));
          }}
        />

        <TextReview
          title={'IMAP'}
          value={imapHost}
          formValue={imapHost}
          campValue={campaign.imapAddress + ':' + campaign.imapPort}
          onRevert={() => {
            setFormValues((values) => ({
              ...values,
              imapAddress: campaign.imapAddress,
              imapPort: campaign.imapPort,
            }));
          }}
        />

        <TextReview
          title={'Daily emails range'}
          value={`${formValues?.invites[0]} to ${formValues?.invites[1]}`}
          formValue={JSON.stringify(formValues?.invites)}
          campValue={JSON.stringify(campaign.invites)}
          fieldName={'invites'}
          setFormValues={setFormValues}
        />

        {formValues?.initialMessage.femaleSalutation && (
          <TextReview
            title={`Women's salutation`}
            value={formValues?.initialMessage.femaleSalutation}
            formValue={formValues?.initialMessage.femaleSalutation}
            campValue={campaign.initialMessage.femaleSalutation}
            fieldName={['initialMessage', 'femaleSalutation']}
            setFormValues={setFormValues}
          />
        )}

        {formValues?.initialMessage.maleSalutation && (
          <TextReview
            title={`Men's salutation`}
            value={formValues?.initialMessage.maleSalutation}
            formValue={formValues?.initialMessage.maleSalutation}
            campValue={campaign.initialMessage.maleSalutation}
            fieldName={['initialMessage', 'maleSalutation']}
            setFormValues={setFormValues}
          />
        )}

        <TextReview
          title={'Message content'}
          value={formValues?.initialMessage.content}
          formValue={formValues?.initialMessage.content}
          campValue={campaign.initialMessage.content}
          fieldName={['initialMessage', 'content']}
          setFormValues={setFormValues}
        />
      </FormSection>

      {hasEmailFollowUps || hasEmailFollowUpsChanged ? (
        <FormSection
          title={`Email follow ups (${emailFollowUps?.length || 0})`}
        >
          {hasEmailFollowUps ? (
            <>
              {hasEmailFollowUpsChanged && (
                <TextReview
                  value={'Emails have been changed'}
                  formValue={JSON.stringify(formValues?.emailFollowups)}
                  campValue={JSON.stringify(campaign.emailFollowups)}
                  fieldName={'emailFollowups'}
                  setFormValues={setFormValues}
                />
              )}

              {formValues.emailFollowups?.map((followUp, idx) => (
                <FollowUpReview key={idx} msg={followUp} idx={idx} />
              ))}
            </>
          ) : (
            hasEmailFollowUpsChanged && (
              <TextReview
                value={'Emails have been deleted'}
                formValue={JSON.stringify(formValues?.emailFollowups)}
                campValue={JSON.stringify(campaign.emailFollowups)}
                fieldName={'emailFollowups'}
                setFormValues={setFormValues}
              />
            )
          )}
        </FormSection>
      ) : null}
    </div>
  );
};

Review.propTypes = {
  campaign: PropTypes.any.isRequired,
};
