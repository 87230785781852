import PropTypes from 'prop-types';

export function ProfileCard({ children, text, icon, iconColor }) {
  return (
    <section className="flex flex-col items-center gap-2 px-4 py-2 bg-gray-100 border rounded hover:border-secondary">
      <div className="flex items-center justify-between w-full px-1 mb-2 text-xs uppercase border border-t-0 border-b-2 border-l-0 border-r-0 border-solid rounded-sm border-secondary text-secondary">
        <div className="text-primary">{text}</div>
        <div className={`text-3xl ${iconColor} relative bottom-6 opacity-80`}>
          {icon}
        </div>
      </div>
      {children}
    </section>
  );
}

ProfileCard.propTypes = {
  children: PropTypes.any,
  text: PropTypes.string.isRequired,
  icon: PropTypes.any,
  iconColor: PropTypes.any,
};
