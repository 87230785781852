import './EditUserModal.styles.css';

import { UndoOutlined } from '@ant-design/icons';
import {
  Button,
  Input,
  message,
  Modal,
  Select,
  Tooltip,
  Typography,
} from 'antd';
import API from 'api';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ConfirmUserEditModal } from './components/ConfirmUserEditModal/ConfirmUserEditModal';

const { Text } = Typography;

const PASSWORD_REGEXP =
  /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,32}$/;

/** User edit modal to change company name, email, password, Bought credit. */
export function EditUserModal({
  selectedUser,
  isModalVisible,
  setIsModalVisible,
  fetchUsers,
}) {
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const [userBoughtCredit, setUserBoughtCredit] = useState(0);

  useEffect(() => {
    if (selectedUser) {
      setCompanyName(selectedUser.companyName);
      setEmail(selectedUser.email);
      setPassword('');
      setRole(selectedUser.role);
      setUserBoughtCredit(selectedUser.userBoughtCredit);
    }
  }, [selectedUser]);

  const changed = useMemo(() => {
    if (!selectedUser) return {};

    return {
      companyName: !!companyName &&
        companyName !== selectedUser.companyName && {
          value: companyName,
          originalValue: selectedUser.companyName,
        },
      email: !!email &&
        email !== selectedUser.email && {
          value: email,
          originalValue: selectedUser.email,
        },
      password: password !== '' && {
        value: password,
        originalValue: '',
      },
      role: !!role &&
        role !== selectedUser.role && {
          value: role,
          originalValue: selectedUser.role,
        },
      userBoughtCredit: userBoughtCredit !== selectedUser.userBoughtCredit && {
        value: userBoughtCredit,
        originalValue: selectedUser.userBoughtCredit,
      },
    };
  }, [companyName, email, password, userBoughtCredit, role, selectedUser]);

  const showPasswordTooltip = useMemo(() => {
    return (password && !PASSWORD_REGEXP.test(password)) || undefined;
  }, [password]);

  const formDisabled = useMemo(() => {
    return (
      !Object.keys(changed).some((key) => changed[key]) ||
      !companyName ||
      !email ||
      (password && !PASSWORD_REGEXP.test(password)) ||
      !role ||
      typeof userBoughtCredit !== 'number' ||
      userBoughtCredit < 0
    );
  }, [changed, companyName, email, password, role, userBoughtCredit]);

  const handlePurchasedCreditChange = useCallback((value) => {
    const parsed = parseInt(value, 10);

    if (isNaN(parsed) || parsed < 0) {
      message.error('Please provide a positive number');
    } else {
      setUserBoughtCredit(parsed);
    }
  }, []);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseModal = useCallback(() => {
    setCompanyName(selectedUser.companyName);
    setEmail(selectedUser.email);
    setPassword('');
    setRole(selectedUser.role);
    setUserBoughtCredit(selectedUser.userBoughtCredit);

    setShowConfirmModal(false);
    setIsModalVisible(false);
  }, [setIsModalVisible, selectedUser]);

  const handleSubmit = useCallback(async () => {
    if (formDisabled || isLoading) {
      return;
    }

    setIsLoading(true);

    const payload = {};

    for (const key in changed) {
      if (changed[key]) {
        payload[key] = changed[key].value;
      }
    }

    try {
      await API.updateUser(selectedUser.id, payload);
      message.success('User updated successfully');
      handleCloseModal();
      fetchUsers();
    } catch (error) {
      /* TODO: Message for if username already exist and if email already exist */
      message.error('An error occurred while updating the user');
    } finally {
      setIsLoading(false);
    }
  }, [
    changed,
    fetchUsers,
    formDisabled,
    handleCloseModal,
    isLoading,
    selectedUser?.id,
  ]);

  return (
    <Modal
      title={`Edit "${selectedUser?.companyName}" account`}
      centered
      open={isModalVisible}
      onCancel={handleCloseModal}
      footer={null}
      width={550}
      destroyOnClose
    >
      <div className="user-edit-modal flex flex-col gap-3">
        <div className="flex justify-between items-center gap-2 min-h-[2rem]">
          <div className="text-gray-600">Company Name:</div>
          <div className="flex items-center gap-2">
            <Text editable={{ onChange: (value) => setCompanyName(value) }}>
              {companyName}
            </Text>
            {changed.companyName && (
              <Tooltip title="Undo">
                <UndoOutlined
                  className="p-1 text-secondary hover:text-white hover:bg-primary hover:rounded-full"
                  onClick={() => setCompanyName(selectedUser.companyName)}
                />
              </Tooltip>
            )}
          </div>
        </div>

        <div className="flex justify-between items-center gap-2 min-h-[2rem]">
          <div className="text-gray-600">Email:</div>
          <div className="flex items-center gap-2">
            <Text editable={{ onChange: (value) => setEmail(value) }}>
              {email}
            </Text>
            {changed.email && (
              <Tooltip title="Undo">
                <UndoOutlined
                  className="p-1 text-secondary hover:text-white hover:bg-primary hover:rounded-full"
                  onClick={() => setEmail(selectedUser.email)}
                />
              </Tooltip>
            )}
          </div>
        </div>

        <div className="flex justify-between items-center gap-2">
          <div className="text-gray-600">New Password:</div>
          <div className="flex items-center gap-2">
            <Tooltip
              open={showPasswordTooltip}
              title={
                'Password must include :  at least One number : 0-9 , at least one upper case character and one lower case character , at least ones special character : [!, @, #, $, %, ^, &, *] and a least 6 character long .'
              }
            >
              {/* This div is "needed" because of a bug in Ant Design. Without
                the div, the opening of the Tooltip throws a `findDOMNode is
                deprecated in StrictMode` error */}
              <div>
                <Input.Password
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  pattern={PASSWORD_REGEXP.toString().slice(1, -1)}
                />
              </div>
            </Tooltip>
          </div>
        </div>

        <div className="flex justify-between items-center gap-2">
          <div className="text-gray-600">Role:</div>
          <div className="flex items-center gap-2">
            <Select
              value={role}
              onChange={(value) => setRole(value)}
              className="w-[150px]"
            >
              <Select.Option value="User">User</Select.Option>
              <Select.Option value="Admin">Admin</Select.Option>
              <Select.Option value="SuperAdmin">SuperAdmin</Select.Option>
            </Select>
            {changed.role && (
              <Tooltip title="Undo">
                <UndoOutlined
                  className="p-1 text-secondary hover:text-white hover:bg-primary hover:rounded-full"
                  onClick={() => setRole(selectedUser.role)}
                />
              </Tooltip>
            )}
          </div>
        </div>

        <div className="flex justify-between items-center gap-2 min-h-[2rem]">
          <div className="text-gray-600">Purchased credits:</div>
          <div className="flex items-center gap-2">
            <Text editable={{ onChange: handlePurchasedCreditChange }}>
              {userBoughtCredit.toString()}
            </Text>
            {changed.userBoughtCredit && (
              <Tooltip title="Undo">
                <UndoOutlined
                  className="p-1 text-secondary hover:text-white hover:bg-primary hover:rounded-full"
                  onClick={() =>
                    setUserBoughtCredit(selectedUser.userBoughtCredit)
                  }
                />
              </Tooltip>
            )}
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between mt-6">
        <Button onClick={handleCloseModal}>Cancel</Button>

        <Button
          disabled={formDisabled}
          type="primary"
          onClick={() => setShowConfirmModal(true)}
        >
          Update
        </Button>
      </div>

      <ConfirmUserEditModal
        isModalVisible={showConfirmModal}
        setIsModalVisible={setShowConfirmModal}
        changed={changed}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </Modal>
  );
}

EditUserModal.propTypes = {
  /** Selected user */
  selectedUser: PropTypes.object,
  /** Modal visibility */
  isModalVisible: PropTypes.bool,
  /** Change modal visibility */
  setIsModalVisible: PropTypes.func,
  /** refetch the users from the API */
  fetchUsers: PropTypes.func,
};
