import { Button, Form, message, Modal, notification } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import api from 'api';
import { useUser } from 'hooks/useUser';
import { useState } from 'react';

import {
  BLACKLIST_STRING_CHECKERS,
  BLACKLIST_TEXT_AREA_PLACEHOLDERS,
  BLACKLIST_TEXT_AREA_RULES,
  BLACKLIST_TITLES,
  BlacklistType,
} from '../constants';
import { formatKeyword, splitContent } from '../helpers';

interface AddBlacklistModalProps {
  blacklistType: BlacklistType;
  isModalOpen: boolean;
  setIsModalOpen: (state: boolean) => void;
  onAddUpdate: () => void;
}

export function AddBlacklistModal({
  blacklistType,
  isModalOpen,
  setIsModalOpen,
  onAddUpdate,
}: AddBlacklistModalProps) {
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  async function onSubmit(values: { keywords: string }) {
    if (!user) return;

    const data = Array.from(
      new Set(values.keywords.split(/,|\n/g).filter(Boolean))
    ).map((keyword: string) => ({
      keyword: formatKeyword(keyword, blacklistType),
      type: blacklistType,
      userId: user.id,
    }));

    try {
      setIsLoading(true);

      await api.createBlacklistEntries(data);

      onAddUpdate();
      notification.success({
        message: 'Added with success',
        description: 'The blacklist has been updated successfully',
      });
    } catch (error) {
      console.log(error);
      message.error('Error occured while created blacklist entries');
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
      form.resetFields(['keywords']);
    }
  }

  const handleDrop: React.DragEventHandler<HTMLTextAreaElement> = (e) => {
    if (!e.dataTransfer) return;

    const reader = new FileReader();

    reader.onload = (evt) => {
      if (!evt?.target?.result) return;

      form.setFieldsValue({ keywords: evt?.target?.result });
      form.validateFields(['keywords']);
    };

    reader.readAsText(e.dataTransfer.files[0]);
  };

  const content = Form.useWatch(['keywords'], form);

  function filterList() {
    const entries = splitContent(content);

    const validEntries = entries.filter(
      BLACKLIST_STRING_CHECKERS[blacklistType]
    );

    form.setFieldsValue({ keywords: validEntries.join('\n') });
  }

  return (
    <Modal
      title={`Add ${BLACKLIST_TITLES[blacklistType]}`}
      open={isModalOpen}
      onOk={() => setIsModalOpen(false)}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
    >
      <Form
        name="dynamic_form_item"
        onFinish={(values) => onSubmit(values)}
        initialValues={{ keywords: null }}
        form={form}
      >
        <div>
          Enter the list of {BLACKLIST_TITLES[blacklistType].toLowerCase()}s
          that you want to blacklist:
        </div>
        <Form.Item
          name="keywords"
          validateTrigger={['onChange', 'onBlur', 'onSubmit']}
          rules={BLACKLIST_TEXT_AREA_RULES[blacklistType]}
        >
          <TextArea
            spellCheck={false}
            name="keywords"
            placeholder={BLACKLIST_TEXT_AREA_PLACEHOLDERS[blacklistType]}
            className="mt-4"
            autoSize={{ minRows: 5, maxRows: 10 }}
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDrop}
          />
        </Form.Item>
        <div className="flex">
          <Button
            size="small"
            className="ml-auto"
            disabled={!content}
            onClick={filterList}
          >
            Filter
          </Button>
        </div>

        <Form.Item>
          <div className="flex justify-between mt-8">
            <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}
