import { CheckIcon } from 'assets/icons/CheckIcon';
import classNames from 'classnames';
import { Fragment } from 'react';

export interface StepsStepProps {
  /** The step to display */
  title: string;
  /** The step number */
  step: number;
  /** Style of the step */
  style?: 'default' | 'active' | 'completed';
  onClick?: () => void;
}

export interface StepsProps {
  /** The steps to display */
  steps: StepsStepProps[];
  /** The current step */
  currentStep: number;
  className?: string;
}

function Step({ title, step, style, onClick }: StepsStepProps) {
  return (
    <div
      className={classNames(
        'flex gap-2 md:flex-col md:items-center md:flex-1 transition-all duration-200',
        {
          'cursor-pointer': !!onClick && style !== 'active',
          'cursor-default': style === 'active',
          'hover:border-secondary hover:text-secondary':
            !!onClick && style !== 'active',
          'border-secondary': style === 'completed' || style === 'active',
          'border-slate-300 text-slate-400': style === 'default',
        }
      )}
      onClick={onClick}
    >
      <div
        className={classNames(
          'border border-solid rounded-full w-6 h-6 flex items-center justify-center text-xs',
          {
            'text-secondary': style === 'completed',
            'bg-secondary text-white border-inherit': style === 'active',
          }
        )}
      >
        {style === 'completed' ? <CheckIcon className="m-1" /> : step}
      </div>
      <div className="md:text-center">{title}</div>
    </div>
  );
}

export function Steps({ steps, currentStep, className = '' }: StepsProps) {
  return (
    <div className={`flex flex-col md:flex-row gap-2 ${className}`}>
      {steps.map((step, index) => {
        /* 
          TODO: tailwind is not able to determine which classname 
          `md:w-1/${steps/length}` would be and therefore does not include it in
          the generated css. This is a workaround for now. If a seventh step is
          added, another condition needs to be added.
				*/
        const widthClass =
          steps.length === 2
            ? `md:w-[44%] md:-mx-[22%]`
            : steps.length === 3
            ? `md:w-[25%] md:-mx-[12.5%]`
            : steps.length === 4
            ? `md:w-[20%] md:-mx-[10%]`
            : `md:w-[14%] md:-mx-[7%]`;

        return (
          <Fragment key={`step${index}`}>
            <Step
              title={step.title}
              step={index + 1}
              style={
                step.style || index < currentStep
                  ? 'completed'
                  : index === currentStep
                  ? 'active'
                  : 'default'
              }
              onClick={step.onClick}
            />
            {index < steps.length - 1 && (
              <div
                key={`stepDivider${index}`}
                className={`w-px h-3 ml-3 md:h-px md:mt-3 ${widthClass} ${
                  index < currentStep ? 'bg-secondary' : 'bg-slate-300'
                }`}
              />
            )}
          </Fragment>
        );
      })}
    </div>
  );
}
