import { Popover } from 'antd';
import PropType from 'prop-types';

import FupsStatisticItem from '../FupsStatisticItem/FupsStatisticItem';
import StatisticItem from '../StatisticItem/StatisticItem';

const ExistingLinkedinTypeStats = ({ cardsData, campaignId }) => {
  return (
    <>
      <StatisticItem
        title="Contacted"
        value={cardsData?.requested.value}
        percentage={cardsData?.requested.percentage}
        color="#D7A600"
      />
      <Popover
        content={<FupsStatisticItem campaignId={parseInt(campaignId)} />}
        placement="left"
        title="Reply Success Rate"
        trigger="hover"
      >
        <StatisticItem
          title="Replied"
          value={cardsData?.responded.value}
          percentage={
            (cardsData?.responded.value / cardsData?.requested.value) * 100
          }
          color="#3f8600"
        />
      </Popover>
    </>
  );
};

export default ExistingLinkedinTypeStats;
ExistingLinkedinTypeStats.propTypes = {
  cardsData: PropType.object,
  campaignId: PropType.string,
};
