import API from 'api';
import { Layout } from 'components/Layout';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  isEmailCampaignType,
  isLinkedinCampaignType,
} from 'services/campaignType';

import { SubHeader } from '../components/SubHeader';
import { EmailLeadsTable } from './EmailLeadsTable/EmailLeadsTable';
import { LinkedinLeadsTable } from './LinkedinLeadsTable/LinkedinLeadsTable';

export function LeadsTable() {
  const [type, setType] = useState('N/A');
  const [name, setName] = useState('N/A');

  const { id: campaignId } = useParams();

  /* Fetch the campaign follow-ups length (client req) and the table data */
  useEffect(() => {
    const getCampaignType = async () => {
      try {
        const {
          data: { type, name },
        } = await API.getCampaignTypeAndName(campaignId);
        setType(type);
        setName(name);
      } catch (error) {
        console.log(error);
      }
    };
    getCampaignType();
  }, [campaignId]);

  return (
    <Layout>
      <SubHeader title="Leads" subtitle={name} />
      {isLinkedinCampaignType(type) && <LinkedinLeadsTable />}
      {isEmailCampaignType(type) && <EmailLeadsTable showBouncedEmails />}
    </Layout>
  );
}
