import { Button, Divider } from 'antd';
import PropTypes from 'prop-types';

export const HeaderFilterType = ({ selected, type, selectedCampaign }) => {
  return (
    <div className="flex justify-center items-center px-2 py-1.5 bg-white rounded-lg ">
      <div className="ml-2 text-lg">{type.icon}</div>
      <div className="ml-2">
        {type.children.map((child, i) => (
          <span key={`child_${i}`}>
            {i != 0 && <Divider type="vertical" className="text-xl" />}
            <Button
              key={`child_${i}`}
              type="link"
              onClick={() => selectedCampaign(child)}
              className={`${
                selected === child.label
                  ? 'text-secondary font-semibold'
                  : 'text-primary'
              } uppercase m-0 px-2 text-xs`}
            >
              {child.label}
            </Button>
          </span>
        ))}
      </div>
    </div>
  );
};

HeaderFilterType.propTypes = {
  selected: PropTypes.string.isRequired,
  type: PropTypes.object.isRequired,
  selectedCampaign: PropTypes.func.isRequired,
};
