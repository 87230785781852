import { Tabs } from 'antd';
import { Layout } from 'components/Layout';
import { SubHeader } from 'modules/Campaign/components/SubHeader';

import { BlacklistTable } from './components/BlacklistTable';
import { BlacklistType } from './constants';

export function Blacklist() {
  return (
    <Layout>
      <SubHeader title={'Blacklist'} />
      <Tabs
        defaultActiveKey="1"
        centered
        tabBarStyle={{ margin: '0 30px' }}
        tabBarGutter={50}
        items={[
          {
            label: `Domains`,
            key: '1',
            className: 'fade-in',
            children: <BlacklistTable type={BlacklistType.EMAIL_DOMAIN} />,
          },
          {
            label: `Emails`,
            key: '2',
            className: 'fade-in',
            children: <BlacklistTable type={BlacklistType.LEAD_EMAIL} />,
          },
          {
            label: `LinkedIn`,
            key: '3',
            className: 'fade-in',
            children: <BlacklistTable type={BlacklistType.LEAD_LINKEDIN} />,
          },
        ]}
      />
    </Layout>
  );
}
