import PropTypes from 'prop-types';
import { formattedTypes } from 'services/campaignType';

import { HeaderFilterType } from './HeaderFilterType';

const FilterHeader = ({ selected, selectCampaign, showHeader }) => {
  return (
    <div className="flex flex-col">
      {showHeader && (
        <div className="mb-2 text-gray-500 uppercase">
          Filter campaign by type
        </div>
      )}
      <div className="flex items-center justify-between gap-2">
        {formattedTypes.map((type, i) => (
          <HeaderFilterType
            key={i}
            selected={selected}
            type={type}
            selectedCampaign={selectCampaign}
          />
        ))}
      </div>
    </div>
  );
};

export default FilterHeader;

FilterHeader.propTypes = {
  selected: PropTypes.string,
  selectCampaign: PropTypes.func.isRequired,
  showHeader: PropTypes.bool,
};
