import { Divider } from 'components/Divider';
import PropTypes from 'prop-types';
import FormLabel from './FormLabel';

const FormSection = ({
  title,
  subTitle,
  children,
  className,
  titleChildren,
}) => {
  return (
    <div className={className}>
      <Divider>
        {title}
        {titleChildren}
      </Divider>
      {subTitle ? <FormLabel>{subTitle}</FormLabel> : null}
      {children}
    </div>
  );
};

export default FormSection;

FormSection.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  titleChildren: PropTypes.node,
};
