import { LinkedinFilled } from '@ant-design/icons';
import { Input, Table, Tag } from 'antd';
import {
  filterCampaignType,
  filterStatus,
  HEADER_SEARCH_LABEL,
} from 'constant/MonitoringCampaign';
import { linkedinIconStyle, mailIconStyle, tagStyle } from 'constant/styles';
import { MailOutline, PeopleIcon } from 'modules/Campaign/components/Icons';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { isLinkedinCampaignType, type } from 'services/campaignType';

import ProgressStat from './ProgressStat';
import TableTitle from './TableTitle';

export const RecordsTable = ({ campaigns }) => {
  const [tablesData, setTablesData] = useState(campaigns);
  const [inputValue, setInputValue] = useState('');

  const [isFilterActive, setIsFilterActive] = useState(false);
  const [tableParams] = useState({
    defaultCurrent: 1,
    defaultPageSize: 10,
    position: ['bottomRight'],
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50],
  });

  const handleChange = (str) => {
    str === '' ? setIsFilterActive(false) : setIsFilterActive(true);
    setInputValue(str);
    setTablesData(
      campaigns.filter(
        (e) =>
          e.name.toLowerCase().includes(str.toLowerCase()) ||
          e.type.toLowerCase().includes(str.toLowerCase()) ||
          e['user.companyName'].toLowerCase().includes(str.toLowerCase()) ||
          e['user.email'].toLowerCase().includes(str.toLowerCase())
      )
    );
  };

  const columns = [
    {
      title: 'Campaign',
      defaultSortOrder: 'ascend',
      align: 'center',
      dataIndex: ['name', 'type'],
      render: (_, tuple) => (
        <div className="flex flex-col">
          <div className="text-xs sm:text-base my-0.5">{tuple.name}</div>
          <div className="text-xs text-gray-400 my-0.5">{tuple.type}</div>
        </div>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      filters: filterCampaignType,
      filterMultiple: true,
      onFilter: (value, tuple) => tuple.type == value,
    },
    {
      title: 'Account',
      align: 'center',
      dataIndex: 'linkedInAccount.email',
      render: (_, tuple) => (
        <div className="flex flex-row justify-center mt-2 align-middle">
          {isLinkedinCampaignType(tuple.type) ? (
            <>
              <LinkedinFilled style={linkedinIconStyle} />{' '}
              <div className="ml-2 dashboard__linked-in-account">
                <div>{tuple['linkedInAccount.email']}</div>
              </div>
            </>
          ) : (
            <>
              <MailOutline style={mailIconStyle} />{' '}
              <div className="ml-2 dashboard__linked-in-account">
                <div>{tuple['email.address']}</div>
              </div>
            </>
          )}
        </div>
      ),
    },
    {
      title: 'Company',
      align: 'center',
      dataIndex: ['user.companyName', 'user.email'],
      render: (_, tuple) => (
        <div className="flex flex-col">
          <div className="text-xs sm:text-base">
            {tuple['user.companyName']}
          </div>
          <div className="flex flex-row justify-center mt-2 align-middle">
            <div className="ml-2 dashboard__linked-in-account">
              <div className="text-xs sm:text-sm">{tuple['user.email']}</div>
            </div>
          </div>
        </div>
      ),
      sorter: (a, b) =>
        a['user.companyName'].localeCompare(b['user.companyName']),
    },
    {
      title: 'Status',
      align: 'center',
      dataIndex: 'status',
      key: 'status',
      className: 'hidden sm:table-cell max-w-xs',
      render: (status, record) => (
        <div className="flex flex-col justify-center max-w-sm align-middle">
          <div className="my-1 text-center">
            {status === 'ACTIVE' ? (
              <Tag style={tagStyle} color="green">
                Active
              </Tag>
            ) : (
              <Tag style={tagStyle} color="red">
                Inactive
              </Tag>
            )}
          </div>
          <div className="flex justify-center my-1 flew-row">
            <PeopleIcon />
            <div>{record.numOfLeads}</div>
          </div>
        </div>
      ),
      filters: filterStatus,
      filterMultiple: false,
      onFilter: (value, tuple) => tuple.status == value,
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: 'Statistics',
      className: 'hidden sm:table-cell',
      align: 'center',
      render: (_, tuple) => {
        switch (tuple.type) {
          case type.OUT_SAL:
          case type.OUT_CSV:
            return (
              <div className="flex justify-between px-2 align-middle">
                <ProgressStat
                  divider={+tuple.numOfLeads}
                  dividend={+tuple.requested}
                  label={`${tuple.requested} Requested`}
                />
                <ProgressStat
                  divider={+tuple.requested}
                  dividend={+tuple.connected}
                  label={`${tuple.connected} Connected`}
                />
                <ProgressStat
                  divider={+tuple.connected}
                  dividend={+tuple.replied}
                  label={`${tuple.replied} Replied`}
                />
              </div>
            );
          case type.EX_CSV:
          case type.EX_SAL:
          case type.EX_LIN:
            return (
              <div className="flex justify-between px-2 align-middle">
                <ProgressStat
                  divider={+tuple.numOfLeads}
                  dividend={+tuple.requested}
                  label={`${tuple.requested} Contacted`}
                />
                <ProgressStat
                  divider={+tuple.requested}
                  dividend={+tuple.replied}
                  label={`${tuple.replied} Replied`}
                />
              </div>
            );
          case type.EM_CSV:
            return (
              <div className="flex justify-between px-2 align-middle">
                <ProgressStat
                  divider={+tuple.numOfLeads}
                  dividend={+tuple.requested}
                  label={`${tuple.requested} Contacted`}
                />
                <ProgressStat
                  divider={+tuple.requested}
                  dividend={+tuple.connected}
                  label={`${tuple.connected} Opened`}
                />
                <ProgressStat
                  divider={+tuple.requested}
                  dividend={+tuple.replied}
                  label={`${tuple.replied} Replied`}
                />
              </div>
            );
        }
      },
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      columns={columns}
      dataSource={tablesData}
      pagination={tableParams}
      title={() => (
        <div className="flex flex-col items-center justify-start gap-4 md:flex-row md:justify-between">
          <TableTitle
            isFilterActive={isFilterActive}
            handleChange={handleChange}
          >
            {HEADER_SEARCH_LABEL}
          </TableTitle>
          <div className="text-right">
            <Input
              allowClear
              className="text-right"
              value={inputValue}
              placeholder="Search"
              autoFocus
              style={{ width: '300px' }}
              onChange={(e) => handleChange(e.target.value)}
            />
          </div>
        </div>
      )}
    />
  );
};

RecordsTable.propTypes = {
  /** Array containing the campaigns */
  campaigns: PropTypes.array.isRequired,
};
