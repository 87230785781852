import './ProgressBar.styles.css';

import PropTypes from 'prop-types';
import { useState } from 'react';
import SemiCircleProgressBar from 'react-progressbar-semicircle';

/**
 * Table rows Percentage progress bar for sent , accepted invitation and replied
 * messages.
 *
 * TODO: Do we want to show "---" when the percentage is NaN? Or should we show
 * 0%? The SemiCircleProgressBar component does not support NaN values.
 */
export function ProgressBar({ percentage, total, name }) {
  const [absoluteValue, setAbsoluteValue] = useState(false);

  return (
    <div
      onPointerOver={() => setAbsoluteValue(true)}
      onPointerOut={() => setAbsoluteValue(false)}
      className="text-gray-500 uppercase"
    >
      <SemiCircleProgressBar
        style={{ fontSize: '1rem' }}
        stroke={'#375361'}
        percentage={isNaN(percentage) ? 0 : percentage}
        strokeWidth={6}
        diameter={90}
        showPercentValue={!absoluteValue}
      />
      <span style={{ fontSize: '0.65rem' }}>
        {absoluteValue ? total + ' leads' : name}
      </span>
    </div>
  );
}

ProgressBar.propTypes = {
  /** Progress bar percentage */
  percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  /** Absolute value */
  total: PropTypes.number.isRequired,
  /** Title of progress bar */
  name: PropTypes.string.isRequired,
};
