import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { ProgressBar } from 'components/TargetMarkets/ProgressBar';
import { FILTER_TYPE } from 'constant/targetMarket';

export function flattenAndRemoveTitleField(array) {
  const flattenedValues = Object.values(array.filters).flat();

  const result = flattenedValues.map((obj) => {
    delete obj.title;
    return obj;
  });
  return result;
}

/**
 * Groups the filters applied by ID, creating an option array in which each single value is put together
 * @param filters[]
 * @returns array of grouped filters
 */
export function groupOptions(objects) {
  const groupedOptions = {};

  for (const obj of objects) {
    const { id, options } = obj;

    if (options !== undefined) {
      if (id in groupedOptions) {
        groupedOptions[id].push(options);
      } else {
        groupedOptions[id] = [options];
      }
    } else {
      groupedOptions[id] = undefined; // Mark the object without "options"
    }
  }

  const result = [];

  for (const obj of objects) {
    const { id, options } = obj;

    if (options !== undefined && groupedOptions[id] !== undefined) {
      result.push({
        id,
        options: groupedOptions[id],
      });
      groupedOptions[id] = undefined; // Avoid duplicating the grouped object
    } else if (options === undefined && groupedOptions[id] === undefined) {
      result.push(obj); // Include object without "options"
    }
  }

  return result;
}

/**
 * Helper function to establish if a filter is a MULTI_SELECT type and has esclusion supported
 * @param filter{}
 * @returns true if conditions are verified
 */
export function isMultiWithExclusion(filter) {
  return filter.type === FILTER_TYPE.MULTI && filter.exclusionSupported;
}

/**
 * List of visible filters (by name) in the creation of Target Market
 */
export const INCLUDE_FILTERS = [
  'INDUSTRY',
  'TECHNOLOGIES_USED',
  'ANNUAL_REVENUE',
  'COMPANY_HEADCOUNT',
  'DEPARTMENT_HEADCOUNT',
];

/** @type {import('antd/lib/table').ColumnProps[]} */
export const targetMarketColumns = (deleteTargetMarket, showTargetMarket) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Companies',
    dataIndex: 'totalAccounts',
    className: 'text-center',
    key: 'companies',
    render: (totalAccounts) =>
      totalAccounts === undefined ? 'Problem fetching data' : totalAccounts,
    width: '8%',
  },
  {
    title: 'Progress',
    dataIndex: 'progress',
    key: 'progress',
    render: (_, record) =>
      record.scrapedAccounts === undefined ||
      record.totalAccounts === undefined ? (
        'Problem fetching data'
      ) : (
        <ProgressBar
          values={[
            {
              label: 'Done',
              color: '#a6ffaa',
              value: record.scrapedAccounts,
            },
          ]}
          total={record.totalAccounts}
        />
      ),
  },
  {
    title: 'Action',
    key: 'action',
    width: '3%',
    render: (_, record) => (
      <div className="flex text-center text-gray-400">
        <>
          <Popconfirm
            title="Are you sure you want to remove this Target Market?"
            onConfirm={() => deleteTargetMarket(record.id)}
          >
            <Button
              type="link"
              danger
              icon={<DeleteOutlined />}
              className="text-red-500 hover:bg-gray-100"
            />
          </Popconfirm>
        </>
        <>
          {/* <Tooltip
              title={
                <div className="p-4">
                  It is not possible to delete or edit this Target Market
                  because it is associated to a campaign that has already
                  started.
                </div>
              }
              placement="left"
            >
              <div className="py-1.5 px-2 text-gray-400">
                <Icon component={QuestionCircleOutlined} />
              </div>
            </Tooltip> */}
          <Button
            type="link"
            icon={<EyeOutlined />}
            className="text-secondary hover:bg-gray-100 hover:text-secondary"
            onClick={() => {
              showTargetMarket(record.id);
            }}
          />
        </>
      </div>
    ),
  },
];
