import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

export function ProgressBar({ values, total, className }) {
  const progressValues = useMemo(() => {
    const valuesTotal = values.reduce((previousValue, currentValue) => {
      return previousValue + currentValue.value;
    }, 0);

    const toDo = total - valuesTotal;
    return [...values, { label: 'To-Do', color: '#fff4b9', value: toDo }];
  }, [total, values]);

  function getPercent(value) {
    return ((100 * value) / total).toFixed(0);
  }

  return (
    <>
      <div className={`flex h-6 rounded-sm ${className}`}>
        {progressValues
          .filter((value) => value.value)
          .map((value, i) => {
            const percent = getPercent(value.value);
            return (
              <Tooltip
                key={`progress-${i}`}
                title={`${value.label} - ${percent}%`}
              >
                <div
                  className="progress-bar__item"
                  style={{
                    backgroundColor: value.color,
                    height: '24px',
                    flex: `${percent}%`,
                    marginBottom: '5px',
                    fontSize: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {percent}%
                </div>
              </Tooltip>
            );
          })}
      </div>
    </>
  );
}

ProgressBar.propTypes = {
  values: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  className: PropTypes.string,
};
