import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Col, Form, Input, Row, Switch, Tooltip } from 'antd';
import { CREATE_CAMPAIGN } from 'constant/campaignForm';
import FormLabel from 'modules/Campaign/components/Form/FormLabel';
import FormSection from 'modules/Campaign/components/Form/FormSection';
import FormElement from 'modules/Campaign/components/Form/FormSection';
import { getTypeDescription, isOutreachSalesNav } from 'services/campaignType';

export const BasicLinkedinInfo = () => {
  const form = Form.useFormInstance();
  const camType = form.getFieldValue('type');

  return (
    <div className="fade-in grid">
      <FormSection
        title={CREATE_CAMPAIGN.name.title}
        subTitle={
          'The campaign selected is <' + getTypeDescription(camType) + '>'
        }
      >
        <FormLabel>Campaign Name</FormLabel>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: 'Please enter a name for the campaign',
            },
          ]}
        >
          <Input placeholder="Campaign name" />
        </Form.Item>
      </FormSection>

      <FormElement
        title={CREATE_CAMPAIGN.linkedinAccount.title}
        subTitle={CREATE_CAMPAIGN.linkedinAccount.subTitle}
      >
        <Row align={'middle'} justify={'space-around'} className="my-8">
          <Col span={11} className="">
            <div className="flex-auto mx-8">
              <FormLabel>LinkedIn Email Address</FormLabel>
              <Form.Item
                name="linkedInAccount"
                rules={[
                  {
                    required: true,
                    message:
                      'Please provide the email address for your LinkedIn account',
                  },
                  {
                    type: 'email',
                    message: 'Please enter a valid email address',
                  },
                ]}
              >
                <Input autoComplete="username" placeholder="Username" />
              </Form.Item>
              <FormLabel>LinkedIn Email Password</FormLabel>
              <Form.Item
                name="linkedInPassword"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a password',
                  },
                  {
                    min: 6,
                    message: 'Password must be 6 characters or longer',
                  },
                ]}
              >
                <Input.Password
                  autoComplete="current-password"
                  placeholder="Password"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
            </div>
          </Col>

          {isOutreachSalesNav(camType) && (
            <Col
              span={10}
              className="mr-4 text-center bg-yellow-400 rounded-md"
            >
              <div className="flex flex-col p-1 pb-1 rounded-lg bg-gray-50">
                <div className="mt-5 text-xs text-gray-600">
                  Use <strong>my LinkedIn account</strong> to define the search
                  parameters{' '}
                  <Tooltip title="If the Sales Navigator search has account related criteria, please enable the scrape through the user account, otherwise won't be possible to reach the totality of the leads present in the original search">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </div>
                <Form.Item
                  name={'useScraperAccounts'}
                  initialValue={false}
                  valuePropName="checked"
                >
                  <Switch
                    className="mx-auto mt-4"
                    defaultChecked={false}
                    size="small"
                  />
                </Form.Item>
              </div>
            </Col>
          )}
        </Row>
      </FormElement>
    </div>
  );
};
