import { PropTypes } from 'prop-types';

export const Text = ({ title, value, className = '' }) => {
  return (
    <div className="flex flex-wrap my-3">
      <div className="w-1/3 min-w-[10rem] flex-shrink-0 text-left text-gray-500">
        {title}
      </div>
      <div className={`flex-1 min-w-[10rem] ${className}`}>{value}</div>
    </div>
  );
};

Text.propTypes = {
  title: PropTypes.string.isRequired,
  /* TODO: maybe change this to `children`? */
  value: PropTypes.any,
  className: PropTypes.string,
};
