import {
  blacklistDomainFields,
  blacklistEmailFields,
  blacklistLinkedInFields,
} from 'constant/cvsExport';
import dayjs from 'dayjs';

import { BLACKLIST_TITLES, BlacklistType } from './constants';

export function splitContent(content: string): string[] {
  return content.split(/,|\n|\r|\s/g).filter(Boolean);
}

/**
 * Format blacklist entry keyword based on the type
 *
 * NOTE: for LEAD_LINKEDIN it should not end with a '/'
 *
 * @param keyword
 * @param blacklistType
 * @returns
 */
export function formatKeyword(
  keyword: string,
  blacklistType: BlacklistType
): string {
  const trimmedKeyword = keyword.trim();

  if (blacklistType === BlacklistType.LEAD_LINKEDIN) {
    return trimmedKeyword.endsWith('/')
      ? trimmedKeyword.slice(0, -1)
      : trimmedKeyword;
  }

  return trimmedKeyword;
}

export const getColumns = (type: BlacklistType) => {
  return [
    {
      title: BLACKLIST_TITLES[type],
      dataIndex: 'keyword',
      className: 'text-gray',
      render: (keyword: string) => <div>{keyword}</div>,
    },
    {
      title: 'Source',
      dataIndex: 'addedByUser',
      className: 'text-gray',
      render: (addedByUser: boolean) => (
        <div className="w-full text-center">
          {addedByUser ? 'Manually added' : 'Email unsubscribe'}
        </div>
      ),
      width: '150px',
    },
    {
      title: 'Added at',
      dataIndex: 'createdAt',
      className: 'text-gray',
      render: (createdAt: string) => (
        <div className="w-full text-center">
          {dayjs(createdAt).format('DD/MM/YYYY')}
        </div>
      ),
      width: '150px',
    },
  ];
};

export function getCorrectFieldnames(type: BlacklistType) {
  switch (type) {
    case BlacklistType.EMAIL_DOMAIN:
      return blacklistDomainFields;
    case BlacklistType.LEAD_EMAIL:
      return blacklistEmailFields;
    case BlacklistType.LEAD_LINKEDIN:
      return blacklistLinkedInFields;
    default:
      return BlacklistType.EMAIL_DOMAIN;
  }
}
