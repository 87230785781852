import './Layout.style.css';

import { Form, Radio } from 'antd';
import { Divider } from 'components/Divider';
import {
  categories,
  emailEntries,
  existingEntries,
  newEntries,
} from 'constant/campaignForm';
import { useEffect, useState } from 'react';

import { CampaignGenre } from './CampaignGenre';
import { RadioItem } from './RadioItem';

/**
 * The form type responsible for specifying the campaign type, either
 * from a link or from CSV file.
 *
 * **NOTE:** When editing, the type will be locked.
 *
 * **NOTE:** We assume that a campaign is from a CSV if it's link is empty. This can be a valid solution to avoid any schema changes. Discuss it further.
 */
export function CampaignTypePicker() {
  const [selectedGenre, setSelectedGenre] = useState();

  const selectedType = Form.useWatch('type');
  const form = Form.useFormInstance();

  /* Reset selected category on form mount */
  useEffect(() => {
    setSelectedGenre();
    form.resetFields(['type']);
  }, [form]);

  return (
    <>
      <Divider orientation="center" className="-mt-4">
        Campaign Type
      </Divider>

      <p className="mt-4 mb-8">
        Please select what category of campaign you would like to create
      </p>

      <section className="flex flex-col gap-4 mb-8 md:flex-row">
        {categories.map((category) => (
          <CampaignGenre
            key={category.type}
            category={category}
            isSelected={selectedGenre === category.type}
            onClick={() => {
              setSelectedGenre(category.type);
              form.setFieldsValue({ type: undefined });
            }}
          />
        ))}
      </section>

      <section>
        <Form.Item
          name="type"
          rules={[
            {
              required: true,
              message: 'Please select a campaign type',
            },
          ]}
        >
          <Radio.Group
            name="type"
            className="flex flex-col w-full h-64 gap-1 p-4 align-middle rounded-md bg-gray-50"
            style={{
              backgroundImage: 'url(bg_picker.png)',
              backgroundSize: '10% auto',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          >
            {selectedGenre === 'new' &&
              newEntries.map((item, idx) => (
                <div className="fade-in" key={`newEntries${idx}`}>
                  <RadioItem
                    campaignType={item.camType}
                    img={item.img}
                    title={item.title}
                    desc={item.desc}
                    selected={selectedType === item.camType}
                  />
                </div>
              ))}
            {selectedGenre === 'existing' &&
              existingEntries.map((item, idx) => (
                <div className="fade-in" key={`existingEntries${idx}`}>
                  <RadioItem
                    campaignType={item.camType}
                    img={item.img}
                    title={item.title}
                    desc={item.desc}
                    selected={selectedType === item.camType}
                  />
                </div>
              ))}
            {selectedGenre === 'mail' &&
              emailEntries.map((item, idx) => (
                <div className="fade-in" key={`emailEntries${idx}`}>
                  <RadioItem
                    campaignType={item.camType}
                    img={item.img}
                    title={item.title}
                    desc={item.desc}
                    selected={selectedType === item.camType}
                  />
                </div>
              ))}
          </Radio.Group>
        </Form.Item>
      </section>
    </>
  );
}
