import { Alert, Button, Modal } from 'antd';
import { Dispatch, SetStateAction } from 'react';

import { BLACKLIST_TITLES, BlacklistType } from '../constants';

interface UploadCSVFileModalProps {
  type: BlacklistType;
  csvData: {
    parsed: string[];
    existing: string[];
  };
  isErrorCsv: {
    isError: boolean;
    message: string;
  };
  csvModalOpen: boolean;
  setIsErrorCsv: Dispatch<
    SetStateAction<{ isError: boolean; message: string }>
  >;
  setCsvModalOpen: Dispatch<SetStateAction<boolean>>;
  sendCsvEntriesToDatabase: () => void; // Replace void with the appropriate return type if needed
}

export default function UploadCSVFileModal({
  type,
  csvData,
  isErrorCsv,
  csvModalOpen,
  setIsErrorCsv,
  setCsvModalOpen,
  sendCsvEntriesToDatabase,
}: UploadCSVFileModalProps) {
  return (
    <Modal
      closable={false}
      width={650}
      title={!isErrorCsv.isError ? `Import CSV file` : 'Error'}
      open={csvModalOpen}
      footer={
        <div>
          <Button
            onClick={() => {
              setIsErrorCsv({ isError: false, message: '' });
              setCsvModalOpen(false);
            }}
          >
            Cancel
          </Button>
          {!isErrorCsv.isError && csvData.parsed.length > 0 && (
            <Button type="primary" onClick={sendCsvEntriesToDatabase}>
              Confirm Import
            </Button>
          )}
        </div>
      }
    >
      {!isErrorCsv.isError ? (
        <>
          <div>
            {csvData.existing.length > 0 && (
              <Alert
                type="warning"
                className="my-2 text-xs font-light"
                description={`
                  ${csvData.existing.length}
                  ${BLACKLIST_TITLES[type].toLowerCase()}s contained in the csv
                  ${
                    csvData.existing.length === 1 ? 'is' : 'are'
                  } already present
                  in your blacklist, therefore
                  ${csvData.existing.length === 1 ? 'it' : 'they'} will not be
                  added again
                `}
              />
            )}
            {csvData.parsed.length > 0 && (
              <div className="max-h-[24rem] p-4 overflow-y-scroll font-semibold rounded">
                Would you like to add the following {csvData.parsed.length}{' '}
                {BLACKLIST_TITLES[type].toLowerCase()}
                {csvData.parsed.length === 1 ? '' : 's'} to your blacklist ?
                <ul className="mt-2 -ml-4 font-normal">
                  {csvData.parsed.map((data, i) => (
                    <li key={`element-${i}`}>{data}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </>
      ) : (
        <Alert description={isErrorCsv.message} type="error" />
      )}
    </Modal>
  );
}
