import { InfoCircleFilled } from '@ant-design/icons';
import { Drawer, Form, Input, InputNumber } from 'antd';
import { CREATE_CAMPAIGN } from 'constant/campaignForm';
import EmailVerifier from 'modules/Campaign/components/EmailVerifier';
import FormLabel from 'modules/Campaign/components/Form/FormLabel';
import FormSection from 'modules/Campaign/components/Form/FormSection';
import EmailConfigurationHelper from 'modules/Campaign/CreateCampaign/components/EmailConfigurationHelper';
import { useState } from 'react';
import {
  getTypeDescription,
  isEmailTargetMargetCampaignType,
} from 'services/campaignType';

export const BasicEmailInfo = () => {
  const form = Form.useFormInstance();
  const camType = form.getFieldValue('type');

  const [open, setOpen] = useState(false);
  const showOnboardingHelper = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className="grid gap-6 fade-in">
      <FormSection
        title={CREATE_CAMPAIGN.name.title}
        subTitle={`Type: ${getTypeDescription(camType)}${
          isEmailTargetMargetCampaignType(camType)
            ? ' | Target Market Selected: ' +
              form.getFieldValue('targetMarketName')
            : ''
        }`}
      >
        <FormLabel>Campaign name</FormLabel>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: 'Please give the campaign a name',
            },
          ]}
          className="mb-0"
        >
          <Input placeholder="Campaign name" />
        </Form.Item>
      </FormSection>

      <FormSection
        title={CREATE_CAMPAIGN.emailAccount.title}
        subTitle={CREATE_CAMPAIGN.emailAccount.subTitle}
      >
        <div className="grid grid-cols-6 gap-x-4">
          <div className="col-span-6 sm:col-span-3">
            <FormLabel>Address</FormLabel>
            <Form.Item
              name="emailAddress"
              rules={[
                {
                  required: true,
                  message: 'Please provide the email address',
                },
                {
                  type: 'email',
                  message: 'Please enter a valid email address',
                },
              ]}
            >
              <Input placeholder="Email Address" />
            </Form.Item>
          </div>

          <div className="col-span-6 sm:col-span-3">
            <FormLabel>Password</FormLabel>
            <Form.Item
              name="emailPassword"
              rules={[
                {
                  required: true,
                  message: 'Please enter the subject of the email',
                },
              ]}
            >
              <Input placeholder="Email Password" type="password" />
            </Form.Item>
          </div>
        </div>
      </FormSection>

      <FormSection
        title={CREATE_CAMPAIGN.emailSetup.title}
        subTitle={CREATE_CAMPAIGN.emailSetup.subTitle}
      >
        <div className="grid grid-cols-6 gap-x-4">
          <div className="col-span-2">
            <FormLabel>SMTP Address</FormLabel>
            <Form.Item
              name="smtpAddress"
              rules={[
                {
                  required: true,
                  message: 'Please provide a valid SMTP address',
                },
              ]}
            >
              <Input placeholder="SMTP Address" />
            </Form.Item>
          </div>

          <div className="col-span-1">
            <FormLabel>SMTP Port</FormLabel>
            <Form.Item
              name="smtpPort"
              rules={[
                {
                  required: true,
                  message: 'Please specify a SMTP port number',
                },
              ]}
            >
              <InputNumber
                placeholder="Port #"
                controls={false}
                className="w-full"
              />
            </Form.Item>
          </div>

          <div className="col-span-2">
            <FormLabel>IMAP Address</FormLabel>
            <Form.Item
              name="imapAddress"
              rules={[
                {
                  required: true,
                  message: 'Please provide a valid IMAP address',
                },
              ]}
            >
              <Input placeholder="IMAP Address" />
            </Form.Item>
          </div>

          <div className="col-span-1">
            <FormLabel>IMAP Port</FormLabel>
            <Form.Item
              name="imapPort"
              rules={[
                {
                  required: true,
                  message: 'Please specify an IMAP port number',
                },
              ]}
            >
              <InputNumber
                placeholder="Port #"
                controls={false}
                className="w-full"
              />
            </Form.Item>
          </div>
        </div>
        <div className="flex gap-3 p-4 rounded-md bg-slate-50">
          <InfoCircleFilled className="text-2xl text-secondary" />
          <div>
            Please note that in order to have the most possible reduced amount
            of bounced emails, SPF, CNAME and DKIM needs to be configured
            correctly. If in doubt, please contact our staff in order to know
            how before proceeding
            {
              //TODO Restore once the email campaign will be starting to be used by actual customers
              /* Click{' '}
            <Button
              type="link"
              className="p-0 m-0"
              onClick={showOnboardingHelper}
            >
              here
            </Button>{' '}
            to see how. */
            }
          </div>
        </div>
      </FormSection>
      <EmailVerifier />
      <Drawer
        title="Advanced Settings Guide"
        placement={'right'}
        onClose={onClose}
        open={open}
        width={800}
      >
        <EmailConfigurationHelper />
      </Drawer>
    </div>
  );
};
