import { useMutation, useQuery } from '@apollo/client';
import {
  Alert,
  Button,
  Form,
  Input,
  message,
  notification,
  Skeleton,
  Spin,
} from 'antd';
import API from 'api';
import { Divider } from 'components/Divider';
import { CREATE_CAMPAIGN } from 'constant/campaignForm';
import {
  ADD_GET_ACCOUNTS_TASK,
  ADD_GET_DECISION_MAKERS_TASK,
  GET_C_ROLES,
  GET_LINKEDIN_FILTERS,
} from 'constant/usersGraphQl';
import { useUser } from 'hooks/useUser';
import { FormButtons } from 'modules/Campaign/components/Form/FormButtons';
import FormLabel from 'modules/Campaign/components/Form/FormLabel';
import FormSection from 'modules/Campaign/components/Form/FormSection';
import { FilterItem } from 'modules/Campaign/TargetMarkets/components/Filters/FilterItem';
import {
  groupOptions,
  INCLUDE_FILTERS,
} from 'modules/Campaign/TargetMarkets/targetMarketHelper';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';

export const CreateTargetMarket = ({
  setShowTargetMarketForm,
  isInForm = true,
  targetToShow,
  setTargetToShow,
}) => {
  const campaignForm = Form.useFormInstance();

  const { user } = useUser();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(undefined);

  const {
    data: linkedInFiltersData,
    loading: filtersLoading,
    error,
  } = useQuery(GET_LINKEDIN_FILTERS, {
    fetchPolicy: 'no-cache',
  });

  const { data: rolesData, loading: rolesLoading } = useQuery(GET_C_ROLES, {
    fetchPolicy: 'no-cache',
  });

  /**
   * If we are showing the TargetMarket we load the values straight from the record (filters) and set the form instance values to it.
   * On unmount of the component we reset the targetToShow to undefined to avoid that going into create target market could show already the values showed in precedence
   * All of this happens only when the component is loaded in the main TargetMarket page and not in the creation of the campaign
   */
  useEffect(() => {
    if (!isInForm && targetToShow) {
      campaignForm.setFieldsValue(targetToShow.filters);
    }

    return () => {
      if (!isInForm) setTargetToShow(undefined);
    };
  }, [campaignForm, isInForm, targetToShow, setTargetToShow]);

  const [addGetAccountsTask] = useMutation(ADD_GET_ACCOUNTS_TASK);
  const [addGetDecisionMakersTask] = useMutation(ADD_GET_DECISION_MAKERS_TASK);
  const { filters, headquartersLocationFilter } = useMemo(() => {
    if (!linkedInFiltersData) return { filters: [], aggregationFilters: [] };

    const filters = [];

    for (const filterName of INCLUDE_FILTERS) {
      const filter = linkedInFiltersData.linkedInFilters.find(
        (filter) => filter.name === filterName
      );
      if (filter) filters.push(filter);
    }

    // TODO: This is a hack to get the headquarters location filter set to Germany, but it should be done in a better way
    // since it's not guaranteed that the filter will always be there from the BE
    const headquartersLocationFilter = linkedInFiltersData.linkedInFilters.find(
      (filter) =>
        filter.name === 'REGION' &&
        filter.aggregationName === 'HEADQUARTERS_LOCATION'
    );

    return { filters, headquartersLocationFilter };
  }, [linkedInFiltersData]);

  function resetFields() {
    campaignForm.resetFields(['targetMarketName', 'filters']);
    setSelectedFilter(undefined);
  }

  /**
   * getDataReadyToBeSent
   * @param {*} formValues - Values of the form before being "cleaned up" for the request
   * @returns requestData, seniorityLevels
   */
  //TODO Rename accordignly
  function getDataReadyToBeSent(formValues) {
    const seniorityLevelsId = formValues?.filters['SENIORITY_LEVEL'][0].id;
    const flattenedValues = Object.values(formValues.filters)
      .flat()
      .map((filter) => ({
        id: filter.id,
        subFilterId: filter.subFilterId,
        rangeValues: filter.rangeValues,
        textValues: filter.textValues,
        options: filter.options,
        aggregationName: filter.aggregationName,
      }));

    const flattenedArray = groupOptions(flattenedValues);
    const seniorityLevelsObj = flattenedArray.find(
      (element) => element.id === seniorityLevelsId
    );
    const seniorityLevels = seniorityLevelsObj.options.map((level) => level.id);
    const requestData = flattenedArray.filter(
      (element) => element.id != seniorityLevelsId
    );
    return { requestData, seniorityLevels };
  }

  const onFinish = async () => {
    campaignForm.validateFields();
    const formValues = campaignForm.getFieldsValue(true);

    if (
      formValues.targetMarketName === undefined ||
      formValues.filters === undefined ||
      formValues.filters.SENIORITY_LEVEL === undefined
    ) {
      message.error(
        'Please give a name, at least one filter and at least one role to this Target Market'
      );
      return;
    }

    setIsLoading(true);
    //We hardcode the REGION value to be Germany
    campaignForm.setFieldValue(
      ['filters', 'REGION'],
      [
        {
          id: headquartersLocationFilter.id,
          options: { id: '101282230', type: 'INCLUDED' },
        },
      ]
    );

    const { requestData, seniorityLevels } = getDataReadyToBeSent(
      campaignForm.getFieldsValue()
    );

    try {
      const { data: addGetAccountsTaskResponse } = await addGetAccountsTask({
        variables: { selectedFilters: requestData },
      });

      await addGetDecisionMakersTask({
        variables: {
          searchId: addGetAccountsTaskResponse.addGetAccountsTask.searchId,
          seniorityLevelIds: seniorityLevels,
        },
      });

      await API.createTargetMarket({
        userId: user.id,
        name: formValues.targetMarketName,
        companyDataServiceGetAccountsTaskId:
          addGetAccountsTaskResponse.addGetAccountsTask.id,
        filters: formValues,
      });

      notification.success({
        message: `The target market ${formValues.targetMarketName} has been created successfully`,
      });
      setIsLoading(false);
      setShowTargetMarketForm(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      notification.error({
        message: 'There was an error during the creation of the Target Market',
      });
    }
  };

  if (error)
    return (
      <div className="w-full h-[200px] flex items-center justify-center gap-2 text-gray-500">
        <Alert
          type="warning"
          showIcon
          description="There was a problem loading the filters. Please try again later."
        />
      </div>
    );

  return (
    <div className="pb-6 fade-in">
      <Form.Item
        name="filters"
        hidden
        required
        rules={[
          { required: true, message: 'Please select at least one filter' },
        ]}
      >
        <Input />
      </Form.Item>
      <Spin
        delay={100}
        spinning={isLoading}
        tip={
          <div className="flex items-center justify-center p-4 text-primary">
            Selecting companies that fit your filters...
          </div>
        }
        size="large"
      >
        <FormSection title={CREATE_CAMPAIGN.targetMarket.title}>
          <div className="grid grid-cols-6 gap-x-4">
            <div className="col-span-6 sm:col-span-3">
              <FormLabel>Name</FormLabel>
              <Form.Item
                name="targetMarketName"
                rules={[
                  {
                    required: true,
                    message: 'Please provide the name',
                  },
                ]}
              >
                <Input
                  placeholder="Target Market Name"
                  disabled={targetToShow != undefined}
                />
              </Form.Item>
            </div>

            <div className="col-span-6 sm:col-span-3">
              <FormLabel>Country</FormLabel>
              <Form.Item
                initialValue={'Germany'}
                name="country"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the country',
                  },
                ]}
              >
                <Input placeholder="Country" disabled />
              </Form.Item>
            </div>
          </div>
        </FormSection>

        {/* //* ______Filters Divider */}
        <Divider
          style={{ fontSize: '12px', marginTop: '2.5rem' }}
          orientation="center"
        >
          Filters available
        </Divider>

        <Skeleton
          active
          paragraph={{ rows: 8 }}
          loading={filtersLoading || rolesLoading}
          size={'small'}
          title={'Title'}
        >
          {/* //* ______Filters Cards */}
          {filters.length != 0 && !filtersLoading && (
            <div>
              <div className="flex flex-col gap-2 md:flex-row">
                <div className="flex-col flex-1 divide-x-0 divide-y divide-gray-200 divide-solid">
                  {filters.map((filter, index) => (
                    <FilterItem
                      key={'filtercard-' + index}
                      filter={filter}
                      selectedFilter={selectedFilter}
                      setSelectedFilter={setSelectedFilter}
                      disableActions={targetToShow !== undefined}
                    />
                  ))}
                </div>
              </div>

              {/* //* ______Filters Divider */}
              <Divider style={{ fontSize: '12px' }} orientation="center">
                Seniority Level
              </Divider>
              {!rolesLoading && (
                <div className="my-2">
                  <FilterItem
                    key={'filtercard-roles'}
                    disableActions={targetToShow !== undefined}
                    filter={rolesData?.linkedInFilterByTypeGroupAndName}
                    rules={[
                      {
                        required: true,
                        message: 'Please select the Seniority level/s',
                      },
                    ]}
                  />
                </div>
              )}
              <FormButtons>
                <Button
                  onClick={() => {
                    resetFields();
                    setShowTargetMarketForm(false);
                  }}
                >
                  Back
                </Button>
                {!targetToShow && (
                  <div className="flex gap-2">
                    <Button onClick={resetFields}>Reset</Button>
                    <Button type="primary" onClick={onFinish}>
                      {'Create'}
                    </Button>
                  </div>
                )}
              </FormButtons>
            </div>
          )}
        </Skeleton>
      </Spin>
    </div>
  );
};

CreateTargetMarket.propTypes = {
  setShowTargetMarketForm: PropTypes.func,
  isInForm: PropTypes.bool,
  targetToShow: PropTypes.object,
  setTargetToShow: PropTypes.func,
};
