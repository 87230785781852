import routes from 'routes';

import { Blacklist } from './Blacklist';

const BlacklistPage = () => <Blacklist />;

export default {
  routeProps: {
    path: routes.blacklist,
    component: BlacklistPage,
  },
  name: 'blacklist',
  routeVisibility: 'private',
};
