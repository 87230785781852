import { Tag } from 'antd';
import { EXCLUSION } from 'constant/targetMarket';
import PropTypes from 'prop-types';

import { isMultiWithExclusion } from '../../targetMarketHelper';

export const ValueSelectedPill = ({ value, filter, children, onClick }) => {
  return (
    <Tag
      color={
        isMultiWithExclusion(filter)
          ? value?.options?.type === EXCLUSION.INCLUDED
            ? 'green'
            : 'red'
          : 'green'
      }
      className="my-0.5 hover:text-red-400 cursor-pointer"
      onClick={onClick}
    >
      {children}
    </Tag>
  );
};

ValueSelectedPill.propTypes = {
  value: PropTypes.object,
  filter: PropTypes.object,
  children: PropTypes.any,
  onClick: PropTypes.func,
};
