import './DashboardTable.styles.css';

import {
  DeleteFilled,
  LinkedinFilled,
  MoreOutlined,
  SettingFilled,
  UserOutlined,
} from '@ant-design/icons';
import Icon from '@ant-design/icons/lib/components/Icon';
import { Dropdown, message, Space, Table, Tag, Tooltip } from 'antd';
import API from 'api';
import { linkedinIconStyle, mailIconStyle, tagStyle } from 'constant/styles';
import {
  ArrowCircleRightRounded,
  LockPerson,
  MailOutline,
  PauseCampaign,
  PlayCampaign,
} from 'modules/Campaign/components/Icons';
import { DeleteCampaign } from 'modules/Campaign/DeleteCampaign/DeleteCampaign';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import routes from 'routes';
import {
  isEmailCampaignType,
  isExistingCampaignType,
  isLinkedinCampaignType,
  isOutreachCampaignType,
} from 'services/campaignType';

import EmailStats from './CampaignTypeStats/EmailStats';
import ExistingStats from './CampaignTypeStats/ExistingStats';
import OutreachStats from './CampaignTypeStats/OutreachStats';

/** Table which contain all the campaign for the user */
export function DashboardTable({
  updateCampaignStatus,
  campaigns,
  reloadCampaigns,
}) {
  const [deleteModal, setDeleteModal] = useState({
    visible: false,
    campaign: {},
  });

  /**
   * On clicking on an action this callback acts on it, parsing the key to extract the action and the campaign id
   * @param {*} key of the action dropdown that gets pressed
   */
  const onClick = ({ key }) => {
    const [itemkey, campaignId] = key.split('_');
    const campaign = campaigns.find((camp) => camp.id === +campaignId);
    switch (itemkey) {
      case 'changeStatus':
        toggleStatus(+campaignId, campaign.status);
        break;
      case 'delete':
        setDeleteModal({ visible: true, campaign: campaign });
        break;
      default:
        break;
    }
  };

  /**
   * Toggles the campaign status
   * @param {*} id number - Campaign ID
   * @param {*} currentStatus string - Status to update to
   */
  const toggleStatus = (id, currentStatus) => {
    const status = currentStatus === 'ACTIVE' ? 'STOPPED' : 'ACTIVE';
    try {
      API.updateCampaign(id, { campaign: { status } });
      updateCampaignStatus(id, status);
    } catch (error) {
      message.error('Campaign could not be updated');
    }
  };

  const columns = [
    {
      title: 'Campaign',
      dataIndex: 'name',
      className: 'w-4/12',
      key: 'name',
      render: (name, campaign) => (
        <div className="flex-1">
          {/* Navigate to the campaign Lead Table */}
          <div className="flex justify-center align-middle">
            <Tooltip
              title="Check the lead details of the campaign"
              mouseEnterDelay={1}
            >
              <Link
                className="text-xs font-bold text-center text-gray-500 hover:text-secondary sm:text-xs lg:text-base"
                to={`/ilg/campaign/${campaign.id}/leads`}
              >
                {name}
              </Link>
            </Tooltip>
            {isLinkedinCampaignType(campaign.type) &&
              campaign['email.address'] &&
              !campaign['email.isAuthenticated'] && (
                <Tooltip title="Email not authenticated">
                  <LockPerson className="mx-1 mt-1.5 text-xs text-red-300" />
                </Tooltip>
              )}
          </div>
          <div className="dashboard__linked-in-account">
            {isLinkedinCampaignType(campaign.type) && (
              <div className="flex flex-row justify-center mt-2 align-middle">
                <LinkedinFilled style={linkedinIconStyle} />{' '}
                <div className="ml-2 dashboard__linked-in-account">
                  <div>{campaign['linkedInAccount.email']}</div>
                </div>
              </div>
            )}
            {isEmailCampaignType(campaign.type) && (
              <div className="flex flex-row justify-center mt-2 align-middle w-">
                <MailOutline style={mailIconStyle} />{' '}
                <div className="ml-2 dashboard__linked-in-account">
                  <div>{campaign['email.address']}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      className: 'hidden sm:table-cell w-2/12',
      key: 'status',
      render: (status, campaign) => (
        <div className="flex flex-col justify-around max-w-sm text-center align-middle">
          <div className="p-2">
            <Tag style={tagStyle} color={status === 'ACTIVE' ? 'green' : 'red'}>
              {campaign.status === 'ACTIVE' ? 'Active' : 'Inactive'}
            </Tag>
          </div>

          <div>
            <Space align="middle" className="text-gray-500">
              <UserOutlined />
              <div>{campaign.numOfLeads}</div>
            </Space>
          </div>
        </div>
      ),
    },
    {
      title: 'Analytics',
      className: 'hidden sm:table-cell w-4/12',
      key: 'status',
      render: (_, campaign) => (
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-between w-11/12 text-center">
            {isEmailCampaignType(campaign.type) && (
              <EmailStats campaign={campaign} />
            )}
            {isOutreachCampaignType(campaign.type) && (
              <OutreachStats campaign={campaign} />
            )}
            {isExistingCampaignType(campaign.type) && (
              <ExistingStats campaign={campaign} />
            )}
          </div>
          <div className="mr-2">
            <Link
              to={`/ilg/campaign/${
                campaign.id
              }/analytics?startDate=${encodeURIComponent(campaign.createdAt)}`}
            >
              <Tooltip title="View the analytics" mouseEnterDelay="0.6">
                <Icon
                  component={ArrowCircleRightRounded}
                  className="text-xl transition cursor-pointer text-secondary hover:text-primary hover:translate-x-1"
                />
              </Tooltip>
            </Link>
          </div>
        </div>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      className: 'max-w-sm text-center w-1/12',
      render: (campaign) => {
        const items = [
          {
            icon:
              campaign.status === 'ACTIVE' ? (
                <Icon component={PauseCampaign} />
              ) : (
                <Icon component={PlayCampaign} />
              ),
            label:
              campaign.status === 'ACTIVE'
                ? 'Pause campaign'
                : 'Resume campaign',
            key: `changeStatus_${campaign.id}`,
          },
          {
            icon: <SettingFilled />,
            label: (
              <Link to={routes.editCampaign.replace(':id', campaign.id)}>
                Edit campaign
              </Link>
            ),
            key: `edit_${campaign.id}`,
          },
          {
            icon: <DeleteFilled />,
            label: 'Delete campaign',
            key: `delete_${campaign.id}`,
            danger: true,
          },
        ];

        return (
          <Dropdown
            //I left this prop commented here because it is not present in the documentation, in case this needs to be modified can be useful to have it here
            // align={{
            //   offset: [0, 65],
            // }}
            placement="left"
            trigger={'click'}
            menu={{ items, onClick }}
            className="text-gray-500"
            openClassName="text-primary"
          >
            <MoreOutlined className="text-xl text-gray-500 pointer" />
          </Dropdown>
        );
      },
    },
  ];
  return (
    <>
      <Table
        tableLayout={'fixed'}
        showHeader={true}
        dataSource={campaigns}
        rowKey={(campaign) => campaign.id}
        columns={columns}
        size="small"
        pagination={false}
      />

      <DeleteCampaign
        visible={deleteModal.visible}
        campaign={deleteModal.campaign}
        setVisible={setDeleteModal}
        reloadCampaigns={reloadCampaigns}
      />
    </>
  );
}

DashboardTable.propTypes = {
  /** SetState function to campaigns state */
  updateCampaignStatus: PropTypes.func.isRequired,
  /** Array containing the campaigns */
  campaigns: PropTypes.array.isRequired,
  /** Function to re-fetch campaigns from the api */
  reloadCampaigns: PropTypes.func.isRequired,
};
