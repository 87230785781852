import { PlusOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import { EmailFollowupsAccountForm } from 'modules/Campaign/components/Form/EmailFollowupsAccountForm';
import FormElement from 'modules/Campaign/components/Form/FormSection';
import { MessageFormItem } from 'modules/Campaign/components/Form/MessageFormItem';
import PropTypes from 'prop-types';
import { useMemo, useRef } from 'react';
import { isLinkedinCampaignType } from 'services/campaignType';

export const EmailFollowups = ({ isEdit = false, campaign }) => {
  /** Used as a scroll target. */
  const bottomRef = useRef(null);

  const formListName = 'emailFollowups';

  const form = Form.useFormInstance();
  const camType = form.getFieldValue('type');
  const emailFollowups = Form.useWatch(formListName, form);

  /* 
    If any follow ups exists then the email address and the subject also need to
    exist
  */
  const isRequired = useMemo(() => !!emailFollowups, [emailFollowups]);

  return (
    <FormElement
      className="fade-in flex flex-col"
      title="Email Follow Ups"
      subTitle={`Follow up emails are sent if a lead didn't reply${
        isLinkedinCampaignType(camType) ? ' on LinkedIn' : ''
      }.
          You must provide a gmail account (that is going to be used for
          sending and tracking the emails) other than the unique subject and content of the actual email.`}
    >
      {isLinkedinCampaignType(camType) && isRequired && (
        <EmailFollowupsAccountForm
          isRequired={isRequired}
          isEditing={isEdit}
          campaign={campaign}
          className="mb-4"
        />
      )}
      <Form.List name={formListName}>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <MessageFormItem
                key={key}
                listName={formListName}
                name={name}
                remove={() => {
                  remove(name);
                  /** Removes the emailFollowups array from the form instance */
                  if (fields.length === 1) {
                    form.setFieldValue(formListName, undefined);
                  }
                }}
                restField={restField}
              />
            ))}
            <Form.Item>
              {fields.length < (isLinkedinCampaignType(camType) ? 2 : 6) && (
                <Button
                  type="dashed"
                  onClick={() => {
                    add({ interval: 1 });
                    setTimeout(() =>
                      bottomRef.current.scrollIntoView({
                        behavior: 'smooth',
                      })
                    );
                  }}
                  className="w-full mt-6 text-primary"
                  icon={<PlusOutlined />}
                >
                  Add email follow up message
                </Button>
              )}
              <Form.ErrorList errors={errors} />
              <div ref={bottomRef} />
            </Form.Item>
          </>
        )}
      </Form.List>
    </FormElement>
  );
};

EmailFollowups.propTypes = {
  isEdit: PropTypes.bool,
  campaign: PropTypes.object,
};
