import Icon from '@ant-design/icons';

export const markStyle = { color: '#808080', fontSize: '12px' };
export const genderIconStyle = {
  margin: '0 3px',
  color: '#646464',
  fontSize: '12px',
};

export function MaleFilledSVG(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="0.92em"
      height="1em"
      viewBox="0 0 22 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M14.145 16.629a23.876 23.876 0 0 1-.052-2.525l-.001.037a4.847 4.847 0 0 0 1.333-2.868l.002-.021c.339-.028.874-.358 1.03-1.666a1.217 1.217 0 0 0-.455-1.218l-.003-.002c.552-1.66 1.698-6.796-2.121-7.326C13.485.35 12.479 0 11.171 0c-5.233.096-5.864 3.951-4.72 8.366a1.222 1.222 0 0 0-.455 1.229l-.001-.008c.16 1.306.691 1.638 1.03 1.666a4.858 4.858 0 0 0 1.374 2.888a24.648 24.648 0 0 1-.058 2.569l.005-.081C7.308 19.413.32 18.631 0 24h22.458c-.322-5.369-7.278-4.587-8.314-7.371z"
      ></path>
    </svg>
  );
}

export const MaleFilledIcon = (props) => (
  <Icon component={MaleFilledSVG} {...props} />
);

export function MaleIconOutlined(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.13em"
      height="1em"
      viewBox="0 0 36 32"
      {...props}
    >
      <path
        fill="#626262"
        d="M.5 31.983a.503.503 0 0 0 .612-.354c1.03-3.843 5.216-4.839 7.718-5.435c.627-.149 1.122-.267 1.444-.406c2.85-1.237 3.779-3.227 4.057-4.679a.5.5 0 0 0-.165-.473c-1.484-1.281-2.736-3.204-3.526-5.416a.492.492 0 0 0-.103-.171c-1.045-1.136-1.645-2.337-1.645-3.294c0-.559.211-.934.686-1.217a.5.5 0 0 0 .243-.408C10.042 5.036 13.67 1.026 18.12 1l.107.007c4.472.062 8.077 4.158 8.206 9.324a.498.498 0 0 0 .178.369c.313.265.459.601.459 1.057c0 .801-.427 1.786-1.201 2.772a.522.522 0 0 0-.084.158c-.8 2.536-2.236 4.775-3.938 6.145a.502.502 0 0 0-.178.483c.278 1.451 1.207 3.44 4.057 4.679c.337.146.86.26 1.523.403c2.477.536 6.622 1.435 7.639 5.232a.5.5 0 0 0 .966-.26c-1.175-4.387-5.871-5.404-8.393-5.95c-.585-.127-1.09-.236-1.336-.344c-1.86-.808-3.006-2.039-3.411-3.665c1.727-1.483 3.172-3.771 3.998-6.337c.877-1.14 1.359-2.314 1.359-3.317c0-.669-.216-1.227-.644-1.663C27.189 4.489 23.19.076 18.227.005l-.149-.002c-4.873.026-8.889 4.323-9.24 9.83c-.626.46-.944 1.105-.944 1.924c0 1.183.669 2.598 1.84 3.896c.809 2.223 2.063 4.176 3.556 5.543c-.403 1.632-1.55 2.867-3.414 3.676c-.241.105-.721.22-1.277.352c-2.541.604-7.269 1.729-8.453 6.147a.5.5 0 0 0 .354.612z"
      ></path>
    </svg>
  );
}

export function FemaleFilledSVG(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="0.92em"
      height="1em"
      viewBox="0 0 22 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M14.041 16.683a14.884 14.884 0 0 1-.035-.72c2.549-.261 4.338-.872 4.338-1.585c-.007 0-.006-.03-.006-.041C16.432 12.619 19.99.417 13.367.663a3.344 3.344 0 0 0-2.196-.664h.008C2.208.677 6.175 12.202 4.13 14.377h-.004c.008.698 1.736 1.298 4.211 1.566c-.007.17-.022.381-.054.734C7.256 19.447.321 18.671.001 24h22.294c-.319-5.33-7.225-4.554-8.253-7.317z"
      ></path>
    </svg>
  );
}

export const FemaleFilledIcon = (props) => (
  <Icon component={FemaleFilledSVG} {...props} />
);

export function FemaleIconOutlined(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.13em"
      height="1em"
      viewBox="0 0 36 32"
      {...props}
    >
      <path
        fill="currentColor"
        d="M22.22 20.726a.485.485 0 0 0-.425.155a.499.499 0 0 0-.126.435c.276 1.45 1.205 3.44 4.057 4.679c.338.146.86.26 1.522.403c2.478.536 6.622 1.434 7.64 5.232a.5.5 0 0 0 .967-.26c-1.177-4.387-5.872-5.404-8.395-5.95c-.584-.127-1.089-.236-1.336-.344c-1.714-.744-2.821-1.848-3.301-3.286c4.762.354 6.918-1.543 7.013-1.629a.5.5 0 0 0-.088-.805C27 17.786 27 12.361 27 10.318C27 4.605 23.152.076 18.219.005a5.875 5.875 0 0 0-.141-.003h-.001C13.072.03 9 4.564 9 10.11c0 2.043 0 7.469-2.748 9.038a.503.503 0 0 0-.06.829c.138.107 3.188 2.441 6.941 1.732c-.502 1.378-1.594 2.438-3.258 3.161c-.241.105-.721.22-1.277.352c-2.54.604-7.269 1.729-8.453 6.147a.5.5 0 0 0 .967.259c1.029-3.844 5.217-4.839 7.718-5.435c.627-.149 1.122-.267 1.444-.406c2.852-1.239 3.78-3.229 4.057-4.679a.497.497 0 0 0-.159-.467a.497.497 0 0 0-.482-.104c-2.707.852-5.208-.32-6.301-.97C10 17.381 10 12.35 10 10.11c0-4.997 3.626-9.083 8.12-9.11l.106.006C22.658 1.069 26 5.072 26 10.318c0 2.241 0 7.283 2.622 9.469c-.903.516-2.962 1.351-6.402.939z"
      ></path>
    </svg>
  );
}

export function FilterIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="m17 21l1.8 1.77c.5.5 1.2.1 1.2-.49V18l2.8-3.4A1 1 0 0 0 22 13h-7c-.8 0-1.3 1-.8 1.6L17 18v3m-5-10h8V3a2 2 0 0 0-2-2H4c-1.1 0-2 .9-2 2v12a2 2 0 0 0 2 2h9.42L12 15v-4m0-6h6v4h-6V5m-2 10H4v-4h6v4m0-6H4V5h6v4Z"
      ></path>
    </svg>
  );
}

export function EditIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 -4 26 26"
      className="w-4 sm:w-6 md:w-8"
    >
      <path
        fill="currentColor"
        d="M13.875 22h-3.75q-.375 0-.65-.25t-.325-.625l-.3-2.325q-.325-.125-.612-.3q-.288-.175-.563-.375l-2.175.9q-.35.125-.7.025t-.55-.425L2.4 15.4q-.2-.325-.125-.7q.075-.375.375-.6l1.875-1.425Q4.5 12.5 4.5 12.337v-.675q0-.162.025-.337L2.65 9.9q-.3-.225-.375-.6q-.075-.375.125-.7l1.85-3.225q.175-.35.537-.438q.363-.087.713.038l2.175.9q.275-.2.575-.375q.3-.175.6-.3l.3-2.325q.05-.375.325-.625t.65-.25h3.75q.375 0 .65.25t.325.625l.3 2.325q.325.125.613.3q.287.175.562.375l2.175-.9q.35-.125.7-.025t.55.425L21.6 8.6q.2.325.125.7q-.075.375-.375.6l-1.875 1.425q.025.175.025.337v.675q0 .163-.05.338l1.875 1.425q.3.225.375.6q.075.375-.125.7l-1.85 3.2q-.2.325-.562.438q-.363.112-.713-.013l-2.125-.9q-.275.2-.575.375q-.3.175-.6.3l-.3 2.325q-.05.375-.325.625t-.65.25Zm-1.825-6.5q1.45 0 2.475-1.025Q15.55 13.45 15.55 12q0-1.45-1.025-2.475Q13.5 8.5 12.05 8.5q-1.475 0-2.488 1.025Q8.55 10.55 8.55 12q0 1.45 1.012 2.475Q10.575 15.5 12.05 15.5Z"
      />
    </svg>
  );
}

export function AnalyticsIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 -4 26 26"
      className="w-4 sm:w-6 md:w-8"
    >
      <path
        fill="currentColor"
        d="M5 21q-.825 0-1.413-.587Q3 19.825 3 19V5q0-.825.587-1.413Q4.175 3 5 3h14q.825 0 1.413.587Q21 4.175 21 5v14q0 .825-.587 1.413Q19.825 21 19 21Zm3-4q.425 0 .713-.288Q9 16.425 9 16v-3q0-.425-.287-.713Q8.425 12 8 12t-.713.287Q7 12.575 7 13v3q0 .425.287.712Q7.575 17 8 17Zm8 0q.425 0 .712-.288Q17 16.425 17 16V8q0-.425-.288-.713Q16.425 7 16 7t-.712.287Q15 7.575 15 8v8q0 .425.288.712q.287.288.712.288Zm-4 0q.425 0 .713-.288Q13 16.425 13 16v-1q0-.425-.287-.713Q12.425 14 12 14t-.712.287Q11 14.575 11 15v1q0 .425.288.712q.287.288.712.288Zm0-5q.425 0 .713-.288Q13 11.425 13 11v-.025q0-.425-.287-.7Q12.425 10 12 10t-.712.287Q11 10.575 11 11v.025q0 .425.288.7q.287.275.712.275Z"
      />
    </svg>
  );
}

export function DeleteIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 -5 26 26"
      className="w-4 sm:w-6 md:w-8"
    >
      <path
        fill="currentColor"
        d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
      />
    </svg>
  );
}

export function MailRead(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M12 19a6.995 6.995 0 0 1 10-6.32V6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h8.08c-.05-.33-.08-.66-.08-1zM4 6l8 5l8-5v2l-8 5l-8-5V6zm13.34 16l-3.54-3.54l1.41-1.41l2.12 2.12l4.24-4.24L23 16.34L17.34 22z"
      ></path>
    </svg>
  );
}

export function MailSent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M13 19c0-3.31 2.69-6 6-6c1.1 0 2.12.3 3 .81V6a2 2 0 0 0-2-2H4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h9.09c-.05-.33-.09-.66-.09-1M4 8V6l8 5l8-5v2l-8 5l-8-5m16 14v-2h-4v-2h4v-2l3 3l-3 3Z"
      ></path>
    </svg>
  );
}

export function MailOpened(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M12 .64L8.23 3H5v2L2.97 6.29C2.39 6.64 2 7.27 2 8v10a2 2 0 0 0 2 2h16c1.11 0 2-.89 2-2V8c0-.73-.39-1.36-.97-1.71L19 5V3h-3.23M7 5h10v4.88L12 13L7 9.88M8 6v1.5h8V6M5 7.38v1.25L4 8m15-.62L20 8l-1 .63M8 8.5V10h8V8.5Z"
      ></path>
    </svg>
  );
}

export function MailNotSent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M21 12.13c-.15.01-.29.06-.39.17l-1 1l2.05 2l1-1c.22-.21.22-.56 0-.77l-1.24-1.23a.557.557 0 0 0-.38-.17m-2 1.75L13 19.94V22h2.06l6.06-6.07M20 4H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h7v-.89l8.24-8.22c.47-.49 1.12-.76 1.8-.76c.34 0 .68.06 1 .19V6c0-1.12-.92-2-2.04-2m0 4l-8 5l-8-5V6l8 5l8-5"
      ></path>
    </svg>
  );
}

export function MailBounced(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M13 19c0-3.31 2.69-6 6-6c1.1 0 2.12.3 3 .81V6a2 2 0 0 0-2-2H4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h9.09c-.05-.33-.09-.66-.09-1M4 8V6l8 5l8-5v2l-8 5l-8-5m14 8v2h4v2h-4v2l-3-3l3-3Z"
      ></path>
    </svg>
  );
}

export function MailAtIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M12 22q-2.05 0-3.875-.788q-1.825-.787-3.187-2.15q-1.363-1.362-2.15-3.187Q2 14.05 2 12q0-2.075.788-3.887q.787-1.813 2.15-3.175Q6.3 3.575 8.125 2.787Q9.95 2 12 2q2.075 0 3.887.787q1.813.788 3.175 2.151q1.363 1.362 2.15 3.175Q22 9.925 22 12v1.45q0 1.475-1.012 2.512Q19.975 17 18.5 17q-.9 0-1.675-.4t-1.275-1.05q-.675.675-1.587 1.063Q13.05 17 12 17q-2.075 0-3.537-1.463Q7 14.075 7 12t1.463-3.538Q9.925 7 12 7t3.538 1.462Q17 9.925 17 12v1.45q0 .725.45 1.137q.45.413 1.05.413q.6 0 1.05-.413q.45-.412.45-1.137V12q0-3.275-2.363-5.638Q15.275 4 12 4Q8.725 4 6.362 6.362Q4 8.725 4 12t2.362 5.637Q8.725 20 12 20h4q.425 0 .712.288q.288.287.288.712t-.288.712Q16.425 22 16 22Zm0-7q1.25 0 2.125-.875T15 12q0-1.25-.875-2.125T12 9q-1.25 0-2.125.875T9 12q0 1.25.875 2.125T12 15Z"
      ></path>
    </svg>
  );
}

export function MailBlacklisted(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M22.1 21.5L2.4 1.7L1.1 3l1.5 1.5c-.4.4-.6.9-.6 1.5v12c0 1.1.9 2 2 2h14.1l2.7 2.7l1.3-1.2M4 8V6l.3.2l5.3 5.3L4 8m9.4 2.2L7.2 4H20c1.1 0 2 .9 2 2v12c0 .2 0 .5-.1.7l-7.3-7.3L20 8V6l-6.6 4.2Z"
      ></path>
    </svg>
  );
}

export function LogoutIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M5 5h6c.55 0 1-.45 1-1s-.45-1-1-1H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h6c.55 0 1-.45 1-1s-.45-1-1-1H5V5z"
      ></path>
      <path
        fill="currentColor"
        d="m20.65 11.65l-2.79-2.79a.501.501 0 0 0-.86.35V11h-7c-.55 0-1 .45-1 1s.45 1 1 1h7v1.79c0 .45.54.67.85.35l2.79-2.79c.2-.19.2-.51.01-.7z"
      ></path>
    </svg>
  );
}

export function CreditCard(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M20 20H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2ZM4 12v6h16v-6H4Zm0-6v2h16V6H4Zm9 10H6v-2h7v2Z"
      ></path>
    </svg>
  );
}

export function CalendarRange(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      {...props}
    >
      <g fill="currentColor">
        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1H2zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5z"></path>
        <path d="M9 7.5a.5.5 0 0 1 .5-.5H15v2H9.5a.5.5 0 0 1-.5-.5v-1zm-2 3v1a.5.5 0 0 1-.5.5H1v-2h5.5a.5.5 0 0 1 .5.5z"></path>
      </g>
    </svg>
  );
}

export function CarbonAnalytics(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path fill="currentColor" d="M4 2H2v26a2 2 0 0 0 2 2h26v-2H4Z"></path>
      <path
        fill="currentColor"
        d="M30 9h-7v2h3.59L19 18.59l-4.29-4.3a1 1 0 0 0-1.42 0L6 21.59L7.41 23L14 16.41l4.29 4.3a1 1 0 0 0 1.42 0l8.29-8.3V16h2Z"
      ></path>
    </svg>
  );
}

export function FileExport(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
        <path d="M11.5 21H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7l5 5v5m-5 6h7m-3-3l3 3l-3 3"></path>
      </g>
    </svg>
  );
}

export function MailOutline(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M4 20q-.825 0-1.413-.588T2 18V6q0-.825.588-1.413T4 4h16q.825 0 1.413.588T22 6v12q0 .825-.588 1.413T20 20H4Zm8-7.175q.125 0 .263-.038t.262-.112L19.6 8.25q.2-.125.3-.313t.1-.412q0-.5-.425-.75T18.7 6.8L12 11L5.3 6.8q-.45-.275-.875-.012T4 7.525q0 .25.1.438t.3.287l7.075 4.425q.125.075.263.113t.262.037Z"
      ></path>
    </svg>
  );
}

export function PeopleIcon(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 18 16" {...props}>
      <path
        fill="#a8a8a8"
        d="M8 2.002a1.998 1.998 0 1 0 0 3.996a1.998 1.998 0 0 0 0-3.996ZM12.5 3a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3Zm-9 0a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3ZM5 7.993A1 1 0 0 1 6 7h4a1 1 0 0 1 1 1v3a3.02 3.02 0 0 1-.146.927A3.001 3.001 0 0 1 5 11V7.993ZM4 8c0-.365.097-.706.268-1H2a1 1 0 0 0-1 1v2.5a2.5 2.5 0 0 0 3.436 2.319A3.983 3.983 0 0 1 4 10.999V8Zm8 0v3c0 .655-.157 1.273-.436 1.819A2.5 2.5 0 0 0 15 10.5V8a1 1 0 0 0-1-1h-2.268c.17.294.268.635.268 1Z"
      />
    </svg>
  );
}

export function LockPerson(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M18 11c.7 0 1.37.1 2 .29V10c0-1.1-.9-2-2-2h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h6.26A6.995 6.995 0 0 1 18 11zM8.9 6c0-1.71 1.39-3.1 3.1-3.1s3.1 1.39 3.1 3.1v2H8.9V6z"
      ></path>
      <path
        fill="currentColor"
        d="M18 13c-2.76 0-5 2.24-5 5s2.24 5 5 5s5-2.24 5-5s-2.24-5-5-5zm0 2c.83 0 1.5.67 1.5 1.5S18.83 18 18 18s-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5zm0 6c-1.03 0-1.94-.52-2.48-1.32c.73-.42 1.57-.68 2.48-.68s1.75.26 2.48.68c-.54.8-1.45 1.32-2.48 1.32z"
      ></path>
    </svg>
  );
}

export function FilterOff(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 -1.5 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M14.8 11.975L6.825 4H19q.625 0 .9.55t-.1 1.05l-5 6.375ZM19.775 22.6L14 16.825V19q0 .425-.288.713T13 20h-2q-.425 0-.713-.288T10 19v-6.175l-8.6-8.6L2.8 2.8l18.4 18.4l-1.425 1.4Z"
      ></path>
    </svg>
  );
}

export function ArrowCircleRightRounded(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="m12.7 15.3l2.6-2.6q.275-.275.275-.7t-.275-.7l-2.625-2.625q-.275-.275-.688-.263T11.3 8.7q-.275.275-.275.7t.275.7l.9.9H8.975q-.425 0-.7.288T8 12q0 .425.288.713T9 13h3.2l-.925.925q-.275.275-.263.688t.288.687q.275.275.7.275t.7-.275ZM12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z"
      ></path>
    </svg>
  );
}

export function PlayCampaign(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="m11.55 12.975l3.2-2.15q.45-.3.45-.825q0-.525-.45-.825l-3.2-2.15q-.5-.35-1.025-.05q-.525.3-.525.9v4.25q0 .6.525.9q.525.3 1.025-.05ZM3.7 20.3q-.475.475-1.088.212Q2 20.25 2 19.575V4q0-.825.588-1.413Q3.175 2 4 2h16q.825 0 1.413.587Q22 3.175 22 4v12q0 .825-.587 1.413Q20.825 18 20 18H6Z"
      ></path>
    </svg>
  );
}

export function PauseCampaign(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M10 14q.425 0 .713-.288Q11 13.425 11 13V7q0-.425-.287-.713Q10.425 6 10 6t-.712.287Q9 6.575 9 7v6q0 .425.288.712Q9.575 14 10 14Zm4 0q.425 0 .713-.288Q15 13.425 15 13V7q0-.425-.287-.713Q14.425 6 14 6t-.712.287Q13 6.575 13 7v6q0 .425.288.712q.287.288.712.288ZM3.7 20.3q-.475.475-1.088.212Q2 20.25 2 19.575V4q0-.825.588-1.413Q3.175 2 4 2h16q.825 0 1.413.587Q22 3.175 22 4v12q0 .825-.587 1.413Q20.825 18 20 18H6Z"
      ></path>
    </svg>
  );
}

export function TargetMarketIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        id="feTarget0"
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
      >
        <g id="feTarget1" fill="currentColor" fillRule="nonzero">
          <path
            id="feTarget2"
            d="M19.938 13A8.004 8.004 0 0 1 13 19.938V22h-2v-2.062A8.004 8.004 0 0 1 4.062 13H2v-2h2.062A8.004 8.004 0 0 1 11 4.062V2h2v2.062A8.004 8.004 0 0 1 19.938 11H22v2h-2.062ZM12 18a6 6 0 1 0 0-12a6 6 0 0 0 0 12Zm0-3a3 3 0 1 0 0-6a3 3 0 0 0 0 6Z"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export function CSVIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M15.959 20.046H4V4.004h6.979v4.98h4.98V10h2.051V8.014l-2.05-2.052L14 4.004l-1.99-1.99h-8a1.997 1.997 0 0 0-1.99 2l-.01 16a1.997 1.997 0 0 0 1.99 2h14.01v-2Zm5.301-2.032l1.75-6h-1.5l-1 3.43l-1-3.43h-1.5l1.75 6h1.5z"
      ></path>
      <path
        fill="currentColor"
        d="M10.01 12.014h-3a1.003 1.003 0 0 0-1 1v4a1.003 1.003 0 0 0 1 1h3a1.003 1.003 0 0 0 1-1v-1h-1.5v.5h-2v-3h2v.5h1.5v-1a1.003 1.003 0 0 0-1-1Zm7 1.506v-1.506h-4a1 1 0 0 0-1 1v1.757a1 1 0 0 0 1 1h2.51v.743h-3.51v1.507h4a1 1 0 0 0 1-1v-1.757a1 1 0 0 0-1-1H13.5v-.743Z"
      ></path>
    </svg>
  );
}
