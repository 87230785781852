/**
 * Get the total number of requested, connected & responded
 * leads in an array of records.
 * @param {Array<*>} records The backend records
 * @returns {{
 *    requested: { value: number | 'NA', percentage: number | 'NA' },
 *    connected: { value: number | 'NA', percentage: number | 'NA' },
 *    responded: { value: number | 'NA', percentage: number | 'NA' }
 * }}
 */
export function getLeadsStatsSum(stats) {
  let totalRequested = 0;
  let totalConnected = 0;
  let totalResponded = 0;

  stats.forEach((stat) => {
    totalRequested += stat.requested;
    totalConnected += stat.connected;
    totalResponded += stat.replied;
  });
  return {
    requested: {
      value: totalRequested ?? 'NA',
      percentage: totalRequested ? 100 : 0,
    },
    connected: {
      value: totalConnected ?? 'NA',
      percentage: totalConnected
        ? Math.round((totalConnected / totalRequested) * 100)
        : 0,
    },
    responded: {
      value: totalResponded ?? 'NA',
      percentage: totalConnected
        ? Math.round((totalResponded / totalConnected) * 100)
        : 0,
    },
  };
}
