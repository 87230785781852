import { Form } from 'antd';
import { FILTER_TYPE } from 'constant/targetMarket';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { Dropdown } from './Dropdown';
import { FilterCard } from './FilterCard';
import { MultiSelect } from './MultiSelect';
import { Range } from './Range';
import { ShowSelectedValues } from './ShowSelectedValues';

export function FilterItem({
  filter,
  selectedFilter,
  setSelectedFilter,
  disableActions,
}) {
  const form = Form.useFormInstance();

  const values = Form.useWatch(['filters', filter.name]);

  const options = useMemo(
    () =>
      filter.options?.filter(
        ({ id }) => !values?.find((value) => value.options.id == id)
      ),
    [values, filter.options]
  );

  const addToFormValues = (item, exclusionValue) => {
    if (disableActions) return;
    let index = form.getFieldValue(['filters', filter.name])?.length || 0;
    let toInsert = {};

    toInsert = filter.exclusionSupported
      ? {
          id: filter.id,
          options: { id: item.id, type: exclusionValue },
          title: item.title,
        }
      : { id: filter.id, options: { id: item.id }, title: item.title };

    form.setFieldValue(['filters', filter.name, index], toInsert);
  };

  if (filter.name === 'SENIORITY_LEVEL') {
    filter.exclusionSupported = false;
    filter.dynamicDownloadOptions = true;

    return (
      <>
        {values && (
          <ShowSelectedValues
            filter={filter}
            values={values}
            disableActions={disableActions}
          />
        )}
        <div className="grid grid-cols-5 mt-2 text-center gap-x-4 gap-y-2">
          {options.map((option) => (
            <li
              key={option.id}
              value={option.title}
              className="py-1.5 bg-gray-100 rounded outline outline-1 outline-gray-200 hover:bg-lime-500 hover:bg-opacity-10 hover:cursor-pointer hover:text-green-600 hover:outline-lime-400"
              onClick={() => addToFormValues(option)}
            >
              {option.title}
            </li>
          ))}
        </div>
      </>
    );
  }

  return (
    <FilterCard
      filter={filter}
      selectedFilter={selectedFilter}
      setSelectedFilter={setSelectedFilter}
      disableActions={disableActions}
    >
      {values && (
        <ShowSelectedValues
          filter={filter}
          values={values}
          disableActions={disableActions}
        />
      )}

      {selectedFilter === filter.name ? (
        <div className="my-2">
          {filter.type === FILTER_TYPE.MULTI && options && (
            <MultiSelect
              filter={filter}
              removeOption={addToFormValues}
              options={options}
            />
          )}

          {filter.type === FILTER_TYPE.DROPDOWN && (
            <Dropdown
              filter={filter}
              closeFilter={() => setSelectedFilter(undefined)}
            />
          )}

          {filter.type === FILTER_TYPE.RANGE && (
            <Range
              filter={filter}
              closeFilter={() => setSelectedFilter(undefined)}
            />
          )}
        </div>
      ) : null}

      {/* {filter.type === FILTER_TYPE.TEXT && (
            <Input type="text" placeholder="Type here" className="" />
          )} */}

      {/* {filter.type === FILTER_TYPE.SINGLE && options && (
        <SingleSelect filter={filter} />
      )} */}
    </FilterCard>
  );
}

FilterItem.propTypes = {
  filter: PropTypes.object,
  setSelectedFilter: PropTypes.func,
  selectedFilter: PropTypes.string,
  disableActions: PropTypes.bool,
};
