import routes from 'routes';

import { LeadsTable } from './LeadsTable';

const LeadsTableScreen = () => <LeadsTable />;

export default {
  routeProps: {
    path: routes.leads,
    component: LeadsTableScreen,
  },
  name: 'leads',
  routeVisibility: 'private',
};
