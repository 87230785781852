export const difference = (param1, param2) => param1 - param2;

/**
 *
 * @param {*} total Number of credits bought from the user
 * @param {*} remaining Number of credits the user has left
 * @returns
 */

export const colorOfPercent = (total, remaining) => {
  const style = 'rounded-md text-white w-3/5 mx-auto';

  const percent = (+remaining / +total) * 100;

  if (percent < 25) return style + ' bg-red-400';
  if (percent < 50) return style + ' bg-orange-400';
  if (percent < 75) return style + ' bg-blue-400';
  if (percent > 75) return style + ' bg-green-600';
};
