export const ILG_JWT_TOKEN = 'ILG_TOKEN';
export const JWT_BEARER_TEXT = 'Bearer';

/** The status of the global filter, "Active" &/ "Inactive" */
export const CURRENT_FILTER = 'CURRENT_FILTER';

export const DEFAULT_MALE_SALUTATION = 'Mr.';
export const DEFAULT_FEMALE_SALUTATION = 'Ms.';

export const LEADS_TABLE_SELECTED_COLUMNS = 'LEADS_TABLE_SELECTED_COLUMNS';
