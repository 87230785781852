import { DownOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useState } from 'react';

import Filter from './Filter';

const FilterToggler = (props) => {
  const {
    viewByValues,
    viewBy,
    setViewBy,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setViewByValues,
  } = props;
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="z-10" style={{ position: 'relative' }}>
      {dayjs(startDate).format('DD.MM.YYYY') +
        ' - ' +
        dayjs(endDate).format('DD.MM.YYYY')}
      <Button
        onClick={() => setOpen(!isOpen)}
        style={{ width: '130px', marginLeft: '10px' }}
      >
        View {viewByValues[viewBy]} <DownOutlined />
      </Button>
      <Filter
        isOpen={isOpen}
        onClickOutside={() => setOpen(false)}
        viewByValues={viewByValues}
        setViewByValues={setViewByValues}
        viewBy={viewBy}
        setViewBy={setViewBy}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
    </div>
  );
};

FilterToggler.propTypes = {
  viewByValues: PropTypes.object,
  setViewByValues: PropTypes.func,
  viewBy: PropTypes.number,
  setViewBy: PropTypes.func,
  startDate: PropTypes.objectOf(dayjs),
  endDate: PropTypes.objectOf(dayjs),
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
};

export default FilterToggler;
