import attraction from '../assets/img/webp/attraction.webp';
import csv from '../assets/img/webp/csv.webp';
import lin from '../assets/img/webp/linkedin.webp';
import mail from '../assets/img/webp/mail.webp';
import recruitment from '../assets/img/webp/recruitment.webp';
import sal from '../assets/img/webp/sales.webp';
import tm from '../assets/img/webp/targetMarketIcon.webp';
import { type as campaignType } from '../services/campaignType';

export const categories = [
  {
    type: 'new',
    title: 'New Leads',
    subTitle: `Reaches a list of leads via LinkedIn either from a Sales Navigator search or from a CSV file`,
    img: attraction,
  },
  {
    type: 'existing',
    title: 'Existing Leads',
    subTitle: `Reaches a list of leads via the LinkedIn Sales Navigator search, a regular LinkedIn search or from a CSV file`,
    img: recruitment,
  },
  {
    type: 'mail',
    title: 'Emails',
    subTitle: `Reaches a list of leads via Email. Importing leads through a CSV File or creating a Target Market`,
    img: mail,
  },
];

export const newEntries = [
  {
    camType: campaignType.OUT_SAL,
    img: sal,
    title: 'Sales Navigator Search',
    desc: 'Message to new leads on LinkedIn from the LinkedIn Sales Navigator search',
  },
  {
    camType: campaignType.OUT_CSV,
    img: csv,
    title: 'From File (CSV)',
    desc: 'Message to new LinkedIn leads imported from a CSV file',
  },
];

export const existingEntries = [
  {
    camType: campaignType.EX_SAL,
    img: sal,
    title: 'Sales Navigator Search',
    desc: 'Message to new leads on LinkedIn from the LinkedIn Sales Navigator search',
  },
  {
    camType: campaignType.EX_LIN,
    img: lin,
    title: 'LinkedIn Search',
    desc: 'Message new leads on LinkedIn from a regular LinkedIn search',
  },
  {
    camType: campaignType.EX_CSV,
    img: csv,
    title: 'From File (CSV)',
    desc: 'Message to new LinkedIn leads imported from a CSV file',
  },
];

export const emailEntries = [
  {
    camType: campaignType.EM_CSV,
    img: csv,
    title: 'From File (CSV)',
    desc: 'Send emails to new leads imported from a CSV file',
  },
  {
    camType: campaignType.EM_TM,
    img: tm,
    title: 'From Target Market',
    desc: `Send emails to new leads based on user's target market.`,
  },
];

export const CREATE_CAMPAIGN = {
  name: {
    title: 'Name',
    subTitle: `Enter a name for your campaign.`,
  },
  url: {
    title: 'Search Link',
    subTitle: `Enter the LinkedIn url`,
  },
  csv: {
    title: 'Csv File',
    subTitle: `Please select the file to upload.`,
  },
  linkedinAccount: {
    title: 'Account Details',
    subTitle: `Please enter your LinkedIn credentials and check if your account should be use to define the search parameters`,
  },
  emailAccount: {
    title: 'Email Setup',
    subTitle: `Please fill all the fields for the email setup`,
  },
  range: {
    title: 'Daily Interactions',
    subTitle: `Amount of messages to send daily`,
  },
  message: {
    title: 'Initial Message',
    subTitle: `Please provide the details of your first message`,
  },
  email: {
    title: 'Initial Email',
    subTitle: `Please provide the details of your first email`,
  },
  emailSetup: {
    title: 'Email Service',
    subTitle: `Enter the SMTP and PORT of your email service.`,
  },
  signature: {
    title: 'Email Signature',
    subTitle: `Add the signature that will be pending at the end of each email. Add each field that you would like to be shown in the signature`,
  },
  targetMarket: {
    title: 'Target Market',
    subTitle: `Name of the target market selected`,
  },
};

export const EDIT_CAMPAIGN = {
  name: {
    title: 'Name',
    subTitle: `Name for your campaign`,
    label: `Actual campaign name`,
  },
  url: {
    title: 'Search Link',
    subTitle: `Enter the LinkedIn url`,
  },
  csv: {
    title: 'Csv File',
    subTitle: `Please select the file to upload.`,
  },
  linkedinAccount: {
    title: 'Account Details',
    subTitle: `Please enter your LinkedIn credentials and check if your account should be use to define the search parameters`,
  },
  emailAccount: {
    title: 'Email Setup',
    subTitle: `Please fill all the fields for the email setup`,
  },
  range: {
    title: 'Daily Interactions',
    subTitle: `Amount of messages to send daily`,
  },
  emailRange: {
    title: 'Daily Outbound Emails',
    subTitle: `Actual range of daily outbound emails`,
  },
  message: {
    title: 'Initial Message',
    subTitle: `Please provide the details of your first message`,
  },
  email: {
    title: 'Initial Email',
    subTitle: `Please provide the details of your first email`,
    label: `Actual first email configuration`,
  },
  smtp: {
    title: 'SMTP Configuration',
    subTitle: `Enter the SMTP address/port of your email service.`,
    label: `SMTP Address & Port`,
  },
  imap: {
    title: 'IMAP Configuration',
    subTitle: `Enter the IMAP address/port of your email service.`,
    label: `IMAP Address & Port`,
  },
  signature: {
    title: 'Email Signature',
    subTitle: `Add the signature that will be pending at the end of each email. Add each field that you would like to be shown in the signature`,
    label: `Your email signature here`,
  },
  subject: {
    title: 'Email subject',
    subTitle: `Subject of each single email of this campaign`,
    label: `Actual email subject`,
  },
};
