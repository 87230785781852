import { useEffect, useState } from 'react';

/**
 * Returns a debounced version of the input. If the input changes faster than
 * the given `delay`, the output will not change - the output changes after
 * `delay`ms of the last input change.
 *
 * @param {any} value A value of any type
 * @param {number} delay Debounce delay in milliseconds
 */
export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    if (value === '') {
      setDebouncedValue(value);
      return debouncedValue;
    }

    const handler = setTimeout(() => setDebouncedValue(value), delay);

    return () => clearTimeout(handler);
  }, [delay, value, debouncedValue]);

  return debouncedValue;
}
