import { InboxOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Input, Slider, Switch, Tooltip } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { CREATE_CAMPAIGN } from 'constant/campaignForm';
import { marks } from 'constant/formComponents';
import FormLabel from 'modules/Campaign/components/Form/FormLabel';
import FormSection from 'modules/Campaign/components/Form/FormSection';
import TooltipCSVDownload from 'modules/Campaign/components/Form/TooltipCSVDownload';
import { useState } from 'react';
import {
  EXISTING_LABEL,
  getDetails,
  isCSV,
  isExistingCampaignType,
  isOutreachSalesNav,
  OUTREACH_LABEL,
} from 'services/campaignType';
import { parseAndValidateLeadsFromCsv } from 'utils/helpers/csv';

import { type as campaignTypeEnum } from '../../../../../services/campaignType';
import { MessageFormItem } from '../../../components/Form/MessageFormItem';

export const CampaignBasicInfo = () => {
  const [fileList, setFileList] = useState([]);

  const form = Form.useFormInstance();
  const campaignType = form.getFieldValue('type');
  const numOfLeads = form.getFieldValue('numOfLeads');

  return (
    <div className="fade-in grid gap-6 md:grid-cols-2">
      <FormSection title={CREATE_CAMPAIGN.name.title}>
        <FormLabel>Campaign Name</FormLabel>
        <Form.Item
          name="name"
          rules={[
            { required: true, message: 'Please give this campaign a name' },
          ]}
          className="mb-0"
        >
          <Input />
        </Form.Item>
      </FormSection>

      {isCSV(campaignType) ? (
        <FormSection
          title={CREATE_CAMPAIGN.csv.title}
          titleChildren={<TooltipCSVDownload isLinkedin />}
        >
          <Form.Item
            name="file"
            rules={[
              {
                validator: async (_, val) => {
                  if (!val) return;
                  const { file } = val;
                  setFileList([]);
                  try {
                    const parsedLeads = await parseAndValidateLeadsFromCsv(
                      file
                    );
                    form.setFieldsValue({ parsedLeads });
                    // Validation passed, set file to state and value to form
                    setFileList([file]);
                    // Extra function for the edit form
                    return Promise.resolve();
                  } catch (error) {
                    return Promise.reject(error);
                  }
                },
              },
            ]}
            className="mb-0"
          >
            <Dragger
              accept=".csv"
              maxCount={1}
              beforeUpload={() => false}
              fileList={fileList}
              showUploadList={{ showRemoveIcon: false }}
            >
              <div className="flex justify-center text-gray-500 align-middle">
                <InboxOutlined className="mx-2 text-2xl text-yellow-400" />
                Click or drag to upload a file
              </div>
            </Dragger>
          </Form.Item>
          {/* This extra form item is used to avoid prop drilling */}
          <Form.Item name="parsedLeads" hidden>
            <Input />
          </Form.Item>
        </FormSection>
      ) : (
        <FormSection title={getDetails(campaignType, 'title')}>
          <FormLabel>Valid URL</FormLabel>
          <Form.Item
            name="campaignLink"
            rules={[
              {
                required: true,
                message: 'Please provide a valid link',
              },
              {
                validator: (_, url) => {
                  if (
                    !url ||
                    url.startsWith(
                      campaignType === campaignTypeEnum.EX_LIN
                        ? 'https://www.linkedin.com/search/results/people'
                        : 'https://www.linkedin.com/sales/search/people'
                    )
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject('The field can not be left empty');
                },
              },
            ]}
            className="mb-0"
          >
            <Input />
          </Form.Item>
        </FormSection>
      )}

      {isOutreachSalesNav(campaignType) && numOfLeads === 0 && (
        <FormSection title={'LinkedIn Account'} className="md:col-span-2">
          <Form.Item noStyle name="linkedInAccount">
            <Input disabled bordered={false} />
          </Form.Item>

          <div className="flex justify-around p-3 align-middle rounded-lg bg-gray-50">
            <div className="text-xs text-gray-600">
              Use <strong>my LinkedIn account</strong> to define the search
              parameters{' '}
              <Tooltip title="If the Sales Navigator search has account related criteria, please enable the scrape through the user account, otherwise won't be possible to reach the totality of the leads present in the original search">
                <QuestionCircleOutlined />
              </Tooltip>
            </div>
            <Form.Item
              noStyle
              name="useScraperAccounts"
              valuePropName="checked"
            >
              <Switch size="small" className="mx-1" />
            </Form.Item>
          </div>
        </FormSection>
      )}

      <FormSection
        title={
          isExistingCampaignType(campaignType) ? EXISTING_LABEL : OUTREACH_LABEL
        }
        className="md:col-span-2"
      >
        <Form.Item name="invites" noStyle>
          <Slider
            range={true}
            step={1}
            getAriaLabel={() => 'Invites per day'}
            min={1}
            max={20}
            trackStyle={{ backgroundColor: '#D7A600' }}
            handleStyle={{ backgroundColor: '#D7A600' }}
            initialValues={[6, 8]}
            valueLabelDisplay="auto"
            getAriaValueText={(value) => `${value} invites`}
            disableSwap
            marks={marks}
            className="mt-4"
          />
        </Form.Item>
      </FormSection>

      <FormSection title={'Initial Message'} className="md:col-span-2">
        <MessageFormItem
          name="initialMessage"
          title="Initial contact message"
          maxLength={300}
          showCount
        />
      </FormSection>
    </div>
  );
};
