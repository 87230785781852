import routes from 'routes';

import { RecordsDashboard } from './RecordsDashboard';

const RecordsDashboardScreen = () => <RecordsDashboard />;

export default {
  routeProps: {
    path: routes.records,
    component: RecordsDashboardScreen,
  },
  name: 'records',
  routeVisibility: 'private',
};
