import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Col,
  Divider,
  message,
  Modal,
  Row,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import { Steps } from 'antd';
import API from 'api';
import {
  FemaleFilledIcon,
  MaleFilledIcon,
} from 'modules/Campaign/components/Icons';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import { EmailStatus } from '../../EmailLeadsTable/components/EmailStatus';

const { Title } = Typography;

const stepItems = [
  { description: 'Not yet sent' },
  { description: 'Has been sent' },
  { description: 'Has been opened' },
  { description: 'Lead has replied' },
];

const stepItemsBounced = [
  { description: 'Not yet sent' },
  { description: 'Email bounced' },
  { description: 'Has been opened' },
  { description: 'Lead has replied' },
];

export function EmailLeadModal({
  visible,
  hideModal,
  data /** Notify the immediate parent that the lead was updated */,
  onLeadUpdate,
}) {
  function getCurrentStatus() {
    if (data.hasAnsweredConversation) return 3;
    if (data.hasSeenConversation) return 2;
    if (data.hasConversation) return 1;
    if (!data.hasConversation) return 0;
  }

  const [updatingSkip, setUpdatingSkip] = useState(false);
  const { hasAnsweredConversation, hasSeenConversation, hasConversation } =
    data;
  const handleSkipUpdate = useCallback(async () => {
    if (updatingSkip || hasAnsweredConversation) return;

    setUpdatingSkip(true);

    try {
      const {
        data: { updatedLead },
      } = await API.updateLead(data.leadId, {
        skip: !data.skip,
        skipReason: !data.skip ? 'EXCULDED_BY_USER' : null,
      });
      onLeadUpdate({
        hasAnsweredConversation,
        hasSeenConversation,
        hasConversation,
        ...updatedLead,
      });

      message.success('Lead skip updated successfully');
    } catch (error) {
      message.error('Could not update lead skip');
    } finally {
      setUpdatingSkip(false);
    }
  }, [
    data.leadId,
    data.skip,
    updatingSkip,
    hasAnsweredConversation,
    hasConversation,
    hasSeenConversation,
    onLeadUpdate,
  ]);

  return (
    <Modal
      open={visible}
      title="Detailed Lead Information"
      onCancel={() => {
        // Hide modal in next event loop so state gets time to update
        setTimeout(() => {
          hideModal();
        });
      }}
      footer={null}
      width={900}
      centered
    >
      {/* Should contain the general information about the lead */}
      <Row className="flex flex-col justify-center text-center align-middle md:flex-row md:justify-around md:p-2">
        {/* Left section */}
        <Col className="flex flex-col justify-center pb-5 text-center align-middle md:w-4/12 md:mt-4">
          <div className="mb-4">
            {data.gender === 'male' ? (
              <MaleFilledIcon className="text-3xl" />
            ) : (
              <FemaleFilledIcon className="text-3xl" />
            )}
          </div>
          <div className="my-1">
            <span className="text-2xl">{data.name}</span>
          </div>
          <Divider />
          <div>
            <div className="flex flex-col justify-center align-middle">
              <EmailStatus record={data} showText />
            </div>
          </div>
        </Col>

        {/* Right section */}
        <Col className="flex flex-col justify-center w-full p-5 text-center bg-gray-100 rounded-lg md:w-7/12">
          <div className="flex justify-between p-2">
            <Title level={5} className={'align-middle'}>
              Email:{' '}
            </Title>
            {data.email?.includes('@') ? (
              <a href={`mailto:${data.email}`}>{data.email}</a>
            ) : (
              <span className="text-gray-500">No email found</span>
            )}
          </div>

          <div className="flex justify-between p-2">
            <Title level={5}>Company: </Title>

            {data.companyUrl?.startsWith('http') ? (
              <div className="text-right">
                <a
                  href={data.companyUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data.companyName}
                </a>
              </div>
            ) : (
              <span>{data.companyName || '-'}</span>
            )}
          </div>

          <div className="flex justify-between p-2">
            <Title level={5}>City: </Title>
            <div>{data.city}</div>
          </div>

          <div className="flex justify-between p-2">
            <Title level={5}>Country: </Title>
            <div>{data.country}</div>
          </div>

          <div className="flex justify-between p-2">
            <Title level={5}>Location: </Title>
            {/* If the name is too long will shorten it showing a tooltip with the full string */}
            <div>
              {data.location.length > 28 ? (
                <Tooltip title={data.location}>
                  {data.location.substr(0, 25)}...
                </Tooltip>
              ) : (
                data.location
              )}
            </div>
          </div>
        </Col>
      </Row>
      <Row className="flex items-center p-4 mt-6">
        <Steps
          progressDot
          labelPlacement={'vertical'}
          current={getCurrentStatus()}
          items={data.bouncedCheck === 'BOUNCED' ? stepItemsBounced : stepItems}
        />
      </Row>
      <Divider />

      <div className="flex justify-center pb-2">
        <label
          className={`flex items-center ${
            data.hasAnsweredConversation
              ? 'text-gray-400'
              : 'text-gray-500 cursor-pointer'
          }`}
        >
          Exclude from campaign
          <Tooltip
            title={'Exclude this lead from the campaign'}
            className="ml-2 mr-8"
          >
            <QuestionCircleOutlined />
          </Tooltip>
          <Switch
            checked={data.skip}
            loading={updatingSkip}
            disabled={updatingSkip || data.hasAnsweredConversation}
            onChange={() => handleSkipUpdate()}
          />
        </label>
      </div>
    </Modal>
  );
}

EmailLeadModal.propTypes = {
  /** The visibility of the modal */
  visible: PropTypes.bool.isRequired,
  /** Function to hide this modal */
  hideModal: PropTypes.func.isRequired,
  /** Notify the immediate parent that the lead was updated */
  onLeadUpdate: PropTypes.func.isRequired,
  /** The data of the current lead */
  data: PropTypes.object.isRequired,
};
