import PropTypes from 'prop-types';
import { calcPercent } from 'utils/helpers';

import { ProgressBar } from '../ProgressBar/ProgressBar';

const EmailStats = ({ campaign }) => {
  return (
    <>
      <div className="hidden w-1/3 md:table-cell">
        <ProgressBar
          percentage={calcPercent(campaign.numOfLeads, campaign.requested)}
          total={+campaign.requested || 0}
          name={'Contacted'}
        />
      </div>
      <div className="hidden w-1/3 sm:table-cell">
        <ProgressBar
          percentage={calcPercent(campaign.requested, campaign.connected)}
          total={+campaign.connected || 0}
          name={'Opened'}
        />
      </div>
      <div className="hidden w-1/3 sm:table-cell">
        <ProgressBar
          percentage={calcPercent(campaign.requested, campaign.replied)}
          total={+campaign.replied || 0}
          name={'Replied'}
        />
      </div>
    </>
  );
};

export default EmailStats;

EmailStats.propTypes = {
  campaign: PropTypes.object.isRequired,
};
