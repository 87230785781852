import { RightOutlined } from '@ant-design/icons';
import { IconWithText } from 'components/IconWithText';

export interface ListProps {
  items: string[];
  icon?: any;
}

export function List({
  items,
  icon: Icon = () => (
    <RightOutlined style={{ fontSize: '10px', color: '#dcb223' }} />
  ),
}: ListProps) {
  return (
    <ul className="list-none p-0 flex flex-col gap-2">
      {items.map((item, index) => (
        <IconWithText key={index} icon={Icon}>
          {item}
        </IconWithText>
      ))}
    </ul>
  );
}
