import {
  PlusOutlined,
  SearchOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Button, Input, Table } from 'antd';
import API from 'api';
import { Card } from 'components/Card';
import { Layout } from 'components/Layout';
import { filterRole } from 'constant/usersTable';
import { SubHeader } from 'modules/Campaign/components/SubHeader';
import { useEffect, useState } from 'react';
import { colorOfPercent } from 'services/arithmetics';
import { calcPercent } from 'utils/helpers';

import { AddCreditModal } from './components/AddCreditModal/AddCreditModal';
import { AddUserModal } from './components/AddUserModal/AddUserModal';
import { EditUserModal } from './components/EditUserModal/EditUserModal';

/**
 * the main component for user management module will fetch all user from the API on load
 */
export function UsersTable() {
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedUser, setSelectedUser] = useState();
  const [isCreditModalVisible, setIsCreditModalVisible] = useState(false);
  const [isEditUserModalVisible, setIsEditUserModalVisible] = useState(false);

  const fetchUsers = async () => {
    setUsers((await API.getAllUsers()).data);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    search !== ''
      ? setFilteredUsers(
          users.filter((User) =>
            User.companyName.toLowerCase().includes(search.toLowerCase())
          )
        )
      : setFilteredUsers(users);
  }, [search, users]);

  const columns = [
    {
      align: 'center',
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'name',
    },
    {
      align: 'center',
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      align: 'center',
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      filters: filterRole,
      onFilter: (value, tuple) => tuple.role == value,
    },
    {
      align: 'center',
      title: 'Credits',
      dataIndex: ['userBoughtCredit, userUsedCredit'],
      key: 'credits',
      render: (_, tuple) => (
        <div
          className={`p-2 ${colorOfPercent(
            tuple.userBoughtCredit,
            tuple.userBoughtCredit - tuple.userUsedCredit
          )}`}
        >
          {tuple.userBoughtCredit - tuple.userUsedCredit} /{' '}
          {tuple.userBoughtCredit}
        </div>
      ),
      sorter: (a, b) =>
        calcPercent(a.userBoughtCredit, a.userUsedCredit) -
        calcPercent(b.userBoughtCredit, b.userUsedCredit),
    },
    {
      align: 'center',
      title: 'Action',
      key: 'action',
      width: 250,
      render: (_, user) => (
        <div>
          <Button
            className="text-secondary"
            icon={<PlusOutlined />}
            type="link"
            onClick={() => {
              setSelectedUser(user);
              setIsCreditModalVisible(true);
            }}
          >
            Add credit
          </Button>
          <Button
            className="text-secondary"
            icon={<SettingOutlined />}
            type="link"
            onClick={() => {
              setSelectedUser(user);
              setIsEditUserModalVisible(true);
            }}
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <SubHeader title="Users" />

      <Card
        shadow
        className="flex flex-wrap items-center justify-between gap-4 mb-6"
        borderRadius="rounded-lg"
      >
        <Button
          type="primary"
          onClick={() => setIsCreateModalVisible(true)}
          icon={<PlusOutlined />}
        >
          New User
        </Button>

        <Input
          prefix={<SearchOutlined style={{ marginRight: '5px' }} />}
          placeholder={'Search user by name...'}
          onChange={(e) => setSearch(e.target.value)}
          className="max-w-xs"
        />
      </Card>

      <Table
        tableLayout="fixed"
        dataSource={filteredUsers}
        columns={columns}
        rowKey={'id'}
      />

      <AddUserModal
        isModalVisible={isCreateModalVisible}
        setIsModalVisible={setIsCreateModalVisible}
        fetchUsers={fetchUsers}
      />

      <AddCreditModal
        selectedUser={selectedUser}
        isModalVisible={isCreditModalVisible}
        setIsModalVisible={setIsCreditModalVisible}
        fetchUsers={fetchUsers}
      />

      <EditUserModal
        selectedUser={selectedUser}
        isModalVisible={isEditUserModalVisible}
        setIsModalVisible={setIsEditUserModalVisible}
        fetchUsers={fetchUsers}
      />
    </Layout>
  );
}
