export const FILTER_TYPE = Object.freeze({
  MULTI: 'MULTI_SELECT',
  TEXT: 'TEXT',
  SINGLE: 'SINGLE_SELECT',
  DROPDOWN: 'RANGE_DROPDOWN',
  RANGE: 'RANGE_TEXT',
});

export const EXCLUSION = Object.freeze({
  INCLUDED: 'INCLUDED',
  EXCLUDED: 'EXCLUDED',
});

export const FORM_TITLE = 'Fill the details to create a new Target Market';
