import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { message } from 'antd';
import { useEffect } from 'react';

import { useUser } from './useUser';

export function GraphQLProvider({ children }: { children: React.ReactNode }) {
  const { user, cdsApiKey } = useUser();

  useEffect(() => {
    if (user && !cdsApiKey) {
      message.error('Error getting API Key');
    }
  }, [user, cdsApiKey]);

  const client = new ApolloClient({
    uri: process.env.REACT_APP_CDS_GRAPHQL_URL,
    cache: new InMemoryCache(),
    headers: {
      'x-user-api-key': cdsApiKey || '',
    },
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
