import { DownOutlined, FileTextOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Dropdown,
  message,
  notification,
  Popover,
  Table,
  TableProps,
} from 'antd';
import Search from 'antd/es/input/Search';
import { CheckboxOptionType } from 'antd/lib/checkbox';
import { ColumnProps } from 'antd/lib/table';
import PropTypes from 'prop-types';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ParamKeyValuePair,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import {
  countLeadsByCampaignId,
  getAllLeadsByCampaignId,
  getUniqueLeadValuesByCampaignId,
} from '../../../../api';
import { Card } from '../../../../components/Card';
import { Divider } from '../../../../components/Divider';
import { DownloadFromAPI } from '../../../../components/DownloadFromApi';
import { LEADS_TABLE_SELECTED_COLUMNS } from '../../../../constant';
import { EmailLeadModal } from '../components/LeadModal/EmailLeadModal';
import { EmailStatus } from './components/EmailStatus';

export interface LeadInfo {
  id: number;
  leadId: string;
  name: string;
  firstName: string;
  lastName: string;
  gender: string;
  email: string;
  companyName?: string;
  companyUrl?: string;
  title?: string;
  city?: string;
  country?: string;
  location?: string;
  hasConversation?: boolean;
  hasSeenConversation?: boolean;
  hasAnsweredConversation?: boolean;
  hasBouncedConversation?: boolean;
  conversationStartedAt?: string;
}

const emailStatusDataIndex = [
  'hasBeenBlacklisted',
  'hasNoConversation',
  'hasConversation',
  'hasSeenConversation',
  'hasAnsweredConversation',
  'hasBouncedConversation',
] as const;

const nameDataIndex = ['firstName', 'lastName', 'name'] as const;

const defaultSearchAndSortableColumn: ColumnProps<LeadInfo> = {
  sorter: { multiple: 1 },
  filters: [],
  filterSearch: true,
};

const availableColumns: ColumnProps<LeadInfo>[] = [
  {
    dataIndex: 'firstName',
    title: 'First Name',
  },
  {
    dataIndex: 'lastName',
    title: 'Last Name',
  },
  {
    dataIndex: 'name',
    title: 'Full Name',
  },
  {
    dataIndex: 'gender',
    title: 'Gender',
    sorter: { multiple: 1 },
    filters: [
      { text: 'Male', value: 'male' },
      { text: 'Female', value: 'female' },
    ],
    render: (gender) => {
      return gender.charAt(0).toUpperCase() + gender.slice(1).toLowerCase();
    },
  },
  {
    dataIndex: 'email',
    title: 'Email',
  },
  {
    dataIndex: 'companyName',
    title: 'Company',
    sorter: { multiple: 1 },
    render: (companyName, { companyUrl }) => {
      if (companyUrl?.length && companyUrl !== '-') {
        return <a href={companyUrl}>{companyName}</a>;
      }

      return companyName;
    },
    filters: [],
  },
  {
    ...defaultSearchAndSortableColumn,
    dataIndex: 'title',
    title: 'Title',
  },
  {
    ...defaultSearchAndSortableColumn,
    dataIndex: 'city',
    title: 'City',
  },
  {
    ...defaultSearchAndSortableColumn,
    dataIndex: 'country',
    title: 'Country',
  },
  {
    ...defaultSearchAndSortableColumn,
    dataIndex: 'location',
    title: 'Location',
  },
  {
    dataIndex: emailStatusDataIndex,
    title: 'Email Status',
    filterMultiple: false,
    render: (_, record) => <EmailStatus showText record={record} />,
    filters: [
      { text: 'Blacklisted', value: 'hasBeenBlacklisted' },
      { text: 'Not yet sent', value: 'hasNoConversation' },
      { text: 'Sent', value: 'hasConversation' },
      // { text: 'Bounced', value: 'hasBouncedConversation' },
      { text: 'Opened', value: 'hasSeenConversation' },
      { text: 'Replied', value: 'hasAnsweredConversation' },
    ],
  },
];

const columnCheckBoxOptions: CheckboxOptionType[] = availableColumns.map(
  (column) => ({
    label: column.title as string,
    value: Array.isArray(column.dataIndex)
      ? column.dataIndex[0]
      : column.dataIndex,
    style: {
      margin: 0,
    },
  })
);

const defaultPageSize = 10;
const defaultPage = 1;
const defaultSelectedColumns = [
  'firstName',
  'lastName',
  'gender',
  'email',
  'companyName',
  'location',
  emailStatusDataIndex[0],
];

function createOrderString(field: string, order: 'ascend' | 'descend') {
  return `${field}_${order === 'ascend' ? 'asc' : 'desc'}`;
}

function TableTitle({
  selectedColumns,
  setSelectedColumns,
  columns,
}: {
  selectedColumns: string[];
  setSelectedColumns: (selectedColumns: string[]) => void;
  columns: ColumnProps<LeadInfo>[];
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [globalSearch, setGlobalSearch] = useState('');

  /*
    When we search globally we first remove all the existing filters and set
    the page to 1 by deleting it from the url. Sorting can stay so we keep it.
    Then for every currently selected column we add an 'orQuery' to the search
    params. An 'orQuery' will be combined using 'OR' instead of 'AND' on the 
    GraphQL Server, allowing us to search globally. We only search in the
    columns that are selected because otherwise you would get results that do
    not make sense for the user.

    I added the 'orQuery' to the url instead of 'globalSearch' or similar,
    because it makes it easier to share the search with others. When the
    receiver of the shared links opens it, all columns that are in the link
    will be selected and the search will be performed. This also allows
    us to use the back button to go back to the previous search.
  */
  const searchGlobally = useCallback(
    (globalSearch_ = globalSearch) => {
      const newSearchParams = new URLSearchParams(searchParams);

      newSearchParams.delete('page');
      newSearchParams.delete('query');
      newSearchParams.delete('orQuery');

      if (globalSearch_) {
        for (const column of columns) {
          if (column.dataIndex === emailStatusDataIndex) {
            continue;
          }

          newSearchParams.append(
            `orQuery`,
            `${column.dataIndex}:${globalSearch_
              .split(',')
              .map((value) => value.trim())
              .filter(Boolean)
              .join(',')}`
          );
        }
      }

      setSearchParams(newSearchParams);
    },
    [searchParams, setSearchParams, globalSearch, columns]
  );

  useEffect(() => {
    /*
      The global search value is the same for all or-queries so we can just
      take the first one.
    */
    const orQueries = searchParams.getAll('orQuery');
    if (orQueries.length) {
      setGlobalSearch(orQueries[0].split(':')[1]);
    } else {
      setGlobalSearch('');
    }
  }, [searchParams]);

  const allColumnsSelected = selectedColumns.length === availableColumns.length;

  return (
    <div className="flex flex-wrap gap-4">
      <Search
        value={globalSearch}
        onChange={(e) => setGlobalSearch(e.target.value)}
        onSearch={searchGlobally}
        placeholder="Search all columns..."
        className="w-auto mr-auto"
        allowClear
      />
      <Dropdown
        trigger={['click']}
        className="w-auto"
        dropdownRender={() => (
          <Card shadow borderRadius="rounded-lg">
            <Checkbox
              indeterminate={!allColumnsSelected}
              onChange={() =>
                setSelectedColumns(
                  allColumnsSelected
                    ? defaultSelectedColumns
                    : availableColumns.map((column) =>
                        Array.isArray(column.dataIndex)
                          ? column.dataIndex[0]
                          : column.dataIndex
                      )
                )
              }
              checked={allColumnsSelected}
            >
              {allColumnsSelected ? 'Check default' : 'Check all'}
            </Checkbox>
            <Divider className="my-2" />
            <Checkbox.Group
              className="grid gap-2"
              options={columnCheckBoxOptions}
              value={selectedColumns}
              onChange={(value) => {
                value.length > 0
                  ? setSelectedColumns(value as string[])
                  : message.info('At least one field needs to be selected');
              }}
            />
          </Card>
        )}
      >
        <Button className="flex items-center gap-2">
          {allColumnsSelected
            ? 'All columns'
            : `${selectedColumns.length} columns`}
          <DownOutlined style={{ margin: 0 }} />
        </Button>
      </Dropdown>
    </div>
  );
}

function TableFooter({
  campaignId,
  columns,
}: {
  campaignId: string | undefined;
  columns: ColumnProps<LeadInfo>[];
}) {
  const { downloadUrl, filename } = useMemo(() => {
    return {
      downloadUrl: new URL(
        `leads/export/csv/${campaignId}`,
        process.env.REACT_APP_BACKEND_API_URL
      ).href,
      filename: `campaign-${campaignId}-leads-${new Date()
        .toJSON()
        .slice(0, 10)}.csv`,
    };
  }, [campaignId]);

  const [searchParams, setSearchParams] = useSearchParams();

  const filteredColumns = useMemo(() => {
    const filteredColumns = columns
      .filter((column) => column.filteredValue)
      .map((column) => {
        const dataIndex = Array.isArray(column.dataIndex)
          ? column.dataIndex[0]
          : column.dataIndex;

        return {
          dataIndex,
          title: column.title,
        };
      });

    if (searchParams.get('orQuery')) {
      filteredColumns.push({
        dataIndex: 'orQuery',
        title: 'Search',
      });
    }

    return filteredColumns;
  }, [columns, searchParams]);

  const handleReset = useCallback(() => {
    const newSearchParams = new URLSearchParams(searchParams);

    newSearchParams.delete('query');
    newSearchParams.delete('orQuery');

    setSearchParams(newSearchParams);
  }, [searchParams, setSearchParams]);

  return (
    <div className="flex flex-wrap justify-between gap-4">
      <Popover content={<span>Download all of the leads as a CSV file</span>}>
        <DownloadFromAPI
          url={downloadUrl}
          filename={filename}
          buttonProps={{
            type: 'link',
            className:
              'font-semibold text-secondary hover:text-secondary-dark active:text-secondary-light',
          }}
        >
          <FileTextOutlined /> <span>Export as CSV</span>
        </DownloadFromAPI>
      </Popover>
      <div className="pl-4">
        <span className="font-semibold">Filter:</span>{' '}
        {filteredColumns.length ? (
          filteredColumns.map((column, index) => (
            <span key={column.dataIndex as string}>
              {index ? ', ' : ''}
              {column.title as string}
            </span>
          ))
        ) : (
          <span className="text-gray-500">No filters</span>
        )}
        <Button
          type="link"
          className="text-secondary hover:text-secondary-dark active:text-secondary-light"
          onClick={handleReset}
          disabled={filteredColumns.length === 0}
        >
          Reset
        </Button>
      </div>
    </div>
  );
}
export function EmailLeadsTable({
  showBouncedEmail,
}: {
  showBouncedEmail: boolean;
}) {
  const { id: campaignId } = useParams<{ id: string }>();
  const [searchParams, setSearchParams] = useSearchParams();

  const pageIndex = parseInt(searchParams.get('page') || '') || defaultPage;
  const pageSize =
    parseInt(searchParams.get('pageSize') || '') || defaultPageSize;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<LeadInfo[]>([]);
  const [total, setTotal] = useState(0);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    defaultSelectedColumns
  );
  const [uniqueValues, setUniqueValues] = useState<Record<string, string[]>>(
    {}
  );
  const [selectedLead, setSelectedLead] = useState<LeadInfo>();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const columns = useMemo(() => {
    const sort = searchParams.getAll('sort');
    const query = searchParams.getAll('query');

    return availableColumns
      .map((column) => {
        const dataIndexIsArray = Array.isArray(column.dataIndex);
        /*
          Email for example is a composed index, we only use the first value of
          that.
        */
        const dataIndex = dataIndexIsArray
          ? (column.dataIndex as string[])[0]
          : (column.dataIndex as string);

        /*
          If the column is not selected, we don't want to show it.
        */
        if (!selectedColumns.includes(dataIndex)) {
          return null;
        }

        let filters: ColumnProps<LeadInfo>['filters'] = undefined;

        /* Unique values are the filter suggestions for single columns. */
        if (uniqueValues[dataIndex]) {
          filters = uniqueValues[dataIndex].map((value) => ({
            text: value.charAt(0).toUpperCase() + value.slice(1),
            value,
          }));
        } else {
          filters = column.filters;
        }

        const newColumn: ColumnProps<LeadInfo> = {
          ...column,
          filters,
          onFilterDropdownOpenChange: (open) => {
            /*
              If the column filter opened, does not have any filters and does
              not have any unique values yet, we fetch them.
            */
            if (open && !filters?.length && !uniqueValues[dataIndex]) {
              getUniqueLeadValuesByCampaignId(campaignId, dataIndex).then(
                (res) => {
                  setUniqueValues({ ...uniqueValues, [dataIndex]: res.data });
                }
              );
            }
          },
        };

        /*
          If the column is sortable, we want to set the sortOrder based on the
          URL.
        */
        if (sort.includes(`${dataIndex}_asc`)) {
          newColumn.sortOrder = 'ascend';
        } else if (sort.includes(`${dataIndex}_desc`)) {
          newColumn.sortOrder = 'descend';
        }

        /*
          If the column is filterable, we want to set the filteredValue based on
          the URL.
        */
        for (const dataIndex of Array.isArray(column.dataIndex)
          ? column.dataIndex
          : [column.dataIndex]) {
          const queryKey = (dataIndex as string) + ':';
          const queryString = query.find((value) => value.startsWith(queryKey));

          if (queryString) {
            if (!newColumn.filteredValue) {
              newColumn.filteredValue = [];
            }

            if (dataIndexIsArray) {
              newColumn.filteredValue.push(dataIndex);
            } else {
              const queryValue = queryString
                .replace(queryKey, '')
                .split(',')
                .filter(Boolean);

              if (queryValue.length) {
                if (!newColumn.filteredValue) {
                  newColumn.filteredValue = [];
                }

                newColumn.filteredValue.push(...queryValue);
              }
            }
          }
        }

        newColumn.filteredValue = newColumn.filteredValue || null;

        /*
          If the column is one of the name columns we want to render it as a
          link that opens the lead modal.
        */
        if (
          typeof newColumn.dataIndex === 'string' &&
          nameDataIndex.includes(newColumn.dataIndex as any)
        ) {
          newColumn.render = (value, record) => {
            return (
              <Button
                type="link"
                className="text-xs sm:text-base my-0.5"
                onClick={() => {
                  setSelectedLead(record);
                  setIsModalVisible(true);
                }}
              >
                <span className="text-sm">{value}</span>
              </Button>
            );
          };
        }

        return newColumn;
      })
      .filter(Boolean) as ColumnProps<LeadInfo>[];
  }, [campaignId, searchParams, selectedColumns, uniqueValues]);

  /*
    We want to update the total count of leads every time the filters or the
    campaign id changes.

    The thing is, i cant just use the result of getAll because its a new array
    every time. I also can not just use `searchParams` as a dependency since we
    only need to update the count if the campaign id or the filters changed, not
    if pagination or sorting changed. By combining the query and orQuery
    parameters into a single string, we can use that as a dependency.
  */
  const queryString = searchParams.getAll('query').join('|');
  const orQueryString = searchParams.getAll('orQuery').join('|');

  useEffect(() => {
    countLeadsByCampaignId(
      campaignId,
      queryString.split('|').filter(Boolean),
      orQueryString.split('|').filter(Boolean)
    ).then(({ data: response }) => {
      setTotal(response);
    });
  }, [campaignId, orQueryString, queryString]);

  const fetchLeads = useCallback(async () => {
    setLoading(true);
    try {
      const { data: response } = await getAllLeadsByCampaignId(
        campaignId,
        (pageIndex - 1) * pageSize,
        pageSize,
        searchParams.getAll('sort'),
        searchParams.getAll('query'),
        searchParams.getAll('orQuery')
      );
      setData(response);
    } catch (error) {
      notification.error({
        message: `There was a problem`,
        description: `Data could not be loaded. Please try again later or contact our support.`,
      });
    }
    setLoading(false);
  }, [campaignId, pageIndex, pageSize, searchParams]);

  /*
    The fetchLeads callback is memoized so it will change when the required
    parameters are updated. When the callback changes we call it to fetch the
    leads.
  */
  useEffect(() => {
    fetchLeads();
  }, [fetchLeads]);

  /* Update the current lead in the dataset when the its updated. */
  const handleOnLeadUpdate = useCallback(
    (updatedLead: LeadInfo) => {
      const index = data.findIndex(
        (lead) => lead.leadId === updatedLead.leadId
      );

      if (index === -1) {
        message.error('Lead to update not found');
        return;
      }

      const updatedData = [...data];

      updatedData[index] = updatedLead;

      setData(updatedData);
      setSelectedLead(updatedLead);
    },
    [data]
  );

  /*
    When the selected columns change we store them in the local storage.
  */
  useEffect(() => {
    localStorage.setItem(
      LEADS_TABLE_SELECTED_COLUMNS,
      selectedColumns.join(',')
    );
  }, [selectedColumns]);

  /*
    Set the selected columns that are stored in the local storage. We use 
    `useLayoutEffect` here to do this before the render so there is no double
    render when setting the columns.
  */
  useLayoutEffect(() => {
    const selectedColumns = localStorage
      .getItem(LEADS_TABLE_SELECTED_COLUMNS)
      ?.split(',')
      ?.filter(Boolean);

    if (selectedColumns?.length) {
      setSelectedColumns(selectedColumns);
    }
  }, []);

  /*
    We want to select columns that are in the url but are not selected yet. This
    could happen if the user receives a link from someone else that had
    different columns selected.
    We need to use a ref here because the next effect should not run if the
    selected columns change but needs the value of them anyway.
  */

  const selectedColumnsRef = useRef(selectedColumns);

  useEffect(() => {
    selectedColumnsRef.current = selectedColumns;
  }, [selectedColumns]);

  useEffect(() => {
    const queries = searchParams.getAll('query');
    const orQueries = searchParams.getAll('orQuery');

    if (queries.length || orQueries.length) {
      const newColumns = [...selectedColumnsRef.current];

      for (const query of [...queries, ...orQueries]) {
        const column = query.split(':')[0];
        if (!newColumns.includes(column)) {
          newColumns.push(column);
        }
      }

      setSelectedColumns(newColumns);
    }
  }, [searchParams]);

  const isPressingShift = useRef(false);

  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (e.key === 'Shift') {
        isPressingShift.current = true;
      }
    }

    function handleKeyUp(e: KeyboardEvent) {
      if (e.key === 'Shift') {
        isPressingShift.current = false;
      }
    }

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const handleOnTableChange: TableProps<LeadInfo>['onChange'] = function (
    pagination,
    filters,
    sorter
  ) {
    const newSearchParams: ParamKeyValuePair[] = [];

    if (pagination.current && pagination.current !== defaultPage) {
      newSearchParams.push(['page', pagination.current.toString()]);
    }

    if (pagination.pageSize && pagination.pageSize !== defaultPageSize) {
      newSearchParams.push(['pageSize', pagination.pageSize.toString()]);
    }

    const sortings = Array.isArray(sorter) ? sorter : [sorter];

    /* Apply only last sorting if shift is not pressed. */
    if (!isPressingShift.current) {
      sortings.splice(0, sortings.length - 1);
    }

    for (const sort of sortings) {
      if (sort.order) {
        const fields = Array.isArray(sort.field) ? sort.field : [sort.field];

        for (const field of fields) {
          newSearchParams.push(['sort', createOrderString(field, sort.order)]);
        }
      }
    }

    /* Add all existing `orQuery` params to the search params */
    const orQuery = searchParams.getAll('orQuery');
    if (orQuery.length) {
      newSearchParams.push(
        ...orQuery.map((value) => ['orQuery', value] as ParamKeyValuePair)
      );
    }

    for (const key in filters) {
      const value = filters[key];
      if (value?.length) {
        /*
          The email status is a special case. It is a composed index of 
          `hasConversation`, `hasSeenConversation`, 'hasBouncedConversation' and `hasAnsweredConversation`
          but has 5 filters. Those need to be translated to the correct query.

          For all other filters the key is the dataIndex which is the
          corresponding field in the query.
        */
        if (key === emailStatusDataIndex.join('.')) {
          if (value.includes('hasBeenBlacklisted'))
            newSearchParams.push([`query`, `hasBeenBlacklisted:true`]);

          if (value.includes('hasNoConversation'))
            newSearchParams.push([`query`, `hasNoConversation:false`]);

          if (value.includes('hasConversation'))
            newSearchParams.push([`query`, `hasConversation:true`]);

          if (value.includes('hasSeenConversation'))
            newSearchParams.push([`query`, `hasSeenConversation:true`]);

          if (value.includes('hasAnsweredConversation'))
            newSearchParams.push([`query`, `hasAnsweredConversation:true`]);

          if (value.includes('hasBouncedConversation'))
            newSearchParams.push(['query', 'hasBouncedConversation:true']);
        } else {
          newSearchParams.push([
            `query`,
            `${key}:${(value as string[])
              .map((value) => value.trim())
              .filter(Boolean)
              .join(',')}`,
          ]);
        }
      }
    }

    setSearchParams(newSearchParams);
  };

  return (
    <>
      <Table
        title={() => (
          <TableTitle
            columns={columns}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
          />
        )}
        columns={columns}
        dataSource={data}
        rowKey={(record) => record.id}
        loading={loading}
        onChange={handleOnTableChange}
        pagination={{
          total,
          current: pageIndex,
          pageSize,
          showTotal: (total) => `${total} Leads Total`,
        }}
        scroll={{ x: true }}
        footer={() => <TableFooter campaignId={campaignId} columns={columns} />}
      />

      {selectedLead && (
        <EmailLeadModal
          visible={isModalVisible}
          hideModal={() => setIsModalVisible(false)}
          onLeadUpdate={handleOnLeadUpdate}
          data={selectedLead}
        />
      )}
    </>
  );
}

EmailLeadsTable.propTypes = {
  showBouncedEmail: PropTypes.bool,
};
