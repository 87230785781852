import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import csvTemplateEmail from 'assets/files/leads-example_email.csv';
import csvTemplateLinkedin from 'assets/files/leads-example_linkedin.csv';
import PropTypes from 'prop-types';

const TooltipCSVDownload = ({ isLinkedin }) => {
  return (
    <Tooltip
      title={
        <>
          Click{' '}
          <a
            href={isLinkedin ? csvTemplateLinkedin : csvTemplateEmail}
            target="_blank"
            rel="noreferrer"
            className="text-secondary hover:text-secondary-light"
          >
            here
          </a>{' '}
          to download an example of a correct CSV file
        </>
      }
    >
      <>
        <QuestionCircleOutlined className="ml-2 text-sm" />
      </>
    </Tooltip>
  );
};

export default TooltipCSVDownload;

TooltipCSVDownload.propTypes = {
  isLinkedin: PropTypes.bool,
};
