import {
  BarChartOutlined,
  CreditCardFilled,
  HomeOutlined,
  RadarChartOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Dropdown, Popover, Progress } from 'antd';
import CompanyLogo from 'assets/img/svg/logo.svg';
import { LAYOUT_X_PADDING } from 'components/Layout';
import { useUser } from 'hooks/useUser';
import {
  LogoutIcon,
  TargetMarketIcon,
} from 'modules/Campaign/components/Icons';
import { NavLink } from 'react-router-dom';
import routes from 'routes';

/**
 * The header for the application.
 * Which includes the user menu as a dropdown on user click.
 *
 */
export function Header() {
  const { user, userInitials, userCredit, setUserAndToken } = useUser();

  /** @type import('antd').MenuProps['items']  */
  const menuItems = [
    {
      key: 'profile',
      label: (
        <NavLink to={routes.profile}>
          <div className="flex flex-col w-full p-1 cursor-default">
            <div className="text-gray-600">{user?.companyName}</div>
            <div className="text-xs text-secondary">{user?.email}</div>
          </div>
        </NavLink>
      ),
    },
    {
      type: 'divider',
    },

    {
      key: 'home',
      label: <NavLink to={routes.dashboard}>Home</NavLink>,
      icon: <HomeOutlined />,
    },
    {
      key: 'targetMarket',
      label: <NavLink to={routes.targetMarkets}>Target Markets</NavLink>,
      icon: <TargetMarketIcon />,
    },
    {
      key: 'blacklist',
      label: <NavLink to={routes.blacklist}>Blacklist</NavLink>,
      icon: <RadarChartOutlined />,
    },
  ];

  if (user?.role === 'SuperAdmin') {
    menuItems.push({
      key: 'users',
      label: <NavLink to={routes.users}>Users</NavLink>,
      icon: <UserOutlined />,
    });
  }

  if (user?.role === 'SuperAdmin' || user?.role === 'Admin') {
    menuItems.push({
      key: 'monitorCampaigns',
      label: <NavLink to={routes.records}>Monitor Campaigns</NavLink>,
      icon: <BarChartOutlined />,
    });
  }

  menuItems.push({
    type: 'divider',
  });

  menuItems.push({
    key: 'Logout',
    danger: true,
    label: (
      <NavLink
        to={routes.login}
        onClick={() => setUserAndToken(undefined, undefined, undefined)}
      >
        <span className="text-gray-800">Logout</span>
      </NavLink>
    ),
    icon: <LogoutIcon />,
  });

  return (
    <header
      className={`flex items-center border border-b border-solid border-slate-200 py-4 md:py-6 ${LAYOUT_X_PADDING}`}
    >
      {/* Logo Incedo Lead Generator */}
      <NavLink
        to={routes.dashboard}
        className="flex flex-wrap items-center mr-auto font-bold no-underline text-primary"
      >
        <img src={CompanyLogo} className="w-8 mr-4" alt="logo" />
        {/* Show for breakpoints > sm */}
        <div className="hidden sm:block">Incedo Lead Generator</div>
        {/* Show for breakpoints < sm */}
        <div className="sm:hidden">ILG</div>
      </NavLink>

      {/* Progress Bar with credits */}
      <Popover
        className="mr-4 bg-white cursor-pointer"
        title={() => (
          <div className="flex justify-between p-1 align-middle text-primary">
            <span> User credits</span>
            <CreditCardFilled className="mt-0.5 text-xs" />
          </div>
        )}
        content={
          <div className="flex flex-col justify-between p-0.5 text-gray-600 align-middle">
            <div className="flex justify-between">
              <span>Purchased</span>
              <span>{userCredit.boughtCredit}</span>
            </div>
            <div className="flex justify-between">
              <span>Used</span>
              <span>{userCredit.usedCredit}</span>
            </div>
            <div className="flex justify-between">
              <span>Remaining</span>
              <span>{userCredit.currentCredit}</span>
            </div>
          </div>
        }
      >
        <div className="text-primary text-sm text-center min-w-[8rem] -mb-1">
          <CreditCardFilled className="mr-2" />
          {`${userCredit.currentCredit}/${userCredit.boughtCredit}`}
        </div>
        <Progress
          strokeColor={{
            '0%': '#375361',
            '100%': '#dcb223',
          }}
          trailColor={'#D0D0CE'}
          percent={userCredit.creditLeftPercentage}
          showInfo={false}
          strokeWidth={10}
        />
      </Popover>

      {/* UserName Circle with Dropdown Menu */}
      <Dropdown
        menu={{ items: menuItems }}
        trigger={['hover']}
        placement="bottomRight"
        className="cursor-pointer"
      >
        <Avatar
          style={{
            backgroundColor: '#375361',
          }}
          size={38}
          gap={9}
        >
          {userInitials}
        </Avatar>
      </Dropdown>
    </header>
  );
}
