import {
  CreditCardFilled,
  InfoCircleFilled,
  LinkedinFilled,
  MailFilled,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Divider, Layout } from 'antd';
import API from 'api';
import { Card } from 'components/Card';
import { useUser } from 'hooks/useUser';
import { SubHeader } from 'modules/Campaign/components/SubHeader';
import { useEffect, useState } from 'react';
import { difference } from 'services/arithmetics';

import { CardEntry } from './components/ProfileCard/CardEntry';
import { CardList } from './components/ProfileCard/CardList';
import { CardTable } from './components/ProfileCard/CardTable';
import { ProfileCard } from './components/ProfileCard/ProfileCard';

export const ProfilePage = () => {
  const [campaigns, setCampaigns] = useState([]);

  const { user } = useUser();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: campaigns } = await API.getCampaigns(user.id);
        setCampaigns(campaigns);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [user.id]);

  function findAccountData(key, nestedKey) {
    const accounts = [];
    for (const item of campaigns) {
      if (item[key]) accounts.push(item[key][nestedKey]);
    }
    const uniqueAccounts = Array.from(new Set(accounts));
    return uniqueAccounts.map((e, i) => (
      <div className="flex flex-col mb-2 text-gray" key={i}>
        <div>{e}</div>
      </div>
    ));
  }

  function findActiveCampaigns() {
    const active = campaigns.filter((campaign) => campaign.status === 'ACTIVE');
    return (
      <div className="flex flex-col text-gray-500">
        <CardEntry label={'Total'} data={campaigns.length} />
        <CardEntry label={'Active'} data={active.length} />
        <CardEntry label={'Paused'} data={campaigns.length - active.length} />
      </div>
    );
  }

  return (
    <Layout className="fade-in">
      <SubHeader title="User Profile" subtitle={user.companyName} />

      <Card
        shadow
        className="mx-auto xs:w-[80%] sm:w-[70%] md:w-[60%] lg:w-[50%] xl:w-[40%] p-8"
        borderRadius="rounded-lg"
      >
        <div className="flex pt-2 align-middle">
          <div>
            <Avatar className="bg-primary" size={48} icon={<UserOutlined />} />
          </div>
          <div className="flex flex-col ml-4 align-middle">
            <div className="text-base text-primary">{user.companyName}</div>
            <div className="mt-1 text-secondary">{user.email}</div>
          </div>
        </div>

        <Divider className="pb-4" />

        {/* Main Grid */}
        <div className="grid grid-cols-2 gap-8 border">
          <ProfileCard
            text={'Credits'}
            icon={<CreditCardFilled className="text-green-700" />}
          >
            <CardTable>
              <CardEntry label={'Purchased'} data={user.userBoughtCredit} />
              <CardEntry label={'Used'} data={user.userUsedCredit} />
              <CardEntry
                label={'Remaining'}
                data={
                  +difference(
                    +user.userBoughtCredit || 0,
                    +user.userUsedCredit || 0
                  )
                }
              />
            </CardTable>
          </ProfileCard>

          <ProfileCard
            text={'Campaigns'}
            icon={<InfoCircleFilled />}
            iconColor={'text-yellow-600'}
          >
            <CardTable>{findActiveCampaigns()}</CardTable>
          </ProfileCard>

          <ProfileCard
            text={'LinkedIn Accounts'}
            icon={<LinkedinFilled />}
            iconColor="text-blue-600"
          >
            <CardList data={findAccountData('linkedInAccount', 'email')} />
          </ProfileCard>

          <ProfileCard
            text={'Email Accounts'}
            icon={<MailFilled />}
            iconColor="text-orange-400"
          >
            <CardList data={findAccountData('email', 'address')} />
          </ProfileCard>
        </div>
      </Card>
    </Layout>
  );
};
