import './LoginForm.styles.css';

import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import API from 'api';
import LoginLogo from 'assets/img/svg/login.svg';
import { Card } from 'components/Card';
import { Layout } from 'components/Layout';
import { useUser } from 'hooks/useUser';
import { useCallback, useState } from 'react';

/** The login interface for the application. */
export function LoginForm() {
  const [isLoading, setIsLoading] = useState(false);

  const { setUserAndToken } = useUser();

  const onFinish = useCallback(
    async ({ username, password }) => {
      try {
        setIsLoading(true);

        const { data } = await API.postLogin({
          username,
          password,
        });

        setIsLoading(false);

        setUserAndToken(data.user, data.token, data.cdsApiKey);

        message.success(`You are connected with ${data.user.companyName}`);
      } catch (err) {
        setIsLoading(false);
        message.error(
          err?.response?.status === 401
            ? `This ILG account doesn't exist. Try a different account.`
            : 'Error occurred while trying to login, try again later...'
        );
      }
    },
    [setUserAndToken]
  );

  return (
    <Layout>
      <Card
        shadow
        className="fadeAndSlideIn max-w-md mx-auto mt-[10vh]"
        borderRadius="rounded-lg"
        padding="p-4 sm:p-6 md:px-8"
      >
        <img
          src={LoginLogo}
          alt="login section"
          width={128}
          className="block mx-auto"
        />
        <section className="form">
          <header>
            <h1>Log In</h1>
            <h5>Enter your email and password to login to your dashboard.</h5>
          </header>
          <Form
            name="login-form"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              label="Email"
              rules={[
                {
                  required: true,
                  message: 'Please provide an email.',
                },
                {
                  type: 'email',
                  message: 'Please provide a valid email.',
                },
              ]}
            >
              <Input prefix={<MailOutlined />} placeholder="john@doe.com" />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: 'Please provide a password.',
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="password"
              />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="w-full mb-2"
              loading={isLoading}
            >
              Sign in
            </Button>
          </Form>
        </section>
      </Card>
    </Layout>
  );
}
