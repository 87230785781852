import { LinkedinFilled, MailFilled } from '@ant-design/icons';
import { FilterOff } from 'modules/Campaign/components/Icons';

/** Enum Simulation to reflect campaign type values */
export const type = Object.freeze({
  OUT_SAL: 'OutreachSalesNav',
  OUT_CSV: 'OutreachCSV',
  EX_SAL: 'ExistingSalesNav',
  EX_LIN: 'ExistingLinkedIn',
  EX_CSV: 'ExistingCSV',
  EM_CSV: 'EmailCSV',
  EM_TM: 'EmailTargetMarket',
});

/** Description of campaign type | used in Review.jsx */
export const description = [
  {
    type: 'OutreachSalesNav',
    label: 'New Leads From Sales Navigator Search List',
  },
  { type: 'OutreachCSV', label: 'New Leads from CSV File' },
  {
    type: 'ExistingSalesNav',
    label: 'Existing Leads From Sales Navigator Search List',
  },
  {
    type: 'ExistingLinkedIn',
    label: 'Existing Leads From LinkedIn Search List',
  },
  { type: 'ExistingCSV', label: 'Existing Leads from CSV File' },
  { type: 'EmailCSV', label: 'Email Leads from CSV File' },
  { type: 'EmailTargetMarket', label: 'Email Leads from a Target Market' },
];

export const formattedTypes = [
  {
    key: 0,
    icon: <FilterOff className="text-[#169614]" />,
    children: [{ key: '2-1', label: 'All', init: '' }],
  },
  {
    key: 1,
    icon: <LinkedinFilled className="text-blue-600" />,
    children: [
      { key: '1-1', label: 'New Leads', init: 'Outreach' },
      { key: '1-2', label: 'Existing Network', init: 'Existing' },
    ],
  },
  {
    key: 2,
    icon: <MailFilled className="text-orange-500" />,
    children: [{ key: '2-1', label: 'Email', init: 'Email' }],
  },
];

export const getUniqueTypes = (campaignData) => {
  return campaignData.map((c) => {
    switch (c.type) {
      case type.EX_SAL:
      case type.EX_CSV:
      case type.EX_LIN:
        return 'Existing';
      case type.OUT_SAL:
      case type.OUT_CSV:
        return 'Outreach';
      case type.EM_CSV:
      case type.EM_TM:
        return 'Email';
      default:
        return '';
    }
  });
};

/** Returns the description of a specific campaign type */
export const getTypeDescription = (typeSelected) => {
  const [sel] = description.filter((e) => e.type === typeSelected);
  return sel.label;
};

/** Returns the link of the campaign (works only if the campaign is not a CSV type) using the <type: {campaignType: link}> from the form instance */
export const getLink = (objValues) => {
  const [link] = objValues;
  return link;
};

/** TRUE if campaign is of LinkedIn type (New Leads or Existing), false otherwise */
export const isLinkedinCampaignType = (str) =>
  isOutreachCampaignType(str) || isExistingCampaignType(str);

/** TRUE if campaign is of Linkedin CSV type, false otherwise */
export const isLinkedInCSV = (str) =>
  str === type.OUT_CSV || str === type.EX_CSV;

/** TRUE if campaign is of CSV LINKEDIN type, false otherwise */
export const isEmailCSV = (str) => str === type.EM_CSV;

/** TRUE if campaign is of EMAIL type, false otherwise */
export const isEmailCampaignType = (str) =>
  str === type.EM_CSV || str === type.EM_TM;

/** TRUE if campaign is of EMAIL Target Market, false otherwise */
export const isEmailTargetMargetCampaignType = (str) => str === type.EM_TM;

/** TRUE if campaign is of CSV EMAIL type, false otherwise */
export const isCSV = (str) =>
  str === type.OUT_CSV || str === type.EX_CSV || str === type.EM_CSV;

/** TRUE if campaign is of OUTREACH type, false otherwise */
export const isOutreachCampaignType = (str) =>
  str === type.OUT_CSV || str === type.OUT_SAL;

/** TRUE if campaign is of EXISTING type, false otherwise */
export const isExistingCampaignType = (camType) =>
  camType === type.EX_LIN || camType === type.EX_CSV || camType === type.EX_SAL;

/** TRUE if campaign is of Outreach Sales Nav, false otherwise */
export const isOutreachSalesNav = (camType) => camType === type.OUT_SAL;

// * String Constants

export const OUTREACH_MESSAGE = `Setup the LinkedIn account that is going to be used for this campaign,
        select the range of daily invites being sent out and then enter the
        initial contact message.`;

export const EXISTING_MESSAGE = `Setup the LinkedIn account that is going to be used for this campaign,
        select the range of daily messages being sent out and then enter the
        initial contact message.`;

export const OUTREACH_LABEL = `Daily invites range`;

export const EXISTING_LABEL = `Daily first contact messages range`;

export const getDetails = (type, prop) => {
  const [item] = TYPE_DETAILS.filter((obj) => obj.type === type);
  return item[prop];
};

/** Helper in the form to get dynamic values */
export const TYPE_DETAILS = [
  {
    type: 'OutreachSalesNav',
    title: 'Sales Navigator Url',
    subTitle:
      'Enter the sales navigator URL containing the list of leads to contact',
    rule: 'Please provide a valid sales navigator URL with a list of leads',
    urlToCheck: 'https://www.linkedin.com/sales/search/people',
    error:
      'Invalid Sales Navigator link, a valid link should look like the following:\n"https://www.linkedin.com/sales/search/people..."',
  },
  {
    type: 'ExistingSalesNav',
    title: 'Sales Navigator Url',
    subTitle:
      'Enter the sales navigator URL containing the list of leads to contact',
    rule: 'Please provide a valid sales navigator URL with a list of leads',
    urlToCheck: 'https://www.linkedin.com/sales/search/people',
    error:
      'Invalid Sales Navigator link, a valid link should look like the following:\n"https://www.linkedin.com/sales/search/people..."',
  },
  {
    type: 'ExistingLinkedIn',
    title: 'LinkedIn Url',
    subTitle: 'Enter the LinkedIn URL containing the list of leads to contact',
    rule: 'Please provide a valid LinkedIn URL with a list of first degree connection leads',
    urlToCheck: 'https://www.linkedin.com/search/results/people',
    error:
      'Invalid LinkedIn search link, a valid link should look like the following:\n"https://www.linkedin.com/search/results/people..."',
  },
  {
    type: 'OutreachCSV',
    title: 'Csv File',
    subTitle: 'Enter the LinkedIn URL containing the list of leads to contact',
    rule: 'Please provide a valid LinkedIn URL with a list of first degree connection leads',
    urlToCheck: 'https://www.linkedin.com/search/results/people',
    error:
      'Invalid LinkedIn search link, a valid link should look like the following:\n"https://www.linkedin.com/search/results/people..."',
  },
  {
    type: 'ExistingCSV',
    title: 'Csv File',
    subTitle: 'Enter the LinkedIn URL containing the list of leads to contact',
    rule: 'Please provide a valid LinkedIn URL with a list of first degree connection leads',
    urlToCheck: 'https://www.linkedin.com/search/results/people',
    error:
      'Invalid LinkedIn search link, a valid link should look like the following:\n"https://www.linkedin.com/search/results/people..."',
  },
];
