import { Spin } from 'antd';
import API from 'api';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { parseCampaignIntoForm } from 'services/campaignForm';
import {
  isEmailCampaignType,
  isLinkedinCampaignType,
} from 'services/campaignType';

import EditEmailCampaign from './EditEmailCampaign/EditEmailCampaign';
import { EditLinkedinCampaign } from './EditLinkedinCampaign/EditLinkedinCampaign';

export function EditCampaign() {
  const { id: campaignId } = useParams();
  const [campaign, setCampaign] = useState(null);
  const [formCampaign, setFormCampaign] = useState(null);
  const [isEmailEditable, setIsEmailEditable] = useState(true);

  useEffect(() => {
    async function fetchCampaign() {
      try {
        const { data: response } = await API.editCampaign(campaignId);
        setFormCampaign(parseCampaignIntoForm(response));
        setCampaign(response);
        setIsEmailEditable(response.numOfInvitesSent !== 0 ? false : true);
      } catch (err) {
        throw new Error('Error fetching data');
      }
    }
    fetchCampaign();
  }, [campaignId]);

  const props = {
    formCampaign: formCampaign,
    campaign: campaign,
  };

  return (
    <>
      {campaign ? (
        <>
          {isLinkedinCampaignType(campaign?.type) && (
            <EditLinkedinCampaign {...props} />
          )}
          {isEmailCampaignType(campaign?.type) && (
            <EditEmailCampaign {...props} isEmailEditable={isEmailEditable} />
          )}
        </>
      ) : (
        <div className="flex h-screen">
          <div className="m-auto">
            <Spin />
          </div>
        </div>
      )}
    </>
  );
}
