import { message } from 'antd';
import { useQuery } from 'hooks/useQuery';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

type Notifications = {
  [key: string]: {
    type: 'success' | 'error';
    message: string;
  };
};

const notificationMessages: Notifications = {
  success1: {
    type: 'success',
    message: 'Email authenticated successfully',
  },
  failure1: {
    type: 'error',
    message:
      "The email address doesn't match the one entered during campaign creation, please try again or contact ILG Support team",
  },
  failure2: {
    type: 'error',
    message: 'Unexpected error, please try again',
  },
};

export function QueryNotifications() {
  const navigate = useNavigate();
  const queryParams = useQuery();

  useEffect(() => {
    const notificationCode = queryParams.get('notificationCode');

    if (notificationCode) {
      queryParams.delete('notificationCode');
      navigate({ search: queryParams.toString() });

      const notification = notificationMessages[notificationCode];

      if (notification) {
        if (notification.type === 'success') {
          message.success(notification.message);
        } else {
          message.error(notification.message);
        }
      }
    }
  }, [navigate, queryParams]);

  return null;
}
