import { Button, Form, notification } from 'antd';
import API from 'api';
import { Layout } from 'components/Layout';
import { FormButtons } from 'modules/Campaign/components/Form/FormButtons';
import { FormCard } from 'modules/Campaign/components/Form/FormCard';
import { SubHeader } from 'modules/Campaign/components/SubHeader';
import { EmailFollowups } from 'modules/Campaign/CreateCampaign/Steps/Shared/EmailFollowups';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import routes from 'routes';
import { isEmailTargetMargetCampaignType } from 'services/campaignType';

import { Steps } from '../../../../components/Steps';
import { useUser } from '../../../../hooks/useUser';
import { handleUpdateCampaign } from '../EditLinkedinCampaign/helpers';
import { AccountDetails } from './components/AccountDetails';
import { BasicEmailInfo } from './components/BasicEmailInfo';
import { Review } from './components/EmailReview';

const EditEmailCampaign = ({ campaign, formCampaign, isEmailEditable }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchData = async () => {
      const { data: targetMarket } = await API.getTargetMarket(
        campaign.targetMarketId
      );
      form.setFieldValue('targetMarketName', targetMarket.name);
    };

    if (isEmailTargetMargetCampaignType(form.getFieldValue('type')))
      fetchData();
  }, [campaign.targetMarketId, form]);

  const openNotification = (type, title, desc) => {
    notification[type]({
      message: title,
      description: desc,
    });
  };

  /** @type import('../../../../components/Steps').StepsProps['steps'] */
  const steps = useMemo(
    () =>
      [
        {
          title: 'Account Details',
          content: <AccountDetails isEmailEditable={isEmailEditable} />,
        },
        { title: 'Basic info', content: <BasicEmailInfo /> },
        {
          title: 'Email follow ups',
          content: <EmailFollowups isEdit campaign={campaign} />,
        },
        { title: 'Review', content: <Review campaign={formCampaign} /> },
      ].map((step, index) => ({
        ...step,
        onClick: () => {
          form
            .validateFields()
            .then(() => setCurrent(index))
            .catch(() => null);
        },
      })),
    [campaign, form, formCampaign, isEmailEditable]
  );

  // Steps state & handlers
  const [current, setCurrent] = useState(0);

  const navigate = useNavigate();
  const backToDashboard = () => {
    navigate(routes.dashboard);
  };

  const { user } = useUser();

  const updateCampaign = () => {
    if (current < steps.length - 1) {
      setCurrent(current + 1);
      return;
    }

    const formData = form.getFieldsValue(true);
    handleUpdateCampaign(
      formData,
      campaign.id,
      formCampaign,
      openNotification,
      backToDashboard,
      user?.id
    );
  };

  return (
    <Layout>
      <SubHeader title="Edit Campaign" subtitle={campaign.name} />
      <Form
        name="newCampaign"
        layout="vertical"
        form={form}
        onFinish={updateCampaign}
        onFinishFailed={(errorInfo) => {
          console.log('Failed:', errorInfo);
        }}
        initialValues={formCampaign}
        scrollToFirstError={{
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        }}
      >
        <FormCard>
          <Steps
            steps={steps}
            currentStep={current}
            className="mt-2 mb-4 sm:mt-4 sm:mb-6"
          />
          {steps[current].content}
          <FormButtons>
            {!current ? (
              <Button onClick={backToDashboard}>Cancel</Button>
            ) : (
              <Button onClick={() => setCurrent(current - 1)}>Previous</Button>
            )}
            <Button type="primary" htmlType="submit">
              {current === steps.length - 1 ? 'Update' : 'Next'}
            </Button>
          </FormButtons>
        </FormCard>
      </Form>
    </Layout>
  );
};
export default EditEmailCampaign;

EditEmailCampaign.propTypes = {
  /** Function the re-fetch the campaigns from the api */
  campaign: PropTypes.object.isRequired,
  /** Function the re-fetch the campaigns from the api */
  formCampaign: PropTypes.object.isRequired,
  /** TRUE if no email has been sent yet */
  isEmailEditable: PropTypes.bool,
};
