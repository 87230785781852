import routes from 'routes';

import { UsersTable } from './components/UsersTable/UsersTable';

const UsersTableScreen = () => <UsersTable />;

export default {
  routeProps: {
    path: routes.users,
    component: UsersTableScreen,
  },
  name: 'users',
  routeVisibility: 'private',
};
