import { Divider } from 'antd';
import { PropTypes } from 'prop-types';

const TableTitle = (props) => {
  return (
    <div>
      {props.isFilterActive ? (
        <div className="flex align-middle">
          <svg className="mx-1" width="20" height="20" viewBox="0 -16 256 256">
            <path
              fill="#D7A600"
              d="M236.7 188L148.8 36a24 24 0 0 0-41.6 0L19.3 188A23.9 23.9 0 0 0 40 224h176a23.9 23.9 0 0 0 20.7-36ZM120 104a8 8 0 0 1 16 0v40a8 8 0 0 1-16 0Zm8 88a12 12 0 1 1 12-12a12 12 0 0 1-12 12Z"
            />
          </svg>
          <span className="mx-1 font-medium">Filters are active</span>
          <Divider type="vertical" style={{ fontSize: '26px' }} />
          <svg className="mx-1" width="20" height="20" viewBox="0 0 24 24">
            <path
              fill="#a5a5a5"
              d="M7.7 16.3q.275.275.7.275q.425 0 .7-.275l2.9-2.9l2.925 2.925q.275.275.688.262q.412-.012.687-.287q.275-.275.275-.7q0-.425-.275-.7L13.4 12l2.925-2.925q.275-.275.262-.688q-.012-.412-.287-.687q-.275-.275-.7-.275q-.425 0-.7.275L12 10.6L9.075 7.675Q8.8 7.4 8.388 7.412q-.413.013-.688.288q-.275.275-.275.7q0 .425.275.7l2.9 2.9l-2.925 2.925q-.275.275-.262.687q.012.413.287.688ZM12 22q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Z"
            />
          </svg>
          <a
            className="mx-1 text-gray-400"
            onClick={() => props.handleChange('')}
          >
            Clear Filter
          </a>
        </div>
      ) : (
        <div className="text-sm text-gray-400">{props.children}</div>
      )}
    </div>
  );
};

export default TableTitle;

TableTitle.propTypes = {
  isFilterActive: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
};
