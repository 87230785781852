import { Button, Form, Select } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';

export const Dropdown = ({ filter, closeFilter }) => {
  const form = Form.useFormInstance();
  const [max, setMax] = useState(null);
  const [min, setMin] = useState(null);
  const [optionSelected, setOptionSelected] = useState({
    subFilterId: filter.subFilters[0].id,
    title: filter.subFilters[0].title,
  });
  const { Option } = Select;
  const [isError, setIsError] = useState(undefined);
  const maxOptions = filter.rangeConfig.acceptedValues.map((option) => ({
    displayValue: option.displayValue,
    id: +option.id,
  }));

  const filteredMaxOptions = useMemo(
    () =>
      maxOptions.filter((opt) => {
        return +opt.displayValue > +min;
      }),
    [min, maxOptions]
  );

  useEffect(() => {
    if (min) {
      const value = filteredMaxOptions[0].displayValue;
      if (+max < +value) {
        setMax(+value);
      }
    }
  }, [filteredMaxOptions, max, min]);

  function validateAndAddValuesToForm() {
    if (!max || !min) {
      setIsError('error');
      return;
    }
    const toInsert = {
      id: filter.id,
      ...optionSelected,
      rangeValues: { minValue: min, maxValue: max },
    };
    form.setFieldValue(['filters', filter.name, 0], toInsert);
  }

  const getSubFilter = (value) => {
    const subFilterSelected = filter.subFilters.find(
      (subFilter) => subFilter.title === value
    );
    setOptionSelected({
      subFilterId: subFilterSelected.id,
      title: subFilterSelected.title,
    });
  };

  return (
    <div className="flex items-center justify-between my-2 fade-in">
      <div className="flex flex-col w-full gap-2">
        {filter?.subFilters && (
          <Select
            dropdownStyle={{ width: '80%' }}
            status={isError && optionSelected === undefined ? 'error' : ''}
            onChange={(value) => {
              getSubFilter(value);
            }}
            defaultValue={filter.subFilters[0].title}
          >
            {filter?.subFilters?.map((option) => {
              return <Option key={option.id} value={option.title}></Option>;
            })}
          </Select>
        )}

        <div className="flex items-center justify-between w-full gap-4">
          <Select
            style={{
              width: 180,
            }}
            placeholder={filter.rangeConfig.minValuePlaceholder}
            value={min}
            status={isError && min === null ? isError : null}
            onChange={(value) => {
              setMin(value);
            }}
          >
            {filter.rangeConfig.acceptedValues.map((option) => {
              return <Option key={option.id} value={option.title}></Option>;
            })}
          </Select>

          <div>to</div>

          <Select
            style={{
              width: 180,
            }}
            placeholder={filter.rangeConfig.maxValuePlaceholder}
            value={max}
            status={isError && max === null ? isError : null}
            onChange={(value) => {
              setMax(value);
            }}
          >
            {filteredMaxOptions.map((option) => {
              return <Option key={option.id} value={option.title}></Option>;
            })}
          </Select>
        </div>

        <div className="flex justify-end">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              validateAndAddValuesToForm();
              closeFilter();
            }}
          >
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  filter: PropTypes.object,
  closeFilter: PropTypes.func,
};
