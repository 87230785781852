import { PlusOutlined } from '@ant-design/icons';
import { Button, Tag } from 'antd';
import PropTypes from 'prop-types';

const AppendText = ({ text, onClick }) => {
  return (
    <Button type="link " onClick={onClick} style={{ padding: '0px' }}>
      <Tag text={text} color={'gold'}>
        <PlusOutlined className="mr-1" />
        {text}
      </Tag>
    </Button>
  );
};

export default AppendText;

AppendText.propTypes = {
  /** The text showed by the Tag component */
  text: PropTypes.string.isRequired,
  /** Function to be called onClick. */
  onClick: PropTypes.func.isRequired,
};
