export const radioOptions = [
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Inactive',
    value: 'STOPPED',
  },
  {
    label: 'All',
    value: 'ALL',
  },
];
