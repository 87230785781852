import PropTypes from 'prop-types';

import ProgressStat from './ProgressStat';

const SingleStat = ({ param1, param2 }) => {
  return (
    <div>
      <ProgressStat divider={+param1} dividend={+param2} />
      <span className="text-xs text-gray-500 uppercase">
        {`${param2} / ${+param1}`}
      </span>
    </div>
  );
};

export default SingleStat;

SingleStat.propTypes = {
  param1: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  param2: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
