import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Form, Input, Tooltip, Typography } from 'antd';
import api from 'api';
import GoogleIcon from 'assets/icons/GoogleIcon';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import FormSection from './FormSection';

const { Text } = Typography;

export function LogoGmail(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="0.8em"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill="currentColor"
        d="M20 18h-2V9.25L12 13L6 9.25V18H4V6h1.2l6.8 4.25L18.8 6H20m0-2H4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2Z"
      ></path>
    </svg>
  );
}

export const EmailFollowupsAccountForm = ({
  isRequired,
  isEditing,
  campaign,
  className,
}) => {
  // Generate OAuth link from the backend
  const handleAuthenticate = async () => {
    try {
      const res = await api.getFollowUpsEmailAuthUrl(
        campaign?.email.id,
        campaign.id,
        campaign?.email.address
      );
      window.open(res.data.url);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FormSection title={'Email Account Details'} className={className}>
      <div
        className={classNames({
          'md:flex md:gap-8 md:items-center': isEditing,
        })}
      >
        <div
          className={classNames('max-w-md mx-auto', {
            'md:max-w-none md:mx-0 md:flex-1': isEditing,
          })}
        >
          <Form.Item
            label="Email address"
            name="emailAddress"
            rules={[
              {
                required: isRequired,
                message:
                  'Please provide an email address for your Email followups',
              },
              {
                pattern:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Please enter a valid email address',
              },
            ]}
          >
            <Input name="emailAddress" autoComplete="username" />
          </Form.Item>
          <Form.Item
            label="Email Subject"
            name="emailFollowUpsSubject"
            rules={[
              {
                required: isRequired,
                message:
                  'Please provide an Email Subject for your email followups',
              },
            ]}
          >
            <Input name="emailFollowUpsSubject" />
          </Form.Item>
        </div>
        {isEditing && (
          <div className="flex flex-col gap-2 items-center max-w-sm mx-auto md:max-w-none md:mx-0 md:border-0 md:border-l md:border-solid md:border-slate-100 md:pl-8">
            <Text style={{ color: '#375361' }} strong>
              <LogoGmail />{' '}
              <span className="mr-2 text-xs uppercase">status</span>
              {!campaign?.email?.isAuthenticated && (
                <Tooltip title="Please authenticate with google in order to be able to use the Email Follow Up feature">
                  <InfoCircleFilled className="text-xs" />
                </Tooltip>
              )}
            </Text>
            <Text
              className="cursor-not-allowed"
              level={4}
              editable={false}
              style={{
                color: campaign?.email?.isAuthenticated
                  ? 'rgb(34 197 94)'
                  : 'rgb(239 68 68)',
              }}
            >
              {campaign?.email?.isAuthenticated
                ? 'Authenticated'
                : 'Not Authenticated'}
            </Text>
            {!campaign?.email?.isAuthenticated && (
              <Button onClick={handleAuthenticate}>
                <div className="flex flex-row justify-center -ml-1 space-x-3">
                  <GoogleIcon />
                  <div
                    style={{
                      fontFamily: 'Roboto',
                      fontWeight: 400,
                      fontSize: 14,
                      opacity: 54,
                    }}
                  >
                    Sign in with Google
                  </div>
                </div>
              </Button>
            )}
          </div>
        )}
      </div>
    </FormSection>
  );
};

EmailFollowupsAccountForm.propTypes = {
  isRequired: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool,
  campaign: PropTypes.object,
  className: PropTypes.string,
};
