import { Button, Divider, Popover } from 'antd';
import PropTypes from 'prop-types';

const MessagePreviewLabel = ({ previewMessage }) => {
  return (
    <div>
      <span className="mr-1">Message Preview:</span>
      <Popover
        title="Female gender leads"
        content={() => previewMessage('female')}
        placement="bottomRight"
      >
        <Button type="link" className="-mx-2 text-secondary">
          Female
        </Button>
      </Popover>
      <Divider type="vertical" />
      <Popover
        title="Male gender leads"
        content={() => previewMessage('male')}
        placement="bottomRight"
      >
        <Button type="link" className="-mx-2 text-secondary">
          Male
        </Button>
      </Popover>
    </div>
  );
};

export default MessagePreviewLabel;

MessagePreviewLabel.propTypes = {
  /** Parent function that creates the preview for the message. */
  previewMessage: PropTypes.func,
};
