import { message, Spin, Typography } from 'antd';
import API from 'api';
import { Card } from 'components/Card';
import { Layout } from 'components/Layout';
import { useEffect, useState } from 'react';

import FilterHeader from '../components/FilterHeader';
import { FilterIcon } from '../components/Icons';
import { SubHeader } from '../components/SubHeader';
import { EmailTable } from './components/EmailTable';
import { ExistingTable } from './components/ExistingTable';
import { OutreachTable } from './components/OutreachTable';
import { RecordsTable } from './components/RecordsTable';

export function RecordsDashboard() {
  const [campaigns, setCampaigns] = useState([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState('All');

  useEffect(() => {
    const fetchAllCampaignsLeadsStat = async () => {
      try {
        const { data: campaignData } = await API.getMonitorCampaignsStats();
        setCampaigns(campaignData);

        setIsLoading(false);
      } catch (error) {
        message.error('Could not fetch campaigns with stats!');
        setIsLoading(false);
      }
    };

    fetchAllCampaignsLeadsStat();
  }, []);

  /** Picks the type of campaign to filter the table in order to populate the table with the correct entries */
  const selectCampaign = (type) => {
    setSelected(type.label);
    setFilteredCampaigns(
      campaigns.filter((campaign) => campaign.type.startsWith(type.init))
    );
  };

  return (
    <Layout>
      <SubHeader title="Monitor" />

      {isLoading ? (
        <div className="flex flex-col justify-center items-center my-8">
          <Spin size="large" style={{ fontSize: '72', color: '#375361' }} />
          <Typography.Title
            level={5}
            style={{ color: '#375361', opacity: 0.5 }}
          >
            Loading...
          </Typography.Title>
        </div>
      ) : (
        <>
          <div className="flex flex-col justify-center mb-4 md:flex-row sm:justify-between">
            <div className="flex items-center justify-center">
              <FilterIcon className="text-xl text-gray-500 md:text-4xl" />
              <div className="ml-4 text-sm text-gray-500 md:text-xl">
                Filter Table by campaign type
              </div>
            </div>

            <Card shadow>
              <FilterHeader
                selected={selected}
                selectCampaign={selectCampaign}
              />
            </Card>
          </div>

          {selected === 'New Leads' ? (
            <OutreachTable campaigns={filteredCampaigns} />
          ) : selected === 'Existing Network' ? (
            <ExistingTable campaigns={filteredCampaigns} />
          ) : selected === 'Email' ? (
            <EmailTable campaigns={filteredCampaigns} />
          ) : selected === 'All' ? (
            <RecordsTable campaigns={campaigns} />
          ) : null}
        </>
      )}
    </Layout>
  );
}
