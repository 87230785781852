import { Skeleton } from 'antd';
import API from 'api';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import SingleStatItem from './components/SingleStatItem';

export default function FupsStatisticItem({ campaignId }) {
  const [fups, setFups] = useState([]);
  const [maxFups, setMaxFups] = useState(0);
  const [isErr, setIsErr] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        //Getting the max number of FollowUps set during the campaign
        const max = await API.findMaxAmountFups(campaignId);
        setMaxFups(max.data);
        //Getting the statistics about Fups | The first 3 elements (index 0, 1, 2 are TotAmount of Replies, Fups Replies, InitMessage Replies)
        const fups = await API.getFupsStats(campaignId);
        setFups(fups.data);
        //Set total amount of Replied overall
        setTotal(+fups.data[0].value);
      } catch (err) {
        setIsErr(true);
      }
    };
    fetchData();
  }, [campaignId]);

  return isErr ? (
    <div>Data could not be retrieved</div>
  ) : (
    <Skeleton loading={fups === undefined} active>
      {/* The first 3 elements (index 0, 1, 2 are TotAmount of Replies, InitMessage Replies, Fups Replies) */}
      {fups.map((item, i) =>
        i > 1 && i <= maxFups + 1 ? (
          <SingleStatItem
            key={`fup${i}`}
            label={item.label}
            value={+item.value}
            total={total}
          />
        ) : null
      )}
    </Skeleton>
  );
}

FupsStatisticItem.propTypes = {
  fups: PropTypes.array,
  campaignId: PropTypes.number,
  repliedTotal: PropTypes.number,
};
