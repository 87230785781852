import { useUser } from 'hooks/useUser';

export function ForUsers({ children }: { children: React.ReactNode }) {
  const { user } = useUser();

  if (user) {
    return children;
  }

  return null;
}
