import { message } from 'antd';
import API from 'api';
import { ILG_JWT_TOKEN } from 'constant';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { calcPercent } from 'utils/helpers';

export interface User {
  id: number;
  companyName: string;
  email: string;
  role: string;
  userBoughtCredit: number;
  userUsedCredit: number;
  exp: number;
  iat: number;
  createdAt: string;
  updatedAt: string;
}

export interface UserCredit {
  usedCredit: number;
  boughtCredit: number;
  currentCredit: number;
  creditLeftPercentage: string | number;
}

export type SetUserAndToken = (
  user: User | undefined,
  token: string | undefined,
  cdsApiKey: string | undefined
) => void;

const emptyUserCredit: UserCredit = {
  usedCredit: 0,
  boughtCredit: 0,
  currentCredit: 0,
  creditLeftPercentage: 0,
};

const UserContext = createContext<{
  user?: User;
  token?: string;
  cdsApiKey?: string;
  userCredit: UserCredit;
  userInitials: string;
  setUserAndToken: SetUserAndToken;
}>({
  userCredit: emptyUserCredit,
  userInitials: '',
  setUserAndToken: () => null,
});

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User>();
  const [token, setToken] = useState(
    localStorage.getItem(ILG_JWT_TOKEN) || undefined
  );
  const [cdsApiKey, setCdsApiKey] = useState<string>();

  const setUserAndToken = useCallback<SetUserAndToken>(
    (user, token, cdsApiKey) => {
      setCdsApiKey(cdsApiKey);
      setUser(user);
      setToken(token);

      if (token) {
        localStorage.setItem(ILG_JWT_TOKEN, token);
      } else {
        localStorage.removeItem(ILG_JWT_TOKEN);
      }
    },
    []
  );

  const userCredit = useMemo(() => {
    if (!user) {
      return emptyUserCredit;
    }

    const currentCredit = user.userBoughtCredit - user.userUsedCredit;
    const creditLeftPercentage = calcPercent(
      user.userBoughtCredit,
      currentCredit
    );

    return {
      usedCredit: user.userUsedCredit,
      boughtCredit: user.userBoughtCredit,
      currentCredit,
      creditLeftPercentage:
        typeof creditLeftPercentage === 'number' ? creditLeftPercentage : 0,
    };
  }, [user]);

  const userInitials = useMemo(() => {
    if (!user) return '';

    return user.companyName
      .split(' ')
      .map((part) => part.slice(0, 1).toUpperCase())
      .join('');
  }, [user]);

  useEffect(() => {
    if (token && !user) {
      API.getAuthorizedUser()
        .then(({ data }) => {
          if (!data.user) {
            throw new Error('User not found');
          }

          if (data.cdsApiKey) {
            setCdsApiKey(data.cdsApiKey);
          }

          setUser(data.user);
        })
        .catch(() => {
          setUserAndToken(undefined, undefined, undefined);
          message.error('You are not authorized to access this page');
        });
    }
  }, [setUserAndToken, token, user]);

  return (
    <UserContext.Provider
      value={{
        user,
        token,
        cdsApiKey,
        userCredit,
        userInitials,
        setUserAndToken,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}
