const ilgPrefix = `/ilg`;
const routes = {
  ilg: ilgPrefix,
  dashboard: `${ilgPrefix}/dashboard`,
  settings: `${ilgPrefix}/settings`,
  campaign: `${ilgPrefix}/campaign/:id/analytics`,
  createCampaign: `${ilgPrefix}/campaign/create`,
  editCampaign: `${ilgPrefix}/campaign/edit/:id`,
  targetMarkets: `${ilgPrefix}/target-markets`,
  leads: `${ilgPrefix}/campaign/:id/leads`,
  users: `${ilgPrefix}/users`,
  records: `${ilgPrefix}/records`,
  profile: `${ilgPrefix}/profile`,
  blacklist: `${ilgPrefix}/blacklist`,
  login: `/login`,
};

export default routes;
