/** Fields for the CSV Header of Specific type */
export const outreachFields = ['Requested', 'Connected', 'Replied'];
export const existingFields = ['Contacted', 'Replied'];
export const emailFields = ['Contacted', 'Opened', 'Replied'];

/** Used in Monitoring Page && LeadsTable to create the CSV Header for each type of table */
export const commonFields = [
  'Name',
  'Type',
  'Status',
  'Email',
  'LinkedIn Email',
  'LinkedIn ID',
  'User ID',
  'Company Name',
  'Number Of Leads',
  'Creation Date',
];

export const emailMonitorPagefields = [
  'Name',
  'Type',
  'Status',
  'User ID',
  'User Email',
  'Active Email Address',
  'Company Name',
  'Number of Leads',
  'Contacted',
  'Opened',
  'Replied',
  'Created',
];

const blacklistCommon = ['Source', 'Added at'];

export const blacklistDomainFields = ['Domain', ...blacklistCommon];
export const blacklistEmailFields = ['Email', ...blacklistCommon];
export const blacklistLinkedInFields = ['LinkedIn', ...blacklistCommon];
