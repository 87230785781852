import './Charts.styles.css';

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useEffect } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { isOutreachCampaignType, type as camType } from 'services/campaignType';
import {
  getDays,
  getMonthDays,
  getQuarters,
  getWeeks,
  getYears,
} from 'utils/helpers/time';

import { formatStatsByViews } from '../../helpers/calculator';
import {
  columnDataToDisplayEmailType,
  columnDataToDisplayLinkedinType,
  lineDataToDisplayOutreachType,
} from '../../helpers/chartsHelper';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const Chart = ({
  viewBy,
  startDate,
  endDate,
  records,
  asLine = false,
  campaignType,
}) => {
  const [title, setTitle] = useState('Showing week view');
  const [labels, setLabels] = useState([]);
  const [data, setData] = useState({
    labels,
    datasets: [],
  });
  //  1. Precalculate the labels ( X Axis of the chart ) on a
  //  specific period depending on the viewBy criteria
  //
  useEffect(() => {
    let labels = [];
    switch (viewBy) {
      case 'Day': // Day
        labels = getDays({ startDate, endDate });
        setTitle('DAILY VIEW');
        break;
      case 'Week': // Week
        labels = getWeeks({ startDate, endDate });
        setTitle('WEEKLY VIEW');
        break;
      case 'Month': // Month
        labels = getMonthDays({ startDate, endDate });
        setTitle('MONTHLY VIEW');
        break;
      case 'Quarter': // Quarter
        labels = getQuarters({ startDate, endDate });
        setTitle('QUARTERLY VIEW');
        break;
      case 'Year': // Year
        labels = getYears({ startDate, endDate });
        setTitle('YEARLY VIEW');
        break;
      default:
        break;
    }

    if (labels.length) {
      setLabels(labels);
    }
  }, [viewBy, startDate, endDate]);

  //  2. After the data fetching, calculate & format
  //  each possible viewBy scenario then supply the chart
  //  with the correspending data ( calculate all views formats
  //  "even though we it will only display one at the time" so that switching
  //  between views becomes smoother, aka the chart only loads on the first render )

  useEffect(() => {
    if (!labels || labels.length === 0) return; // No labels, exit
    if (records.length === 0) return; // No data, exit

    // when data fetched, we calculate the record by views
    const formattedRecords = formatStatsByViews(records)[viewBy];
    setData({
      labels,
      datasets: asLine
        ? lineDataToDisplayOutreachType(formattedRecords, campaignType)
        : getCorrectedType(formattedRecords),
    });
  }, [asLine, labels, records, viewBy]);

  const getCorrectedType = (formattedRecords) => {
    switch (campaignType) {
      case camType.OUT_CSV:
      case camType.OUT_SAL:
      case camType.EX_CSV:
      case camType.EX_LIN:
      case camType.EX_SAL:
        return columnDataToDisplayLinkedinType(formattedRecords, campaignType);
      case camType.EM_CSV:
      case camType.EM_TM:
        return columnDataToDisplayEmailType(formattedRecords, campaignType);
    }
  };

  const barOptions = {
    responsive: true,
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          maxTicksLimit: 30,
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: title,
      },
    },
  };
  const lineOptions = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: 100, // y axis can surpass 100% (e.g. when replied > connected )
        // max: 100, // y axis cannot surpass 100%
        ticks: {
          stepSize: 10,
          callback: function (value) {
            return value + ' %';
          },
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: 'CONVERSION VIEW',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (context.parsed.y !== null) {
              label += ' ' + context.parsed.y + '%';
            }
            return label;
          },
        },
      },
    },
  };

  return (
    <div className="relative z-0 mx-auto w-full flex-1 overflow-hidden">
      {asLine ? (
        isOutreachCampaignType(campaignType) && (
          <Line options={lineOptions} data={data} />
        )
      ) : (
        <Bar options={barOptions} data={data} />
      )}
    </div>
  );
};

Chart.propTypes = {
  viewBy: PropTypes.string,
  startDate: PropTypes.objectOf(dayjs),
  endDate: PropTypes.objectOf(dayjs),
  records: PropTypes.array,
  asLine: PropTypes.bool,
  campaignType: PropTypes.string,
};
