import routes from 'routes';

import { ProfilePage } from './ProfilePage';

const Profile = () => <ProfilePage />;

export default {
  routeProps: {
    path: routes.profile,
    component: Profile,
  },
  name: 'profile',
  routeVisibility: 'private',
};
