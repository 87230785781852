import PropType from 'prop-types';

import StatisticItem from '../StatisticItem/StatisticItem';

const EmailTypeStats = ({ cardsData }) => {
  return (
    <>
      <StatisticItem
        title="Contacted"
        value={cardsData?.requested.value}
        percentage={cardsData?.requested.percentage}
        color="#D7A600"
      />
      <StatisticItem
        title="Opened"
        value={cardsData?.connected.value}
        percentage={cardsData?.connected.percentage}
        color="#375361"
      />
      <StatisticItem
        title="Replied"
        value={cardsData?.responded.value}
        percentage={
          (cardsData?.responded.value / cardsData?.requested.value) * 100
        }
        color="#3f8600"
      />
    </>
  );
};

export default EmailTypeStats;

EmailTypeStats.propTypes = {
  cardsData: PropType.object,
  campaignId: PropType.string,
};
