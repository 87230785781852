import dayjs from 'dayjs';
import Papa from 'papaparse';

/** Headers expected to be present in a CSV file for LinkedIn Type Campaign*/
export const CSV_HEADERS = {
  firstName: 'firstName',
  lastName: 'lastName',
  gender: 'gender',
  linkedInProfileUrl: 'linkedInProfileUrl',
};

export const LINKEDIN_CSV_HEADERS = {
  firstName: 'firstName',
  lastName: 'lastName',
  gender: 'gender',
  linkedInProfileUrl: 'linkedInProfileUrl',
};

/** Headers expected to be present in a CSV file for LinkedIn Type Campaign*/
export const EMAIL_CSV_HEADERS = {
  firstName: 'firstName',
  lastName: 'lastName',
  gender: 'gender',
  email: 'email',
};

/** Headers expected to be present in a CSV file for LinkedIn Type Campaign*/
export const EMAIL_CSV_OPTIONAL = {
  companyName: 'companyName',
  companyUrl: 'companyUrl',
  title: 'title',
  city: 'city',
  country: 'country',
  location: 'location',
};

/**
 * Parses a CSV file to a JSON array.
 *
 * For more configuration about client side parsing lib.
 * @see https://www.papaparse.com/docs#config
 * @param {File} rawFile
 * @return {Promise<{headers: string[], data: Object[] }>}
 */
export const parseCsvtoJson = (rawFile) => {
  return new Promise((resolve, reject) => {
    Papa.parse(rawFile, {
      header: true,
      complete: ({ data, meta: { fields } }) => {
        resolve({ headers: fields, data });
      },
      error: ({ message }) => {
        reject(message);
      },
    });
  });
};

/**
 * Parse the leads from a passed CSV file, validate it then return the parsed data.
 * If the CSV is not valid, throw an error.
 * @param {File} file The uploaded CSV file
 * @returns {Promise<Array.<{
 *  firstName: string,
 *  lastName: string,
 *  gender: 'male' | 'female',
 *  linkedInProfileUrl: string
 * }>>} An array of the parsed leads
 */
export async function parseAndValidateLeadsFromCsv(file) {
  if (!file.name.endsWith('.csv'))
    throw new Error('Please upload a valid CSV file');

  // 1. Parse the CSV file and check if parsed JSON array is valid
  let { headers: parsedHeaders, data: parsedData } = await parseCsvtoJson(
    file
  ).catch(() => {
    throw new Error('Data parsed from CSV is not valid!');
  });
  if (parsedData.length === 0) {
    throw new Error('Could not find any data from the CSV file.');
  }

  // 2. Check if the parsed headers match the expected ones and print the difference otherwise
  const neededHeaders = Object.keys(CSV_HEADERS);
  const extraHeaders = parsedHeaders.filter(
    (item) => !neededHeaders.includes(item)
  );
  const missingHeaders = neededHeaders.filter(
    (item) => !parsedHeaders.includes(item)
  );
  if (extraHeaders.length !== 0 || missingHeaders.length !== 0) {
    const extraMsg =
      extraHeaders.length !== 0
        ? `\nParsed headers have ${
            extraHeaders.length
          } extra headers. ${JSON.stringify(extraHeaders)}`
        : '';
    const missingMsg =
      missingHeaders.length !== 0
        ? `\nParsed headers have ${
            missingHeaders.length
          } missing headers. ${JSON.stringify(missingHeaders)}`
        : '';
    throw new Error(
      `Headers parsed from CSV does not match the expected headers.${extraMsg}${missingMsg}`
    );
  }

  // 3. Check for undefined values and throw back which line and column caused it
  let undefinedAt = { name: '', idx: 0 };
  if (
    parsedData.some((item, idx) => {
      for (const [key, value] of Object.entries(item)) {
        if (!value || value === '') {
          undefinedAt.name = key;
          undefinedAt.idx = idx;
          return true;
        }
      }
    })
  )
    throw new Error(
      `No value is defined for the column ${undefinedAt.name} at line ${
        undefinedAt.idx + 1
      }`
    );

  // 4. Reject if a gender value does not either have 'male' or 'female'
  // This is validated here since field is not an enum in db
  let lastGenderValue = '';
  if (
    parsedData.some(({ gender }) => {
      lastGenderValue = gender;
      return !['male', 'female'].includes(gender);
    })
  )
    throw new Error(
      `Gender value "${lastGenderValue}" is not valid. Supported values should either be "male" or "female" for the system to work properly.`
    );

  // 5. Do a validation on the linkedInProfile values
  let lastLinkedInProfileValue = '';
  if (
    parsedData.some(({ linkedInProfileUrl }) => {
      lastLinkedInProfileValue = linkedInProfileUrl;
      return !linkedInProfileUrl.startsWith('https://www.linkedin.com/in/');
    })
  )
    throw new Error(
      `Profile link "${lastLinkedInProfileValue}" is not valid.\nA valid link should be as follows "https://www.linkedin.com/in/..."`
    );

  // 6. Validate whether we have any duplication in a linkedInProfileUrl value
  let duplicatedAt = { value: '', idx: 0, name: '' };
  const seen = new Set();
  if (
    parsedData.some(({ linkedInProfileUrl, firstName, lastName }, idx) => {
      if (seen.size === seen.add(linkedInProfileUrl).size) {
        duplicatedAt.name = `${firstName} ${lastName}`;
        duplicatedAt.value = linkedInProfileUrl;
        duplicatedAt.idx = idx;
        return true;
      }
    })
  )
    throw new Error(
      `The entry at line ${duplicatedAt.idx + 1} (${duplicatedAt.name} - ${
        duplicatedAt.value
      }) has duplicate LinkedIn Profile URL. Please edit the CSV file or upload a different one.`
    );

  return parsedData;
}

/**
 * To parse CSV files in case of Email Type Campaign.
 * There could be optional fields [EMAIL_CSV_OPTIONAL]
 */
export async function parseAndValidateLeadsFromCsvForEmail(file) {
  if (!file.name.endsWith('.csv'))
    throw new Error('Please upload a valid CSV file');

  // 1. Parse the CSV file and check if parsed JSON array is valid
  let { headers: parsedHeaders, data: parsedData } = await parseCsvtoJson(
    file
  ).catch(() => {
    throw new Error('Data parsed from CSV is not valid!');
  });
  // console.log(parsedData);
  if (parsedData.length === 0) {
    throw new Error('Could not find any data from the CSV file.');
  }

  // 2. Check if the parsed headers match the expected ones and print the difference otherwise
  const neededHeaders = Object.keys(EMAIL_CSV_HEADERS);
  const extraHeaders = parsedHeaders.filter(
    (item) => !neededHeaders.includes(item)
  );
  const missingHeaders = neededHeaders.filter(
    (item) => !parsedHeaders.includes(item)
  );

  //Check if the extra headers are right
  if (extraHeaders.length !== 0) {
    // console.log('Extra Headers: ', extraHeaders);
    extraHeaders.forEach((header) => {
      if (!Object.hasOwn(EMAIL_CSV_OPTIONAL, header)) {
        const extraMsg = `\nHeader ${header} is not a valid column`;
        throw new Error(
          `Headers parsed from CSV does not match the expected headers.${extraMsg}`
        );
      }
    });
  }

  if (missingHeaders.length !== 0) {
    const missingMsg =
      missingHeaders.length !== 0
        ? `\nParsed headers have ${
            missingHeaders.length
          } missing headers. ${JSON.stringify(missingHeaders)}`
        : '';
    throw new Error(
      `Headers parsed from CSV does not match the expected headers.${missingMsg}`
    );
  }

  // 3. Check for undefined values and throw back which line and column caused it
  parsedData.map((record) => {
    // console.log('Record -> ', record);
    for (let [key, value] of Object.entries(record)) {
      if (!value || value === '') {
        record[key] = '-';
      }
    }
    return record;
  });

  // 4. Reject if a gender value does not either have 'male' or 'female'
  // This is validated here since field is not an enum in db
  let lastGenderValue = '';
  if (
    parsedData.some(({ gender }) => {
      lastGenderValue = gender;
      return !['male', 'female'].includes(gender);
    })
  )
    throw new Error(
      `Gender value "${lastGenderValue}" is not valid. Supported values should either be "male" or "female" for the system to work properly.`
    );

  // 5. Validate whether we have any duplication in a linkedInProfileUrl value
  let duplicatedAt = { value: '', idx: 0, name: '' };
  const seen = new Set();
  if (
    parsedData.some(({ email, firstName, lastName }, idx) => {
      if (seen.size === seen.add(email).size) {
        duplicatedAt.name = `${firstName} ${lastName}`;
        duplicatedAt.value = email;
        duplicatedAt.idx = idx;
        return true;
      }
    })
  )
    throw new Error(
      `The entry at line ${duplicatedAt.idx + 1} (${duplicatedAt.name} - ${
        duplicatedAt.value
      }) has a duplicate email address. Please edit the CSV file or upload a different one.`
    );

  // console.log(parsedData);
  return parsedData;
}

export function generateBlacklistCSV(entries, fieldNames, filename) {
  const dataToExport = entries.map((entry) => [
    entry.keyword,
    entry.addedByUser ? 'Manually added' : 'Lead unsubscribed',
    dayjs(entry.createdAt).format('DD/MM/YYYY'),
  ]);
  downloadCSV(dataToExport, fieldNames, filename);
}

/* Csv export utils */
export const downloadCSV = (data, fields, filename) => {
  const csv = Papa.unparse(
    { data, fields },
    {
      dynamicTyping: true,
    }
  );
  const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const csvUrl = URL.createObjectURL(csvData, { type: 'text/plain' });
  link.href = csvUrl;
  link.download = filename;
  link.click();

  URL.revokeObjectURL(csvUrl);
};

/**
 * To parse CSV files in case of Blacklist CSV.
 */
export async function parseAndValidateBlacklistEntries(file, header) {
  if (!file.name.endsWith('.csv'))
    throw new Error('Please upload a valid CSV file');

  // 1. Parse the CSV file and check if parsed JSON array is valid
  let { headers: parsedHeaders, data: parsedData } = await parseCsvtoJson(
    file
  ).catch(() => {
    throw new Error('Data parsed from CSV is not valid!');
  });
  if (parsedData.length === 0) {
    throw new Error('Could not find any data from the CSV file.');
  }

  // 2. Check if the parsed headers match the expected ones and print the difference otherwise
  const extraHeaders = parsedHeaders.filter((item) => ![header].includes(item));
  const missingHeaders = [header].filter(
    (item) => !parsedHeaders.includes(item)
  );

  //Check if the extra headers are right
  if (extraHeaders.length !== 0) {
    throw new Error(
      `The file contains unnecessary headers. Please correct the headers or try with a different file`
    );
  }

  if (missingHeaders.length !== 0) {
    const missingMsg =
      missingHeaders.length !== 0
        ? `\nParsed headers have ${
            missingHeaders.length
          } missing headers. ${JSON.stringify(missingHeaders)}`
        : '';
    throw new Error(`The header ${missingMsg} is missing`);
  }

  const filteredData = parsedData
    .map((record) => {
      // Replace missing or empty fields with '-'
      for (let [key, value] of Object.entries(record)) {
        if (!value || value === '') {
          record[key] = '-';
        }
      }
      return record;
    })
    .filter((record) => {
      // Filter out records with '-' in any field (i.e., missing or empty fields)
      return Object.values(record).every((value) => value !== '-');
    });

  if (header === 'LinkedIn Profile Link') {
    // 4. Validate whether we have any duplication in a linkedInProfileUrl value
    let duplicatedAt = { value: '', idx: 0, name: '' };
    const seen = new Set();
    if (
      filteredData.some(({ email, firstName, lastName }, idx) => {
        if (seen.size === seen.add(email).size) {
          duplicatedAt.name = `${firstName} ${lastName}`;
          duplicatedAt.value = email;
          duplicatedAt.idx = idx;
          return true;
        }
      })
    )
      throw new Error(
        `The entry at line ${duplicatedAt.idx + 1} (${duplicatedAt.name} - ${
          duplicatedAt.value
        }) is a duplicate entry. Please edit the CSV file or upload a different one.`
      );
  }
  return filteredData;
}
