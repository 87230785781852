import classNames from 'classnames';

export interface IconWithTextProps {
  icon?: any;
  children?: React.ReactNode;
  className?: string;
  /** @default 'items-baseline' */
  alignment?: string;
  /** @default 'div' */
  elementType?: keyof JSX.IntrinsicElements;
}

export function IconWithText({
  icon: Icon,
  children,
  className,
  alignment = 'items-baseline',
  elementType: ElementType = 'div',
}: IconWithTextProps) {
  return (
    <ElementType className={classNames('flex gap-2', alignment, className)}>
      {Icon && <Icon />}
      <div>{children}</div>
    </ElementType>
  );
}
