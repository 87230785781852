import { gql } from '@apollo/client';

export const GET_LINKEDIN_FILTERS = gql`
  query GetLinkedInFilters {
    linkedInFilters(filterType: "ACCOUNT") {
      id
      name
      title
      type
      subFilters {
        id
        title
      }
      rangeConfig {
        minValuePlaceholder
        maxValuePlaceholder
        acceptedValues {
          id
          displayValue
        }
        inputType
        minValue
      }
      options {
        id
        title
      }
      dynamicDownloadOptions
      exclusionSupported
      aggregationTitle
      aggregationName
    }
  }
`;

export const GET_LINKEDIN_FILTER_BY_ID = gql`
  query GetLinkedInFilterById($id: UUID!) {
    linkedInFilterById(id: $id) {
      id
      name
    }
  }
`;

export const GET_ACCOUNTS_TASKS_BY_ID = gql`
  query GetAccountsTasksById($ids: [UUID!]!) {
    getAccountsTasksById(ids: $ids) {
      id
      status
      totalAccounts
      scrapedAccounts
    }
  }
`;

export const ADD_GET_ACCOUNTS_TASK = gql`
  mutation AddJob($selectedFilters: [SelectedLinkedInFilterInput!]!) {
    addGetAccountsTask(selectedFilters: $selectedFilters) {
      id
      status
      searchId
      totalAccounts
    }
  }
`;

export const STOP_GET_ACCOUNTS_TASK = gql`
  mutation stopAccountsTask($id: UUID!) {
    stopGetLinkedInAccountsTask(id: $id) {
      id
      status
      searchId
      totalAccounts
    }
  }
`;
export const DELETE_GET_ACCOUNTS_TASK = gql`
  mutation deleteAccountsTask($id: UUID!) {
    deleteGetLinkedInAccountsTask(id: $id) {
      id
      status
      searchId
      totalAccounts
    }
  }
`;

export const STOP_DECISION_MAKERS_TASK = gql`
  mutation stopDecisionMakersTask($searchId: String!) {
    stopGetLinkedInDecisionMakersTask(searchId: $searchId) {
      id
      status
    }
  }
`;
export const DELETE_DECISION_MAKERS_TASK = gql`
  mutation deleteDecisionMakersTask($searchId: String!) {
    deleteGetLinkedInDecisionMakersTask(searchId: $searchId) {
      id
      status
    }
  }
`;

export const GET_C_ROLES = gql`
  query GetLinkedInFilterByTypeGroupAndName {
    linkedInFilterByTypeGroupAndName(
      filterType: "LEAD"
      filterGroup: "Role"
      filterName: "SENIORITY_LEVEL"
    ) {
      id
      name
      title
      linkedInFilterGroupId
      type
      options {
        id
        title
      }
      dynamicDownloadOptions
      exclusionSupported
    }
  }
`;

export const ADD_GET_DECISION_MAKERS_TASK = gql`
  mutation addGetDecisionMakersTask(
    $searchId: String!
    $seniorityLevelIds: [String!]!
  ) {
    addGetDecisionMakersTask(
      searchId: $searchId
      seniorityLevelIds: $seniorityLevelIds
    ) {
      id
      status
      seniorityLevelIds
      searchId
    }
  }
`;
