import {
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import Icon from '@ant-design/icons/lib/components/Icon';
import {
  Button,
  Input,
  message,
  notification,
  Table,
  TablePaginationConfig,
  Upload,
} from 'antd';
import api from 'api';
import { Card } from 'components/Card';
import dayjs from 'dayjs';
import { useDebounce } from 'hooks/useDebounce';
import { useUser } from 'hooks/useUser';
import { CSVIcon } from 'modules/Campaign/components/Icons';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  generateBlacklistCSV,
  parseAndValidateBlacklistEntries,
} from 'utils/helpers/csv';

import {
  BLACKLIST_DESCRIPTIONS,
  BLACKLIST_TITLES,
  BLACKLIST_TYPES,
  BlacklistType,
} from '../constants';
import { formatKeyword, getColumns, getCorrectFieldnames } from '../helpers';
import { BlacklistItem } from '../type';
import { AddBlacklistModal } from './AddBlacklistModal';
import UploadCSVFileModal from './UploadCSVFileModal';

interface BlacklistTableProps {
  type: BlacklistType;
}

export function BlacklistTable({ type }: BlacklistTableProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [entriesToDelete, setEntriesToDelete] = useState<BlacklistItem[]>([]);
  const [tableData, setTableData] = useState<BlacklistItem[] | null>(null);
  const [isAdded, setIsAdded] = useState<boolean>(false);
  const [csvData, setCsvParsedData] = useState<{
    parsed: string[];
    existing: string[];
  }>({
    parsed: [],
    existing: [],
  });

  const [csvModalOpen, setCsvModalOpen] = useState(false);
  const [isErrorCsv, setIsErrorCsv] = useState({
    isError: false,
    message: '',
  });

  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 400);
  const { user } = useUser();

  // Fetching the blacklist entries loading state
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(1);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  useEffect(() => {
    async function getBlacklistData() {
      try {
        setIsFetching(true);
        const res = await api.getPaginatedBlacklists(
          type,
          pageIndex,
          pageSize,
          debouncedSearchText
        );
        const { paginatedBlacklist, countLeadsBlacklisted } = res.data;

        setTableData(paginatedBlacklist);
        setTotal(countLeadsBlacklisted);
      } catch (error) {
        message.error('Error occurred while fetching the blacklist entries.');
        console.log(error);
      } finally {
        setIsFetching(false);
      }
    }
    getBlacklistData();
  }, [pageIndex, pageSize, type, debouncedSearchText, isAdded]);

  /**
   * Gets the entries that have been parsed and checked for existing ones on the database and calls the api to write them in the db,
   * alerting the user with a notification in both case of success and error
   */
  const sendCsvEntriesToDatabase = async () => {
    try {
      const dataToSend = csvData.parsed.map((keyword) => {
        return {
          keyword: formatKeyword(keyword, type),
          type: type,
          userId: user?.id,
        };
      });

      await api.createBlacklistEntries(dataToSend);

      setIsAdded(true);
      setCsvModalOpen(false);
      notification.success({
        message: 'Added with success',
        description: 'The blacklist has been updated successfully',
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Error',
        description: 'Error occured while created blacklist entries',
      });
    } finally {
      setIsModalOpen(false);
    }
  };

  /**
   *
   * @returns Props of Upload component (for CSV file)
   */
  const props = {
    accept: '.csv',
    maxCount: 1,
    showUploadList: false,
    beforeUpload: async (file: File) => {
      setIsFetching(true);
      const expectedHeader: string[] | BlacklistType =
        getCorrectFieldnames(type);

      //Gets a list of parsed entries from CSV file
      try {
        // Parse and validate the CSV file headers
        const parsedData = await parseAndValidateBlacklistEntries(
          file,
          expectedHeader[0]
        );
        const parsedEntries: string[] = parsedData.flatMap((entry) =>
          Object.values(entry)
        );

        //Checks if any of those leads exists already
        let cvsExistingEntries: string[] = [];
        const { data } = await api.getBlacklistedFromKeywords(
          parsedEntries,
          type
        );

        if (data.numOfEntries > 0) {
          cvsExistingEntries = data.entries.map(
            (entry: BlacklistItem) => entry.keyword
          );
        }

        setCsvParsedData({
          parsed: parsedEntries.filter(
            (item) => !cvsExistingEntries.includes(item)
          ),
          existing: cvsExistingEntries,
        });
        return false;
      } catch (error: Error | any) {
        console.log('Error', error);
        setIsErrorCsv({ isError: true, message: error.message });
        return false;
      } finally {
        setIsFetching(false);
        setCsvModalOpen(true);
      }
    },
  };

  /**
   * Filters the array from the selected domains that need to be removed
   * @returns void
   */
  const deleteDomainsFromList = async () => {
    setIsDeleting(true);
    try {
      await api.deleteBlacklistEntries(
        entriesToDelete.map((entry) => entry.id)
      );

      notification.success({
        message: 'Domain removed successfully',
        description: `The selected domains have been removed from your blacklist`,
      });

      setTableData((prevTableData) => {
        return prevTableData?.filter(
          (entry) =>
            !entriesToDelete.find((toDelete) => toDelete.id === entry.id)
        ) as BlacklistItem[];
      });

      setTotal((prevTotal) => prevTotal - entriesToDelete.length);

      setEntriesToDelete([]);
    } catch (error) {
      console.log(error);
      message.error('An error occured deleting a blacklist entry');
    } finally {
      setIsDeleting(false);
    }
  };

  /**
   * Updates pagination params, triggering effect
   * @param pagination
   */
  const handlePaginationChange = (pagination: TablePaginationConfig) => {
    setEntriesToDelete([]);
    const { current, pageSize } = pagination;
    setPageIndex(current || 1);
    setPageSize(pageSize || 10);
  };

  return (
    <div className="p-6">
      <Card
        shadow
        className="flex flex-wrap items-center justify-between gap-4 mb-6"
        borderRadius="rounded-lg"
      >
        <div className="text-gray">{BLACKLIST_DESCRIPTIONS[type]}</div>

        <div className="flex gap-2">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsModalOpen(true)}
          >
            {`Add ${BLACKLIST_TITLES[type]}`}
          </Button>
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Import from CSV</Button>
          </Upload>
        </div>
      </Card>

      {/* //? Table display */}
      {tableData || isFetching ? (
        <div>
          {/* //* ________________________________ Table Data */}
          <Table
            rowKey={'id'}
            size="small"
            columns={getColumns(type)}
            dataSource={tableData ?? undefined}
            pagination={{
              hideOnSinglePage: true,
              total: total,
              current: pageIndex,
              pageSize,
              showTotal: (total, range) =>
                `${range[0]} - ${range[1]} of ${total} leads`,
              onChange: (page, pageSize) => {
                handlePaginationChange({ current: page, pageSize });
              },
            }}
            rowSelection={{
              selectedRowKeys: entriesToDelete.map((entry) => entry.id),
              onChange: (_: any, selectedRows: any) => {
                setEntriesToDelete(selectedRows);
              },
              getCheckboxProps: (record) => ({
                disabled: !record.addedByUser,
                name: record.keyword,
              }),
            }}
            loading={isFetching}
            footer={() =>
              tableData?.length != 0 && (
                <div className="flex items-center">
                  <Icon
                    component={CSVIcon}
                    className="ml-2 text-xl text-secondary"
                  />
                  {searchText ? (
                    <Button
                      type="link"
                      onClick={async () => {
                        const { data: blacklist } = await api.exportBlacklist(
                          type,
                          searchText
                        );
                        generateBlacklistCSV(
                          blacklist.blacklistToExport,
                          getCorrectFieldnames(type),
                          `blacklist_${type.toLowerCase()}_export_SEARCH='${searchText}'_${dayjs().format(
                            'DD/MM/YYYY'
                          )}`
                        );
                      }}
                      className="text-secondary hover:text-secondary-dark active:text-secondary-light"
                    >
                      Export Current Search ({total})
                    </Button>
                  ) : (
                    <Button
                      type="link"
                      onClick={async () => {
                        const { data: blacklist } = await api.exportBlacklist(
                          type
                        );
                        generateBlacklistCSV(
                          blacklist.blacklistToExport,
                          getCorrectFieldnames(type),
                          `blacklist_${type.toLowerCase()}_export_${dayjs().format(
                            'DD/MM/YYYY'
                          )}`
                        );
                      }}
                      className="text-secondary hover:text-secondary-dark active:text-secondary-light"
                    >
                      Export All ({total})
                    </Button>
                  )}
                </div>
              )
            }
            title={() => (
              //* ________________________________ Action Buttons
              <div className="flex items-center justify-between">
                <div className="flex items-center w-1/3">
                  <Button
                    type="primary"
                    icon={<DeleteOutlined />}
                    onClick={deleteDomainsFromList}
                    disabled={entriesToDelete.length === 0}
                    loading={isDeleting}
                  >
                    {entriesToDelete.length > 0 &&
                      `Remove ${entriesToDelete.length} ${
                        entriesToDelete.length == 1 ? 'domain' : 'domains'
                      } `}
                  </Button>
                </div>
                <div className="w-3/5">
                  <Input
                    placeholder="Search domain"
                    addonBefore={<SearchOutlined />}
                    allowClear
                    onChange={(e) => setSearchText(e.target.value)}
                    value={searchText}
                  />
                </div>
              </div>
            )}
          />
        </div>
      ) : (
        <div className="text-xs text-center text-gray-400">
          No blacklist entries found
        </div>
      )}

      <AddBlacklistModal
        blacklistType={type}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onAddUpdate={() => setIsAdded(true)}
      />

      <UploadCSVFileModal
        type={type}
        csvData={csvData}
        isErrorCsv={isErrorCsv}
        csvModalOpen={csvModalOpen}
        setIsErrorCsv={setIsErrorCsv}
        setCsvModalOpen={setCsvModalOpen}
        sendCsvEntriesToDatabase={sendCsvEntriesToDatabase}
      />
    </div>
  );
}

BlacklistTable.propTypes = {
  type: PropTypes.oneOf(BLACKLIST_TYPES).isRequired,
};
