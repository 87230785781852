import PropTypes from 'prop-types';

export const FormLabel = ({ style = '', children }) => {
  return <div className={`mb-2 text-gray-500 ${style}`}>{children}</div>;
};

export default FormLabel;

FormLabel.propTypes = {
  style: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};
