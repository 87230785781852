import AnalyticsDashboard from './Campaign/AnalyticsDashboard';
import RecordsDashboardScreen from './Campaign/CampaignMonitoring';
import CreateCampaign from './Campaign/CreateCampaign';
import Dashboard from './Campaign/Dashboard';
import EditCampaign from './Campaign/EditCampaign';
import LeadsTable from './Campaign/ManageLeads';
import TargetMarkets from './Campaign/TargetMarkets';
import Login from './Login';
import Blacklist from './Users/Blacklist';
import UsersTable from './Users/ManageUsers';
import ProfilePage from './Users/ProfilePage';

export const modules = {
  Login,
  Dashboard,
  UsersTable,
  RecordsDashboardScreen,
  LeadsTable,
  AnalyticsDashboard,
  CreateCampaign,
  EditCampaign,
  TargetMarkets,
  Blacklist,  ProfilePage,
};
