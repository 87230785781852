import { Button, Form } from 'antd';
import api from 'api';
import FormSection from 'modules/Campaign/components/Form/FormSection';
import PropTypes from 'prop-types';
import { useState } from 'react';

const EmailVerifier = ({ isEditCampaign = false }) => {
  const form = Form.useFormInstance();

  const [isVerifying, setIsVerifying] = useState(false);
  const [isVerified, setIsVerified] = useState({
    creds: null,
    spf: null,
  });

  /**
   * Verify the email setup by checking the credentials and the SPF record
   */
  const handleVerify = async () => {
    const values = form.getFieldsValue();
    // first validate the email setup form
    try {
      await form.validateFields([
        'emailAddress',
        'emailPassword',
        'smtpAddress',
        'smtpPort',
        'imapAddress',
        'imapPort',
      ]);
      setIsVerifying(true);
      setIsVerified({
        creds: null,
        spf: null,
      });
      // First verify the credentials
      const credsResult = await api.verifyCredentials(
        values.emailAddress,
        values.emailPassword,
        values.smtpAddress + ':' + values.smtpPort,
        values.imapAddress + ':' + values.imapPort,
        isEditCampaign
      );
      setIsVerified({ creds: credsResult.data.success, spf: null });
      // Then verify the SPF record of the domain (DNS TXT Lookup)
      const dnsResult = await api.verifyDNS(values.emailAddress.split('@')[1]);
      setIsVerified({
        creds: credsResult.data.success,
        spf: dnsResult.data.spf,
      });
      setIsVerifying(false);
    } catch (error) {
      console.log(error);
      setIsVerifying(false);
    }
  };

  return (
    <FormSection title={'Email Setup Verifier'}>
      <div className="flex flex-col">
        <div>
          Verify if the credentials entered above are valid to connect from our
          end to your provider and check the domain SPF records.
        </div>
        <div className="flex justify-center w-full my-4">
          <Button color="primary" onClick={handleVerify} loading={isVerifying}>
            Verify
          </Button>
        </div>
      </div>
      {(isVerifying || isVerified.creds != null || isVerified.spf != null) && (
        <div className="p-4 rounded-lg" style={detectStyles(isVerified)}>
          <VerifyStep
            title="IMAP & SMTP"
            loading={isVerified.creds === null}
            result={isVerified.creds}
            failureMessage="Failed to authenticate, please check your credentials or the firewall settings of your email provider!"
            successMessage={'Authenticated succesfully!'}
          />
          <VerifyStep
            title="SPF"
            loading={isVerified.spf === null}
            result={isVerified.spf}
            failureMessage="Failed to find the DNS Record, SPF may not be setup on your domain!"
            successMessage={'DNS Record found succesfully!'}
          />
        </div>
      )}
    </FormSection>
  );
};

EmailVerifier.propTypes = {
  isEditCampaign: PropTypes.bool,
};

const VerifyStep = ({
  title,
  loading,
  result,
  failureMessage,
  successMessage,
}) => {
  return (
    <div>
      <span className="font-bold">{title}: </span>
      {loading && 'Verifying...'}
      {!loading && result && successMessage}
      {!loading && !result && failureMessage}
    </div>
  );
};

VerifyStep.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  result: PropTypes.bool,
  failureMessage: PropTypes.string,
  successMessage: PropTypes.string,
};

const styles = {
  init: {
    border: '1px solid #ffe58f',
    color: '#d48806',
    background: '#fffbe6',
    borderColor: '#ffe58f',
  },
  success: {
    border: '1px solid #b7eb8f',
    color: '#389e0d',
    background: '#f6ffed',
    borderColor: '#b7eb8f',
  },
  error: {
    border: '1px solid #ffa39e',
    color: '#cf1322',
    background: '#fff1f0',
    borderColor: '#ffa39e',
  },
};

const detectStyles = (isVerified) => {
  const loading = isVerified.creds === null || isVerified.spf === null;
  if (loading) {
    return styles.init;
  }
  if (!isVerified.creds) {
    return styles.error;
  }
  if (isVerified.creds && !isVerified.spf) {
    return styles.init;
  }
  if (isVerified.creds && isVerified.spf) {
    return styles.success;
  }
  return styles.init;
};

export default EmailVerifier;
