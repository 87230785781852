import { Col, Divider, Row } from 'antd';
import PropTypes from 'prop-types';

export const SubHeader = ({ title, subtitle }) => {
  return (
    <Row justify="center" align={'middle'} className="mb-6">
      <Col className="flex justify-around px-6 py-2 -mt-[1px] items-center bg-white border border-t-0 border-gray-200 border-solid rounded-b-lg">
        <div className="text-2xl font-semibold text-primary">{title}</div>
        {subtitle && (
          <>
            <Divider type="vertical" className="text-3xl" />
            <div className="text-xl text-gray-500">{subtitle}</div>
          </>
        )}
      </Col>
    </Row>
  );
};

SubHeader.propTypes = {
  /** Title */
  title: PropTypes.string.isRequired,
  /** Subtitle */
  subtitle: PropTypes.string,
};
