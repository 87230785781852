import { Divider as AntDivider, DividerProps } from 'antd';

export function Divider({
  children,
  orientation = 'left',
  orientationMargin = 0,
  ...props
}: { children?: React.ReactNode } & DividerProps) {
  return (
    <AntDivider
      {...props}
      orientation={orientation}
      orientationMargin={orientationMargin}
      style={{
        color: '#375361',
        ...props.style,
      }}
    >
      {children}
    </AntDivider>
  );
}
