import { Collapse } from 'antd';
import { Divider } from 'components/Divider';
import PropTypes from 'prop-types';
import { getOrdinalNumber } from 'utils/helpers';
const { Panel } = Collapse;
import { RightOutlined } from '@ant-design/icons';

import { Text } from './Text';

const FollowUpReview = ({ msg, idx }) => {
  return (
    <div className="my-1">
      <Divider plain dashed orientation="center">
        {getOrdinalNumber(idx + 1)} Follow Up setup
      </Divider>
      <div className="px-3 py-2 bg-gray-100 rounded-md">
        {msg.femaleSalutation && (
          <Text title={"Women's salutations"} value={msg.femaleSalutation} />
        )}
        {msg.maleSalutation && (
          <Text title={`Men's salutation`} value={msg.maleSalutation} />
        )}
        <Text
          title={`Message content`}
          value={
            msg.content.length < 120 ? (
              msg.content
            ) : (
              <CollapsibleMessageContent msg={msg.content} idx={idx} />
            )
          }
        />
      </div>
    </div>
  );
};

export function CollapsibleMessageContent({ msg, idx }) {
  return (
    <Collapse
      ghost
      className="-my-3 -ml-4 bg-gray-100"
      expandIconPosition="start"
      bordered={false}
      expandIcon={({ isActive }) => (
        <RightOutlined rotate={isActive ? 90 : 0} />
      )}
    >
      <Panel
        key={idx}
        header={
          <div className="-py-8 text-ellipsis hover:text-secondary">{`${msg.substring(
            0,
            120
          )}...`}</div>
        }
      >
        <div className="whitespace-pre-wrap">{msg}</div>
      </Panel>
    </Collapse>
  );
}

export default FollowUpReview;

FollowUpReview.propTypes = {
  msg: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
};
CollapsibleMessageContent.propTypes = {
  msg: PropTypes.string.isRequired,
  idx: PropTypes.number.isRequired,
};
