import { Checkbox, message, Modal, Space } from 'antd';
import API from 'api';
import PropTypes from 'prop-types';
import { useState } from 'react';

/**
 * Delete campaign confirmation modal.
 */
export function DeleteCampaign({
  campaign,
  visible,
  setVisible,
  reloadCampaigns,
}) {
  const [checked, setChecked] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const handleOk = async () => {
    try {
      setConfirmLoading(true);
      await API.deleteCampaign(campaign.id);
      reloadCampaigns();
      setVisible({
        visible: false,
        campaign: {},
      });
      setConfirmLoading(false);
      setChecked(false);
      message.success(`"${campaign.name}" campaign deleted`);
    } catch (error) {
      setConfirmLoading(false);
      message.error(
        `Something went wrong couldn't delete "${campaign.name}" campaign.`
      );
    }
  };
  const handleCancel = () => {
    setChecked(false);
    setVisible({
      visible: false,
      campaign: {},
    });
  };
  return (
    <Modal
      centered
      title={`Confirm delete "${campaign.name}" campaign?`}
      open={visible}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      okText="Delete"
      okButtonProps={{ disabled: !checked }}
    >
      <Space direction="vertical" size={20}>
        <div>
          {`This action is irreversible, you might want to download a copy of
          campaign's data`}
          <a
            href={
              new URL(
                `leads/export/csv/${campaign.id}`,
                process.env.REACT_APP_BACKEND_API_URL
              )
            }
          >
            {' '}
            clicking here
          </a>
          .
        </div>
        <Checkbox checked={checked} onChange={() => setChecked(!checked)}>
          I have read the above.
        </Checkbox>
      </Space>
    </Modal>
  );
}

DeleteCampaign.propTypes = {
  /** Campaign's info which will be deleted  */
  campaign: PropTypes.object.isRequired,
  /** The visibility of the modal */
  visible: PropTypes.bool.isRequired,
  /** SetState Function to change the visibility of the modal */
  setVisible: PropTypes.func.isRequired,
  /** Function the re-fetch the campaigns from the api */
  reloadCampaigns: PropTypes.func.isRequired,
};
