import { PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, message, notification, Spin, Table } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import API from 'api';
import { Card } from 'components/Card';
import { Divider } from 'components/Divider';
import { Layout } from 'components/Layout';
import {
  GET_ACCOUNTS_TASKS_BY_ID,
  STOP_DECISION_MAKERS_TASK,
  STOP_GET_ACCOUNTS_TASK,
} from 'constant/usersGraphQl';
import { useUser } from 'hooks/useUser';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { FormButtons } from '../components/Form/FormButtons';
import { SubHeader } from '../components/SubHeader';
import { CreateTargetMarket } from '../CreateCampaign/Steps/EmailType/steps/CreateTargetMarket';
import { targetMarketColumns } from './targetMarketHelper';
export function TargetMarkets({ setCurrent, setIsSetup, isInForm = false }) {
  const campaignForm = useFormInstance();
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [targets, setTargets] = useState([]);
  const [targetToShow, setTargetToShow] = useState(undefined);
  const [showTargetMarketForm, setShowTargetMarketForm] = useState(false);

  const [getAccountsTasks, { loading: taskLoading }] = useLazyQuery(
    GET_ACCOUNTS_TASKS_BY_ID
  );

  const [stopGetAccountsTask] = useMutation(STOP_GET_ACCOUNTS_TASK);
  const [stopDecisionMakersTask] = useMutation(STOP_DECISION_MAKERS_TASK);

  useEffect(() => {
    setIsLoading(true);
    async function fetchTargetMarkets() {
      try {
        const { data: targetMarkets } = await API.getTargetMarketsByUserId(
          user.id
        );

        const targetMarketIds = {
          ids: targetMarkets.map(
            (targetMarket) => targetMarket.companyDataServiceGetAccountsTaskId
          ),
        };

        const accountsTasks = await getAccountsTasks({
          variables: targetMarketIds,
        });

        /**
         * Adds the data of the record in CompanyDataService - GetLinkedInAccountsTask to the TargetMarket
         * record to be able to know the scrapedAccounts and totalAccounts
         */
        const fullTargetMarketsData = targetMarkets.map((targetMarket) => {
          const task = accountsTasks.data?.getAccountsTasksById.find(
            (task) =>
              task.id === targetMarket.companyDataServiceGetAccountsTaskId
          );
          return {
            ...targetMarket,
            status: task?.status,
            scrapedAccounts: task?.scrapedAccounts,
            totalAccounts: task?.totalAccounts,
          };
        });
        setTargets(fullTargetMarketsData);
      } catch (e) {
        console.log(e);
        message.error('There was a problem in loading the Target Markets');
      } finally {
        setIsLoading(false);
      }
    }

    fetchTargetMarkets();
  }, [getAccountsTasks, user.id, showTargetMarketForm]);

  async function deleteTargetMarket(targetMarketId) {
    const searchId = targets.find(
      (market) => market.id === targetMarketId
    ).companyDataServiceGetAccountsTaskId;

    try {
      const { data: hasMarketBeenDeleted } = await API.deleteTargetMarket(
        targetMarketId
      );

      if (hasMarketBeenDeleted) {
        const { data: targetMarketsActiveOnSearchId } =
          await API.getTargetMarketsByAccountsTaskId(searchId);

        /**
         * If there is any target market using the same getAccountsTaskId then we won't change the status of the task, otherwise we will stop it
         */
        if (targetMarketsActiveOnSearchId.length === 0) {
          const { data: stoppedAccountsTask } = await stopGetAccountsTask({
            variables: { id: searchId },
          });
          await stopDecisionMakersTask({
            variables: {
              searchId:
                stoppedAccountsTask.stopGetLinkedInAccountsTask.searchId,
            },
          });
        }

        const targetMarketName = targets.find(
          (target) => target.id === targetMarketId
        ).name;
        setTargets((current) =>
          current.filter((target) => target.id !== targetMarketId)
        );
        notification.success({
          message: 'Target Market Deleted',
          description: `The target market ${targetMarketName} has been successfully removed from your list. Companies belonging to that Market will not be contacted anymore.`,
        });
      }
    } catch (e) {
      notification.error(
        'There was an error during the deletion of the selected target market'
      );
    }
  }

  /**
   * When selecting a Target Market, we set the values of targetMarketId and tagetMarketName into the form Object,
   * in order to display the correct information in the form and to be able to send the ID to the campaign creation.
   * Then we go the the next step of the form
   */
  function selectTargetMarket(targetSelected) {
    campaignForm.setFieldValue('targetMarketId', targetSelected.id);
    campaignForm.setFieldValue('targetMarketName', targetSelected.name);
    setCurrent(1);
  }

  function showTargetMarket(id) {
    const fullTargetMarketRecord = targets.find((target) => target.id === id);
    setTargetToShow(fullTargetMarketRecord);
    setShowTargetMarketForm(true);
  }

  if (showTargetMarketForm) {
    return isInForm ? (
      <CreateTargetMarket
        setShowTargetMarketForm={setShowTargetMarketForm}
        isInForm={true}
      />
    ) : (
      <Layout>
        <SubHeader
          title={targetToShow ? 'View Target Market' : `Create Target Market`}
        />
        <Form name="form" layout="inline">
          <Card
            shadow
            padding="p-6"
            className="mx-auto w-[90%] sm:w-[80%] lg:w-[60%] xl:w-[50%]"
          >
            <CreateTargetMarket
              setShowTargetMarketForm={setShowTargetMarketForm}
              isInForm={isInForm}
              targetToShow={targetToShow}
              setTargetToShow={setTargetToShow}
            />
          </Card>
        </Form>
      </Layout>
    );
  }

  if (!isInForm) {
    return (
      <Layout>
        <SubHeader title={'Target Markets'} />
        <TMHeader classNames={'bg-white p-6 my-4 rounded-md shadow'} />
        <Table
          rowKey={'id'}
          loading={isLoading || taskLoading}
          dataSource={targets}
          columns={targetMarketColumns(deleteTargetMarket, showTargetMarket)}
          footer={() => (
            <div className="text-right">
              <Button
                type={'primary'}
                icon={<PlusOutlined />}
                onClick={() => setShowTargetMarketForm(true)}
              >
                Create a new market
              </Button>
            </div>
          )}
        />
      </Layout>
    );
  }

  return (
    <div className="mb-6">
      <TMHeader />
      {taskLoading || isLoading ? (
        <div className="flex items-center justify-center gap-2 py-8">
          <Spin spinning={true} />
          Loading...
        </div>
      ) : targets.length ? (
        <>
          <div className="flex justify-between p-2 text-white rounded bg-primary text-md">
            <div className="w-7/12">Name</div>
            <div className="w-3/12 text-center">Companies</div>
            <div className="w-2/12 text-right"></div>
          </div>

          <Spin
            spinning={isLoading || taskLoading}
            tip={
              <div className="flex items-center justify-center text-gray-500">
                Loading Target Markets...
              </div>
            }
            size="large"
          >
            {targets.map((targetMarket) => (
              <div
                key={targetMarket.id}
                className="flex items-center justify-between px-2 py-1 rounded hover:bg-gray-100"
              >
                <div className="w-7/12">{targetMarket.name}</div>
                <div className="w-3/12 text-center">
                  {targetMarket.totalAccounts ?? 'Problem fetching data'}
                </div>
                <Button
                  type="link"
                  className="w-2/12 pr-2 m-0 text-right p text-secondary"
                  onClick={() => selectTargetMarket(targetMarket)}
                >
                  Select
                </Button>
              </div>
            ))}
          </Spin>
        </>
      ) : (
        <div className="py-8 text-xs text-center text-gray-500">
          No target markets defined
        </div>
      )}
      {isInForm && (
        <FormButtons>
          <Button onClick={() => setIsSetup(false)}>Previous</Button>
          <Button
            type={'primary'}
            icon={<PlusOutlined />}
            onClick={() => {
              setShowTargetMarketForm(true);
            }}
          >
            Create a new market
          </Button>
        </FormButtons>
      )}
    </div>
  );
}

function TMHeader({ classNames }) {
  return (
    <div className="flex flex-col">
      <div
        className={`flex flex-col items-center text-primary leading-6 mt-4 ${classNames}`}
      >
        A target market is the specific group of people most likely to buy your
        products or services. They’re the people you should be laser focused on
        attracting—the type of people who return again, recommend you to their
        friends, and rave about you on social media.
      </div>
      <Divider />
    </div>
  );
}

TargetMarkets.propTypes = {
  setIsSetup: PropTypes.func,
  setCurrent: PropTypes.func,
  isInForm: PropTypes.bool,
};

TMHeader.propTypes = {
  classNames: PropTypes.string,
};
