import {
  CheckCircleFilled,
  ClockCircleOutlined,
  CompassFilled,
  LinkedinFilled,
  MinusCircleOutlined,
} from '@ant-design/icons';
import { Avatar, Col, Popover, Row } from 'antd';
import UserPlaceHolderImg from 'assets/img/webp/user-placeholder.webp';
import PropTypes from 'prop-types';

/**
 * The component that renders the lead row name. Furthermore, we provide
 * the whole lead data as well as the `showLeadModal` to open the modal from
 * this component.
 * @param {*} param0
 * @returns
 */
export const NameRender = ({ name, title, imageUrl, showLeadModal }) => {
  // const handleBrokenImage = (e) => (e.target.src = UserPlaceHolderImg);
  return (
    <Row gutter={10} wrap={false}>
      <Col>
        <Popover
          content={
            <span className="text-xs">
              See more details about <strong>{name}</strong>
            </span>
          }
        >
          <Avatar
            onClick={showLeadModal}
            className="cursor-pointer"
            src={imageUrl?.startsWith('http') ? imageUrl : UserPlaceHolderImg}
            // NOTE: Looks like this is available only in newer versions
            // imgProps={{ onError: handleBrokenImage }}
            size="large"
          />
        </Popover>
      </Col>
      <Col flex="auto" className="overflow-hidden overflow-ellipsis">
        <span className="font-semibold">{name}</span>
        <br />
        <span className="text-xs text-gray-500">{title}</span>
      </Col>
    </Row>
  );
};

NameRender.propTypes = {
  /** The name to display */
  name: PropTypes.string.isRequired,
  /** The title or position of the lead */
  title: PropTypes.string.isRequired,
  /** URL of the lead image */
  imageUrl: PropTypes.string.isRequired,
  /** Populate and open the lead modal */
  showLeadModal: PropTypes.func.isRequired,
};

/**
 * The column renderer for the LinkedIn links.
 * @param {*} param0
 * @returns
 */
export const LinkedInLinksRender = ({
  linkedInProfileUrl,
  salesNavProfileUrl,
}) => {
  return (
    <Row gutter={5} justify="center">
      <Col>
        <a
          className="text-blue-500 hover:text-blue-200"
          href={linkedInProfileUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedinFilled style={{ fontSize: '1.2rem' }} />
        </a>
      </Col>
      <Col>
        <a
          className="text-blue-500 hover:text-blue-200"
          href={salesNavProfileUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <CompassFilled style={{ fontSize: '1.2rem' }} />
        </a>
      </Col>
    </Row>
  );
};

LinkedInLinksRender.propTypes = {
  linkedInProfileUrl: PropTypes.string.isRequired,
  salesNavProfileUrl: PropTypes.string.isRequired,
};

/**
 * The status of the current lead displayed in the `Status` columns.
 * @param {*} param0
 * @returns
 */
export const StatusRenderer = ({ leadData, onClick }) => {
  let status;
  const { inviteSentAt, inviteWithdrawnAt, inviteAccepted, replied } = leadData;

  if (replied)
    status = {
      name: 'Replied',
      color: 'green',
      icon: CheckCircleFilled,
      details: 'This lead successfully replied to your campaign',
    };
  else if (inviteAccepted)
    status = {
      name: 'Accepted',
      color: 'orange',
      icon: ClockCircleOutlined,
      details:
        'This lead accepted your connection invite, follow ups will be sent until they reply',
    };
  else if (inviteWithdrawnAt)
    status = {
      name: 'Withdrawn',
      color: 'red',
      icon: MinusCircleOutlined,
      details:
        'The lead ignored the invitation for a long time therefore it was withdrawn and the process was stopped',
    };
  else if (inviteSentAt)
    status = {
      name: 'Invited',
      color: 'orange',
      icon: ClockCircleOutlined,
      details:
        "An invite was sent to this lead and we're waiting for their reply",
    };
  else
    status = {
      name: 'Scraped',
      color: 'orange',
      icon: ClockCircleOutlined,
      details: 'This lead was added to the database',
    };

  return (
    <Row justify="center" key={leadData.id}>
      <Popover
        content={
          <>
            <strong>{status.name}</strong>{' '}
            <span className="text-xs">{status.details}</span>
          </>
        }
      >
        <status.icon
          style={{
            color: status.color,
            cursor: 'pointer',
            fontSize: '1rem',
          }}
          onClick={onClick}
        />
      </Popover>
    </Row>
  );
};

StatusRenderer.propTypes = {
  leadData: PropTypes.object.isRequired,
  /**
   * Status icon click event.
   */
  onClick: PropTypes.func.isRequired,
};
