import { Button, Col, Input, Modal, notification, Row, Typography } from 'antd';
import API from 'api';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const { Text } = Typography;

/** Add Credit to user modal */
export function AddCreditModal({
  selectedUser = {},
  isModalVisible,
  setIsModalVisible,
  fetchUsers,
}) {
  const [credit, setCredit] = useState(0);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [disableAddButton, setDisableAddButton] = useState(true);

  useEffect(() => {
    credit <= 0 ? setDisableAddButton(true) : setDisableAddButton(false);
  }, [credit]);

  const handleAdd = async () => {
    setConfirmVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    API.incrementCredit(selectedUser.id, { credit: credit })
      .then(() => {
        setConfirmVisible(false);
        setConfirmLoading(false);
        fetchUsers();
        notification.success({
          message: `Credits added successfully.`,
          description: `${credit} credits were added to ${selectedUser.companyName}`,
        });
        setIsModalVisible(false);
      })
      .catch((error) => {
        notification.error({
          message: `There was a problem`,
          description: `Credits could not be added ${error}`,
        });
      });
  };

  const handleCancel = () => {
    setConfirmVisible(false);
  };

  return (
    <>
      <Modal
        title="Add credit"
        centered
        open={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setCredit(0);
        }}
        footer={null}
      >
        <Row className="p-1 my-3" justify="space-between">
          <Col>
            <Text style={{ color: '#375361' }} strong>
              Company name:
            </Text>
          </Col>
          <Col className="mr-2">
            <Text>{selectedUser.companyName}</Text>
          </Col>
        </Row>
        <Row className="p-1 my-3" justify="space-between">
          <Col flex="none">
            <Text style={{ color: '#375361' }} strong>
              E-mail:
            </Text>
          </Col>
          <Col className="mr-2">
            <Text>{selectedUser.email}</Text>
          </Col>
        </Row>
        <Row className="p-1 my-3" justify="space-between">
          <Col flex="none">
            <Text style={{ color: '#375361' }} strong>
              Current credit:
            </Text>
          </Col>
          <Col className="mr-2">
            <Text>
              {selectedUser.userBoughtCredit - selectedUser.userUsedCredit}
            </Text>
          </Col>
        </Row>
        <Row className="p-1 my-3" justify="space-between" align="middle">
          <Col flex="none">
            <Text style={{ color: '#375361' }} strong>
              Amount of credit to add:
            </Text>
          </Col>
          <Col className="w-1/3">
            <Input
              type={'number'}
              value={credit}
              onChange={(e) => {
                setCredit(e.target.value);
              }}
            />
          </Col>
        </Row>
        <div className="flex justify-between mt-6">
          <Button
            type="default"
            onClick={() => {
              setIsModalVisible(false);
              setCredit(0);
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => handleAdd()}
            disabled={disableAddButton}
          >
            Add
          </Button>
        </div>
      </Modal>
      <Modal
        title="Confirm action"
        centered
        open={confirmVisible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <Text>
          Confirm Adding <strong>{credit}</strong> Credits to{' '}
          <strong>{selectedUser.companyName}</strong>.
        </Text>
      </Modal>
    </>
  );
}

AddCreditModal.propTypes = {
  /** Selected user to be adding the credit to */
  selectedUser: PropTypes.object,
  /** Modal visibility */
  isModalVisible: PropTypes.bool,
  /** set State for modal visibility */
  setIsModalVisible: PropTypes.func,
  /** refetch the users from the data base */
  fetchUsers: PropTypes.func,
};
