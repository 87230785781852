import { Tag, Tooltip } from 'antd';
import { EXCLUSION } from 'constant/targetMarket';
import PropTypes from 'prop-types';

export default function OptionWithExclusion({ item, removeOption }) {
  function alterEventBehaviour(e) {
    e.preventDefault();
    e.stopPropagation();
  }
  return (
    <li
      className="px-2 py-0.5 text-gray-500 border-none rounded cursor-pointer hover:text-secondary hover:bg-white active:text-secondary-dark"
      onClick={() => {
        removeOption(item, EXCLUSION.INCLUDED);
      }}
    >
      <Tooltip title={item.title} mouseEnterDelay={0.4}>
        <div className="flex items-center justify-between">
          <div className="text-xs truncate">{item.title}</div>
          <div className="flex">
            <Tag
              color="green"
              className="my-0.5 mx-1 text-green-600 uppercase rounded-none text-[8px] hover:bg-green-700 hover:text-white"
              onClick={(e) => {
                alterEventBehaviour(e);
                removeOption(item, EXCLUSION.INCLUDED);
              }}
            >
              Include
            </Tag>
            <Tag
              color="red"
              className="my-0.5 text-red-600 uppercase rounded-none text-[8px] hover:bg-red-700 hover:text-white"
              onClick={(e) => {
                alterEventBehaviour(e);
                removeOption(item, EXCLUSION.EXCLUDED);
              }}
            >
              Exclude
            </Tag>
          </div>
        </div>
      </Tooltip>
    </li>
  );
}

OptionWithExclusion.propTypes = {
  item: PropTypes.object,
  removeOption: PropTypes.func,
};
