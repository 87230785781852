import './CampaignAnalyticsDashboard.styles.css';

import { message } from 'antd';
import API from 'api';
import { Card } from 'components/Card';
import { Divider } from 'components/Divider';
import { Layout } from 'components/Layout';
import dayjs from 'dayjs';
import { useQuery } from 'hooks/useQuery';
import { useUser } from 'hooks/useUser';
import { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useParams } from 'react-router-dom';
import {
  isEmailCampaignType,
  isExistingCampaignType,
  isOutreachCampaignType,
} from 'services/campaignType';
import { getLeadsStatsSum } from 'utils/helpers/calculator';

import { SubHeader } from '../components/SubHeader';
import { Chart } from './components/Charts/Charts';
import FilterToggler from './components/Filter/FilterToggler';
import EmailType from './components/SingleStatItem/EmailTypeStats';
import ExistingLinkedinTypeStats from './components/SingleStatItem/ExistingLinkedinTypeStats';
import OutreachTypeStats from './components/SingleStatItem/OutreachTypeStats';
import { formatToCSV } from './helpers/calculator';

// DEVONLY ( for testing large amount of record ):
//import { generateDummyData } from './dummy';

export function CampaignAnalyticsDashboard() {
  const query = useQuery();

  const [viewBy, setViewBy] = useState(0);
  const [startDate, setStartDate] = useState(
    dayjs(query.get('startDate')).subtract(15, 'days')
  );
  const [endDate, setEndDate] = useState(dayjs());
  const [viewByValues, setViewByValues] = useState({
    0: 'Day',
  });

  const cardsDataLoaded = useRef(false);

  const [cardsData, setCardsData] = useState(undefined);
  const [records, setRecords] = useState([]);
  const [type, setType] = useState('');
  const [name, setName] = useState('');

  const { id: campaignId } = useParams();

  const { user } = useUser();

  /* To update the data in the charts with the filter toggle */
  useEffect(() => {
    async function fetchData() {
      try {
        const {
          data: { type, name },
        } = await API.getCampaignTypeAndName(campaignId);
        setType(type);
        setName(name);
        const { data: stats } = await API.getLeadsStats(
          user?.id,
          campaignId,
          startDate.toJSON(),
          endDate.toJSON()
        );
        if (!cardsDataLoaded.current) {
          cardsDataLoaded.current = true;
          setCardsData(getLeadsStatsSum(stats));
        }
        setRecords(stats);
      } catch (error) {
        message.error('Stats could not be loaded.');
      }
    }
    fetchData();
  }, [startDate, endDate, campaignId, user?.id]);

  const csvHeaders = [
    { label: 'Day', key: 'date' },
    { label: 'Requested', key: 'requested' },
    { label: 'Connected', key: 'connected' },
    { label: 'Replied', key: 'replied' },
    { label: 'Connection Rate', key: 'connectedRate' },
    { label: 'Response Rate', key: 'repliedRate' },
    { label: 'Conversion Rate', key: 'convertedRate' },
  ];

  //TODO Big refactor depending on the campaign type. Values differ so multiple components need to be defined
  return (
    <Layout>
      <SubHeader title="Analytics" subtitle={name} />

      <div className="flex items-center justify-center mb-6 md:justify-start">
        <div className="ml-4 text-gray-500 text-md md:text-xl">
          Overall Statistics
        </div>
      </div>
      <div className="flex flex-col justify-center gap-4 mb-8 sm:flex-row sm:mb-12">
        {isEmailCampaignType(type) && (
          <EmailType cardsData={cardsData} campaignId={campaignId} />
        )}

        {isExistingCampaignType(type) && (
          <ExistingLinkedinTypeStats
            cardsData={cardsData}
            campaignId={campaignId}
          />
        )}
        {isOutreachCampaignType(type) && (
          <OutreachTypeStats cardsData={cardsData} campaignId={campaignId} />
        )}
      </div>

      {/* Subheader & Filterer */}
      <div className="flex flex-col justify-center mb-2 md:flex-row sm:justify-between">
        <div className="flex items-center justify-center">
          <div className="mb-4 ml-4 text-gray-500 text-md md:text-xl md:mb-0">
            Charts
          </div>
        </div>

        <Card shadow className="flex items-center justify-between gap-3">
          <FilterToggler
            viewByValues={viewByValues}
            setViewByValues={setViewByValues}
            viewBy={viewBy}
            setViewBy={setViewBy}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
          <Divider type="vertical hidden md:block text-4xl items-center" />
          <div className="flex justify-end align-middle">
            <CSVLink
              data={formatToCSV(records)}
              headers={csvHeaders}
              filename={`ILG_${query.get('name')}.csv`}
              className="mr-2 text-sm text-secondary hover:text-secondary-dark active:text-secondary-light"
            >
              Export CSV
            </CSVLink>
          </div>
        </Card>
      </div>

      {type && (
        <Card border className="flex flex-col gap-6 xl:flex-row">
          <Chart
            viewBy={viewByValues[viewBy]}
            startDate={startDate}
            endDate={endDate}
            records={records}
            campaignType={type}
          />

          <Chart
            viewBy={viewByValues[viewBy]}
            startDate={startDate}
            endDate={endDate}
            records={records}
            asLine={true}
            campaignType={type}
          />
        </Card>
      )}
    </Layout>
  );
}
