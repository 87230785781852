import { Form } from 'antd';
import { FILTER_TYPE } from 'constant/targetMarket';
import PropTypes from 'prop-types';

import { ValueSelectedPill } from './ValueSelectedPill';

export function ShowSelectedValues({ values, filter, disableActions }) {
  const form = Form.useFormInstance();

  const removeValueFromSelected = (value) => {
    if (disableActions) return;
    form.setFieldValue(
      ['filters', filter.name],
      values.filter((v) => v !== value)
    );
  };

  if (filter.type === FILTER_TYPE.TEXT) return <div className="my-2"></div>;

  if (filter.type === FILTER_TYPE.RANGE || filter.type === FILTER_TYPE.DROPDOWN)
    return (
      <div className="mb-1.5">
        {values
          ? values?.map((value) => (
              <ValueSelectedPill
                key={`${value.title}`}
                filter={filter}
                value={value}
                onClick={() => removeValueFromSelected(value)}
              >
                {value.title
                  ? `${value.title} - ${value.rangeValues.minValue}M to ${value.rangeValues.maxValue}M`
                  : `${value.rangeValues.minValue}% - ${value.rangeValues.maxValue}%`}
              </ValueSelectedPill>
            ))
          : null}
      </div>
    );

  if (filter.type === FILTER_TYPE.MULTI)
    return (
      <div>
        {values
          ? values?.map((value, i) => (
              <ValueSelectedPill
                key={`${value.id}${i}`}
                value={value}
                filter={filter}
                onClick={() => removeValueFromSelected(value)}
              >
                {value.title}
              </ValueSelectedPill>
            ))
          : null}
      </div>
    );

  //   if (filter.type === FILTER_TYPE.SINGLE) return <div className="my-2"></div>;
}

ShowSelectedValues.propTypes = {
  values: PropTypes.array,
  filter: PropTypes.object,
  disableActions: PropTypes.bool,
};
