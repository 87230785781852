import classNames from 'classnames';

export interface CardProps {
  children: React.ReactNode;
  className?: string;
  background?: string;
  shadow?: boolean;
  shadowOnHover?: boolean;
  border?: boolean;
  borderRadius?: string | false;
  padding?: string | false;
}

export function Card({
  children,
  className = '',
  background = 'bg-white',
  shadow = false,
  shadowOnHover = false,
  border = false,
  borderRadius = 'rounded',
  padding = 'p-4',
}: CardProps) {
  return (
    <div
      className={classNames(className, background, borderRadius, padding, {
        'shadow-md shadow-slate-200': shadow,
        'hover:shadow-lg transition-shadow duration-200': shadowOnHover,
        'border border-solid border-slate-200': border,
      })}
    >
      {children}
    </div>
  );
}
