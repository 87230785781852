import { Form, message } from 'antd';
import api from 'api';
import { EmailSignaturePreview } from 'modules/Campaign/components/Form/EmailSignaturePreview';
import FollowUpReview from 'modules/Campaign/components/Form/FollowUpReview';
import FormLabel from 'modules/Campaign/components/Form/FormLabel';
import FormSection from 'modules/Campaign/components/Form/FormSection';
import { Text } from 'modules/Campaign/components/Form/Text';
import { BlacklistedLeadsAlert } from 'modules/Campaign/EditCampaign/EditEmailCampaign/components/BlacklistedLeadsAlert';
import { useEffect, useState } from 'react';
import { getTypeDescription, isEmailCSV } from 'services/campaignType';

export const EmailTypeReview = () => {
  const form = Form.useFormInstance();
  const formValues = form.getFieldsValue(true);

  const [numOfBlacklisted, setNumOfBlacklisted] = useState(0);
  const [isCheckingBlacklisted, setIsCheckingBlacklisted] = useState(false);

  const checkBlacklistedLeads = async () => {
    try {
      setIsCheckingBlacklisted(true);
      const emails = formValues.parsedLeads.map((lead) => lead.email);
      const { data: leadsRawBlacklist } = await api.getBlacklists('LEAD_EMAIL');
      const { data: domainsRawBlacklist } = await api.getBlacklists(
        'EMAIL_DOMAIN'
      );
      // build app and array of strings out of the blacklist keywords
      // instead of the full objects for faster/easier lookup
      const domainsBlacklist = domainsRawBlacklist.map((item) => item.keyword);
      const leadsBlacklist = leadsRawBlacklist.map((item) => item.keyword);

      let numOfBlacklistedLeads = 0;
      emails.map((email) => {
        // check if the lead's email is part of the leads's blacklist,
        // or the lead's email domain is part of the domain's blacklist
        if (
          leadsBlacklist.includes(email) ||
          domainsBlacklist.includes(email.split('@')[1])
        )
          numOfBlacklistedLeads++;
      });
      setIsCheckingBlacklisted(false);

      setNumOfBlacklisted(numOfBlacklistedLeads);
    } catch (error) {
      console.log(error);
      message.error('Error occured while checking for blacklists');
    }
  };

  useEffect(() => {
    if (isEmailCSV(formValues?.type)) {
      checkBlacklistedLeads();
    }

    // eslint-disable-next-line
  }, [formValues]);

  return (
    <div className="flex flex-col gap-2 pt-4 fade-in">
      <FormLabel>
        Please review the informations then click submit to create your
        campaign.
      </FormLabel>

      <FormSection title={'Campaign basic information'}>
        <Text title={'Campaign name'} value={formValues.name} />
        <Text
          title={'Campaign type'}
          value={getTypeDescription(formValues.type) || ''}
        />
        {isEmailCSV(formValues.type) && (
          <>
            <Text title={'CSV File'} value={formValues.file.file.name} />
            <Text
              title={'Leads to create'}
              value={
                <div className="flex items-center ">
                  <div>{formValues.parsedLeads.length ?? 0}</div>
                  <div className="flex flex-col justify-start items-start">
                    {isCheckingBlacklisted && (
                      <span className="ml-4 text-secondary">Validating...</span>
                    )}
                    {!!numOfBlacklisted && (
                      <BlacklistedLeadsAlert number={numOfBlacklisted} />
                    )}
                  </div>
                </div>
              }
            />
          </>
        )}
      </FormSection>

      <FormSection title={'Account information'}>
        <Text title={'Email address'} value={formValues.emailAddress} />
        <Text
          title={'Email subject'}
          value={formValues.emailFollowUpsSubject}
        />
        <Text
          title={'SMTP Host'}
          value={`${formValues.smtpAddress}:${formValues.smtpPort}`}
        />
        <Text
          title={'IMAP Host'}
          value={`${formValues.imapAddress}:${formValues.imapPort}`}
        />
        <Text
          title={'Daily messages range'}
          value={`${formValues?.invites[0]} to ${formValues?.invites[1]}`}
        />
        {formValues?.initialMessage.femaleSalutation && (
          <Text
            title={`Women's salutation`}
            value={formValues.initialMessage.femaleSalutation}
          />
        )}
        {formValues?.initialMessage.maleSalutation && (
          <Text
            title={`Men's salutation`}
            value={formValues.initialMessage.maleSalutation}
          />
        )}
        <Text
          title={'Email content'}
          value={formValues?.initialMessage.content}
        />
      </FormSection>

      {formValues.emailSignature !== undefined && (
        <FormSection title={'Signature'}>
          <Text
            title={'Email Signature'}
            value={
              <EmailSignaturePreview signature={formValues.emailSignature} />
            }
          />
        </FormSection>
      )}

      {formValues.emailFollowups && formValues.emailFollowups.length > 0 && (
        <FormSection
          title={`Email follow ups (${formValues.emailFollowups.length})`}
        >
          {formValues.emailFollowups.map((followUp, idx) => (
            <FollowUpReview key={idx} msg={followUp} idx={idx} />
          ))}
        </FormSection>
      )}
    </div>
  );
};
