import { InfoCircleFilled } from '@ant-design/icons';
import { Form, Input, Tooltip } from 'antd';
import { CREATE_CAMPAIGN } from 'constant/campaignForm';
import EmailVerifier from 'modules/Campaign/components/EmailVerifier';
import FormLabel from 'modules/Campaign/components/Form/FormLabel';
import FormSection from 'modules/Campaign/components/Form/FormSection';
import PropTypes from 'prop-types';

export const AccountDetails = ({ isEmailEditable }) => {
  return (
    <FormSection
      title={CREATE_CAMPAIGN.emailAccount.title}
      subTitle={CREATE_CAMPAIGN.emailAccount.subTitle}
      className="grid fade-in"
    >
      <div className="grid grid-cols-6 gap-x-4">
        {/* Email Account */}
        <div className="col-span-6 sm:col-span-3">
          <FormLabel>
            Email Address{' '}
            {!isEmailEditable && (
              <Tooltip
                title={`The email account cannot be change because the campaign has already sent an invite from this account`}
              >
                <InfoCircleFilled />
              </Tooltip>
            )}
          </FormLabel>
          <Form.Item
            name="emailAddress"
            rules={[
              {
                required: true,
                message: 'Please provide the email address',
              },
              {
                type: 'email',
                message: 'Please enter a valid email address',
              },
            ]}
          >
            <Input placeholder="Email Address" disabled={!isEmailEditable} />
          </Form.Item>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <FormLabel>Email Password</FormLabel>
          <Form.Item
            name="emailPassword"
            rules={[
              {
                required: true,
                message: 'Please enter the subject of the email',
              },
            ]}
          >
            <Input placeholder="Email Password" type="password" />
          </Form.Item>
        </div>

        <div className="col-span-4">
          <FormLabel>SMTP Address</FormLabel>
          <Form.Item
            name="smtpAddress"
            rules={[
              {
                required: true,
                message: 'Please provide a valid SMTP address',
              },
            ]}
          >
            <Input placeholder="SMTP Address" />
          </Form.Item>
        </div>

        <div className="col-span-2">
          <FormLabel>SMTP Port</FormLabel>
          <Form.Item
            name="smtpPort"
            rules={[
              {
                required: true,
                message: 'Please provide a port number',
              },
            ]}
          >
            <Input placeholder="SMTP Port" />
          </Form.Item>
        </div>

        <div className="col-span-4">
          <FormLabel>IMAP Address</FormLabel>
          <Form.Item
            name="imapAddress"
            rules={[
              {
                required: true,
                message: 'Please provide a valid IMAP address',
              },
            ]}
          >
            <Input placeholder="IMAP Address" />
          </Form.Item>
        </div>

        <div className="col-span-2">
          <FormLabel>IMAP Port</FormLabel>
          <Form.Item
            name="imapPort"
            rules={[
              {
                required: true,
                message: 'Please provide a port number',
              },
            ]}
          >
            <Input placeholder="IMAP Port" />
          </Form.Item>
        </div>
      </div>
      <EmailVerifier isEditCampaign />
    </FormSection>
  );
};

AccountDetails.propTypes = {
  /** TRUE if no email has been sent yet */
  isEmailEditable: PropTypes.bool,
};
