import PropTypes from 'prop-types';
export const CardEntry = ({ label, data }) => {
  return (
    <div className="flex items-center justify-between m-1">
      <div>{label}</div>
      <div>{data}</div>
    </div>
  );
};

CardEntry.propTypes = {
  label: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
