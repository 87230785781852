import { Card } from 'components/Card';

export interface FormCardProps {
  children: React.ReactNode;
}

export function FormCard({ children }: FormCardProps) {
  return (
    <Card
      shadow
      borderRadius="rounded-lg max-w-4xl mx-auto"
      padding="p-4 sm:px-6 md:px-8 lg:px-10"
    >
      {children}
    </Card>
  );
}
