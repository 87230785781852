export const tagStyle = {
  padding: '3px 8px',
  textAlign: 'center',
  borderRadius: '3px',
  margin: '0 auto',
  width: '65px',
};

export const linkedinIconStyle = {
  fontSize: '15px',
  color: '#138efb',
};

export const mailIconStyle = {
  fontSize: '16px',
  color: 'orange',
};

export const ACCENT_COLOR = '#dcb223';
