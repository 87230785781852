import dayjs from 'dayjs';
import {
  MailBlacklisted,
  MailNotSent,
  MailOpened,
  MailRead,
  MailSent,
} from 'modules/Campaign/components/Icons';
import PropTypes from 'prop-types';

function parseEnumToReadableString(enumValue) {
  let readableString = enumValue.replace(/_/g, ' ').toLowerCase();
  readableString =
    readableString.charAt(0).toUpperCase() + readableString.slice(1);
  return readableString;
}

function EmailStatusText({ children }) {
  return <div className="text-xs text-gray-500">{children}</div>;
}

EmailStatusText.propTypes = {
  children: PropTypes.node,
};

export function EmailStatus({ record, showText }) {
  if (record.skip)
    return (
      <div className="flex flex-col items-center">
        {/* Email will not be contacted */}
        <MailBlacklisted className="text-2xl text-red-600" />
        {showText && (
          <EmailStatusText>
            {parseEnumToReadableString(record.skipReason)}
          </EmailStatusText>
        )}
      </div>
    );

  if (record.hasBouncedConversation)
    return (
      <div className="flex flex-col items-center">
        {/* Email Bounced */}
        <MailNotSent className="text-2xl text-red-600" />
        {showText && <EmailStatusText>Email bounced</EmailStatusText>}
      </div>
    );

  if (record.hasAnsweredConversation)
    return (
      <div className="flex flex-col items-center">
        {/* Email replied */}
        <MailRead className="text-2xl text-green-600" />
        {showText && <EmailStatusText>Replied</EmailStatusText>}
      </div>
    );

  if (record.hasSeenConversation)
    return (
      <div className="flex flex-col items-center">
        {/* Email Opened sent */}
        <MailOpened className="text-2xl text-blue-600" />
        {showText && <EmailStatusText>Opened</EmailStatusText>}
      </div>
    );

  if (record.hasConversation)
    return (
      <div className="flex flex-col items-center">
        {/* Email sent */}
        <MailSent className="text-2xl text-secondary" />
        <EmailStatusText>
          {dayjs(record.conversationStartedAt).format('DD/MM/YYYY')}
        </EmailStatusText>
      </div>
    );

  return (
    <div className="flex flex-col items-center">
      <>
        {/* Email not sent */}
        <MailNotSent className="text-2xl text-gray-400" />
        {showText && <EmailStatusText>Not yet sent</EmailStatusText>}
      </>
    </div>
  );
}

EmailStatus.propTypes = {
  /** record of a single lead coming from the table */
  record: PropTypes.object,
  /** To show text only when used in Modal */
  showText: PropTypes.bool,
};
