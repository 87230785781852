import { Divider } from 'antd';
import PropTypes from 'prop-types';

export function CampaignGenre({ category, isSelected, onClick }) {
  return (
    <div className="flex flex-col flex-1 cursor-pointer" onClick={onClick}>
      <div
        className={`flex-1 px-4 pb-10 rounded-lg bg-gray-100 ${
          isSelected ? 'bg-yellow-100' : ''
        }`}
      >
        <Divider>{category.title}</Divider>
        <div className="mb-6 text-gray-500">{category.subTitle}</div>
      </div>
      <img
        className="block object-bottom w-20 -mt-10 mx-auto h-auto"
        src={category.img}
        alt="leadsLogo"
      />
    </div>
  );
}

CampaignGenre.propTypes = {
  category: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
