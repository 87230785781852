import { InfoCircleFilled } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Select,
  Tooltip,
} from 'antd';
import API from 'api';
import PropTypes from 'prop-types';

/**
 * Add user to the application modal
 */
export function AddUserModal({
  isModalVisible,
  setIsModalVisible,
  fetchUsers,
}) {
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    API.createUser(values)
      .then(() => {
        setIsModalVisible(false);
        resetForm();
        fetchUsers();
        notification.success({
          message: `Account created successfully. `,
          description: `The account for the user ${values.companyName} was created with ${values.userBoughtCredit} credits. `,
        });
      })
      .catch((error) => {
        notification.error({
          message: `There was a problem`,
          description: `User could not be created ${error}`,
        });
      });
  };

  const resetForm = () => {
    form.resetFields();
  };

  const validatePassword = (_, value) => {
    if (
      value &&
      !/^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(
        value
      )
    )
      return Promise.reject('Please enter a valid password');
    else {
      return Promise.resolve();
    }
  };

  return (
    <Modal
      title="Create new user"
      centered
      open={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      footer={null}
      width={600}
      destroyOnClose
    >
      <Form
        name="new user"
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
      >
        <div className="flex justify-between gap-4">
          <Form.Item
            label="Company name"
            name="companyName"
            rules={[
              { required: true, message: 'Please give the company name' },
            ]}
            className="w-full"
          >
            <Input />
          </Form.Item>
          <Form.Item
            className="w-full"
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Please give the company email' },
            ]}
          >
            <Input id="email" type={'email'} />
          </Form.Item>
        </div>

        <div className="flex gap-4">
          <Form.Item
            validateTrigger="onChange"
            className="w-full"
            label={
              <>
                <div>Password</div>
                <Tooltip
                  title={`Password must be minimum 6 characters long and must include at least:\n - one number\n - one uppercase character\n - one lowercase character\n - one special character.`}
                >
                  <div>
                    <InfoCircleFilled className="ml-1 text-secondary" />
                  </div>
                </Tooltip>
              </>
            }
            name="password"
            rules={[
              {
                required: true,
                message: 'Please set a password for the user',
              },
              { validator: validatePassword },
            ]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Form.Item
            className="w-full"
            label="Role"
            name="role"
            initialValue="User"
            rules={[{ required: true, message: 'Please give the user role' }]}
          >
            <Select id="role">
              <Select.Option value="User">User</Select.Option>
              <Select.Option value="Admin">Admin</Select.Option>
              <Select.Option value="SuperAdmin">SuperAdmin</Select.Option>
            </Select>
          </Form.Item>
        </div>

        <div className="flex gap-4">
          <Form.Item
            className="w-full"
            label="Initial credits"
            name="userBoughtCredit"
            rules={[
              { required: true, message: 'Please set the initial credits' },
            ]}
          >
            <InputNumber min={1} className="w-full" />
          </Form.Item>
        </div>
        <div className="flex items-center justify-between mt-8">
          <Button type="default" onClick={resetForm}>
            Reset form
          </Button>

          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

AddUserModal.propTypes = {
  /** Modal visibility */
  isModalVisible: PropTypes.bool,
  /** Set state for modal visibility */
  setIsModalVisible: PropTypes.func,
  /** re-fetch users from the api */
  fetchUsers: PropTypes.func,
};
