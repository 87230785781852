import { Button, Form, InputNumber, message, Select } from 'antd';
import PropTypes from 'prop-types';
import { useState } from 'react';

export const Range = ({ filter, closeFilter }) => {
  const form = Form.useFormInstance();
  const { Option } = Select;

  const [max, setMax] = useState(null);
  const [min, setMin] = useState(null);
  const [optionSelected, setOptionSelected] = useState(
    filter?.subFilters.length
      ? {
          subFilterId: filter.subFilters[0].id,
          title: filter.subFilters[0].title,
        }
      : null
  );
  const [isError, setIsError] = useState(undefined);

  function validateAndAddValuesToForm() {
    if (!max || !min) {
      message.error('Please fill all the fields');
      setIsError('error');
      return;
    }
    if (max < min) {
      message.error('Min value has to be inferior to Max value');
      return;
    }
    const toInsert = {
      id: filter.id,
      ...optionSelected,
      rangeValues: { minValue: min.toString(), maxValue: max.toString() },
    };
    form.setFieldValue(['filters', filter.name, 0], toInsert);
  }

  const getSubFilter = (value) => {
    const subFilterSelected = filter.subFilters.find(
      (subFilter) => subFilter.title === value
    );
    setOptionSelected({
      subFilterId: subFilterSelected.id,
      title: subFilterSelected.title,
    });
  };

  return (
    <div className="flex items-center justify-between mb-2 fade-in">
      <div className="flex flex-col gap-2">
        {filter?.subFilters.length > 0 && (
          <Select
            dropdownMatchSelectWidth={false}
            notFoundContent={`Not found`}
            showSearch
            style={{
              width: '100%',
            }}
            onChange={(value) => {
              getSubFilter(value);
            }}
            className="w-full"
            defaultValue={filter?.subFilters[0].title}
          >
            {filter?.subFilters.map((option) => {
              return (
                <Option key={option.id} value={option.title}>
                  {option.title}
                </Option>
              );
            })}
          </Select>
        )}
        <div className="flex items-center flex-1 gap-4">
          <InputNumber
            placeholder={filter.rangeConfig.minValuePlaceholder}
            onChange={(value) => setMin(value.toString())}
            status={isError && min === null ? isError : null}
            value={min}
            min={1}
            style={{
              width: '100%',
            }}
          />

          <div>to</div>

          <InputNumber
            placeholder={filter.rangeConfig.maxValuePlaceholder}
            status={isError && max === null ? isError : null}
            onChange={(value) => setMax(value.toString())}
            value={max}
            min={+min + 1}
            style={{
              width: '100%',
            }}
          />
        </div>

        <div className="flex justify-end">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              validateAndAddValuesToForm();
              closeFilter();
            }}
          >
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};

Range.propTypes = {
  filter: PropTypes.object,
  closeFilter: PropTypes.func,
};
