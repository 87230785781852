import { axiosInstance } from './axiosMiddleware';

const postLogin = async ({ username, password }) => {
  return axiosInstance.post('auth/login', {
    username,
    password,
  });
};

const getIsAuth = async () => {
  return axiosInstance.get('auth/is-authorized');
};

const getCampaignById = async (id) => {
  return axiosInstance.get(`campaigns/${id}`).catch((error) => {
    console.log('Error fetching the campaign', error);
    throw new Error('There was a problem fetching the campaign.');
  });
};

const postNewCampaign = async (data) => {
  return axiosInstance.post('campaigns', data).catch((error) => {
    console.log(error.response.data);
    throw error.response.data;
  });
};

const createCampaignMessages = async (data) => {
  return axiosInstance.post('messages/many', data);
};

const editCampaign = async (id) => {
  return axiosInstance.get(`campaigns/edit/${id}`).catch(() => {
    throw new Error('There was a problem fetching the data.');
  });
};

const updateCampaignMessages = async (data) => {
  return axiosInstance.post(`messages/override`, data);
};

const getCampaignMessages = async (campaignId) => {
  return axiosInstance.get(`messages/campaign/${campaignId}`);
};

/**
 * Get the paginated number of leads
 * @param {number} campaignId
 * @param {{ page: number, limit: number }} param1
 * @param {string} keyword used to filter the result
 * @returns
 */
const getPaginatedCampaignLeads = async (
  campaignId,
  { page = 1, limit = 5 },
  { filters, sorter },
  keyword
) => {
  return axiosInstance.post(
    `/leads/campaign/${campaignId}`,
    {
      filters,
      sorter,
    },
    {
      params: {
        page,
        limit,
        keyword,
      },
    }
  );
};

const createUser = async (data) => {
  return axiosInstance.post('auth/signup', data);
};

const getAuthorizedUser = async () => {
  return axiosInstance.get(`auth/user`);
};

const getCampaigns = async (userId) => {
  return axiosInstance.get(`campaigns/user/${userId}`);
};

const updateCampaign = async (id, data) => {
  return axiosInstance.patch(`campaigns/${id}`, data);
};

const deleteCampaign = async (id) => {
  return axiosInstance.delete(`campaigns/${id}`);
};

const getCredit = async (id) => {
  return axiosInstance.get(`user/${id}/credit`);
};

const userRole = async () => {
  return axiosInstance.get(`user/role`);
};

const getAllUsers = async () => {
  return axiosInstance.get(`user/all`);
};

const updateUser = async (id, data) => {
  return axiosInstance.patch(`user/${id}`, data);
};

const incrementCredit = async (id, data) => {
  return axiosInstance.post(`user/${id}/credit/increment`, data);
};

const getRecords = async (from, to, status) => {
  return axiosInstance.post(`record`, { from, to, status });
};

/**
 * Gets a list of records if the user has the campaign or if a SuperAdmin.
 * @param {number} userId
 * @param {number} campaignId
 * @param {Date} from
 * @param {Date} to
 * @param {Array<String>} status
 * @returns
 */
const getRecordsByUserAndCampaign = async (
  userId,
  campaignId,
  from,
  to,
  status
) => {
  return axiosInstance.post(`/record/campaign/${campaignId}/user/${userId}`, {
    from,
    to,
    status,
  });
};

const getLeadsStats = async (userId, campaignId, startDate, endDate) => {
  return axiosInstance.get(
    `/leads/stats/user/${userId}/campaign/${campaignId}`,
    {
      params: {
        startDate,
        endDate,
      },
    }
  );
};

const updateLead = async (leadId, partialLead) => {
  return axiosInstance.put(`/leads/${encodeURIComponent(leadId)}`, partialLead);
};

const getFollowUpsEmailAuthUrl = async (emailId, campaignId, emailAddress) => {
  return axiosInstance.get(
    `/emails/auth?emailId=${emailId}&campaignId=${campaignId}&emailAddress=${emailAddress}`
  );
};

const getFupsStats = async (campaignId) => {
  return axiosInstance.get(`/leads/stats/campaign/${campaignId}`);
};

const findMaxAmountFups = async (campaignId) => {
  return axiosInstance.get(`/messages/fups/${campaignId}`);
};

const getMonitorCampaignsStats = async (userId) => {
  return axiosInstance.get(`/leads/monitor/stats/campaign`, {
    params: {
      userId,
    },
  });
};

export const getAllLeadsByCampaignId = async (
  campaignId,
  offset,
  limit,
  sort,
  query,
  orQuery
) => {
  return axiosInstance.get(`/leads/campaign/leads/${campaignId}`, {
    params: {
      offset,
      limit,
      sort,
      query,
      orQuery,
    },
  });
};

export const countLeadsByCampaignId = async (campaignId, query, orQuery) => {
  return axiosInstance.get(`/leads/campaign/leads-count/${campaignId}`, {
    params: {
      query,
      orQuery,
    },
  });
};

export const getUniqueLeadValuesByCampaignId = async (campaignId, field) => {
  return axiosInstance.get(`/leads/campaign/unique-lead-values/${campaignId}`, {
    params: {
      field,
    },
  });
};

const getCampaignTypeAndName = async (campaignId) => {
  return axiosInstance.get(`/campaigns/type-and-name/${campaignId}`);
};

const getSingleUser = async (userId) => {
  return axiosInstance.get(`/user/${userId}`);
};

const verifyDNS = async (domain) => {
  return axiosInstance.post(`/emails/verify/dns`, { domain });
};

const verifyCredentials = async (
  email,
  password,
  smtpHost,
  imapHost,
  passwordIsEncrypted
) => {
  return axiosInstance.post(
    `/emails/verify/credentials`,
    {
      email,
      password,
      smtpHost,
      imapHost,
      passwordIsEncrypted,
    },
    { timeout: 0 }
  );
};

const createTargetMarket = async (data) => {
  return axiosInstance.post(`/target-markets/create`, data);
};

const getTargetMarketById = async (targetMarketId) => {
  return axiosInstance.get(`/target-markets/market/${targetMarketId}`);
};

const getTargetMarketsByUserId = async (userId) => {
  return axiosInstance.get(`/target-markets/by-user-id/${userId}`);
};

const getTargetMarketsByAccountsTaskId = async (taskId) => {
  return axiosInstance.get(
    `/target-markets/market-by-account-task-id/${taskId}`
  );
};

const deleteTargetMarket = async (targetMarketId) => {
  return axiosInstance.delete(`/target-markets/market/${targetMarketId}`);
};

/**
 * Retrieve the blacklist entries of the user based on the type
 * @param {BlacklistType} type
 * @returns array of blacklists
 */
const getPaginatedBlacklists = async (type, pageIndex, pageSize, search) => {
  return axiosInstance.get(
    `/user/blacklist/paginated?type=${type}&pageIndex=${pageIndex}&pageSize=${pageSize}&search=${search}`
  );
};

/**
 * Retrieve the blacklisted entries of the user based on a list of keywords and the type
 * @param {string} keyword - can be domain names, emails or LinkedIn profile urls
 * @param {BlacklistType} type
 * @returns array of blacklists
 */
const getBlacklistedFromKeywords = async (keywords, type) => {
  return axiosInstance.post(`/user/blacklist/check?type=${type}`, { keywords });
};

/**
 * Delete blacklist entries given their Ids
 * @param {number[]} idsList
 */
const deleteBlacklistEntries = async (idsList) => {
  return axiosInstance.delete(`/user/blacklist`, { data: idsList });
};

/**
 * Create blacklist entries
 * @param {*} BlacklistEntryInput[]
 */
const createBlacklistEntries = async (data) => {
  return axiosInstance.post(`/user/blacklist`, { data });
};

const exportBlacklist = async (type, search) => {
  const encodedSearch = encodeURIComponent(search);
  return axiosInstance.get(
    `/user/blacklist/export?type=${type}&search=${encodedSearch}`
  );
};

const getCompanyDataServiceApiKey = async () => {
  return axiosInstance.get(`auth/cds-api-key`);
};

export default {
  getRecords,
  updateUser,
  incrementCredit,
  createUser,
  getAllUsers,
  getAuthorizedUser,
  userRole,
  getCredit,
  postLogin,
  getIsAuth,
  postNewCampaign,
  createCampaignMessages,
  updateCampaignMessages,
  getCampaignMessages,
  getPaginatedCampaignLeads,
  getCampaigns,
  updateCampaign,
  deleteCampaign,
  getRecordsByUserAndCampaign,
  getLeadsStats,
  updateLead,
  getCampaignById,
  getFollowUpsEmailAuthUrl,
  getFupsStats,
  findMaxAmountFups,
  getMonitorCampaignsStats,
  editCampaign,
  getCampaignTypeAndName,
  getSingleUser,
  getAllLeadsByCampaignId,
  verifyDNS,
  verifyCredentials,
  createTargetMarket,
  getTargetMarketsByUserId,
  getTargetMarketsByAccountsTaskId,
  deleteTargetMarket,
  getPaginatedBlacklists,
  deleteBlacklistEntries,
  createBlacklistEntries,
  getBlacklistedFromKeywords,
  getTargetMarket: getTargetMarketById,
  getCompanyDataServiceApiKey,
  exportBlacklist,
};
