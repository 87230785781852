import API from 'api';
import { isEmailTargetMargetCampaignType } from 'services/campaignType';

/**
 * Submits the creation of a new campaign.
 */
export const submitCampaignCreation = async (
  formData,
  openNotification,
  backToDashboard,
  userId
) => {
  if (isEmailTargetMargetCampaignType(formData.type)) {
    delete formData.targetMarketName;
    delete formData.filters;
  }

  const post = {
    ...formData,
    minimumInvites: formData.invites[0],
    maximumInvites: formData.invites[1],
    useFirstName: false,
    numOfLeads: formData?.parsedLeads?.length ?? 0,
    smtpHost: formData.smtpAddress
      ? `${formData?.smtpAddress}:${formData?.smtpPort}`
      : null,
    imapHost: formData.imapAddress
      ? `${formData?.imapAddress}:${formData?.imapPort}`
      : null,
    emailAddress: formData.emailAddress || null,
    emailPassword: formData.emailPassword || null,
    emailFollowUpsSubject: formData.emailFollowUpsSubject || null,
    campaignLink: formData.campaignLink ?? '',
    parsedLeads: formData.parsedLeads || [],
    femaleSalutation: 'dummy-for-backwards-compatibility',
    maleSalutation: 'dummy-for-backwards-compatibility',
    firstMessage: 'dummy-for-backwards-compatibility',
    secondMessage: 'dummy-for-backwards-compatibility',
    thirdMessage: 'dummy-for-backwards-compatibility',
    status: 'ACTIVE',
    userId,
    /**  TWIMC: On the database the implementation logic is:
     *  TRUE -> We use INCEDO scraper
     *  FALSE -> We use Client scraper
     *  By requirement this value is defaulted to FALSE where the label says "Use my account to..." therefore needs to be flipped.
     */
    useScraperAccounts: !formData.useScraperAccounts,
  };

  try {
    await API.postNewCampaign(post);
  } catch (error) {
    openNotification('error', 'Campaign not created', error.error);
    return;
  }
  openNotification(
    'success',
    `Campaign created successfully`,
    `The campaign "${formData.name}" has been added to your dashboard`
  );
  backToDashboard();
};
