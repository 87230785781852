import { Form, Input } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { renderSignaturePreview } from 'services/campaignForm';

import { EmailSignaturePreview } from './EmailSignaturePreview';
import FormLabel from './FormLabel';

const initialValues = {
  firstname: '',
  lastname: '',
  title: '',
  company: '',
  number: '',
  website: '',
  extra1: '',
  extra2: '',
};

export const EmailSignatureForm = ({
  templatedSignature,
  setTemplatedSignature,
}) => {
  const signaturePreview = useMemo(() => {
    return renderSignaturePreview(templatedSignature);
  }, [templatedSignature]);

  useEffect(() => {
    setTemplatedSignature(initialValues);
  }, [setTemplatedSignature]);

  /**
   * Set the state for the typed input with the corrispondent value
   * @param {*} onValuesChange event
   */
  function onValuesChange(formItemChanged) {
    const [keyChanged] = Object.keys(formItemChanged.emailSignature);
    const [valueChanged] = Object.values(formItemChanged.emailSignature);
    setTemplatedSignature({
      ...templatedSignature,
      [keyChanged]: valueChanged,
    });
  }

  return (
    <>
      <Form
        layout="vertical"
        onValuesChange={onValuesChange}
        requiredMark={false}
        style={{ width: '95%', margin: '0 auto' }}
      >
        <div className="mb-6">
          <FormLabel>Preview</FormLabel>
          <EmailSignaturePreview signature={signaturePreview} />
        </div>

        <div className="grid grid-cols-1 gap-2 xs:grid-cols-2 xs:gap-x-4 md:gap-4">
          <div>
            <FormLabel>Firstname</FormLabel>
            <FormItem name={['emailSignature', 'firstname']} noStyle>
              <Input
                name="firstname"
                placeholder="Name"
                value={templatedSignature.firstname}
              />
            </FormItem>
          </div>

          <div>
            <FormLabel>Lastname</FormLabel>
            <FormItem name={['emailSignature', 'lastname']} noStyle>
              <Input
                name="lastname"
                placeholder="Surname"
                value={templatedSignature.lastname}
              />
            </FormItem>
          </div>

          <div>
            <FormLabel>Title | Department</FormLabel>
            <FormItem name={['emailSignature', 'title']} noStyle>
              <Input
                name="title"
                placeholder="Title or Department"
                value={templatedSignature.title}
              />
            </FormItem>
          </div>

          <div>
            <FormLabel>Company</FormLabel>
            <FormItem name={['emailSignature', 'company']} noStyle>
              <Input
                name="company"
                placeholder="Company Name"
                value={templatedSignature.company}
              />
            </FormItem>
          </div>

          <div>
            <FormLabel>Website</FormLabel>
            <FormItem name={['emailSignature', 'website']} noStyle>
              <Input
                name="website"
                placeholder="Website"
                value={templatedSignature.website}
              />
            </FormItem>
          </div>

          <div>
            <FormLabel>Number</FormLabel>
            <FormItem name={['emailSignature', 'number']} noStyle>
              <Input
                name="number"
                placeholder="number"
                value={templatedSignature.number}
              />
            </FormItem>
          </div>

          <div>
            <FormLabel>Extra Information (new line)</FormLabel>
            <FormItem name={['emailSignature', 'extra1']} noStyle>
              <Input
                name="extra1"
                placeholder="Extra Information"
                value={templatedSignature.extra1}
              />
            </FormItem>
          </div>

          <div>
            <FormLabel>Extra Information (new line)</FormLabel>
            <FormItem name={['emailSignature', 'extra2']} noStyle>
              <Input
                name="extra2"
                placeholder="Extra Information"
                value={templatedSignature.extra2}
              />
            </FormItem>
          </div>
        </div>
      </Form>
    </>
  );
};

EmailSignatureForm.propTypes = {
  /** The form instance used for the form item. */
  setTemplatedSignature: PropTypes.func.isRequired,
  templatedSignature: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
