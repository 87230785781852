import { Popover } from 'antd';
import PropType from 'prop-types';

import FupsStatisticItem from '../FupsStatisticItem/FupsStatisticItem';
import StatisticItem from '../StatisticItem/StatisticItem';

const OutreachTypeStats = ({ cardsData, campaignId }) => {
  return (
    <>
      <StatisticItem
        title="Requested"
        value={cardsData?.requested.value}
        percentage={cardsData?.requested.percentage}
        color="#D7A600"
      />
      <StatisticItem
        title="Connected"
        value={cardsData?.connected.value}
        percentage={cardsData?.connected.percentage}
        color="#375361"
      />
      <Popover
        content={<FupsStatisticItem campaignId={parseInt(campaignId)} />}
        placement="left"
        title="Reply Success Rate"
        trigger="hover"
      >
        <StatisticItem
          title="Replied"
          value={cardsData?.responded.value}
          percentage={cardsData?.responded.percentage}
          color="#3f8600"
        />
      </Popover>
    </>
  );
};

export default OutreachTypeStats;

OutreachTypeStats.propTypes = {
  cardsData: PropType.object,
  campaignId: PropType.string,
};
