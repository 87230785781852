import { Col, Input, Row, Typography } from 'antd';
import { Divider } from 'components/Divider';
import PropTypes from 'prop-types';
import React from 'react';

const { TextArea } = Input;
const { Text } = Typography;

/**
 * Component used in confirm edit modal for changed fields
 */
export function Fields({ oldValue, newValue, title }) {
  return (
    <>
      <Divider>{title}</Divider>
      <Row>
        <Col span={8}>
          <Text strong>Changed from:</Text>
        </Col>
        <Col span={16}>
          <TextArea
            value={oldValue}
            bordered={false}
            readOnly={true}
            autoSize={true}
          />
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Text strong>To:</Text>
        </Col>
        <Col span={16}>
          <TextArea
            value={newValue}
            bordered={false}
            readOnly={true}
            autoSize={true}
          />
        </Col>
      </Row>
    </>
  );
}

Fields.propTypes = {
  /** String that present the old value in campaign info */
  oldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  /** String that present the new value  */
  newValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  /** String that present the Field name  */
  title: PropTypes.string.isRequired,
};
