import dayjs from 'dayjs';

/**
 *
 * Get the data to show in the chart from a campaign's records array.
 * @param {Array<*>} data The backend records data
 * @returns {{
 *    Day: Array<{numOfInvitesSend: number, numOfInvitesAccepted: number, numOfRepliesReceived: number, date: Date}>,
 *    Week: Array<{numOfInvitesSend: number, numOfInvitesAccepted: number, numOfRepliesReceived: number, weekStart: Date, weekEnd: Date}>,
 *    Month: Array<{numOfInvitesSend: number, numOfInvitesAccepted: number, numOfRepliesReceived: number, month: String}>,
 *    Quarter: Array<{numOfInvitesSend: number, numOfInvitesAccepted: number, numOfRepliesReceived: number, }>,
 *    Year: Array<{numOfInvitesSend: number, numOfInvitesAccepted: number, numOfRepliesReceived: number}>, year: number
 * }}
 * The array data where each item corresponds to a label item (same length)
 */

export function formatStatsByViews(data) {
  //View by week
  const weekRecords = [];
  for (let i = 0; i < data.length; i += 7) {
    let requested = 0;
    let connected = 0;
    let replied = 0;
    let weekStart = '';
    let weekEnd = '';
    for (let j = 0; j < 7; j++) {
      const idx = i + j;
      if (idx < data.length) {
        let record = data[idx];
        if (j === 0) weekStart = record.date;
        if (j === 6) weekEnd = record.date;
        requested = requested + record.requested;
        connected = connected + record.connected;
        replied = replied + record.replied;
      }
    }
    weekRecords.push({
      requested,
      connected,
      replied,
      weekStart,
      weekEnd,
    });
  }
  //View by months
  let mr = {};
  for (let i = 0; i < data.length; i++) {
    const date = new Date(data[i].date);
    let month = date.getMonth() + 1 + '/' + date.getFullYear();
    if (!mr[month]) {
      mr[month] = {
        requested: 0,
        connected: 0,
        replied: 0,
      };
    }
    mr[month].requested += data[i].requested;
    mr[month].connected += data[i].connected;
    mr[month].replied += data[i].replied;
    mr[month].month = month;
  }
  const monthsRecords = Object.values(mr);
  //Veiw by quarters
  const quartersRecords = [];
  for (let i = 0; i < monthsRecords.length; i += 3) {
    let requested = 0;
    let connected = 0;
    let replied = 0;
    for (let j = 0; j < 3; j++) {
      if (monthsRecords[j + i]) {
        requested += monthsRecords[j + i].requested;
        connected += monthsRecords[j + i].connected;
        replied += monthsRecords[j + i].replied;
      }
    }
    quartersRecords.push({
      connected,
      requested,
      replied,
    });
  }
  //View by years
  let yr = {};
  for (let i = 0; i < data.length; i++) {
    const date = new Date(data[i].date);
    let year = date.getFullYear();
    if (!yr[year]) {
      yr[year] = {
        requested: 0,
        connected: 0,
        replied: 0,
      };
    }
    yr[year].requested += data[i].requested;
    yr[year].connected += data[i].connected;
    yr[year].replied += data[i].replied;
    yr[year].year = year;
  }
  const yearsRecords = Object.values(yr);
  return {
    Day: data,
    Week: weekRecords,
    Month: monthsRecords,
    Quarter: quartersRecords,
    Year: yearsRecords,
  };
}

export function formatToCSV(records) {
  const res = [];
  records.map((record) => {
    const { date, requested, connected, replied } = record;
    let rec = {
      requested,
      connected,
      replied,
    };
    rec.date = dayjs(new Date(date)).format('DD.MM.YYYY').toString();
    rec.connectedRate =
      (requested === 0 ? 0 : Math.round((connected / requested) * 100)) + '%';
    rec.repliedRate =
      (connected === 0 ? 0 : Math.round((replied / connected) * 100)) + '%';
    rec.convertedRate =
      (requested === 0 ? 0 : Math.round((replied / requested) * 100)) + '%';
    res.push(rec);
  });
  return res;
}
