export const HELP = {
  SPF: `

Your domain (e.g. comx.io) is crucial for effective email deliverability and it is essential that
you take care of it. The last thing you need is your domain being blacklisted and your
emails being marked as spam. The proper setup of SPF and DKIM records on your DNS
server is a necessary step towards the security of your domain and high deliverability of
your messages.


What is SPF?
Sender Policy Framework (SPF) is a security mechanism created to prevent others from
sending emails on your behalf. This mechanism essentially communicates between DNS
servers.


How to implement SPF on your server
The general idea is to make certain that all applications that send emails on your behalf
(and are using their own SMTP, not yours) are included in your SPF. For instance, if you
are using Google Apps to send emails from your domain, Google should be included in
your SPF. Please see Google’s instruction on how to go about this.
First step to setting up your SPF
The first step is to ascertain your current SPF, which can be achieved using tools such as:


MxToolbox
Google Apps Toolbox


When you enter your domain using these tools (for instance, I would type in “comx.io”),
various tests will be run, where the result is displaying your current SPF. Alternatively, a
notification will indicate that this has not been set up yet.


What are the next steps?
It is exceedingly difficult to prepare a step-by-step tutorial for such as setup, because this
takes place in the administrative console for your domain. Depending on your domain
host, the steps will differ. Basically, it requires pasting a properly structured line of text in
the right place in the console.


For instance, when using Google Apps to send emails from your domain, the line would
read as follows:


“v=spf1 include:_spf.google.com ~all”


However, if other apps are being employed, such as an app for newsletter distribution or
support messaging, the line will be slightly longer, because all the other apps will need to
be included.

On the other hand, if you do not use Google Apps, but a server from another
host, such as GoDaddy, the line will again be different.


We recommend contacting your IT or email admin to set up the correct records, as they
will know how to formulate the text and where to paste this.
`,
  CNAME: `Corporate spam prevention systems filter out emails with links which are not verified to your domain (for ComX - comx.io). Each email sent from ComX includes an unsubscribe link and an open tracking pixel to measure email opens. This is what we need from you:

    Add a new CNAME record date.wcheck.org for your tracking subdomain(s) in your domain settings panel. If using separate subdomains for each tracker, add this CNAME record for each of these.
    When creating the CNAME please put a letter o (for Oscar, not the number) in front of your domain, just like o.comx.io or o.volkwagen.de
    The CNAME must point to date.wcheck.org

Example ComX:

Host: o.comx.io Points to: date.wcheck.org

You did wait a bit, right?

 

Remember that the DNS may sometimes take longer to update your settings. The waiting time varies from several minutes up to 72 hours (in extreme cases).
`,
  DKIM: `What is DKIM?


DomainKeys Identified Mail (DKIM) standard has been created for the same reason as
SPF: to prevent fraudsters from impersonating you as an email sender. Basically, it entails
an additional email sign-off that will allow the recipient’s server to determine if the sender
was genuinely you.


Ultimately, by setting up DKIM on your DNS server, you are communicating to your
receivers, saying, “Yes, it’s really me who’s sending this message”. The idea is based on encrypting and decrypting the additional signature, which resides in
the header of your message. To apply this practice, you need to have two keys:


● the private key (which is unique to your domain and available exclusively to you. It
allows you to encrypt your signature in the header of your messages.)
● the public key (which you add to your DNS records using DKIM standard, in order
to allow your recipient’s server to retrieve it and decrypt your hidden signature from
the header of your message.)


Setting up DKIM simply entails inputting the information regarding the public key into your
server’s records. It is also a txt record that needs to be entered in the right place.
Once this is executed, each time someone receives an email from you, the recipient’s
server will attempt to decrypt your hidden signature using the public key. If it succeeds,
this will additionally authenticate your message, so increasing the deliverability of your
emails.


How to set DKIM on your server


First, you need to generate the public key. To do so, log in to your email provider’s admin
console. From this point, the steps may differ depending on your email provider.


If you are using Google Apps to send your emails, follow these step-by-step instructions.
By default, Google Apps does not activate DKIM signatures, so these need to be turned
on manually in your Google admin console.


Once you possess the public key, the generated txt record must be pasted into the right
place in your DNS records.


Finally, turn on email signing functionality to start sending emails with your signature
encrypted with your private key. If you are using Google Apps, here is how to do this.


Again, it is best to ask someone tech-savvy for their assistance in correctly implementing
DKIM.


If you are a current user of ComX, without an IT person to ask for help with SPF and DKIM
settings, you may contact us`,
  FACTORS: `Creating a new Email Adress might become a bottle neck if your Email
            Admin is busy or Outsourced. It is important you explain your
            internal stakeholder how the ComX.io system works and why it is
            important to create a new email adress. Furthermore we do recommend
            to have a decision maker (C-Level or the like) as outbound profile
            to ensure max results. Please watch ComX.io Hub section`,
};
