import { Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const SingleStatItem = ({ label, value, total }) => {
  //Style for the label
  const labelStyle = { fontSize: '0.8rem', color: '#6c6c6c' };
  //If the value is > 0 use color green
  const statValue = (val) => ({ color: val > 0 && 'green' });

  //When the total of replied is 0, just set to 0 to avoid NaN
  const percent = (value, total) => (total == 0 ? 0 : (value / total) * 100);

  return (
    <Row
      style={labelStyle}
      className="flex justify-between px-3 m-1"
      gutter={30}
    >
      <div>{label}</div>
      <div style={statValue(value)}>{percent(value, total).toFixed(1)}%</div>
    </Row>
  );
};

export default SingleStatItem;

SingleStatItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  total: PropTypes.number,
};
