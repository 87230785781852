import './MessageFormItem.styles.css';

import {
  InfoCircleOutlined,
  MinusCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Alert, Col, Divider, Form, Input, Row, Select, Tooltip } from 'antd';
import classNames from 'classnames';
import { DEFAULT_FEMALE_SALUTATION, DEFAULT_MALE_SALUTATION } from 'constant';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getOrdinalNumber } from 'utils/helpers';

import AppendText from './AppendText';
import MessagePreviewLabel from './MessagePreviewLabel';

const maxFirstNameLength = { value: 12, text: '12characters' };
const maxLastNameLength = { value: 16, text: '16characters____' };
const averageCompanyName = { value: 16, text: 'Incedo Services GmbH' };

/**
 * The templating options used in the messages typed by the users.
 * Messages include the first ones being set as well as the follow-up messages.
 */
const templatingOptions = [
  { label: 'First Name', value: '{{ first_name }}' },
  { label: 'Last Name', value: '{{ last_name }}' },
  { label: 'Salutation', value: '{{ salutation }}' },
  { label: 'Company Name', value: '{{ company_name }}' },
];

/**
 * The effective max length, meaning the max length that will be dynamically set
 * to the message. This is only relevant if `maxLength` is not null (or falsy
 * since no typescript).
 */
const parseMessage = (text, firstName, lastName, salutation) => {
  text = text.replaceAll(templatingOptions[0].value, firstName);
  text = text.replaceAll(templatingOptions[1].value, lastName);
  text = text.replaceAll(templatingOptions[2].value, salutation);
  text = text.replaceAll(templatingOptions[3].value, averageCompanyName.text);

  return text;
};

export const MessageFormItem = ({
  listName,
  title,
  name = 0,
  type = 'LinkedIn',
  remove,
  restField,
  maxLength = null,
  showCount = false,
  className,
}) => {
  const form = Form.useFormInstance();

  const [maleSalInput, setMaleSalInput] = useState('');
  const [femaleSalInput, setFemaleSalInput] = useState('');
  const [contentInput, setContentInput] = useState('');

  const salutationRequired = useMemo(
    () => contentInput?.includes('{{ salutation }}'),
    [contentInput]
  );

  const showCompanyNameAlert = useMemo(
    () => contentInput?.includes('{{ company_name }}'),
    [contentInput]
  );

  const inputRef = useRef(null);

  /* 
    'Name' as a prop represent the "index" that ANTD reserves to the Form.Item
    list objects. 'Listname' the master property of a nested one. Therefore each
    time the component loads can get its own state from the form instance
    pointing with both listname and name (eg.: [followUps, 2])
  */
  useEffect(() => {
    const messageObject =
      name === 'initialMessage'
        ? form.getFieldValue('initialMessage')
        : form.getFieldValue(listName)[name];

    if (messageObject) {
      setMaleSalInput(messageObject.maleSalutation);
      setFemaleSalInput(messageObject.femaleSalutation);
      setContentInput(messageObject.content);
    }
  }, [form, listName, name]);

  /*
    Create the longest possible message. This uses the female or male salutation
    depending on which one is longer. For the first name and last name an
    estimate is used. This is used to show the user how many characters they
    have left and to set the max length of the input field.
  */
  const parsedMessage = useMemo(() => {
    if (!contentInput) return '';

    return parseMessage(
      contentInput,
      maxFirstNameLength.text,
      maxLastNameLength.text,
      maleSalInput?.length > femaleSalInput?.length
        ? maleSalInput
        : femaleSalInput
    );
  }, [contentInput, maleSalInput, femaleSalInput]);

  /* Get the character count of the longest possible message. */
  const characterCount = useMemo(() => parsedMessage.length, [parsedMessage]);

  const intervalOptions = useMemo(() => {
    return Array.from({ length: 180 }, (_, idx) => ({
      label: ++idx,
      value: idx,
    }));
  }, []);

  const previewMessage = useCallback(
    (gender) => {
      const salutation = gender === 'male' ? maleSalInput : femaleSalInput;

      if (!contentInput) return; // Sanity check for when the form is reset

      let previewText = parseMessage(
        contentInput,
        gender === 'male' ? 'John' : 'Jane',
        'Doe',
        salutation
          ? salutation
          : gender === 'male'
          ? DEFAULT_MALE_SALUTATION
          : DEFAULT_FEMALE_SALUTATION
      );
      return (
        <div>
          <p className="max-w-xl whitespace-pre-line preview-text">
            {previewText}
          </p>
        </div>
      );
    },
    [contentInput, maleSalInput, femaleSalInput]
  );

  const addText = useCallback(
    (text) => {
      const textArea = inputRef.current.resizableTextArea.textArea;

      textArea.setRangeText(
        text,
        textArea.selectionStart,
        textArea.selectionEnd,
        'end'
      );

      const newValue = textArea.value;

      setContentInput(newValue);

      form.setFieldValue(
        listName ? [listName, name, 'content'] : ['initialMessage', 'content'],
        newValue
      );

      inputRef.current.focus();
    },
    [form, listName, name]
  );

  return (
    <>
      {/* Title, label and remove button */}
      {typeof title !== 'string' && (
        <div className="flex flex-col items-center justify-center">
          <Divider orientation="center">
            <span className="text-sm uppercase text-primary">
              {`${getOrdinalNumber(name + 1)} ${
                type === 'Email' ? ' email' : ''
              } follow up message`}{' '}
            </span>
            {remove && (
              <Tooltip
                placement="bottomRight"
                className="h-6 p-1 ml-2 text-red-600 transition-all ease-in-out cursor-pointer hover:bg-gray-50"
                title={<span>Delete message</span>}
                onClick={() => remove(name)}
              >
                <MinusCircleOutlined className="text-sm text-red-600 dynamic-delete-button" />
              </Tooltip>
            )}
          </Divider>
        </div>
      )}

      <section className={classNames('text-gray-600', className)}>
        {/* Salutation for men and women */}
        <Row gutter={6}>
          <Col span={12}>
            <Form.Item
              name={[name, 'femaleSalutation']}
              value={femaleSalInput}
              {...restField}
              label={
                <span className="text-gray-600">
                  <svg width="20" height="14" viewBox="0 -2 22 26">
                    <path
                      fill="currentColor"
                      d="M14.041 16.683a14.884 14.884 0 0 1-.035-.72c2.549-.261 4.338-.872 4.338-1.585c-.007 0-.006-.03-.006-.041C16.432 12.619 19.99.417 13.367.663a3.344 3.344 0 0 0-2.196-.664h.008C2.208.677 6.175 12.202 4.13 14.377h-.004c.008.698 1.736 1.298 4.211 1.566c-.007.17-.022.381-.054.734C7.256 19.447.321 18.671.001 24h22.294c-.319-5.33-7.225-4.554-8.253-7.317z"
                    />
                  </svg>
                  Salutation for women{' '}
                  <Tooltip title="Only required if the salutation dynamic value is used below">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              rules={[
                {
                  required: salutationRequired,
                  message: 'Please enter a salutation',
                },
              ]}
            >
              <Input
                placeholder={DEFAULT_FEMALE_SALUTATION}
                onChange={(e) => {
                  setFemaleSalInput(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={[name, 'maleSalutation']}
              {...restField}
              label={
                <span className="text-gray-600">
                  <svg width="20" height="14" viewBox="0 -2 22 26">
                    <path
                      fill="currentColor"
                      d="M14.145 16.629a23.876 23.876 0 0 1-.052-2.525l-.001.037a4.847 4.847 0 0 0 1.333-2.868l.002-.021c.339-.028.874-.358 1.03-1.666a1.217 1.217 0 0 0-.455-1.218l-.003-.002c.552-1.66 1.698-6.796-2.121-7.326C13.485.35 12.479 0 11.171 0c-5.233.096-5.864 3.951-4.72 8.366a1.222 1.222 0 0 0-.455 1.229l-.001-.008c.16 1.306.691 1.638 1.03 1.666a4.858 4.858 0 0 0 1.374 2.888a24.648 24.648 0 0 1-.058 2.569l.005-.081C7.308 19.413.32 18.631 0 24h22.458c-.322-5.369-7.278-4.587-8.314-7.371z"
                    />
                  </svg>
                  <span>Salutation for men </span>
                  <Tooltip title="Only relevant if the salutation dynamic value is used below">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              rules={[
                {
                  required: salutationRequired,
                  message: 'Please enter a salutation',
                },
              ]}
            >
              <Input
                placeholder={DEFAULT_MALE_SALUTATION}
                onChange={(e) => {
                  setMaleSalInput(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Message label & Interval input */}
        <Row justify={'space-between'} align={'middle'} className="mb-1">
          <Col>
            <span className="custom-required">Message content </span>
            <Tooltip title="Click on the buttons to the right to add the relative values.">
              <QuestionCircleOutlined />
            </Tooltip>
            {/* TAG Buttons */}
            <span className="ml-2"></span>
          </Col>
          <Col>
            {listName && (
              <>
                <span className="mr-1 text-red-500">*</span>
                Days interval
                <Tooltip
                  placement="bottomRight"
                  title={
                    <span>
                      The duration (in days) to wait before sending this message
                      to a lead if the previous message has already been sent.
                    </span>
                  }
                  className="h-6 mt-1"
                >
                  <InfoCircleOutlined className="ml-1.5" />
                </Tooltip>
                <Form.Item
                  label="Days interval"
                  {...restField}
                  name={[name, 'interval']}
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: 'Please select an interval',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    id="number"
                    size="small"
                    className="w-16 ml-1.5"
                    options={intervalOptions}
                  />
                </Form.Item>
              </>
            )}
          </Col>
        </Row>
        <Row justify={'between'} align={'middle'} className="mb-1">
          <Col flex={'auto'}>
            {templatingOptions.map((opt) => (
              <AppendText
                key={opt.label}
                text={opt.label}
                onClick={() => addText(opt.value)}
              />
            ))}
          </Col>
          <Col>
            {contentInput != '' && (
              <MessagePreviewLabel
                contentInput={contentInput}
                previewMessage={previewMessage}
              />
            )}
          </Col>
        </Row>

        <Form.Item
          className="mb-2"
          name={[name, 'content']}
          {...restField}
          rules={[
            {
              required: true,
              message: 'Please enter the message you would like to send',
            },
            maxLength && {
              max: 300,
              message: `Only a maximum of 300 characters is allowed`,
              /*
                Use the parsed message for validation since text can contain
                dynamic values that are not yet replaced like {{ first_name }}.
                For example the string `{{ first_name }}` has 16 characters, but
                the estimated length of the first name is only 12 characters.
              */
              transform: () => parsedMessage,
            },
          ].filter(Boolean)}
        >
          <Input.TextArea
            ref={inputRef}
            placeholder="Please type here your message."
            rows={6}
            showCount={
              showCount != false && {
                formatter: () => `Characters typed: ${characterCount} / 300`,
              }
            }
            value={contentInput}
            onChange={(e) => {
              setContentInput(e.target.value);
            }}
          />
        </Form.Item>
        {showCompanyNameAlert && (
          <Alert
            closeText="Ok"
            showIcon
            type="warning"
            message={
              <div className="text-xs">
                Please note: If a lead has no <strong>company name</strong>{' '}
                present on his/her profile, no invite will be sent to such lead.
                On average in our campaign ca. 1.1% of the leads does not have
                the company name as part of their profile.
              </div>
            }
            className="mt-6 text-gray-600 fade-in"
          />
        )}
      </section>
    </>
  );
};

MessageFormItem.propTypes = {
  /** The name of the Form.list item if called from a list. */
  listName: PropTypes.string,
  /** The title to show for the message. */
  title: PropTypes.string,
  /** The message form input's name. */
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** Type of the message ( LinkedIn / Email ) */
  type: PropTypes.oneOf(['LinkedIn', 'Email']),
  /** The function used to delete the current message form item. */
  remove: PropTypes.func,
  /** Rest of the form fields provided by antd. */
  restField: PropTypes.any,
  /** The max length of the message. `null` for unlimited. */
  maxLength: PropTypes.number,
  /** Whether to show the length of the message. */
  showCount: PropTypes.bool,
  /** Message content. */
  initialContent: PropTypes.shape({
    campaignId: PropTypes.number,
    /** Male salutation value. */
    maleSalutation: PropTypes.string,
    /** Female salutation value. */
    femaleSalutation: PropTypes.string,
    /** Interval of the follow up message. Not considered for initial contact message. */
    interval: PropTypes.number,
    /** Message content. */
    content: PropTypes.string,
  }),
  className: PropTypes.string,
};
