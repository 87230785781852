import { useUser } from 'hooks/useUser';
import { modules } from 'modules';
import { useMemo } from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router';
import routes from 'routes';

export function AppRoutes() {
  const { user, token } = useUser();

  const routeObjects: RouteObject[] = useMemo(() => {
    const routeObjects = Object.values(modules)
      .map<RouteObject | undefined>((pageModule) => {
        if (pageModule.routeVisibility === 'private' && !user) {
          return;
        }

        if (pageModule.routeVisibility === 'public' && user) {
          return;
        }

        const Component = pageModule.routeProps.component;

        return {
          path: pageModule.routeProps.path,
          element: <Component />,
        };
      })
      .filter(Boolean) as RouteObject[];

    if (!token || user) {
      routeObjects.push({
        path: '*',
        element: (
          <Navigate to={user ? routes.dashboard : routes.login} replace />
        ),
      });
    }

    return routeObjects;
  }, [token, user]);

  return useRoutes(routeObjects);
}
