import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

dayjs.extend(duration);
dayjs.extend(quarterOfYear);

/* 
  TODO: The missing quarterOfYear plugin was causing the getQuarters function
  to be stuck in an infinite loop. This is because `currDate.add(1, "quarter")`
  did not work as expected. The plugin was added to fix this issue, but the
  function still needs to be refactored to be more readable.

  Try to avoid the while loop altogether and also write a test for this function.
*/
function enumerateUnitBetweenDates(unit, startDate, endDate, format) {
  let currDate = dayjs(startDate).startOf('day');
  let lastDate = dayjs(endDate).startOf('day');

  if (unit === 'days') {
    lastDate = lastDate.add(1, 'day');
  }

  const dates = [];

  while (currDate.diff(lastDate) < 0) {
    if (unit === 'week') {
      dates.push(
        currDate.format(format) +
          ' to ' +
          currDate.add(6, 'days').format(format)
      );
    } else {
      dates.push(currDate.format(format));
    }

    currDate = currDate.add(1, unit);
  }

  return dates;
}

export function getMonthDays(period) {
  return enumerateUnitBetweenDates(
    'months',
    period.startDate,
    period.endDate,
    'MM.YYYY'
  );
}

export function getDays(period) {
  return enumerateUnitBetweenDates(
    'days',
    period.startDate,
    period.endDate,
    'DD.MM.YY'
  );
}

export function getWeeks(period) {
  return enumerateUnitBetweenDates(
    'week',
    period.startDate,
    period.endDate,
    'DD.MM.YY'
  );
}

export function getQuarters(period) {
  return enumerateUnitBetweenDates(
    'quarter',
    period.startDate,
    period.endDate,
    'YY.[Q]Q'
  );
}

export function getYears(period) {
  return enumerateUnitBetweenDates(
    'year',
    period.startDate,
    period.endDate,
    'YYYY'
  );
}

export function getLastNMonths(n, date) {
  const months = [];
  for (let i = n - 1; i >= 0; i--) {
    months.push(dayjs(date).subtract(i, 'month').format('MMMM yyyy'));
  }
  return months;
}
