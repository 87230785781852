import {
  CompassFilled,
  EditOutlined,
  EnvironmentFilled,
  LinkedinFilled,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Image,
  Input,
  message,
  Modal,
  Popover,
  Row,
  Space,
  Steps,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import API from 'api';
import UserPlaceHolderImg from 'assets/img/webp/user-placeholder.webp';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const followUpFields = [
  'firstFollowUpSentAt',
  'secondFollowUpSentAt',
  'thirdFollowUpSentAt',
  'fourthFollowUpSentAt',
  'fifthFollowUpSentAt',
  'sixthFollowUpSentAt',
  'firstEmailFollowUpSentAt',
  'secondEmailFollowUpSentAt',
];

export function LinkedinLeadModal({
  visible,
  hideModal,
  onLeadUpdate,
  data,
  followUpsNo = -1,
}) {
  const { Title } = Typography;

  /**
   * **NOTE:** No enums are used since this is not TypeScript. The weird 1.5 is a client requirement.
   * Else it would have been made a separate index as previously implemented.
   *
   * The index of the status of the lead depending on `statusIdx`. Statuses are:
   * `0 - Scraped - createdAt`
   * `1 - Invited - inviteSentAt` | `1.5 - Withrawn - inviteWithdrawnAt`
   * `2 - Connected - inviteAccepted`
   * `3 - Follow ups - [first-sixth]FollowUpSentAt, [first|second]EmailFollowUpSentAt
   * `4 - Replied - replied`
   */
  const statusIdx = useMemo(() => {
    const { inviteSentAt, inviteWithdrawnAt, inviteAccepted, replied } = data;
    if (replied) return 4;
    if (inviteAccepted) return 3;
    if (inviteWithdrawnAt) return 1.5;
    if (inviteSentAt) return 1;
    return 0;
  }, [data]);

  /** A appropriate status message for the lead */
  const statusMessage = useMemo(() => {
    const { inviteSentAt, inviteWithdrawnAt, inviteAccepted, replied } = data;

    if (replied) return 'Replied';
    if (inviteAccepted) return 'Accepted';
    if (inviteWithdrawnAt) return 'Withdrawn';
    if (inviteSentAt) return 'Invited';

    return 'Scraped';
  }, [data]);

  const tooltipRef = useRef(null);

  const [firstName, setFirstName] = useState(data.firstName);
  const [lastName, setLastName] = useState(data.lastName);

  useEffect(() => {
    setFirstName(data.firstName);
    setLastName(data.lastName);
  }, [data]);

  const [editClicked, setEditClicked] = useState(false);
  const [updatingName, setUpdatingName] = useState(false);

  const handleNameUpdate = useCallback(async () => {
    setUpdatingName(true);
    try {
      const {
        data: { updatedLead },
      } = await API.updateLead(data.leadId, {
        firstName,
        lastName,
        name: `${firstName} ${lastName}`,
      });

      onLeadUpdate(updatedLead);
    } catch (error) {
      message.error('Could not update lead name');
    } finally {
      setUpdatingName(false);
      setEditClicked(false);
    }
  }, [data.leadId, firstName, lastName, onLeadUpdate]);

  const handleNameCancel = useCallback(() => {
    setEditClicked(false);
    setFirstName(data.firstName);
    setLastName(data.lastName);
  }, [data.firstName, data.lastName]);

  /**
   * The custom component for each Step item.
   *
   * This is impure as it is using the `statusIdx`. Passing it as argument is
   * tricky so it is left as is due to time constraints.
   */
  const customDot = (dot, { index }) => {
    let message = '';
    switch (index) {
      case 0: // Scraped
        message = 'The lead was added to the database';
        break;
      case 1: // Invited or Withdrawn
        message =
          statusIdx === 1
            ? 'An invitation request was sent to the lead'
            : 'The lead ignored the invitation for a long time therefore it was withdrawn and the process was stopped';
        break;
      case 2: // Connected
        message = 'The lead accepted the invite request';
        break;
      case 3: // Follow ups
        message = 'The total number of follow ups sent to the lead';
        break;
      case 4: // Replied
        message = 'The lead successfully replied to your messages';
        break;
    }
    return <Popover content={<span>{message}</span>}>{dot}</Popover>;
  };

  const [updatingSkip, setUpdatingSkip] = useState(false);

  const skipUpdateDisabled = useMemo(() => {
    if (statusIdx === 4) return true;

    return false;
  }, [statusIdx]);

  const handleSkipUpdate = useCallback(async () => {
    if (updatingSkip || skipUpdateDisabled) return;

    setUpdatingSkip(true);

    try {
      const {
        data: { updatedLead },
      } = await API.updateLead(data.leadId, {
        skip: !data.skip,
        skipReason: !data.skip ? 'EXCULDED_BY_USER' : null,
      });

      onLeadUpdate(updatedLead);

      message.success('Lead skip updated successfully');
    } catch (error) {
      message.error('Could not update lead skip');
    } finally {
      setUpdatingSkip(false);
    }
  }, [data.leadId, data.skip, onLeadUpdate, skipUpdateDisabled, updatingSkip]);

  return (
    <Modal
      open={visible}
      title="Detailed Lead Information"
      onCancel={() => {
        setEditClicked(false);
        // Hide modal in next event loop so state gets time to update
        setTimeout(() => {
          hideModal();
        });
      }}
      footer={null}
      width={900}
      centered
    >
      <Row className="flex flex-col justify-center text-center align-middle md:flex-row md:justify-around md:p-2">
        {/* Left section */}
        <Col className="flex flex-col justify-center pb-5 text-center align-middle md:w-4/12 md:mt-4">
          <Popover
            open={editClicked}
            placement="bottom"
            title={
              <div className="flex items-center justify-between">
                <span>Edit the lead name</span>
                <Tooltip title="You should ONLY change the name in ILG if it is different from the name on LinkedIn. Else, this lead might not be contacted anymore.">
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            }
            content={
              <Space direction="vertical" size={'middle'}>
                <Row>
                  <Col span={24}>
                    <Input
                      size="middle"
                      placeholder="First Name"
                      status={!firstName && 'error'}
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Input
                      size="middle"
                      placeholder="Last Name"
                      status={!lastName && 'error'}
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row align="middle" justify="space-between">
                  <Col>
                    <Button
                      type="primary"
                      size="middle"
                      onClick={handleNameUpdate}
                      disabled={!(firstName && lastName)}
                      loading={updatingName}
                    >
                      Update
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      size="middle"
                      onClick={handleNameCancel}
                      disabled={updatingName}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Space>
            }
          >
            <div className="mb-4">
              <span className="text-2xl">{`${data.firstName} ${data.lastName}`}</span>
              <Tooltip
                title="Edit the name of this lead"
                open={editClicked ? false : undefined}
                ref={tooltipRef}
              >
                <EditOutlined
                  className="ml-2 text-xl hover:text-secondary"
                  onClick={() => {
                    setEditClicked(!editClicked);
                    tooltipRef.current?.close();
                  }}
                />
              </Tooltip>
            </div>
          </Popover>
          <div className="mb-4">
            <Image
              width={120}
              height={120}
              className="rounded-full"
              preview={false}
              src={data.imageUrl?.startsWith('http') ? data.imageUrl : 'error'}
              fallback={UserPlaceHolderImg}
            />
          </div>
          <div className="mb-4 text-base">{data.title}</div>
          <div>
            <EnvironmentFilled /> {data.location}
          </div>
        </Col>

        {/* Right section */}
        <Col className="flex flex-col justify-center w-full p-5 text-center bg-gray-100 rounded-lg md:w-7/12">
          <div className="flex justify-between p-2">
            <Title level={5} className="mt-2">
              Profile:
            </Title>
            <Space>
              <a
                className="flex items-center text-blue-500 hover:text-blue-200"
                href={data.linkedInProfileUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedinFilled
                  className="mr-2"
                  style={{ fontSize: '1.5rem' }}
                />
              </a>
              <a
                className="flex items-center text-blue-500 hover:text-blue-200"
                href={data.salesNavProfileUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <CompassFilled
                  className="mr-2"
                  style={{ fontSize: '1.5rem' }}
                />
              </a>
            </Space>
          </div>

          <div className="flex justify-between p-2">
            <Title level={5} className={'align-middle'}>
              Email:{' '}
            </Title>
            {data.email?.includes('@') ? (
              <a href={`mailto:${data.email}`}>{data.email}</a>
            ) : (
              <span className="text-gray-500">No email found</span>
            )}
          </div>

          <Col>
            <div className="flex justify-between p-2">
              <Title level={5}>Company: </Title>

              {data.companyUrl?.startsWith('http') ? (
                <div className="text-right">
                  <a
                    href={data.companyUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data.companyName}
                  </a>
                </div>
              ) : (
                <span>{data.companyName || '-'}</span>
              )}
            </div>
          </Col>

          <Col>
            <div className="flex justify-between p-2">
              <Title level={5}>Location: </Title>
              {/* If the name is too long will shorten it showing a tooltip with the full string */}
              <div>
                {data.location.length > 28 ? (
                  <Tooltip title={data.location}>
                    {data.location.substr(0, 25)}...
                  </Tooltip>
                ) : (
                  data.location
                )}
              </div>
            </div>
          </Col>

          <Col className="md:hidden">
            <div className="flex justify-between p-2">
              <Title level={5}>Status: </Title>
              <div>{statusMessage}</div>
            </div>
          </Col>
        </Col>
      </Row>

      <div className="hidden md:block md:mt-12">
        <Col>
          <Steps
            current={statusIdx === 1.5 ? 1 : statusIdx}
            status={
              statusIdx === 1.5
                ? 'error'
                : statusIdx === 4
                ? 'finish'
                : 'process'
            }
            progressDot={customDot}
            items={[
              {
                title: 'Scraped',
                description: `On ${dayjs(data.createdAt).format('DD.MM.YYYY')}`,
              },
              {
                title: statusIdx === 1.5 ? 'Withdrawn' : 'Invited',
                description:
                  statusIdx === 1.5
                    ? `On ${dayjs(data.inviteWithdrawnAt).format('DD.MM.YYYY')}`
                    : data.inviteSentAt
                    ? `On ${dayjs(data.inviteSentAt).format('DD.MM.YYYY')}`
                    : 'Not yet',
              },
              {
                title: 'Connected',
                description: data.inviteAccepted ? 'Done' : 'Not yet',
              },
              {
                title: 'Follow ups',
                description:
                  followUpsNo === -1
                    ? 'None'
                    : `${followUpFields.reduce(
                        (prev, fieldName) => (prev += data[fieldName] ? 1 : 0),
                        0
                      )} of ${followUpsNo} sent`,
              },
              {
                title: 'Replied',
                description: data.replied ? 'Done' : 'Not yet',
              },
            ]}
          />
        </Col>
      </div>

      {/* TODO: Make follow ups toggelable by clicking on the follow ups dot */}

      <Divider />

      <div className="flex justify-center pb-2">
        <label
          className={`flex items-center ${
            skipUpdateDisabled
              ? 'text-gray-400'
              : 'text-gray-500 cursor-pointer'
          }`}
        >
          Exclude from campaign
          <Tooltip
            title={
              skipUpdateDisabled
                ? 'This lead already replied, you cannot exclude it'
                : 'Exclude this lead from the campaign'
            }
            className="ml-2 mr-8"
          >
            <QuestionCircleOutlined />
          </Tooltip>
          <Switch
            checked={data.skip}
            loading={updatingSkip}
            disabled={updatingSkip || skipUpdateDisabled}
            onChange={() => handleSkipUpdate()}
          />
        </label>
      </div>
    </Modal>
  );
}

LinkedinLeadModal.propTypes = {
  /** The visibility of the modal */
  visible: PropTypes.bool.isRequired,
  /** Function to hide this modal */
  hideModal: PropTypes.func.isRequired,
  /** Notify the immediate parent that the lead was updated */
  onLeadUpdate: PropTypes.func.isRequired,
  /** The data of the current lead */
  data: PropTypes.object.isRequired,
  /** The number of follow-ups for the current campaign */
  followUpsNo: PropTypes.number.isRequired,
};
