import routes from 'routes';

import { CampaignAnalyticsDashboard } from './CampaignAnalyticsDashboard';

const CampaignAnalyticsDashboardScreen = () => <CampaignAnalyticsDashboard />;

export default {
  routeProps: {
    path: routes.campaign,
    component: CampaignAnalyticsDashboardScreen,
  },
  name: 'campaign',
  routeVisibility: 'private',
};
