import routes from 'routes';

import { LoginForm } from './components/LoginForm/LoginForm';

const LoginScreen = () => <LoginForm />;

export default {
  routeProps: {
    path: routes.login,
    component: LoginScreen,
  },
  name: 'login',
  routeVisibility: 'public',
};
