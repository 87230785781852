import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import { Button } from 'antd';
import PropTypes from 'prop-types';

export const FilterCard = ({
  filter,
  children,
  isAggregation,
  selectedFilter,
  setSelectedFilter,
  disableActions,
}) => {
  return (
    <div
      className={`px-4 py-1.5 text-sm text-primary cursor-pointer ${
        selectedFilter === filter.name ? 'bg-gray-100' : 'bg-white'
      }`}
    >
      <div
        className={`flex items-center justify-between ${
          disableActions && 'p-1'
        }`}
        onClick={() => {
          disableActions
            ? false
            : selectedFilter === filter.name
            ? setSelectedFilter(undefined)
            : setSelectedFilter(filter.name);
        }}
      >
        <div>{isAggregation ? filter.aggregationTitle : filter.title}</div>

        {!disableActions && (
          <Button
            type="link"
            className="text-secondary hover:text-secondary-light"
            onClick={() => {
              selectedFilter === filter.name
                ? setSelectedFilter(undefined)
                : setSelectedFilter(filter.name);
            }}
            icon={
              selectedFilter === filter.name ? (
                <MinusCircleFilled className="text-primary" />
              ) : (
                <PlusCircleFilled />
              )
            }
          />
        )}
      </div>
      {children}
    </div>
  );
};

FilterCard.propTypes = {
  filter: PropTypes.object,
  children: PropTypes.any,
  isAggregation: PropTypes.bool,
  selectedFilter: PropTypes.string,
  setSelectedFilter: PropTypes.func,
  disableActions: PropTypes.bool,
};
