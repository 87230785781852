import { CURRENT_FILTER } from 'constant';

/**
 * Get the globally set filters, this is synchronous.
 * @returns Either `['Active', 'Inactive']` or one of them in an array.
 */
export function getGlobalFilters() {
  return localStorage.getItem(CURRENT_FILTER) ?? 'ALL';
}

/**
 * Set the global filters.
 *
 * Either `['ACTIVE', 'STOPPED']` or one of them in an array.
 * Passing `[]` will instead reset the filters.
 * @param {*} filterOptions
 */
export function setGlobalFilters(filter) {
  try {
    localStorage.setItem(CURRENT_FILTER, filter);
  } catch (error) {
    console.error(`Global filter setting: ${error}`);
  }
}
