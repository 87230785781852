export const filterRole = [
  {
    text: 'User',
    value: 'User',
  },
  {
    text: 'Admin',
    value: 'Admin',
  },
  {
    text: 'SuperAdmin',
    value: 'SuperAdmin',
  },
];
