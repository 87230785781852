import classNames from 'classnames';
import { Card } from 'components/Card';

export interface EmailSignaturePreviewProps {
  signature: string;
  className?: string;
}

export function EmailSignaturePreview({
  signature,
  className,
}: EmailSignaturePreviewProps) {
  return signature === '' ? (
    'No signature'
  ) : (
    <Card
      background="bg-gray-100"
      borderRadius="rounded-md"
      className={classNames('min-h-[8rem]', className)}
    >
      <pre className="font-inherit">{signature}</pre>
    </Card>
  );
}
