import './Dashboard.styles.css';

import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, message, Radio, Spin, Typography } from 'antd';
import API from 'api';
import { Card } from 'components/Card';
import { Layout } from 'components/Layout';
import { radioOptions } from 'constant/dashboard';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import routes from 'routes';
import { formattedTypes } from 'services/campaignType';
import { getGlobalFilters, setGlobalFilters } from 'utils/helpers/storage';

import { useUser } from '../../../hooks/useUser';
import { HeaderFilterType } from '../components/HeaderFilterType';
import { SubHeader } from '../components/SubHeader';
import { DashboardTable } from './components/DashboardTable';

/**
 * The main components for the dashboard which will fetch campaigns from the API and
 * render the campaigns table
 */

export function Dashboard() {
  const [campaigns, setCampaigns] = useState([]);
  const [radioValue, setRadioValue] = useState(getGlobalFilters());
  const [filterList, setFilterList] = useState(
    getFilterValues(getGlobalFilters())
  );
  const [search, setsearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [campaignTypeSelected, setCampaignTypeSelected] = useState({
    label: 'All',
    init: '',
  });

  useEffect(() => {
    setGlobalFilters([radioValue]);
  }, [radioValue]);

  const filteredCampaigns = useMemo(() => {
    const campaignsToShow = [];
    campaigns.forEach((campaign) => {
      if (filterList.includes(campaign.status)) {
        campaignsToShow.push(campaign);
      }
    });
    return search !== ''
      ? campaignsToShow.filter((campaign) =>
          campaign.name.toLowerCase().includes(search.toLowerCase())
        )
      : campaignsToShow;
  }, [filterList, campaigns, search]);

  const singleTypeCampaigns = useMemo(() => {
    return campaignTypeSelected.label !== 'All'
      ? filteredCampaigns.filter((campaign) =>
          campaign.type.startsWith(campaignTypeSelected.init)
        )
      : filteredCampaigns;
  }, [campaignTypeSelected, filteredCampaigns]);

  function getFilterValues(value) {
    switch (value) {
      case 'ALL':
      default:
        return ['ACTIVE', 'STOPPED'];
      case 'ACTIVE':
        return ['ACTIVE'];
      case 'STOPPED':
        return ['STOPPED'];
    }
  }

  const { user } = useUser();

  const fetchCampaigns = useCallback(async () => {
    if (!user) return;

    try {
      setIsLoading(true);
      const { data: campaignData } = await API.getMonitorCampaignsStats(
        user.id
      );
      setCampaigns(campaignData);
      setIsLoading(false);
    } catch (error) {
      message.error('Could not fetch campaigns with stats!');
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchCampaigns();
  }, [fetchCampaigns]);

  /**
   * Updates the initial campaign list (unfiltered) in the UI
   * @param {*} updatedCampaign
   */
  const updateCampaignStatus = (id, status) => {
    setCampaigns(
      campaigns.map((campaign) => {
        if (campaign.id === id) {
          campaign.status = status;
        }
        return campaign;
      })
    );
  };

  const onRadioChange = ({ target: { value } }) => {
    setFilterList(getFilterValues(value));
    setRadioValue(value);
  };

  return (
    <Layout>
      <SubHeader title="Campaigns" />
      <Card
        shadow
        className="flex flex-col items-center gap-4 mb-6 md:flex-row md:flex-wrap md:justify-between"
        borderRadius="rounded-lg"
      >
        <Link to={routes.createCampaign}>
          <Button type="primary" icon={<PlusOutlined />}>
            New Campaign
          </Button>
        </Link>
        <div className="flex gap-2">
          {formattedTypes.map((type, i) => (
            <HeaderFilterType
              key={`filter_${i}`}
              selected={campaignTypeSelected.label}
              type={type}
              selectedCampaign={(type) =>
                setCampaignTypeSelected({ label: type.label, init: type.init })
              }
            />
          ))}
        </div>
        <div className="flex flex-col gap-2">
          <Input
            allowClear
            prefix={<SearchOutlined style={{ marginRight: '5px' }} />}
            placeholder={'Search ...'}
            value={search}
            onChange={(e) => setsearch(e.target.value)}
          />
          <Radio.Group
            options={radioOptions}
            onChange={onRadioChange}
            value={radioValue}
            optionType="button"
            buttonStyle="solid"
            className="text-right"
          />
        </div>
      </Card>

      {isLoading ? (
        <div className="flex flex-col items-center justify-center my-8">
          <Spin size="large" style={{ fontSize: '72', color: '#375361' }} />
          <Typography.Title
            level={5}
            style={{ color: '#375361', opacity: 0.5 }}
          >
            Loading...
          </Typography.Title>
        </div>
      ) : (
        <>
          <DashboardTable
            updateCampaignStatus={updateCampaignStatus}
            campaigns={
              campaignTypeSelected.label != 'All'
                ? singleTypeCampaigns
                : filteredCampaigns
            }
            reloadCampaigns={fetchCampaigns}
          />
        </>
      )}
    </Layout>
  );
}
