/**
 * Check If the given URL is valid URL
 * @param {[String[]]} String : URL to check
 * @returns {boolean} : true if the URL is valid
 */
export const isValidHttpUrl = (string) => {
  var res = string.match(
    // eslint-disable-next-line no-useless-escape
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return res !== null;
};

/**
 * Check If the given Email is valid Email
 * @param {[String[]]} String : Email to check
 * @returns {boolean} : true if the URL is valid
 */
export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

/**
 * **FIXME:** Returning '---' will cause **NaN is an invalid value** and **Invalid percentage** warnings
 *
 * How much percent of a is b
 * @param {number} a: number
 * @param {number} b: number
 * @returns percent<number> or '---'<string>
 */
export const calcPercent = (a, b) => {
  const percent = a !== 0 ? (b / a) * 100 : NaN;
  return isNaN(percent) ? '---' : Math.round(percent);
};

/**
 * get the oridal string of a number, eg: (2/3/4) -> (2nd/3rd/4th)
 * @param {number} n number
 * @returns {string}
 */
export const getOrdinalNumber = (n) => {
  return n + (['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th');
};

/**
 * Check if two objects are equal based on their properties.
 *
 * Note that `Object.keys` will not work on `null` and `undefined` values
 * so these values are defaulted to `0` when re-calling the function recursively.
 * @param {any} o1
 * @param {any} o2
 * @returns {boolean} whether the objects are equal
 */
export const objectsEqual = (o1, o2) =>
  typeof o1 === 'object' && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every((p) => objectsEqual(o1[p] || 0, o2[p] || 0))
    : o1 === o2;

/**
 * Check if two arrays are equal by comparing their objects individually.
 * @param {Array} a1
 * @param {Array} a2
 * @returns {boolean} whether the arrays are equal
 */
export const arraysEqual = (a1, a2) =>
  a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

export const compare = (a, b) => {
  if (a.order < b.order) {
    return -1;
  }
  if (a.order > b.order) {
    return 1;
  }
  return 0;
};
