import PropTypes from 'prop-types';
export const CardTable = ({ children }) => {
  return (
    <div className="flex flex-col w-full mb-3 text-gray-500">{children}</div>
  );
};

CardTable.propTypes = {
  children: PropTypes.any,
};
