import React from 'react';
import { useLocation } from 'react-router-dom';

/**
 * A custom hook to get query params.
 *
 * **Usage:**
 * ```
 * let query = useQuery();
 * query.get('name')
 * ```
 * @returns
 */
export const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
