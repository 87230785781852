import { InfoCircleFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';

export const BlacklistedLeadsAlert = ({ number }) => {
  return (
    <div className="flex items-center ml-4 text-gray-400">
      <InfoCircleFilled className="mx-1 text-md text-secondary" />
      <div className="text-xs ">
        {number}{' '}
        <span className="font-semibold text-secondary">
          {number === 1 ? 'lead' : 'leads'}
        </span>{' '}
        will be skipped (blacklisted)
      </div>
    </div>
  );
};

BlacklistedLeadsAlert.propTypes = {
  number: PropTypes.number,
};
