import { Col, Input, Modal, Row, Typography } from 'antd';
import { Divider } from 'components/Divider';
import { Fields } from 'components/Fields/Fields';
import PropTypes from 'prop-types';

const { Text } = Typography;

/** Confirm User edit modal before sending a request to the API */
export function ConfirmUserEditModal({
  isModalVisible,
  setIsModalVisible,
  changed,
  handleSubmit,
  isLoading,
}) {
  return (
    <Modal
      title="Confirm updates ?"
      centered
      open={isModalVisible}
      onOk={handleSubmit}
      confirmLoading={isLoading}
      onCancel={() => setIsModalVisible(false)}
    >
      {changed.companyName && (
        <Fields
          title={'Company name'}
          newValue={changed.companyName.value}
          oldValue={changed.companyName.originalValue}
        />
      )}
      {changed.email && (
        <Fields
          title={'Email'}
          newValue={changed.email.value}
          oldValue={changed.email.originalValue}
        />
      )}
      {changed.password && (
        <>
          <Divider>Changed password</Divider>
          <Row align="middle">
            <Col span={8}>
              <Text strong>New password: </Text>
            </Col>
            <Col span={16}>
              <Input.Password
                value={changed.password.value}
                bordered={false}
                onClick={(e) => e.preventDefault()}
              />
            </Col>
          </Row>
        </>
      )}
      {changed.role && (
        <Fields
          title={'Role'}
          newValue={changed.role.value}
          oldValue={changed.role.originalValue}
        />
      )}

      {changed.userBoughtCredit && (
        <Fields
          title={'Purchased credits'}
          newValue={changed.userBoughtCredit.value}
          oldValue={changed.userBoughtCredit.originalValue}
        />
      )}
    </Modal>
  );
}

ConfirmUserEditModal.propTypes = {
  /**Modal visibility */
  isModalVisible: PropTypes.bool,
  /** set state for modal visibility */
  setIsModalVisible: PropTypes.func,
  /** Object of changed fields and its new and original values */
  changed: PropTypes.object,
  /** function to handle submit */
  handleSubmit: PropTypes.func,
  /** loading state */
  isLoading: PropTypes.bool,
};
