import routes from 'routes';

import { EditCampaign } from './EditCampaign';

const EditScreen = () => <EditCampaign />;

export default {
  routeProps: {
    path: routes.editCampaign,
    component: EditScreen,
  },
  name: 'edit',
  routeVisibility: 'private',
};
