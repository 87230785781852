import { createElement } from 'react';

export const LAYOUT_X_PADDING = `px-4 xs:px-6 sm:px-8 md:px-10 lg:px-12 xl:px-20 2xl:px-32`;

export type LayoutElementTypes = 'section' | 'div';

export interface LayoutProps {
  children: React.ReactNode[];
  /** @default 'section' */
  as?: LayoutElementTypes;
}

export function Layout({ children, as = 'section' }: LayoutProps) {
  return createElement(
    as,
    {
      className: `fade-in flex flex-col pb-8 ${LAYOUT_X_PADDING}`,
    },
    children
  );
}
