import axios from 'axios';
import { ILG_JWT_TOKEN, JWT_BEARER_TEXT } from 'constant';
import { v1 as uuidV1 } from 'uuid';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
  timeout: process.env.REACT_APP_API_TIMEOUT * 1000 || 30 * 1000,
});

export const axiosIntercept = axiosInstance.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
      'X-Request-ID': uuidV1(),
      Authorization: `${JWT_BEARER_TEXT} ${localStorage.getItem(
        ILG_JWT_TOKEN
      )}`,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
