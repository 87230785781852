import { WarningFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';

export const DuplicateLeadsAlert = ({ duplicates, objkey }) => {
  return (
    <div className="flex items-center ml-4 text-gray-400">
      <div className="text-xs">
        <WarningFilled className="mx-1 text-red-500" />
        {duplicates.length}{' '}
        <Tooltip
          color={'#375361'}
          title={
            <div>
              {duplicates.map((item, i) => (
                <div className="p-1" key={`duplicates_${i}`}>
                  {`${i + 1}: ${item.firstName} ${item.lastName} - ${
                    item[objkey]
                  } `}
                </div>
              ))}
            </div>
          }
        >
          <span className="font-semibold underline text-secondary">
            {duplicates.length === 1 ? 'lead' : 'leads'}
          </span>
        </Tooltip>{' '}
        {duplicates.length === 1 ? 'is' : 'are'} already present in your
        campaign and will not be added again
      </div>
    </div>
  );
};

DuplicateLeadsAlert.propTypes = {
  duplicates: PropTypes.array,
  objkey: PropTypes.string.isRequired,
};
