import { Input } from 'antd';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import OptionWithExclusion from './OptionWithExclusion';

export const MultiSelect = ({ filter, removeOption, options }) => {
  const [textInput, setTextInput] = useState('');

  const filteredOptions = useMemo(
    () =>
      options.filter((option) =>
        option.title.toLowerCase().includes(textInput.toLowerCase())
      ),
    [options, textInput]
  );

  return (
    <div className="mb-4 fade-in">
      {filter.dynamicDownloadOptions && (
        <Input
          value={textInput}
          className="mb-2"
          autoFocus
          allowClear
          onChange={(e) => setTextInput(e.target.value)}
          placeholder={`Search ${filter.title}...`}
        />
      )}
      <ul className="overflow-auto list-none -ml-9 max-h-36">
        {filteredOptions.map((item) =>
          filter.exclusionSupported ? (
            <OptionWithExclusion
              key={item.id}
              item={item}
              removeOption={removeOption}
              filteredOptions={filteredOptions}
            />
          ) : (
            <li
              key={item.id}
              value={item.title}
              className="focus:outline-none border-none px-2 py-1.5 text-gray-500 rounded cursor-pointer hover:text-secondary hover:bg-white text-ellipsis text-xs"
              onClick={() => removeOption(item)}
            >
              {item.title}
            </li>
          )
        )}
      </ul>
    </div>
  );
};

MultiSelect.propTypes = {
  filter: PropTypes.object,
  removeOption: PropTypes.func,
  options: PropTypes.array,
};
