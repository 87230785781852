import { LinkedinFilled, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Tag } from 'antd';
import { commonFields, outreachFields } from 'constant/cvsExport';
import { filterStatus, HEADER_SEARCH_LABEL } from 'constant/MonitoringCampaign';
import { linkedinIconStyle, tagStyle } from 'constant/styles';
import dayjs from 'dayjs';
import { FilterOff, PeopleIcon } from 'modules/Campaign/components/Icons';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { downloadCSV } from 'utils/helpers/csv';

import SingleStat from './SingleStat';
import TableTitle from './TableTitle';

export const OutreachTable = ({ campaigns }) => {
  const [tablesData, setTablesData] = useState(campaigns);
  const [inputValue, setInputValue] = useState('');

  const csvHeaderFields = [...commonFields, ...outreachFields];

  const [isFilterActive, setIsFilterActive] = useState(false);

  const handleChange = (str) => {
    str === '' ? setIsFilterActive(false) : setIsFilterActive(true);
    setInputValue(str);
    setTablesData(
      campaigns.filter(
        (e) =>
          e.name.toLowerCase().includes(str.toLowerCase()) ||
          e.type.toLowerCase().includes(str.toLowerCase()) ||
          e['user.companyName'].toLowerCase().includes(str.toLowerCase()) ||
          e['user.email'].toLowerCase().includes(str.toLowerCase())
      )
    );
  };

  const [_, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const renderOnFilter = (dataIndex, fieldName) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: '10px 4px',
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          bordered={false}
          className="w-80"
          allowClear
          ref={searchInput}
          placeholder={`Search ${fieldName}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          onBlur={() => {
            if (selectedKeys.length === 0) {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }
          }}
        />
        <Space className="mx-1">
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
          >
            Search
          </Button>
          <Button
            icon={<FilterOff className="mr-2 -ml-1" />}
            onClick={() => {
              clearFilters();
              setSearchText('');
              confirm();
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (_, tuple) => {
      if (searchedColumn === dataIndex) {
        switch (dataIndex) {
          case 'linkedInAccount.email':
            return (
              <div className="flex flex-row justify-center mt-2 align-middle">
                <LinkedinFilled style={linkedinIconStyle} />{' '}
                <div className="ml-2 dashboard__linked-in-account">
                  <div>{tuple['linkedInAccount.email']}</div>
                </div>
              </div>
            );
          case 'name':
            return (
              <div className="flex flex-col">
                <div className="text-xs sm:text-base my-0.5">{tuple.name}</div>
                <div className="text-xs text-gray-400 my-0.5">{tuple.type}</div>
              </div>
            );
          case 'user.companyName':
            return (
              <div className="flex flex-col">
                <div className="text-xs sm:text-base">
                  {tuple['user.companyName']}
                </div>
                <div className="flex flex-row justify-center mt-2 align-middle">
                  <div className="ml-2 dashboard__linked-in-account">
                    <div className="text-xs sm:text-sm">
                      {tuple['user.email']}
                    </div>
                  </div>
                </div>
              </div>
            );
        }
      } else {
        switch (dataIndex) {
          case 'name':
            return (
              <div className="flex flex-col">
                <div className="text-xs sm:text-base my-0.5">{tuple.name}</div>
                <div className="text-xs text-gray-400 my-0.5">{tuple.type}</div>
              </div>
            );
          case 'linkedInAccount.email':
            return (
              <div className="flex flex-row justify-center mt-2 align-middle">
                <LinkedinFilled style={linkedinIconStyle} />{' '}
                <div className="ml-2 dashboard__linked-in-account">
                  <div>{tuple['linkedInAccount.email']}</div>
                </div>
              </div>
            );
          case 'user.companyName':
            return (
              <div className="flex flex-col">
                <div className="text-xs sm:text-base">
                  {tuple['user.companyName']}
                </div>
                <div className="flex flex-row justify-center mt-2 align-middle">
                  <div className="ml-2 dashboard__linked-in-account">
                    <div className="text-xs sm:text-sm">
                      {tuple['user.email']}
                    </div>
                  </div>
                </div>
              </div>
            );
        }
      }
    },
  });

  const columns = [
    {
      key: 'name',
      title: 'Campaign',
      defaultSortOrder: 'ascend',
      align: 'center',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...renderOnFilter('name', 'Campaign Name'),
    },
    {
      title: 'Account',
      align: 'center',
      dataIndex: 'linkedInAccount.email',
      sorter: (a, b) =>
        a['linkedInAccount.email'].localeCompare(b['linkedInAccount.email']),
      ...renderOnFilter('linkedInAccount.email', 'LinkedIn Account'),
    },
    {
      title: 'Company',
      align: 'center',
      dataIndex: ['user.companyName', 'user.email'],
      sorter: (a, b) =>
        a['user.companyName'].localeCompare(b['user.companyName']),
      ...renderOnFilter('user.companyName', 'Company Name'),
    },
    {
      title: 'Status',
      align: 'center',
      dataIndex: 'status',
      className: 'hidden sm:table-cell max-w-xs',
      render: (status, record) => (
        <div className="flex flex-col justify-center max-w-sm align-middle">
          <div className="my-1 text-center">
            {status === 'ACTIVE' ? (
              <Tag style={tagStyle} color="green">
                Active
              </Tag>
            ) : (
              <Tag style={tagStyle} color="red">
                Inactive
              </Tag>
            )}
          </div>
          <div className="flex justify-center my-1 flew-row">
            <PeopleIcon />
            <div>{record.numOfLeads}</div>
          </div>
        </div>
      ),
      filters: filterStatus,
      filterMultiple: false,
      onFilter: (value, tuple) => tuple.status == value,
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: 'Requested',
      className: 'hidden sm:table-cell',
      align: 'center',
      dataIndex: ['requested', 'connected', 'replied'],
      render: (requested, tuple) => (
        <SingleStat param1={tuple.numOfLeads} param2={tuple.requested} />
      ),
      sorter: (a, b) =>
        calcPercent(a.numOfLeads, a.requested) -
        calcPercent(b.numOfLeads, b.requested),
    },
    {
      title: 'Connected',
      className: 'hidden sm:table-cell',
      align: 'center',
      dataIndex: ['requested', 'connected', 'replied'],
      render: (text, tuple) => (
        <SingleStat param1={tuple.requested} param2={tuple.connected} />
      ),
      sorter: (a, b) =>
        calcPercent(a.requested, a.connected) -
        calcPercent(b.requested, b.connected),
    },
    {
      title: 'Replied',
      className: 'hidden sm:table-cell',
      align: 'center',
      dataIndex: ['requested', 'connected', 'replied'],
      render: (text, tuple) => (
        <SingleStat param1={tuple.connected} param2={tuple.replied} />
      ),
      sorter: (a, b) =>
        calcPercent(a.connected, a.replied) -
        calcPercent(b.connected, b.replied),
    },
  ];

  const calcPercent = (a, b) => {
    const percent = a !== 0 ? (b / a) * 100 : NaN;
    return isNaN(percent) ? 0 : Math.round(percent);
  };

  const getCSVdata = () =>
    tablesData.map((obj) => [
      obj.name,
      obj.type,
      obj.status,
      obj['user.email'],
      obj['linkedInAccount.email'],
      obj['linkedInAccount.id'],
      obj.userId,
      obj['user.companyName'],
      obj.numOfLeads,
      obj.requested,
      obj.connected,
      obj.replied,
      dayjs(obj.createdAt).format('DD/MM/YYYY HH:ss'),
    ]);

  return (
    <Table
      rowKey={(record) => record.id}
      columns={columns}
      dataSource={tablesData}
      pagination={{
        defaultCurrent: 1,
        defaultPageSize: 10,
        position: ['bottomRight'],
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50],
        className: 'p-r-4',
      }}
      title={() => (
        <div className="flex flex-row items-center justify-between">
          <TableTitle
            isFilterActive={isFilterActive}
            handleChange={handleChange}
          >
            {HEADER_SEARCH_LABEL}
          </TableTitle>
          <div className="text-right">
            <Input
              allowClear
              className="text-right"
              value={inputValue}
              placeholder="Search"
              autoFocus
              style={{ width: 300 }}
              onChange={(e) => handleChange(e.target.value)}
            />
          </div>
        </div>
      )}
      footer={() => (
        <Button
          type="link"
          onClick={() =>
            downloadCSV(
              getCSVdata(),
              csvHeaderFields,
              `outreach_campaigns_export_${dayjs().format('DD/MM/YYYY')}`
            )
          }
          className="text-secondary hover:text-secondary-dark active:text-secondary-light"
        >
          Export CSV file
        </Button>
      )}
    />
  );
};

OutreachTable.propTypes = {
  /** Array containing the campaigns */
  campaigns: PropTypes.array.isRequired,
};
