import PropTypes from 'prop-types';
export const CardList = ({ data }) => {
  return (
    <div className="flex flex-col w-full px-1 mb-2">
      {data.length ? (
        data.map((i, index) => (
          <div key={index} className="my-0.5 text-gray-500">
            {i}
          </div>
        ))
      ) : (
        <div className="my-0.5 text-gray-400">No accounts used</div>
      )}
    </div>
  );
};

CardList.propTypes = {
  data: PropTypes.array,
};
