export function CheckIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M20.61 5.2a1 1 0 0 1 .18 1.41l-10 13a1 1 0 0 1-1.5.1l-5-5a1 1 0 0 1 1.42-1.42l4.2 4.2 9.3-12.1a1 1 0 0 1 1.4-.18Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
