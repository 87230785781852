import routes from 'routes';

import { CreateCampaign } from './CreateCampaign';

const CreateScreen = () => <CreateCampaign />;

export default {
  routeProps: {
    path: routes.createCampaign,
    component: CreateScreen,
  },
  name: 'createaa',
  routeVisibility: 'private',
};
