import routes from 'routes';

import { Dashboard } from './Dashboard';

const DashboardScreen = () => <Dashboard />;

export default {
  routeProps: {
    path: routes.dashboard,
    component: DashboardScreen,
  },
  name: 'login',
  routeVisibility: 'private',
};
