import { InboxOutlined } from '@ant-design/icons';
import { Form, Input, Slider } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { CREATE_CAMPAIGN } from 'constant/campaignForm';
import { marks } from 'constant/formComponents';
import FormSection from 'modules/Campaign/components/Form/FormSection';
import { MessageFormItem } from 'modules/Campaign/components/Form/MessageFormItem';
import TooltipCSVDownload from 'modules/Campaign/components/Form/TooltipCSVDownload';
import { useState } from 'react';
import { getDetails, isCSV } from 'services/campaignType';
import { parseAndValidateLeadsFromCsv } from 'utils/helpers/csv';

export const LinkedinCampaignSetup = () => {
  const form = Form.useFormInstance();
  const camType = form.getFieldValue('type');
  const existingfileList = form.getFieldValue('file')?.fileList;

  const [fileList, setFileList] = useState(
    existingfileList ? existingfileList : []
  );

  return (
    <div className="fade-in grid">
      {isCSV(camType) ? (
        <FormSection
          title={CREATE_CAMPAIGN.csv.title}
          subTitle={CREATE_CAMPAIGN.csv.subTitle}
          titleChildren={<TooltipCSVDownload isLinkedin />}
        >
          <Form.Item
            name="file"
            rules={[
              {
                required: true,
                message: 'CSV file is required',
              },
              {
                validator: async (_, val) => {
                  if (!val) return;
                  const { file } = val;
                  setFileList([]);
                  try {
                    const parsedLeads = await parseAndValidateLeadsFromCsv(
                      file
                    );
                    form.setFieldsValue({ parsedLeads });
                    // Validation passed, set file to state and value to form
                    setFileList([file]);
                    // Extra function for the edit form
                    return Promise.resolve();
                  } catch (error) {
                    return Promise.reject(error);
                  }
                },
              },
            ]}
          >
            <Dragger
              accept=".csv"
              maxCount={1}
              beforeUpload={() => false}
              fileList={fileList}
              showUploadList={{ showRemoveIcon: false }}
            >
              <div className="flex justify-center text-gray-500 align-middle">
                <InboxOutlined className="mx-2 text-2xl text-yellow-400" />
                Click or drag to upload a file
              </div>
            </Dragger>
          </Form.Item>
          {/* This extra form item is used to avoid prop drilling */}
          <Form.Item name="parsedLeads" initialValue={[]} hidden>
            <Input />
          </Form.Item>
        </FormSection>
      ) : (
        <FormSection
          title={getDetails(camType, 'title')}
          subTitle={getDetails(camType, 'subTitle')}
        >
          <Form.Item
            name="campaignLink"
            rules={[
              {
                required: true,
                message: getDetails(camType, 'rule'),
              },
              {
                validator: (_, url) => {
                  if (
                    !url ||
                    url.startsWith(getDetails(camType, 'urlToCheck'))
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(getDetails(camType, 'error'));
                },
              },
            ]}
          >
            <Input placeholder="Url" />
          </Form.Item>
        </FormSection>
      )}

      <FormSection title={CREATE_CAMPAIGN.range.title} subTitle={CREATE_CAMPAIGN.range.subTitle}>
        <Form.Item name="invites" initialValue={[6, 8]}>
          <Slider
            range={true}
            step={1}
            getAriaLabel={() => 'Invites per day'}
            min={1}
            max={20}
            trackStyle={{ backgroundColor: '#D7A600' }}
            handleStyle={{ backgroundColor: '#D7A600' }}
            valueLabelDisplay="auto"
            getAriaValueText={(value) => `${value} invites`}
            minimumTrackTintColor="blue"
            maximumTrackTintColor="red"
            disableSwap
            marks={marks}
          />
        </Form.Item>
      </FormSection>

      <FormSection
        title={CREATE_CAMPAIGN.message.title}
        subTitle={CREATE_CAMPAIGN.message.subTitle}
      >
        <MessageFormItem
          name="initialMessage"
          title="Initial contact message"
          maxLength={300}
          showCount
        />
      </FormSection>
    </div>
  );
};
